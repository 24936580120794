import React, { useState } from "react";
import { OptiweCard } from "components/OptiweCard";
import { Link, useLocation } from "react-router-dom";
import { Button, Form, Input, message, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import api from "requests/api";
import axios, { AxiosError } from "axios";
import TimezoneSelect, { ITimezone, ITimezoneOption } from 'react-timezone-select'
import { SmileTwoTone } from "@ant-design/icons";


interface LocationState {
    adminEmail: string
}


enum Languages {
    English = "ENGLISH",
    Spanish = "SPANISH"
}

interface FormFields {
    workspaceName: string,
    language: Languages,
}
enum UIStates {
    Init,
    Loading,
    Successful,
}


export default function CreateWorkspace() {
    const { t } = useTranslation();
    const location = useLocation<LocationState>();
    const [form] = Form.useForm();

    // @ts-ignore
    const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>({});
    const [uiState, setUiState] = useState(UIStates.Init);



    async function handleFormSubmit({ workspaceName, language }: FormFields) {
        setUiState(UIStates.Loading)
        try {
            const timezoneOffset = (selectedTimezone as ITimezoneOption).offset
            await api.workspaces().createWorkspaceV2(workspaceName, location.state.adminEmail, language, timezoneOffset)
        } catch (err: any | AxiosError) {
            setUiState(UIStates.Init)
            if (!axios.isAxiosError(err)) throw err
            if (axios.isAxiosError(err)) {
                const statusCode = err.response?.status
                if (statusCode === 400) {
                    message.error(t('signUp.errors.workspaceAlreadyExists'))
                    return
                }
                message.error(t('unknownError'))
            }
            return
        }
        setUiState(UIStates.Successful)
    }


    function renderUiStateInit() {
        return (
            <Form
                form={form}
                onFinish={handleFormSubmit}
                layout="vertical"
                requiredMark="optional"
            >
                <Form.Item label={t('signUp.workspaceName')} name="workspaceName" rules={[{ required: true, message: t("required") }]}>
                    <Input size="large" placeholder="Workspace name" />
                </Form.Item>

                <Form.Item name="language"
                    label={t('signUp.language')}
                    rules={[{ required: true, message: t("required") }]}
                >
                    <Select
                        placeholder={t('signUp.selectLanguage')}
                    >
                        <Select.Option value={Languages.English}>{t('signUp.enLanguage')}</Select.Option>
                        <Select.Option value={Languages.Spanish}>{t('signUp.esLanguage')}</Select.Option>
                    </Select>
                </Form.Item>

                <span>{t('signUp.timezone')}</span>
                <TimezoneSelect
                    // @ts-ignore
                    value={selectedTimezone}
                    onChange={setSelectedTimezone}
                    placeholder={t('signUp.selectTimezone')}
                />

                <Button
                    className="opt-button"
                    type="primary"
                    htmlType="submit"
                >
                    {t('submit')}
                </Button>
            </Form>
        )
    }

    function renderUiStateLoading() {
        return <Spin size="large" style={{ display: 'flex', justifyContent: 'center', marginTop: '30%' }} />
    }

    function renderUiStateSuccess() {
        return <>
            <div style={{ textAlign: 'center' }}>
                <SmileTwoTone style={{ fontSize: '4rem' }} />
                <h1 className="signup__subtitle">{t("requestSent")}</h1>
                <p>{t("signUp.thankYouForCreatingAnAccount")}</p>
                <button className="opt-button opt-button--light" >
                    <Link to="/">{t("back")}</Link>
                </button>
            </div>
        </>
    }
    function renderState(): JSX.Element {
        switch (uiState) {
            case UIStates.Init:
                return renderUiStateInit()
            case UIStates.Loading:
                return renderUiStateLoading()
            case UIStates.Successful:
                return renderUiStateSuccess()
            default:
                throw Error('Not implemented')
        }
    }


    return (
        <div
            className="signup"
            style={{ height: '100%' }}
        >
            <OptiweCard title={t('signUp.createWorkspace')}>
                {renderState()}
            </OptiweCard>
        </div>
    )
}