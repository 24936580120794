import React from "react";
import RouteErrorCatcher from "../../components/ErrorBoundary/RouteErrorCatcher";
import CreateUser from "./CreateUser";
import "./Signup.scss";
function Signup() {
    return (
        <main className="signup">
            <RouteErrorCatcher exact path="/signup" component={CreateUser} />
        </main>
    );
}
export default Signup;
