import moment, { Moment } from "moment";

export function momentToYYMMDD(momentObj: Moment): string {
    let baseDate = momentObj;
    if (typeof momentObj === "string") {
        baseDate = moment(momentObj);
    }
    let dateObj = baseDate.toDate();
    let year = dateObj.getUTCFullYear();
    let month = dateObj.getUTCMonth() + 1;
    let day = dateObj.getUTCDate();
    return year + "-" + month + "-" + day;
}
