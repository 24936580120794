import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Popover, Select, Switch, notification } from "antd";
import ChannelCharWarnings from "components/ChannelCharWarnings/ChannelCharWarnings";
import LoadMore from "components/LoadMore/LoadMore";
import Loader from "components/Loader/Loader";
import useWindowSize from "hooks/useWindowSize";
import questionExample from "img/chat-example.png";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import api from "requests/api";
import "./KnowledgeForm.scss";
import ListChannelSizes from "./ListChannelSizes";

function getInitialKnowledgeState() {
    return {
        answer: {
            text: "",
        },
        title: "",
        category: {
            name: "",
        },
        keywords: [],
        questions: [],
        tags: [],
        usersId: [],
        forAllUsers: true,
        baseChatbotsId: [],
        forAllChatbots: true
    };
}

const { Panel } = Collapse;

function KnowledgeForm() {
    const location = useLocation();
    const history = useHistory();
    const size = useWindowSize();
    const [tooltipPlacement, setTooltipPlacement] = useState("top");
    const [isLoading, setIsLoading] = useState(false);
    const [newQuestion, setNewQuestion] = useState("");
    const [tags, setTags] = useState([]);
    const [files, setFiles] = useState([]);
    const [filePage, setFilePage] = useState(0);
    const [fileHasNext, setFileHasNext] = useState(false);
    const [knowledge, setKnowledge] = useState(getInitialKnowledgeState());
    const [users, setUsers] = useState([]);
    const [usersPaginatorPage, setUsersPaginatorPage] = useState(0);
    const [usersPaginatorHasNext, setUsersPaginatorHasNext] = useState(false);
    const [chatbots, setChatbots] = useState([]);
    const { t } = useTranslation();

    function handleKnowledgeChange(evt) {
        const value = evt.target.value;
        setKnowledge({
            ...knowledge,
            [evt.target.name]: value,
        });
    }

    function handleQuestionChange(evt) {
        const value = evt.target.value;
        const index = evt.target.name;
        const newState = { ...knowledge };
        newState.questions[index].text = value;
        setKnowledge(newState);
    }

    function handleAnswerChange(evt) {
        const value = evt.target.value;
        const newState = { ...knowledge };
        newState.answer = { ...newState.answer, [evt.target.name]: value };
        setKnowledge(newState);
    }

    function handleNewQuestion(evt) {
        const value = evt.target.value;
        setNewQuestion(value);
    }

    function addNewQuestion() {
        if (newQuestion) {
            const newState = { ...knowledge };
            // todo check edit view
            if (location.state && location.state.edit) {
                api.knowledge()
                    .addNewWayOfAsk(knowledge.id, { text: newQuestion })
                    .then((response) => {
                        if (!newState.questions) {
                            newState.questions = [];
                        }
                        newState.questions.push({ text: newQuestion, id: response.data.id });
                        setKnowledge(newState);
                        setNewQuestion("");
                    });
            } else {
                if (!newState.questions) {
                    newState.questions = [];
                }
                newState.questions.push({ text: newQuestion });
                setKnowledge(newState);
                setNewQuestion("");
            }
        }
    }

    function sendForm(event) {
        event.preventDefault();
        event.stopPropagation();
        if (event.keyCode === 13) {
            return false;
        }
        if (knowledge.questions.length > 0) {
            knowledge.category = { name: "general" };

            if (location.state && location.state.edit) {
                setIsLoading(true);
                api.knowledge()
                    .edit(knowledge)
                    .then((response) => {
                        setIsLoading(false);
                        history.push("/dashboard/knowledge");
                    })
                    .catch(() => setIsLoading(false));
            } else {
                api.knowledge()
                    .create(knowledge)
                    .then((response) => {
                        setIsLoading(false);
                        history.push("/dashboard/knowledge");
                    })
                    .catch(() => setIsLoading(false));
            }
        } else {
            notification.error({
                message: t("knowledgeForm.errorAddQuestionTitle"),
                description: t("knowledgeForm.errorAddQuestionDescription"),
                duration: 4,
            });
        }
    }

    function deleteQuestion(question, index) {
        if (location.state) {
            if (knowledge.questions.length > 1) {
                const knowledgeId = location.state.edit;
                api.knowledge()
                    .removeWayOfAsk(knowledgeId, question.id)
                    .then((response) => {
                        const newState = { ...knowledge };
                        newState.questions = newState.questions.filter(
                            (item) => item.id !== question.id
                        );
                        setKnowledge(newState);
                    });
            } else {
                notification.error({
                    message: t("knowledgeForm.errorRemoveQuestionTitle"),
                    description: t("knowledgeForm.errorRemoveQuestionDescription"),
                    duration: 4,
                });
            }
        } else {
            // New knowledge form
            const newState = { ...knowledge };
            newState.questions = newState.questions.filter((item, i) => i !== index);
            setKnowledge(newState);
        }
    }

    function preventReload(e) {
        e.preventDefault();
        return false;
    }

    function handleTagSelectChange(tags_id) {
        const newState = { ...knowledge };
        newState.tags = tags_id.map((tag_id) => {
            return { id: tag_id };
        });
        setKnowledge(newState);
    }

    function handleFiles(files) {
        const newState = { ...knowledge };
        newState.filesMetadata = files.map((file_id) => {
            return { id: file_id };
        });
        setKnowledge(newState);
    }

    function handleFilesViewMorel() {
        if (fileHasNext) {
            api.files()
                .list(filePage + 1)
                .then((res) => {
                    setFiles((files) => [...files, ...res.data.filesMetadata]);
                    setFileHasNext(res.data.paginator.hasNext);
                    setIsLoading(false);
                });
            setFilePage((filePage) => filePage + 1);
        }
    }

    function handleUsersChange(usersId) {
        const newState = { ...knowledge };
        newState.usersId = usersId
        setKnowledge(newState);
    }

    function handleForAllUsersChange(checked) {
        const newState = { ...knowledge };
        newState.forAllUsers = checked
        if (checked) {
            newState.usersId = []
        }
        setKnowledge(newState);
    }

    function handleChatbotsChange(baseChatbotsId) {
        const newState = { ...knowledge };
        newState.baseChatbotsId = baseChatbotsId
        setKnowledge(newState);
    }

    function handleForAllChatbotsChange(checked) {
        const newState = { ...knowledge };
        newState.forAllChatbots = checked
        if (checked) {
            newState.baseChatbotsId = []
        }
        setKnowledge(newState);
    }

    useEffect(() => {
        setIsLoading(true);
        let promises = [];
        let tagsPromise = api
            .knowledge()
            .getTags()
            .then((response) => {
                let tags = response.data.tags;
                setTags(tags);
            });

        let filesPromise = api
            .files()
            .list(filePage)
            .then((res) => {
                setFiles(res.data.filesMetadata);
                setFileHasNext(res.data.paginator.hasNext);
            });

        let usersPromise = api
            .users()
            .list(usersPaginatorPage, 32)
            .then((res) => {
                const newUsers = [...users, ...res.data.users];
                setUsers(newUsers);
                setUsersPaginatorHasNext(res.data.paginator.hasNext);
            });

        let chatbotsPromise = api
            .chatbots()
            .list()
            .then((res) => {
                setChatbots(res.data.chatBots);
            });

        promises.push(tagsPromise, filesPromise, usersPromise, chatbotsPromise);
        if (location.state && location.state.edit) {
            setIsLoading(true);
            const knowledgeId = location.state.edit;
            let knowledgePromise = api
                .knowledge()
                .get(knowledgeId)
                .then((response) => {
                    if (!response.data.title) {
                        response.data.title = response.data.questions[0].text;
                    }
                    if (!response.data.category) {
                        response.data.category = {
                            name: "",
                        };
                    }
                    setKnowledge(response.data);
                });
            promises.push(knowledgePromise);
        }

        Promise.all(promises).then(() => {
            setIsLoading(false);
        });

        return function cleanup() {
            setKnowledge(getInitialKnowledgeState());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (size.width > 1280) {
            setTooltipPlacement("rightTop");
        } else {
            setTooltipPlacement("bottom");
        }
    }, [size.width]);

    useEffect(() => {
        if (usersPaginatorPage === 0) {
            return
        }

        setIsLoading(true);
        api.users()
            .list(usersPaginatorPage, 32)
            .then((res) => {
                const newUsers = [...users, ...res.data.users];
                setUsers(newUsers);
                setUsersPaginatorHasNext(res.data.paginator.hasNext);
                setIsLoading(false);
            });
    }, [usersPaginatorPage]);


    return (
        <div className="opt-view opt-view--knowledge opt-view--form">
            <Loader isLoading={isLoading} />
            <div className="knowledge-header">
                <h1 className="opt-title">
                    <NavLink to="/dashboard/knowledge">← {t("knowledgeForm.goBack")}</NavLink>
                </h1>
                {location.state && location.state.edit ? (
                    <input
                        type="button"
                        onClick={sendForm}
                        className="opt-button"
                        value={t("knowledgeForm.editAnswer")}
                    />
                ) : (
                    <input
                        type="button"
                        onClick={sendForm}
                        className="opt-button"
                        value={t("knowledgeForm.createNewAnswer")}
                    />
                )}
            </div>

            <form onSubmit={preventReload}>
                <div className="card-wrapper">
                    <div className="basic-info">
                        <h2 className="opt-subtitle">{t("knowledgeForm.basicInfo")}</h2>
                        <div className="opt-card opt-card--form knowledge-card-info">
                            <div className="opt-form-group">
                                <label className="opt-label-title">
                                    {t("knowledgeForm.title")}
                                </label>
                                <Popover
                                    trigger="focus"
                                    placement={tooltipPlacement}
                                    content={
                                        <div className="opt-popover-wrapper-content">
                                            <span className="opt-popover-text opt-form-text--grey">
                                                {t("knowledgeForm.knowledgeTitleDescription")}
                                            </span>
                                        </div>
                                    }
                                >
                                    <input
                                        type="text"
                                        className="opt-text-input"
                                        placeholder={t("knowledgeForm.knowledgeTitle")}
                                        name="title"
                                        value={knowledge.title}
                                        onChange={handleKnowledgeChange}
                                        required
                                    />
                                </Popover>
                            </div>
                            <div className="opt-form-group">
                                <label className="opt-label-title">
                                    {t("knowledgeForm.question")}
                                </label>
                                <span className="opt-form-text opt-form-text--grey">
                                    {t("knowledgeForm.questionDescription")}
                                </span>
                                <div className="opt-knowledge-questions">
                                    <div className="opt-form-group">
                                        <Popover
                                            trigger="focus"
                                            placement={tooltipPlacement}
                                            content={
                                                <div className="opt-popover-wrapper-content opt-popover--question">
                                                    <span className="opt-popover-text opt-form-text--grey">
                                                        {t("knowledgeForm.questionPopOver")}
                                                    </span>
                                                    <div className="opt-popover-img-wrapper">
                                                        <img
                                                            src={questionExample}
                                                            className="opt-popover-example-img"
                                                            alt="example of a question"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <div className="opt-input-button">
                                                <input
                                                    type="text"
                                                    className="opt-text-input"
                                                    placeholder={t("knowledgeForm.newQuestion")}
                                                    name="newQuestion"
                                                    onKeyDown={(k) =>
                                                        k.keyCode === 13 && addNewQuestion()
                                                    }
                                                    value={newQuestion}
                                                    onChange={handleNewQuestion}
                                                />
                                                <button
                                                    type="button"
                                                    onClick={addNewQuestion}
                                                    className="opt-button"
                                                >
                                                    {t("add")}
                                                </button>
                                            </div>
                                        </Popover>
                                    </div>
                                    <div className="opt-knowledge-questions-wrapper">
                                        {knowledge.questions &&
                                            knowledge.questions.map((question, index) => (
                                                <div
                                                    className="opt-form-group edit-knowledge"
                                                    key={index}
                                                >
                                                    <label>
                                                        {t("knowledgeForm.question")} {index + 1}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="opt-text-input opt-text-input--icon"
                                                        onChange={handleQuestionChange}
                                                        name={index}
                                                        value={question.text}
                                                    />
                                                    <div
                                                        className="opt-delete-button"
                                                        onClick={() =>
                                                            deleteQuestion(question, index)
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTrash}
                                                            className="delete-icon"
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            <div className="opt-form-group">
                                <label className="opt-label-title">
                                    {t("knowledgeForm.answer")}
                                </label>
                                <textarea
                                    className="opt-text-input opt-textarea"
                                    value={knowledge.answer.text}
                                    name="text"
                                    onChange={handleAnswerChange}
                                    placeholder={t("knowledgeForm.answerInputPlaceholder")}
                                    required
                                ></textarea>
                                <ChannelCharWarnings text={knowledge.answer.text} />
                            </div>
                        </div>
                    </div>
                    <div className="basic-info">
                        <h2 className="opt-subtitle">{t("knowledgeForm.classifyAnswer")}</h2>
                        <div className="opt-card opt-card--form">
                            <div className="opt-form-group">
                                <label className="opt-label-title">
                                    {t("knowledgeForm.addTags")}
                                </label>
                                <div className="opt-input-button">
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        placeholder={t("knowledgeForm.knowledgeTagsPlaceholder")}
                                        value={knowledge.tags.map((tag) => {
                                            return tag.id;
                                        })}
                                        onChange={handleTagSelectChange}
                                    >
                                        {tags.map((tag) => {
                                            return (
                                                <Select.Option key={tag.id} value={tag.id}>
                                                    {tag.value}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <h2 style={{ marginTop: "10px" }}>{t("knowledgeForm.filesTitle")}</h2>
                        <div className="opt-card opt-card--form">
                            <div className="opt-form-group">
                                <label className="opt-label-title">
                                    {t("knowledgeForm.filesTitle")}
                                </label>
                                <div className="opt-input-button">
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        placeholder={t("knowledgeForm.filesPlaceholder")}
                                        value={
                                            knowledge.filesMetadata &&
                                            knowledge.filesMetadata.map((file) => {
                                                return file.id;
                                            })
                                        }
                                        onChange={handleFiles}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {fileHasNext && (
                                                    <LoadMore
                                                        onClick={handleFilesViewMorel}
                                                        style={{
                                                            marginTop: "0px",
                                                            marginBottom: "15px",
                                                        }}
                                                    />
                                                )}
                                            </>
                                        )}
                                    >
                                        {files.map((file) => {
                                            return (
                                                <Select.Option key={file.id} value={file.id}>
                                                    {file.name}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <Collapse accordion>
                                        <Panel header={t("knowledgeForm.filesPopover")} key="1">
                                            <ListChannelSizes />
                                        </Panel>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                        <h2 style={{ marginTop: "10px" }}>{t("knowledgeForm.choseWhoCanUseThisAnswer")}</h2>
                        <div className="opt-card opt-card--form">
                            <div className="opt-form-group">
                                <label className="opt-label-title">
                                    {t("knowledgeForm.users")}
                                </label>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <span style={{ marginTop: "10px" }}>
                                            {t("knowledgeForm.forAllUsers")}
                                        </span>
                                        <Switch
                                            style={{ marginLeft: "10px", marginTop: "10px" }}
                                            checked={knowledge.forAllUsers}
                                            onChange={handleForAllUsersChange}
                                        />
                                    </div>
                                    <Select
                                        disabled={knowledge.forAllUsers}
                                        mode="multiple"
                                        style={{ width: "100%", marginTop: "10px" }}
                                        placeholder={t("knowledgeForm.selectUsersPlaceholder")}
                                        onChange={handleUsersChange}
                                        value={knowledge.usersId}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {usersPaginatorHasNext && (
                                                    <LoadMore
                                                        onClick={() => setUsersPaginatorPage((usersPaginatorPage) => usersPaginatorPage + 1)}
                                                        style={{
                                                            marginTop: "0px",
                                                            marginBottom: "15px",
                                                        }}
                                                    />
                                                )}
                                            </>
                                        )}
                                    >
                                        {users.map((user) => (
                                            <Select.Option key={user.id} value={user.id}>
                                                {user.email}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>

                        <h2 style={{ marginTop: "10px" }}>{t("knowledgeForm.choseWhatChatbotsCanUseThisAnswer")}</h2>
                        <div className="opt-card opt-card--form">
                            <div className="opt-form-group">
                                <label className="opt-label-title">
                                    {t("knowledgeForm.chatbots")}
                                </label>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <span style={{ marginTop: "10px" }}>
                                            {t("knowledgeForm.forAllChatbots")}
                                        </span>
                                        <Switch
                                            style={{ marginLeft: "10px", marginTop: "10px" }}
                                            checked={knowledge.forAllChatbots}
                                            onChange={handleForAllChatbotsChange}
                                        />
                                    </div>
                                    <Select
                                        disabled={knowledge.forAllChatbots}
                                        mode="multiple"
                                        style={{ width: "100%", marginTop: "10px" }}
                                        placeholder={t("knowledgeForm.selectChatbotsPlaceholder")}
                                        onChange={handleChatbotsChange}
                                        value={knowledge.baseChatbotsId}
                                    >
                                        {chatbots.map((chatbot) => (
                                            <Select.Option key={chatbot.id} value={chatbot.id}>
                                                {chatbot.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    );
}
export default KnowledgeForm;
