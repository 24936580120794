import React, { Dispatch, SetStateAction, useState, useContext, useEffect } from "react";
import { Row, Col, Input, Button, Divider, message } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import api from "requests/api";
import { AppContext } from "components/AppContext";
import axios, { AxiosError } from "axios";
import { OptiweCard } from "components/OptiweCard";
import FirebaseAuth from "views/Signup/FirebaseAuth";

export default function SignIn() {
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    //@ts-ignore
    const { state, actions } = useContext(AppContext);
    const history = useHistory();

    const [email, setEmail] = useState<string | undefined>();
    const [password, setPassword] = useState<string | undefined>();

    const [recoverPasswordView, setRecoverPasswordView] = useState(false);
    const [recoveryEmail, setRecoveryEmail] = useState<string | undefined>();

    const userLangIsSpanish = navigator.language === "es" || navigator.language.includes("es-");

    useEffect(() => {
        if (userLangIsSpanish) {
            i18n.changeLanguage("es");
        } else {
            i18n.changeLanguage("en");
        }
    }, [i18n, userLangIsSpanish]);

    function handleChangeInput(
        e: React.ChangeEvent<HTMLInputElement>,
        setFunction: Dispatch<SetStateAction<string | undefined>>
    ) {
        const { value: inputValue } = e.target;
        setFunction(inputValue);
    }

    async function sendRecoverEmail() {
        if (!recoveryEmail) return;
        setIsLoading(true);
        try {
            await api.users().clearPassword(recoveryEmail);
            message.success(t("signIn.passwordRecover"));
            setRecoveryEmail(undefined);
            setRecoverPasswordView(false);
        } catch (err: any | AxiosError) {
            throw err;
        } finally {
            setIsLoading(false);
        }
    }

    async function onClickSignInButton() {
        if (!(email && password)) {
            return;
        }

        setIsLoading(true);

        try {
            const resLogin = await api.login().session_create({ email, password });
            const resWorkspace = await api.workspaces().get(resLogin.data.workspace.id);

            actions.setDemoState(false);
            actions.setUserData(resLogin.data);
            actions.setUserData(resLogin.data.workspace.id);
            actions.setIsLoggedIn(true);
            actions.setWorkspaceConfigs(resWorkspace.data.jsonConfigs);
            history.push("/workspaces");
        } catch (err: any | AxiosError) {
            if (!axios.isAxiosError(err)) {
                throw err;
            }

            const errorStatus = err.response?.status;
            const errorBody = err.response?.data.error;

            if (errorStatus === 400) {
                if (errorBody?.key === "EMAIL_LOGIN_DISABLED") {
                    message.error(t("signIn.emailLoginDisabled"), 10);
                } else {
                    message.error(t("signIn.userNotFound"));
                }
            } else if (errorStatus === 403) {
                message.error(t("signIn.workspaceDisabled"));
            } else if (errorStatus === 404 && errorBody?.key === "GLOBAL_USER_NO_WORKSPACE") {
                history.push("/createworkspace", { adminEmail: email });
            } else {
                message.error(t("unknownError"));
            }
        } finally {
            setIsLoading(false);
        }
    }

    async function onFirebaseAuth(accessToken: string) {
        try {
            var resUser = await api.login().signInFirebase(accessToken);
        } catch (err: any | AxiosError) {
            if (axios.isAxiosError(err)) {
                const statusCode = err.response?.status;
                if (statusCode === 403 || statusCode === 404) {
                    message.error(t("signIn.authFirebase.userNotFound"));
                } else {
                    message.error(t("signIn.authFirebase.unknownError"));
                }
            } else {
                throw err;
            }
            history.push("/");
            return;
        }
        try {
            var resWorkspace = await api.workspaces().get(resUser.data.workspace.id);
        } catch (err: any | AxiosError) {
            if (axios.isAxiosError(err)) {
                const statusCode = err.response?.status;
                const errorBody = err.response?.data.error;
                if (statusCode === 403) {
                    message.error(t("signIn.workspaceDisabled"));
                } else if (statusCode === 404 && errorBody?.key === "GLOBAL_USER_NO_WORKSPACE") {
                    history.push("/createworkspace", { adminEmail: errorBody?.email });
                } else {
                    message.error(t("signIn.authFirebase.unknownError"));
                }
            }
            history.push("/");
            return;
        }

        actions.setDemoState(false);
        actions.setUserData(resUser.data, resUser.data.workspace.id);
        actions.setIsLoggedIn(true);
        actions.setWorkspaceConfigs(resWorkspace.data.jsonConfigs);

        message.success(t("signIn.authFirebase.success"));
        history.push("/workspaces");
    }

    useEffect(() => {
        setSubmitDisabled(!(email && password));
    }, [email, password]);

    useEffect(() => {
        if (state.isLoggedIn) {
            history.push('dashboard/home/')
        }
    }, [state, history])


    return (
        <div className="signup" style={{ height: "100%" }}>
            <OptiweCard>
                {!recoverPasswordView ? (
                    <>
                        <span> Email</span>
                        <Input
                            value={email}
                            onChange={(e) => handleChangeInput(e, setEmail)}
                            style={{ borderRadius: "10px", fontSize: "120%", marginBottom: "10px" }}
                            onKeyUp={e => e.key === 'Enter' && onClickSignInButton()}
                        />

                        <span>{t("signUp.password")}</span>
                        <Input
                            type="password"
                            onChange={(e) => handleChangeInput(e, setPassword)}
                            style={{ borderRadius: "10px", fontSize: "120%" }}
                            onKeyUp={e => e.key === 'Enter' && onClickSignInButton()}
                        />
                        <Button
                            className="opt-button"
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                            disabled={submitDisabled}
                            onClick={onClickSignInButton}
                        >
                            {t("signIn.signIn")}
                        </Button>
                        <Divider style={{ color: "#B4B4B4" }}>{t("signIn.or")}</Divider>
                        <FirebaseAuth onAuthCb={onFirebaseAuth} />
                        <div style={{ marginTop: "50px" }}>
                            <Row style={{ color: "#EC725E" }}>
                                <Col span={8}>
                                    <small>
                                        <Link to="/signup" style={{ color: "#EC725E" }}>
                                            {" "}
                                            {t("signIn.dontHaveAnAccount")}{" "}
                                        </Link>
                                    </small>
                                </Col>
                                <Col span={8}></Col>
                                <Col span={8}>
                                    <small
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setRecoverPasswordView((r) => !r);
                                        }}
                                    >
                                        {t("signIn.forgotPassword")}
                                    </small>
                                </Col>
                            </Row>
                        </div>
                    </>
                ) : (
                    <>
                        <p>{t("signIn.emailToRecover")}</p>

                        <Input
                            style={{
                                width: "100%",
                                borderRadius: "10px",
                                fontSize: "120%",
                                marginBottom: "10px",
                            }}
                            value={recoveryEmail}
                            onChange={(e) => handleChangeInput(e, setRecoveryEmail)}
                        />
                        <Button
                            className="opt-button"
                            disabled={isLoading}
                            onClick={() => {
                                sendRecoverEmail();
                            }}
                        >
                            {t("signIn.sendRecoveryRequest")}
                        </Button>
                        <div style={{ marginTop: "170px" }}>
                            <Button onClick={() => setRecoverPasswordView(false)}>
                                {t("back")}
                            </Button>
                        </div>
                    </>
                )}
            </OptiweCard>
        </div>
    );
}
