import { Template, isTemplateDeniedReason } from "frontend-lib/DomainTypes/Template";
import React, { useCallback } from "react";
import { List, Typography } from "antd";
import { useTranslation } from "react-i18next";
import TitleTooltip from "components/TitleTooltip/TitleTooltip";
import WhatsappMessagePreview from "frontend-lib/Components/WhatsappMessagePreview/WhatsappMessagePreview";

const { Item } = List;
const { Text, Paragraph } = Typography;

interface TemplateItemProps {
    itemName: string;
    value: string | JSX.Element;
    tooltipContent?: string;
    style?: React.CSSProperties;
}
function TemplateItem({ itemName, value, tooltipContent, style }: TemplateItemProps) {
    const { t } = useTranslation();

    const renderValueString = (input: string) => {
        if (isTemplateDeniedReason(input)) {
            return <Paragraph>{t(`outbound.templateObj.deniedReasons.${input}`)}</Paragraph>;
        }
        return <Paragraph>{value}</Paragraph>;
    };
    return (
        <div style={style}>
            <Typography>
                <Text type="secondary">{itemName}</Text>
                {tooltipContent && <TitleTooltip content={tooltipContent} />}
                {typeof value === "string" ? renderValueString(value) : value}
            </Typography>
        </div>
    );
}

interface ShowTemplateProps {
    template: Template;
}

const statusTooltipContent = (
    tt: (s: string) => string,
    template: Template
): string | undefined => {
    switch (template.status) {
        case "APPROVED":
            return;
        case "IN_REVIEW":
            return tt("statusInReviewTooltip");
        case "DENIED":
            return;
        case "DISABLED":
            return tt("disabledTooltip");
        case "PAUSED":
            return tt("pausedTooltip");
        case "DELETED":
            return;
        case "DEACTIVATED":
            return;
    }
};

export default function ShowTemplate({ template }: ShowTemplateProps) {
    const buttons = template.buttons;
    const urlButtonText = buttons?.find((t) => t.type === "URL")?.text;
    const quickReplyButtonsText = buttons
        ?.filter((t) => t.type === "QUICK_REPLY")
        .map((b) => b.text);
    const phoneButtonText = buttons?.find((t) => t.type === "PHONE_NUMBER")?.text;
    const { t } = useTranslation();
    const tt = useCallback(
        (key: string) => {
            return t(`outbound.templateObj.${key}`);
        },
        [t]
    );

    return (
        <>
            <List>
                <List.Item>
                    <TemplateItem itemName={tt("name")} value={template.name} />
                </List.Item>
                <Item>
                    <TemplateItem itemName={tt("category")} value={template.category} />
                </Item>
                <Item>
                    <TemplateItem
                        itemName={tt("language")}
                        value={t(template.language.toLowerCase())}
                    />
                </Item>
                <Item>
                    <TemplateItem
                        itemName={tt("createdOn")}
                        value={new Date(template.createdOn).toLocaleString()}
                    />
                </Item>
                <Item>
                    <TemplateItem
                        itemName={tt("status")}
                        value={tt(`statusText.${template.status}`)}
                        tooltipContent={statusTooltipContent(tt, template)}
                    />
                </Item>
                {template.status === "DENIED" && (
                    <Item>
                        <TemplateItem
                            itemName={tt("deniedReason")}
                            value={template.deniedReason}
                            tooltipContent={tt("deniedReasonTooltip")}
                        />
                    </Item>
                )}
                <Item>
                    <TemplateItem
                        style={{ width: "100%" }}
                        itemName={tt("preview")}
                        value={
                            <WhatsappMessagePreview
                                values={{
                                    templateBodyExample: template.text,
                                    urlButtonText: urlButtonText,
                                    quickReplyButtons: quickReplyButtonsText,
                                    phoneNumberButtonText: phoneButtonText,
                                    footer: template.footer,
                                }}
                                fileUrl={template.file?.url}
                            />
                        }
                    />
                </Item>
            </List>
        </>
    );
}
