import React from "react";
import { i18next } from "../../../../../../lang/i18n";
class TotalInteractionsItem {
    constructor(name = null) {
        this.name = name;
        this.hour = null;
        this.totalInteractions = null;
        this.formatedHour = null;
    }
    setValues(hour, totalInteractions) {
        this.hour = hour;
        this.totalInteractions = totalInteractions;
        this.formatedHour = this.getFormatted(this.hour);
    }
    getFormatted(hour) {
        return hour;
    }
}
const totalInteractionsChartTexts = [
    {
        id: 1,
        name: i18next.t("reportTotalInteractions.total"),
        label: i18next.t("reportTotalInteractions.total"),
        description: i18next.t("reportTotalInteractions.total"),
        color: "#5B8FF9",
    },
    {
        id: 2,
        name: i18next.t("reportTotalInteractions.bot"),
        label: i18next.t("reportTotalInteractions.bot"),
        description: i18next.t("reportTotalInteractions.bot"),
        color: "#83E1BD",
    },
    {
        id: 3,
        name: i18next.t("reportTotalInteractions.agent"),
        label: i18next.t("reportTotalInteractions.agent"),
        description: i18next.t("reportTotalInteractions.agentDesc"),
        color: "#5D7092",
    },
    {
        id: 4,
        name: i18next.t("reportTotalInteractions.user"),
        label: i18next.t("reportTotalInteractions.user"),
        description: i18next.t("reportTotalInteractions.userDesc"),
        color: "#F8CD50",
    },
    {
        id: 5,
        name: i18next.t("reportTotalInteractions.userToBot"),
        label: i18next.t("reportTotalInteractions.userToBot"),
        description: i18next.t("reportTotalInteractions.userToBotDesc"),
        color: "#7666F9",
    },
    {
        id: 6,
        name: i18next.t("reportTotalInteractions.userToAgent"),
        label: i18next.t("reportTotalInteractions.userToAgent"),
        description: i18next.t("reportTotalInteractions.userToAgentDesc"),
        color: "#74CBED",
    },
];
const totalInteractionsConfig = {
    height: 300,
    xField: "formatedHour",
    yField: "totalInteractions",
    isGroup: true,
    seriesField: "name",
    legend: {
        position: "top-left",
    },
    tooltip: {
        formatter: (data) => {
            let visibleData = {};
            visibleData.name = totalInteractionsChartTexts.find(
                (item) => item.name === data.name
            ).label;
            visibleData.value = data.totalInteractions;
            return visibleData;
        },
        showMarkers: false,
    },
    color: (data) => {
        return totalInteractionsChartTexts.find((item) => item.name === data.name).color;
    },
};

const totalInteractionsModal = (
    <ul>
        {totalInteractionsChartTexts.map((item) => (
            <li>
                <h3>
                    <span
                        style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            marginRight: "5px",
                            background: item.color,
                        }}
                    ></span>
                    {item.name}
                </h3>
                <p>{item.description}</p>
            </li>
        ))}
    </ul>
);

export {
    TotalInteractionsItem,
    totalInteractionsChartTexts,
    totalInteractionsConfig,
    totalInteractionsModal,
};
