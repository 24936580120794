import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import esES from "antd/lib/locale/es_ES";
import enUS from "antd/lib/locale/en_US";
import "moment/locale/es";
import { ConfigProvider } from "antd";
import { AppContext } from "./AppContext";
import i18n from "i18next";
import Loader from "./Loader/Loader";
import api from "../requests/api";

function ConfigWrapper({ children }) {
    const [antdLang, setAntdLang] = useState(enUS);
    const appContext = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                if (error.response?.status === 403) {
                    appContext.actions.setIsLoggedIn(false);
                }
                return Promise.reject(error);
            }
        );

        api.workspaces()
            .get(appContext.state.workspaceId)
            .then((response) => {
                const configs = JSON.parse(response.data.configs);
                appContext.actions.setWorkspaceConfigs(response.data.jsonConfigs);
                appContext.actions.setWorkspaceName(response.data.name);
                if (configs.language === "SPANISH") {
                    i18n.changeLanguage("es");
                    moment.locale("es");
                    setAntdLang(esES);
                    setIsLoading(false);
                }
                if (configs.language === "ENGLISH") {
                    i18n.changeLanguage("en");
                    setAntdLang(enUS);
                    setIsLoading(false);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ConfigProvider locale={antdLang}>
            <Loader isLoading={isLoading} />
            {children}
        </ConfigProvider>
    );
}

export default ConfigWrapper;
