import { Intention } from "frontend-lib/DomainTypes/Intentions";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../requests/api";

export enum errorKeys {
    INTENTION_NAME_ERROR = "INTENTION_NAME_ERROR",
    INTENTION_DESCRIPTION_ERROR = "INTENTION_DESCRIPTION_ERROR",
    INTENTION_EXAMPLES_ERROR = "INTENTION_EXAMPLES_ERROR",
    XML_SYNTAX_ERROR = "XML_SYNTAX_ERROR",
    XML_UNKNOWN_ERROR = "XML_UNKNOWN_ERROR",
    INTENTION_ALREADY_EXISTS = "INTENTION_ALREADY_EXISTS",
    INTENTION_CREATION_ERROR = "INTENTION_CREATION_ERROR",
    INTENTION_UPDATE_ERROR = "INTENTION_UPDATE_ERROR",
    INTENTION_DELETE_ERROR = "INTENTION_DELETE_ERROR",
}

export default function useIntentions(
    setIsLoading: (isLoading: boolean) => void,
): [
    Intention[],
    (intention: Intention) => void,
    (intention: Intention) => void,
    (xml: string) => Promise<boolean>,
    (intendionId: number) => void,
    errorKeys | undefined,
        (baseChatbotId?: number, searchKeywords?: string[]) => void
] {
    const history = useHistory();
    const [intentions, setIntentions] = useState<Intention[]>([]);

    const [errorKey, setErrorKey] = useState<errorKeys | undefined>();

    const loadIntentions = useCallback(async (baseChatbotId?: number, searchKeywords?: string[]) => {
        const res = await api.chatbots().intentionsList(baseChatbotId, searchKeywords);
        setIntentions((intentions) => [...res.data.intentions]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function createIntention(intention: Intention) {
        setIsLoading(true);
        api.chatbots()
            .intentionCreate(
                intention.name,
                buildIntentionKey(intention.name),
                intention.examples,
                intention.description,
                intention.xml,
                intention.forAllChatbots,
                intention.baseChatbotsId
            )
            .then((response) => {
                setIsLoading(false);
                history.push("/dashboard/intentions");
                setErrorKey(undefined);
                setIntentions((intentions) => [...intentions, intention]);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error.response.data.error.key);
                if (error.response.data.error.key === errorKeys.INTENTION_ALREADY_EXISTS) {
                    setErrorKey(errorKeys.INTENTION_ALREADY_EXISTS);
                } else {
                    setErrorKey(errorKeys.INTENTION_CREATION_ERROR);
                }
            });
    }

    function buildIntentionKey(input: string): string {
        const cleanString = input.toLowerCase().replace(/[^\w\s]/g, "");
        const underscoreCleanString = cleanString.replace(/\s+/g, "_");

        return underscoreCleanString;
    }

    function updateIntention(intention: Intention) {
        setIsLoading(false);
        api.chatbots()
            .intentionUpdate(
                intention.id,
                intention.name,
                buildIntentionKey(intention.name),
                intention.examples,
                intention.description,
                intention.xml,
                intention.forAllChatbots,
                intention.baseChatbotsId
            )
            .then((response) => {
                setIsLoading(false);
                history.push("/dashboard/intentions");
                setErrorKey(undefined);
                setIntentions((intentions) => [...intentions, intention]);
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response.data.error.key === errorKeys.INTENTION_ALREADY_EXISTS) {
                    setErrorKey(errorKeys.INTENTION_ALREADY_EXISTS);
                } else {
                    setErrorKey(errorKeys.INTENTION_UPDATE_ERROR);
                }
            });
    }

    async function validateXML(xml: string): Promise<boolean> {
        setIsLoading(true);
        try {
            await api.chatbots().xmlValidation(xml);
            setErrorKey(undefined);
            setIsLoading(false);
            return true;
        } catch (error: any) {
            setIsLoading(false);
            setErrorKey(errorKeys[error.response.data.error.key as keyof typeof errorKeys]);
            return false;
        }
    }

    function deleteIntention(intentionId: number) {
        setIsLoading(true);
        api.chatbots()
            .intentionDelete(intentionId)
            .then((reponse) => {
                setIsLoading(false);
                setErrorKey(undefined);
            })
            .catch((error) => {
                setIsLoading(false);
                setErrorKey(errorKeys.INTENTION_DELETE_ERROR);
            });
        setIntentions((intentions) => intentions.filter((i) => i.id !== intentionId));
    }

    return [
        intentions,
        createIntention,
        updateIntention,
        validateXML,
        deleteIntention,
        errorKey,
        loadIntentions
    ];
}
