import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { AppProvider } from "./components/AppContext";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import "./lang/i18n";

ReactDOM.render(
    <Router>
        <AppProvider>
            <App />
            <NotificationContainer />
        </AppProvider>
    </Router>,
    document.getElementById("optiwe_dash")
);

// Service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister();
