import { i18next } from "../../../../lang/i18n";
const UISTATES = {
    LOADING: "LOADING",
    EMPTY: "EMPTY",
    FINISHED: "FINISHED",
};
const FORMATS = {
    DATE: "M/D/YYYY",
    WEEK: "MM/DD",
    MONTH: "MM/YYYY",
    YEAR: "YYYY",
};
function TranslatedTabList() {
    const tabList = [
        { key: "general", tab: i18next.t("reports.general") },
        { key: "agents", tab: i18next.t("reports.agents") },
        { key: "tags", tab: i18next.t("reports.knowledgeTags") },
        { key: "answers", tab: i18next.t("reports.answers") },
        { key: "labels", tab: i18next.t("reports.conversationTags") },
    ];
    return tabList;
}
export { UISTATES, FORMATS, TranslatedTabList };
