import React from "react";
import "./EmptyState.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function EmptyState(props) {
    return (
        <div className="opt-empty-state">
            <FontAwesomeIcon icon={props.icon} />
            <p className="opt-app__text">{props.text}</p>
        </div>
    );
}
export default EmptyState;
