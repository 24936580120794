import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select, Switch, InputNumber } from "antd";
import useAgentsWithPaginator from "./useAgentsWithPaginator";
import { default as deepmerge } from "deepmerge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import TitleTooltip from "../../../components/TitleTooltip/TitleTooltip";

interface ConversationNotificationEmailConfig {
    backend: {
        emailConversationNotification: {
            enabled: boolean;
            newMessageEnabled: boolean;
            newMessageEmailDelay: number;
            users: { id: number; email: string | null }[];
        };
    };
}

interface ConversationNotificationMailSettingsProps {
    configs: ConversationNotificationEmailConfig;
    setConfig(c: ConversationNotificationEmailConfig): void;
}

function ConversationNotificationEmailSettings({
    configs,
    setConfig,
}: ConversationNotificationMailSettingsProps) {
    const [selectedAgentsLabel, setSelectedAgentsLabel] = useState<number[]>();
    const [agents] = useAgentsWithPaginator(false); // This hook is also used for other settings. TODO: Create a context to avoid re-render
    const enabled = useMemo(() => {
        return configs.backend.emailConversationNotification.enabled;
    }, [configs.backend.emailConversationNotification.enabled]);
    const newMessageNotificationsEnabled = useMemo(() => {
        return configs.backend.emailConversationNotification.newMessageEnabled;
    }, [configs.backend.emailConversationNotification.newMessageEnabled]);
    const newMessageEmailDelay = useMemo(() => {
        return configs.backend.emailConversationNotification.newMessageEmailDelay;
    }, [configs.backend.emailConversationNotification.newMessageEmailDelay]);
    const { t } = useTranslation();

    function addAgentsToConfig(selectedAgentsId: number[]) {
        const config = { ...configs };

        const actualSelectedUsersId = config.backend.emailConversationNotification.users.map(
            (user) => user.id
        );

        for (const userId of selectedAgentsId) {
            const userIdMustBeAdded = !actualSelectedUsersId.includes(userId);
            if (userIdMustBeAdded) {
                config.backend.emailConversationNotification.users.push({
                    id: userId,
                    email: null,
                });
            }
        }

        for (const userId of actualSelectedUsersId) {
            const userIdMustBeRemoved = !selectedAgentsId.includes(userId);
            if (userIdMustBeRemoved) {
                config.backend.emailConversationNotification.users =
                    config.backend.emailConversationNotification.users.filter(
                        (user) => user.id !== userId
                    );
            }
        }

        setConfig(config);
    }

    function onEnableConversationNotificationEmail(checked: boolean) {
        if (checked === false) {
            const config = { ...configs };
            config.backend.emailConversationNotification.users = [];
            setConfig(config);
        }

        let mergedConfigs = deepmerge(configs, {
            backend: {
                emailConversationNotification: {
                    enabled: checked,
                },
            },
        });
        setConfig(mergedConfigs);
    }

    function onChangeSelectAgents(selectedAgents: number[]) {
        addAgentsToConfig(selectedAgents);
        const agentsLabel = agents.filter((agent) => selectedAgents.includes(agent.id));
        setSelectedAgentsLabel(agentsLabel.map((agent) => agent.id));
    }

    function onClickSelectAllAgents() {
        setSelectedAgentsLabel(agents.map((agent) => agent.id));
        onChangeSelectAgents(agents.map((agent) => agent.id));
    }

    function onEnableNewMessageNotifications(checked: boolean) {
        let mergedConfigs = deepmerge(configs, {
            backend: {
                emailConversationNotification: {
                    newMessageEnabled: checked,
                },
            },
        });
        setConfig(mergedConfigs);
    }

    function onChangeNewMessageEmailDelay(delay: string | number | undefined) {
        if (typeof delay !== "number") return;
        let mergedConfigs = deepmerge(configs, {
            backend: {
                emailConversationNotification: {
                    newMessageEmailDelay: delay,
                },
            },
        });
        setConfig(mergedConfigs);
    }

    useEffect(() => {
        const config = { ...configs };
        let users = config.backend.emailConversationNotification.users;
        setSelectedAgentsLabel(users.map((user) => user.id));
    }, [configs]);

    return (
        <>
            <div>
                <div style={{ display: "flex", marginBottom: "20px" }}>
                    <span style={{ fontWeight: "bold" }}>
                        {t("settings.conversationNotificationsEmails.title")}
                    </span>
                    <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                        <Switch
                            checked={enabled}
                            onChange={onEnableConversationNotificationEmail}
                        />
                    </div>
                </div>
                <span style={{ fontSize: 14, color: "#727272" }}>
                    {t("settings.conversationNotificationsEmails.description")}
                </span>
            </div>

            {enabled && (
                <div style={{ marginBottom: "2px", marginTop: "5px" }}>
                    <label style={{ display: "block" }}>
                        {t("settings.conversationNotificationsEmails.sendEmailTo")}
                    </label>
                    <Select
                        id="agents"
                        mode="multiple"
                        allowClear
                        onChange={onChangeSelectAgents}
                        style={{ width: "100%" }}
                        value={selectedAgentsLabel}
                        showSearch={false}
                        dropdownRender={(menu) => (
                            <>
                                {
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginBottom: "8px",
                                        }}
                                        onClick={onClickSelectAllAgents}
                                    >
                                        <span
                                            style={{
                                                fontStyle: "normal",
                                                fontWeight: "bold",
                                                fontSize: "16px",
                                                color: "#3f3356",
                                                cursor: "pointer",
                                            }}
                                        >
                                            {t(
                                                "settings.conversationNotificationsEmails.selectAll"
                                            )}
                                            <FontAwesomeIcon icon={faChevronDown} />
                                        </span>
                                    </div>
                                }
                                {menu}
                            </>
                        )}
                    >
                        {agents.map((agent) => (
                            <Select.Option key={agent.id} value={agent.id}>
                                {`${agent.firstName} ${agent.lastName}`}
                            </Select.Option>
                        ))}
                    </Select>
                    <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                        <div style={{ marginLeft: "5px", marginTop: "15px" }}>
                            <label
                                style={{
                                    marginLeft: "auto",
                                    marginRight: "0px",
                                }}
                            >
                                {t(
                                    "settings.conversationNotificationsEmails.newMessageNotifications"
                                )}
                            </label>
                            <Switch
                                checked={newMessageNotificationsEnabled}
                                onChange={onEnableNewMessageNotifications}
                                size="small"
                                style={{ marginLeft: "20px" }}
                            />
                        </div>
                    </div>
                    {newMessageNotificationsEnabled && (
                        <div style={{ marginLeft: "15px", marginRight: "0px" }}>
                            <label
                                style={{
                                    marginLeft: "auto",
                                    marginRight: "0px",
                                }}
                            >
                                {t("settings.conversationNotificationsEmails.newMessageFrequency")}
                                <TitleTooltip
                                    content={t(
                                        "settings.conversationNotificationsEmails.newMessageFrequencyTooltip"
                                    )}
                                />
                            </label>
                            <div style={{ marginTop: "5px", marginLeft: "0px" }}>
                                <InputNumber
                                    style={{ width: "100%" }}
                                    size={"small"}
                                    min={0}
                                    value={newMessageEmailDelay}
                                    onChange={onChangeNewMessageEmailDelay}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default ConversationNotificationEmailSettings;
