import React from "react";
import CrudManagerTable from "./CrudManagerTable";
import MANAGER_STATES from "./modalStates";
function CrudManager({ managerState, createCb, deleteCb, dataSource, columns, formContent }) {
    if (formContent && formContent() && managerState === MANAGER_STATES.FORM) {
        return formContent();
    }
    return (
        <CrudManagerTable
            dataSource={dataSource}
            columns={columns}
            createCb={createCb}
            deleteCb={deleteCb}
        />
    );
}
export default CrudManager;
