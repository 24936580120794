import React from "react";
import { useTranslation } from "react-i18next";
import { InboxOutlined } from "@ant-design/icons";
import SizeWarnings from "frontend-lib/SizeChecker/SizeWarnings/SizeWarnings";
import { FileType } from "frontend-lib/SizeChecker/";
import type { ChannelWarningMessages } from "frontend-lib/SizeChecker/types";

type ImagePreviewProps = {
    image: string;
};

type FilePreviewProps = {
    name: string;
    file: string;
    type: FileType;
    warnings: ChannelWarningMessages;
};

function ImagePreview({ image }: ImagePreviewProps) {
    return <img style={{ maxWidth: "100%" }} src={image} alt="upload file" />;
}

function FilePreview({ name, file, type, warnings }: FilePreviewProps) {
    const { t } = useTranslation();
    return (
        <>
            <p className="ant-upload-text">{name}</p>
            {type === FileType.IMAGE ? <ImagePreview image={file} /> : <InboxOutlined />}
            <SizeWarnings warnings={warnings} text={t("channelFileExceded")} />
        </>
    );
}

export default FilePreview;
