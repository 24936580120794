import React, { useState, useEffect } from "react";
import { Modal, Spin } from "antd";
import CrudManager from "./CrudManager";
import CrudModalFooter from "./CrudManagerFooter";
import MANAGER_STATES from "./modalStates";
function CrudManagerModal({
    isVisible,
    isLoading,
    modalTitle,
    closeCb,
    saveCb,
    createCb,
    deleteCb,
    dataSource,
    columns,
    formContent,
}) {
    const [managerState, setManagerState] = useState(MANAGER_STATES.LIST);
    function goBackToList() {
        setManagerState(MANAGER_STATES.LIST);
    }
    function onCreate() {
        setManagerState(MANAGER_STATES.FORM);
        createCb();
    }
    function onCancelModal() {
        setManagerState(MANAGER_STATES.LIST);
        closeCb();
    }
    function onSave() {
        saveCb();
    }
    useEffect(() => {
        if (formContent() === null) {
            setManagerState(MANAGER_STATES.LIST);
        }
    }, [formContent]);
    return (
        <Modal
            title={modalTitle}
            visible={isVisible}
            onCancel={onCancelModal}
            footer={[
                <CrudModalFooter
                    modalState={managerState}
                    goBackCb={goBackToList}
                    saveCb={onSave}
                    closeCb={closeCb}
                />,
            ]}
        >
            <Spin spinning={isLoading}>
                <CrudManager
                    formContent={formContent}
                    managerState={managerState}
                    dataSource={dataSource}
                    columns={columns}
                    createCb={onCreate}
                    deleteCb={deleteCb}
                />
            </Spin>
        </Modal>
    );
}
export default CrudManagerModal;
