import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import MANAGER_STATES from "./modalStates";
function CrudModalFooter({ modalState, goBackCb, saveCb, closeCb }) {
    const { t } = useTranslation();
    if (modalState === MANAGER_STATES.FORM) {
        return (
            <>
                <Button onClick={goBackCb}> {t("customers.customerGroups.back")} </Button>
                <Button onClick={saveCb} type="primary">
                    {" "}
                    {t("customers.customerGroups.save")}{" "}
                </Button>
            </>
        );
    }
    return <Button onClick={closeCb}> {t("customers.customerGroups.close")} </Button>;
}
export default CrudModalFooter;
