import { useCallback, useEffect, useState } from "react";
import { message } from "antd";
import api from "../../../requests/api";
import { useTranslation } from "react-i18next";

export interface SalesforceIntegration {
    isConnected: boolean;
    clientId: string;
    clientSecret: string;
    username: string;
    password: string;
    isSandbox: boolean;
}

export function useSalesforceIntegration(): [
    SalesforceIntegration, 
    React.Dispatch<React.SetStateAction<SalesforceIntegration>>,
    (clientId: string, clientSecret: string, username: string, password: string, isSandbox: boolean) => Promise<void>,
    boolean
] {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const initialSalesforceIntegration: SalesforceIntegration = {
        isConnected: false,
        clientId: '',
        clientSecret: '',
        username: '',
        password: '',
        isSandbox: false
    }
    const [salesforceIntegration, setSalesforceIntegration] = useState<SalesforceIntegration>(initialSalesforceIntegration);

    const getSalesforceIntegration = useCallback(async () => {
        setIsLoading(true)
        try {
            const response = await api.integrations().getSalesforceIntegration()
            setSalesforceIntegration(response.data)
        }
        catch(e) {}
        finally {
            setIsLoading(false)
        }
    } , []) 

    const connectSalesforceIntegration = useCallback(async(clientId: string, clientSecret: string, username: string, password: string, isSandbox: boolean) => {
        setIsLoading(true)
        api.integrations()
        .connectSalesforceIntegration(clientId, clientSecret, username, password, isSandbox)
        .then((res) => {
            message.success(t("settings.integrations.salesforceSuccess"));
            setSalesforceIntegration((prevState: any) => ({
                ...prevState,
                isConnected: true
              }));
            setIsLoading(false)
        })
        .catch((err) => {
            message.error(t("settings.integrations.salesforceFailed"));
            setSalesforceIntegration((prevState: any) => ({
                ...prevState,
                isConnected: false
              }));
            setIsLoading(false)
        });

        }, [t]
    )

    useEffect(() => {
        getSalesforceIntegration();
    }, [getSalesforceIntegration, salesforceIntegration.isConnected]);

    return [salesforceIntegration, setSalesforceIntegration, connectSalesforceIntegration, isLoading];
}
