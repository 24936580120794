import React, { useState, useEffect, useContext, useMemo } from "react";
import api from "../../../requests/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faPencilAlt,
    faLock,
    faUsers,
    faTrashAlt,
    faList,
} from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../components/AppContext";
import Loader from "../../../components/Loader/Loader";
import EmptyState from "../../../components/EmptyState/EmptyState";
import "./Users.scss";
import OptModal from "../../../components/Modal/Modal";
import { Select, message, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NavLink } from "react-router-dom";
import UsersGroups from "./UsersGroups";
import { InviteUser } from "./InviteUser";

function Users() {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(false);
    const [modal, setModal] = useState(null);
    const [chatGroups, setChatGroups] = useState([]);
    const [toggleGroupsModal, setToggleGroupsModal] = useState(false);
    const [selectedChatGroups, setSelectedChatGroups] = useState([]);
    const [modalDeleteUserConfirm, setModalDeleteUserConfirm] = useState(false);
    const [users, setUsers] = useState(null);
    const MESSAGE_DURATION = 4;
    const [modalUser, setModalUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordRepeated: "",
        chatGroups: [],
    });
    const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);

    const messageSuccess = (text) => message.success(text, MESSAGE_DURATION);
    const messageError = (text) => message.error(`Error: ${text}`, MESSAGE_DURATION);

    const isSysadmin = useMemo(() => appContext.state?.globalUser?.isSysadmin, [appContext]);
    const currUserRol = getUserRol({ ...appContext.state?.userData, isRoot: isSysadmin });

    const deletePermissionMatrix = {
        root: { root: false, admin: true, basic: true, self: false },
        admin: { root: false, admin: false, basic: true, self: false },
        basic: { root: false, admin: false, basic: false, self: false },
    };

    const updatePermissionMatrix = {
        root: { root: true, admin: true, basic: true, self: true },
        admin: { root: false, admin: false, basic: true, self: true },
        basic: { root: false, admin: false, basic: false, self: true },
    };

    const setAdminPermissionMatrix = {
        root: { root: false, admin: true, basic: true, self: false },
        admin: { root: false, admin: false, basic: true, self: false },
        basic: { root: false, admin: false, basic: false, self: false },
    };

    function handleModalForm(evt) {
        const value = evt.target.value;
        setModalUser({
            ...modalUser,
            [evt.target.name]: value,
        });
    }

    function closeModal() {
        setModal(null);
        setModalUser({
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            passwordRepeated: "",
            chatGroups: [],
        });
    }

    function openModal(view, user) {
        setModal(view);
        setModalUser(user);
        const selectedGroups = [];
        // eslint-disable-next-line
        user.chatGroups.map((item) => {
            // eslint-disable-next-line
            chatGroups.map((group) => {
                if (group.id === item.id) {
                    selectedGroups.push({ label: group.name, value: group.id });
                }
            });
        });
        setSelectedChatGroups(selectedGroups);
    }

    function saveModalChanges() {
        let errors = null;

        if (!modalUser.email || !modalUser.lastName) {
            errors = "Email and lastnames are required";
        }

        if (modalUser.password !== "" && modalUser.password !== modalUser.passwordRepeated) {
            errors = "Passwords don't match";
        }

        if (errors) {
            messageError(errors);
            return false;
        } else {
            setIsLoading(true);
            const widthCredentials = true;
            if (modal === "new") {
                api.users()
                    .create_user(modalUser, widthCredentials)
                    .then((response) => {
                        setUsers([...users, response.data]);
                        messageSuccess("User created");
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                        messageError(error.response.data.error.message);
                    })
                    .then(function () {
                        // always executed
                        setIsLoading(false);
                    });
            } else if (modal === "edit" || modal === "editPass") {
                api.users()
                    .edit(modalUser, widthCredentials)
                    .then((response) => {
                        setUsers(
                            users.map((user) => (modalUser.id === user.id ? modalUser : user))
                        );
                        messageSuccess("User edited");
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                        messageError(error.response.data.error.message);
                    })
                    .then(function () {
                        // always executed
                        setIsLoading(false);
                    });
            }
        }
        setModal(null);
    }

    function openModalDeleteUserConfirm(user) {
        setModalDeleteUserConfirm(true);
        setModalUser(user);
    }

    function deleteUser() {
        setIsLoading(true);
        api.users()
            .delete(modalUser)
            .then(() => {
                messageSuccess("User deleted");
                setUsers(users.filter((user) => modalUser.id !== user.id));
            })
            .catch((error) => {
                console.log(error);
                let msg = "Server unreachable, try again later";
                if (error.response) {
                    msg = error.response.data.error.message;
                }
                messageError(msg);
            });

        setIsLoading(false);
        setModalDeleteUserConfirm(false);
    }

    const loadMoreItems = function () {
        let nextPage = page + 1;
        setPage(nextPage);
        setIsLoading(true);
        api.users()
            .list(nextPage)
            .then((response) => {
                setNextPage(response.data.paginator.hasNext);
                setUsers([...users, ...response.data.users]);
                setIsLoading(false);
            });
    };

    const loadItems = function () {
        setIsLoading(true);
        api.users()
            .list(page)
            .then((response) => {
                setNextPage(response.data.paginator.hasNext);
                setUsers(response.data.users);
                setIsLoading(false);
            });
    };
    async function loadGroups() {
        try {
            const response = await api.chat().groups();
            setChatGroups(response.data.chatGroups);
        } catch (err) {
            console.log(err);
        }
    }

    function openGroupsModal() {
        loadGroups();
        setToggleGroupsModal(true);
    }

    function handleChatGroup(list) {
        if (list.length === 0) {
            setSelectedChatGroups([]);
            setModalUser((user) => ({ ...user, chatGroups: [] }));
        } else {
            const newList = [];
            list.map((item) => newList.push({ id: item.value, value: item.label }));
            setSelectedChatGroups(list);
            const newModalUserChatGroups = list.map((group) => ({ id: group.value }));
            setModalUser((user) => ({ ...user, chatGroups: newModalUserChatGroups }));
        }
    }

    useEffect(() => {
        setPage(0);
        loadItems();
        loadGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function isCurrentUser(user) {
        return appContext.state.userData.id === user.id;
    }

    function getUserRolOrSelf(user) {
        if (isCurrentUser(user)) {
            return "self";
        } else {
            return getUserRol(user);
        }
    }

    function getUserRol(user) {
        if (user.isRoot) {
            return "root";
        } else if (user.isAdmin) {
            return "admin";
        } else {
            return "basic";
        }
    }

    function checkIfCanDelete(user) {
        const argUserRol = getUserRolOrSelf(user);
        return deletePermissionMatrix[currUserRol][argUserRol];
    }

    function checkIfCanEdit(user) {
        const argUserRol = getUserRolOrSelf(user);
        return updatePermissionMatrix[currUserRol][argUserRol];
    }

    function checkIfCanSetAsAdmin(user) {
        const argUserRol = getUserRolOrSelf(user);
        return setAdminPermissionMatrix[currUserRol][argUserRol];
    }

    return (
        <div className="opt-view opt-view--users-list">
            <UsersGroups
                chatGroups={chatGroups}
                setChatGroups={setChatGroups}
                isModalVisible={toggleGroupsModal}
                isModalVisibleCb={() => {
                    setToggleGroupsModal(false);
                }}
            />
            <div className="opt-view-header">
                <h1 className="opt-title"> {t("users.users")}</h1>
                <div className="opt-view-header__actions">
                    <button onClick={loadItems} className="opt-button opt-button--light">
                        {t("reload")}
                    </button>
                    <button onClick={openGroupsModal} className="opt-button opt-button--light">
                        {t("users.manageGroups")}
                    </button>
                    <button
                        className="opt-button opt-button--light"
                        onClick={() => setIsInviteModalVisible(true)}
                    >
                        {t("users.invite.buttonText")}
                    </button>
                </div>
            </div>
            <Loader isLoading={isLoading} />
            {users && users.length > 0 && (
                <div className="opt-card opt-card--table">
                    <table className="opt-table opt-table--first-highlight">
                        <thead>
                            <tr>
                                <th>{t("users.name")}</th>
                                <th>{t("users.lastName")}</th>
                                <th>{t("users.email")}</th>
                                <th>{t("users.agentState")}</th>
                                <th>{t("users.lastActivityTimestamp")}</th>
                                <th className="align-right">{t("users.actions")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, key) => (
                                <tr key={key}>
                                    <td>{user.firstName ? user.firstName : "-"}</td>
                                    <td>{user.lastName ? user.lastName : "-"}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        {user.agentState
                                            ? t(
                                                  "users.agentStates." +
                                                      user.agentState.name.toLowerCase()
                                              )
                                            : "-"}
                                    </td>
                                    <td>
                                        {user.lastActivityTimestamp
                                            ? moment(user.lastActivityTimestamp).format(
                                                  "DD/MM/YY HH:mm:ss"
                                              )
                                            : "-"}
                                    </td>
                                    <td className="align-right">
                                        {checkIfCanEdit(user) ? (
                                            <>
                                                <button
                                                    onClick={() => openModal("edit", user)}
                                                    className="opt-table__button"
                                                >
                                                    <FontAwesomeIcon icon={faPencilAlt} />
                                                </button>
                                                <button
                                                    onClick={() => openModal("editPass", user)}
                                                    className="opt-table__button"
                                                >
                                                    <FontAwesomeIcon icon={faLock} />
                                                </button>
                                                <NavLink
                                                    to={{
                                                        pathname: `/dashboard/users/history/${user.agentId}`,
                                                        state: user,
                                                    }}
                                                    className="opt-table__button"
                                                >
                                                    <FontAwesomeIcon icon={faList} />
                                                </NavLink>
                                            </>
                                        ) : (
                                            t("users.adminUser")
                                        )}
                                        {checkIfCanDelete(user) ? (
                                            <>
                                                <button
                                                    onClick={() => openModalDeleteUserConfirm(user)}
                                                    className="opt-table__button"
                                                >
                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                </button>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {nextPage && (
                        <div className="view-more" onClick={loadMoreItems}>
                            <span className="view-more__text">
                                View more <FontAwesomeIcon icon={faChevronDown} />
                            </span>
                        </div>
                    )}
                </div>
            )}
            {users && users.length === 0 && (
                <EmptyState icon={faUsers} text="There are no users created yet." />
            )}
            <OptModal
                medium
                show={modal}
                closeCb={closeModal}
                acceptCb={saveModalChanges}
                acceptText={modal === "edit" || modal === "editPass" ? "Save" : "Create"}
            >
                <div className="user-modal-form">
                    <h2 className="user-modal-title">
                        {modal === "new" ? "Create" : "Edit"} {t("users.users")}
                    </h2>
                    {modal === "editPass" ? (
                        <div className="user-pass-form">
                            <div className="opt-form-group">
                                <label className="opt-label-title">{t("users.newPassword")}</label>
                                <input
                                    type="password"
                                    className="opt-text-input"
                                    placeholder={t("users.newPassword")}
                                    name="password"
                                    value={modalUser.password}
                                    onChange={handleModalForm}
                                    required
                                />
                            </div>
                            <div className="opt-form-group">
                                <label className="opt-label-title">{t("users.repeat")}</label>
                                <input
                                    type="password"
                                    className="opt-text-input"
                                    placeholder={t("users.repeat")}
                                    name="passwordRepeated"
                                    value={modalUser.passwordRepeated}
                                    onChange={handleModalForm}
                                    required
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="user-form">
                            <div className="opt-form-group">
                                <label className="opt-label-title">{t("users.name")}</label>
                                <input
                                    type="text"
                                    className="opt-text-input"
                                    placeholder="User name"
                                    name="firstName"
                                    value={modalUser.firstName}
                                    onChange={handleModalForm}
                                    required
                                />
                            </div>
                            <div className="opt-form-group">
                                <label className="opt-label-title">{t("users.lastName")}</label>
                                <input
                                    type="text"
                                    className="opt-text-input"
                                    placeholder="User last name"
                                    name="lastName"
                                    value={modalUser.lastName}
                                    onChange={handleModalForm}
                                    required
                                />
                            </div>
                            <div className="opt-form-group">
                                <label className="opt-label-title">{t("users.email")}</label>
                                <input
                                    type="text"
                                    className="opt-text-input"
                                    placeholder="User email"
                                    name="email"
                                    value={modalUser.email}
                                    onChange={handleModalForm}
                                    required
                                />
                            </div>
                            <div className="opt-form-group">
                                <label className="opt-label-title">{t("users.groups")}</label>{" "}
                                <br />
                                <Select
                                    labelInValue
                                    style={{ width: "100%" }}
                                    mode="multiple"
                                    onChange={handleChatGroup}
                                    value={selectedChatGroups}
                                >
                                    {chatGroups.map((group, index) => (
                                        <Select.Option key={index} value={group.id}>
                                            {group.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                            {modal === "edit" && (
                                <div className="opt-form-group">
                                    <Checkbox
                                        checked={modalUser.isAdmin}
                                        disabled={!checkIfCanSetAsAdmin(modalUser)}
                                        onChange={(e) =>
                                            setModalUser({
                                                ...modalUser,
                                                isAdmin: e.target.checked,
                                            })
                                        }
                                    >
                                        {t("users.isAdmin")}
                                    </Checkbox>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </OptModal>
            <InviteUser isVisible={isInviteModalVisible} setIsVisible={setIsInviteModalVisible} />
            <OptModal
                small
                show={modalDeleteUserConfirm}
                closeCb={() => setModalDeleteUserConfirm(false)}
                acceptCb={deleteUser}
                acceptText={"Confirm"}
            >
                <h2 className="user-modal-title">Delete user</h2>
                <p className="opt-text">Are you sure you want to delete this user?</p>
            </OptModal>
        </div>
    );
}
export default Users;
