import ACTIONS from "./actions";
export default function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.CHANGE_STATUS:
            return {
                ...state,
                status: action.payload.status,
            };
        case ACTIONS.CHANGE_DATE:
            return {
                ...state,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
            };
        case ACTIONS.SET_PAGINATOR:
            return {
                ...state,
                paginator: action.payload.paginator,
            };
        case ACTIONS.CHANGE_PAGE:
            return {
                ...state,
                paginator: {
                    ...state.paginator,
                    page: action.payload.page,
                },
            };
        case ACTIONS.CHANGE_CHANNEL:
            return {
                ...state,
                customerChannel: action.payload.customerChannel,
            };
        case ACTIONS.CHANGE_CUSTOMER:
            return {
                ...state,
                customerId: action.payload.customerId,
            };
        case ACTIONS.CHANGE_CUSTOMER_STATUS:
            return {
                ...state,
                customerStatus: action.payload.customerStatus,
            };
        case ACTIONS.CHANGE_AGENT_TYPE:
            return {
                ...state,
                participatingAgentType: action.payload.participatingAgentType,
            };
        case ACTIONS.CHANGE_OWNER_TYPE:
            return {
                ...state,
                ownedByAgentType: action.payload.ownedByAgentType,
            };
        case ACTIONS.CHANGE_AGENT:
            return {
                ...state,
                participatingAgentId: action.payload.participatingAgentId,
            };
        case ACTIONS.SET_ALL_FILTERS:
            return action.payload.state;
        case ACTIONS.CHANGE_CUSTOMER_DATE:
            return {
                ...state,
                customersStartDate: action.payload.startDate,
                customersEndDate: action.payload.endDate,
            };
        case ACTIONS.CHANGE_TAGS:
            return {
                ...state,
                tagsId: action.payload.tagsId,
            };
        case ACTIONS.CHANGE_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.payload.searchText,
            };
        case ACTIONS.CHANGE_TAGS_GROUPS:
            return {
                ...state,
                selectedGroupsIds: action.payload.selectedGroupsIds,
                groupedTagsIds: action.payload.groupedTagsIds,
            };
        case ACTIONS.CHANGE_OPTIN:
            return {
                ...state,
                hasOptIn: action.payload.hasWhatsappOptIn,
            };
        case ACTIONS.CHANGE_CUSTOMERS_ID:
            return {
                ...state,
                customersId: action.payload.customersId,
            };
        case ACTIONS.CHANGE_CUSTOMER_GROUPS_IDS:
            return {
                ...state,
                customerGroupsIds: action.payload.customerGroupsIds,
            };
        case ACTIONS.CHANGE_CONVERSATION_TAGS_IDS:
            return {
                ...state,
                conversationTagsIds: action.payload.conversationTagsIds,
            };
        case ACTIONS.CHANGE_CONVERSATION_GROUPS_IDS:
            return {
                ...state,
                conversationGroupsIds: action.payload.conversationGroupsIds,
            };
        case ACTIONS.CHANGE_AGENTS_IDS:
            return {
                ...state,
                agentsIds: action.payload.agentsIds,
            };
        case ACTIONS.CHANGE_OR_CUSTOMERS_AND_GROUPS:
            return {
                ...state,
                orCustomersAndGroups: action.payload.orCustomersAndGroups,
            };
        case ACTIONS.CHANGE_LAST_ACTIVITY_DATE:
            return {
                ...state,
                lastActivityStartDate: action.payload.lastActivityStartDate,
                lastActivityEndDate: action.payload.lastActivityEndDate,
            };
        case ACTIONS.CLEAR_FILTERS:
            return {
                ...state,
            };
        default:
            return state;
    }
}
