import React, { useState, useEffect } from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { Table, message, DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../components/Loader/Loader";
import api from "../../../requests/api";
import moment from "moment";
import { useTranslation } from "react-i18next";
const { RangePicker } = DatePicker;

function AgentStateHistory() {
    const { agentId } = useParams();
    const location = useLocation();
    const [historicAgentState, setHistoricAgentState] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [nextPage, setNextPage] = useState(false);
    const [page, setPage] = useState(0);
    const [redirectBack, setRedirectBack] = useState(false);
    const { t } = useTranslation();
    const [dateFormat] = useState("DD/MM/YYYY");
    const [dates, setDates] = useState([moment().subtract(1, "week"), moment()]);
    const columns = [
        {
            title: t("agents.timestamp"),
            dataIndex: "createdOn",
            key: 1,
            render: (data) => (data ? data : "-"),
        },
        {
            title: t("agents.previousState"),
            dataIndex: "previousState",
            key: 2,
            render: (data) => (data ? t("users.agentStates." + data.name.toLowerCase()) : "-"),
        },
        {
            title: t("agents.laterState"),
            dataIndex: "currentState",
            key: 3,
            render: (data) => (data ? t("users.agentStates." + data.name.toLowerCase()) : "-"),
        },
    ];

    const loadItems = function () {
        setIsLoading(true);
        const startDate = momentToYYMMDD(dates[0]);
        const endDate = momentToYYMMDD(dates[1]);
        api.agent()
            .list(agentId, startDate, endDate, page, 20)
            .then((response) => {
                let formattedHistoricAgentState = formatHistoricAgentState(
                    response.data.historicAgentState
                );
                setNextPage(response.data.paginator.hasNext);
                setHistoricAgentState(formattedHistoricAgentState);
                setIsLoading(false);
            })
            .catch((error) => {
                message.error(error.response?.data?.error?.message);
                setIsLoading(false);
            });
    };

    const loadMoreItems = function (data) {
        setIsLoading(true);
        setPage(page + 1);
        const startDate = momentToYYMMDD(dates[0]);
        const endDate = momentToYYMMDD(dates[1]);
        api.agent()
            .list(agentId, startDate, endDate, page + 1, 20)
            .then((response) => {
                let formattedHistoricAgentState = formatHistoricAgentState(
                    response.data.historicAgentState
                );
                setNextPage(response.data.paginator.hasNext);
                setHistoricAgentState([...historicAgentState, ...formattedHistoricAgentState]);
                setIsLoading(false);
            })
            .catch((error) => {
                message.error(error.response?.data?.error?.message);
                setIsLoading(false);
            });
    };

    const momentToYYMMDD = (momentObj) => {
        let baseDate = momentObj;
        if (typeof momentObj === "string") {
            baseDate = moment(momentObj);
        }
        let dateObj = baseDate.toDate();
        let year = dateObj.getUTCFullYear();
        let month = dateObj.getUTCMonth() + 1;
        let day = dateObj.getUTCDate();
        return year + "-" + month + "-" + day;
    };

    const formatHistoricAgentState = function (historicAgentState) {
        return historicAgentState.map((value) => {
            value.createdOn = moment(value.createdOn).format("DD/MM/YY HH:mm:ss");
            return value;
        });
    };

    function goBack() {
        setRedirectBack(true);
    }

    function disabledDate(current) {
        return current && current > moment().endOf("day");
    }

    function onDatesChange(dates) {
        setDates(dates);
    }

    useEffect(() => {
        loadItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPage(0);
        loadItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dates]);

    if (redirectBack) {
        return (
            <Redirect
                to={{
                    pathname: "/dashboard/users",
                }}
            />
        );
    }

    return (
        <div className="opt-view">
            <h1 className="opt-title opt-title--link" onClick={goBack}>
                ← {t("users.users")}
            </h1>
            <div className="opt-view-header">
                <h1 className="opt-title">{`${t("agents.historicStateChanges")} - ${
                    location.state.firstName
                } ${location.state.lastName}`}</h1>
                <div className="opt-view-header__actions">
                    <div className="date-picker">
                        <RangePicker
                            format={dateFormat}
                            value={dates}
                            onChange={onDatesChange}
                            disabledDate={disabledDate}
                        />
                    </div>
                </div>
            </div>
            <Loader isLoading={isLoading} />
            <div className="opt-card opt-card--table opt-card--no-padding">
                <Table
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={historicAgentState}
                    pagination={false}
                />
                {nextPage && (
                    <div className="view-more" onClick={loadMoreItems}>
                        <span className="view-more__text">
                            {" "}
                            {t("viewMore")} <FontAwesomeIcon icon={faChevronDown} />
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default AgentStateHistory;
