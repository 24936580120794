import React from "react";
import { Table } from "antd";
import WithPaginator from "../../../components/WithPaginator/WithPaginator";
function MonitoringTable({ columns, chats, paginator, onChangeCb, onChangePageCb }) {
    return (
        <div className="opt-card opt-card--table opt-card--no-padding">
            <WithPaginator
                page={paginator.page}
                hasNext={paginator.hasNext}
                changePageCb={onChangePageCb}
                showPage={true}
                viewMoreCb={null}
            >
                <Table
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={chats}
                    pagination={false}
                    onChange={onChangeCb}
                />
            </WithPaginator>
        </div>
    );
}
export default MonitoringTable;
