import React, { useState, useEffect } from "react";
import { Row, Col, Skeleton, Empty } from "antd";
import OptChart from "../../../../../components/OptChart/OptChart";
import { TagUsesItem, tagUsesTexts, tagUsesConfigs, tagUsesModal } from "./charts/TagUses";
import { UISTATES } from "../../configs/Constants";
import moment from "moment";
function TagReports({ data, uistate, chartsRange, layout }) {
    const [tagUsesData, setTagUsesData] = useState([]);
    const showChart = (
        title,
        type,
        config,
        data,
        description,
        extraData = null,
        modalContent = null,
        showModal = null
    ) => {
        if (uistate === UISTATES.LOADING) {
            return <Skeleton paragraph={{ rows: 8 }} />;
        }
        if (uistate === UISTATES.EMPTY) {
            return <Empty />;
        }
        if (uistate === UISTATES.FINISHED) {
            return (
                <OptChart
                    title={title}
                    type={type}
                    config={config}
                    data={data}
                    showModal={showModal}
                    modalContent={modalContent}
                    tooltipText={description}
                    extraData={extraData}
                />
            );
        }
    };
    useEffect(() => {
        const tagUses = [];
        chartsRange.forEach((date) => {
            const filtered = data.filter(
                (item) => moment(item.date).utc().format("DD/MM/YYYY") === date
            );
            const totalUsesItem = new TagUsesItem(tagUsesTexts[0].name, date);
            const positiveFeedbackItem = new TagUsesItem(tagUsesTexts[1].name, date);
            const negativeFeedbackItem = new TagUsesItem(tagUsesTexts[2].name, date);
            if (filtered.length > 0) {
                const sourceItem = JSON.parse(JSON.stringify(filtered[0]));
                const parsedDate = moment(sourceItem.date).utc().format("DD/MM/YYYY");
                totalUsesItem.setValues(parsedDate, sourceItem.amountQuestionsReplied);
                positiveFeedbackItem.setValues(
                    parsedDate,
                    sourceItem.amountQuestionsRepliedWithPositiveFeedback
                );
                negativeFeedbackItem.setValues(
                    parsedDate,
                    sourceItem.amountQuestionsRepliedWithNegativeFeedback
                );
            }
            tagUses.push(totalUsesItem, positiveFeedbackItem, negativeFeedbackItem);
        });
        setTagUsesData(tagUses);
    }, [data, chartsRange]);

    const tagUsesChart = {
        title: "Tag uses",
        type: "column",
        config: tagUsesConfigs,
        data: tagUsesData,
        extraData: null,
        description: "Total amount of interactions",
        showModal: true,
        modalContent: tagUsesModal,
    };

    return (
        <>
            <Row gutter={[50, 50]}>
                <Col span={layout === "grid" ? 12 : 24}>
                    {showChart(
                        tagUsesChart.title,
                        tagUsesChart.type,
                        tagUsesChart.config,
                        tagUsesChart.data,
                        tagUsesChart.description,
                        tagUsesChart.extraData,
                        tagUsesChart.modalContent,
                        tagUsesChart.showModal
                    )}
                </Col>
            </Row>
        </>
    );
}
export default TagReports;
