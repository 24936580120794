import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import * as queryString from "query-string";

function FacebookCallback() {
    const [redirectPath, setRedirectPath] = useState(null);
    const [redirectQueryString, setRedirectQueryString] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const hashQueryString = queryString.parse(location.hash);
        const searchQueryString = queryString.parse(location.search);
        setRedirectPath(searchQueryString.redirectPath);

        const redirectQueryStringObj = {
            facebookAccessToken: hashQueryString.access_token,
        };
        setRedirectQueryString(new URLSearchParams(redirectQueryStringObj));
    }, [location]);

    return (
        <>
            {redirectPath === null ? (
                <></>
            ) : (
                <Redirect to={{ pathname: redirectPath, search: `?${redirectQueryString}` }} />
            )}
        </>
    );
}

export default FacebookCallback;
