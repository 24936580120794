import React, { useEffect, useCallback } from "react";
import "./Modal.scss";
function Modal(props) {
    const closeModal = function (event) {
        if (event.target.className === "opt-modal-wrapper") {
            props.closeCb();
        }
    };

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeCb();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (props.show) {
        return (
            <div className="opt-modal-wrapper" onClick={closeModal}>
                <div
                    className={`opt-modal ${props.small && "opt-modal--small"} ${
                        props.medium && "opt-modal--medium"
                    }`}
                >
                    {props.children}
                    {props.acceptCb && (
                        <div className="opt-modal__actions">
                            <button
                                className="opt-button opt-button--light"
                                onClick={() => props.closeCb()}
                            >
                                Cancel
                            </button>
                            <button className="opt-button" onClick={() => props.acceptCb()}>
                                {props.acceptText ? props.acceptText : "Accept"}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    } else {
        return false;
    }
}
export default Modal;
