import React, { useEffect, useState } from "react";
import { Button, Form, Input, Row, Col, Checkbox, message } from "antd";
import { useTranslation } from "react-i18next";
import api from "requests/api";
import axios, { AxiosError } from "axios";
import { SmileTwoTone } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { OptiweCard } from "components/OptiweCard";

interface FormParams {
    password: string;
    passwordRepeated: string;
    firstName: string;
    lastName: string;
}

enum UiState {
    FormSended,
    InitAskData,
    InitNotAskData,
}

export default function Invitation() {
    const [checkTerms, setCheckTerms] = useState(false);
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const token = queryParameters.get("token");
    const askForData = !(queryParameters.get("askForData") === "False");
    const [uiState, setUiState] = useState(
        queryParameters.get("askForData") === "False" ? UiState.InitNotAskData : UiState.InitAskData
    );
    const [isLoading, setIsLoading] = useState(false);

    const agentUrl = process.env?.REACT_APP_AGENT_URL;

    const lang: "en" | "es" = queryParameters.get("lang") === "SPANISH" ? "es" : "en";

    const { t, i18n } = useTranslation();
    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [i18n, lang]);

    async function onFormFinish(params: FormParams) {
        setIsLoading(true);
        try {
            await api
                .workspaces()
                .join(
                    token,
                    params.password,
                    params.passwordRepeated,
                    params.firstName,
                    params.lastName,
                    checkTerms
                );
            setUiState(UiState.FormSended);
        } catch (err: any | AxiosError) {
            if (axios.isAxiosError(err)) {
                if (err.response?.status === 401) {
                    message.error(t("signUp.invitationExpired"));
                    return;
                }
            }
            message.error("unknownError");
        } finally {
            setIsLoading(false);
        }
    }

    async function onClickAcceptInvitation() {
        setIsLoading(true);
        try {
            await api.workspaces().join(token, undefined, undefined, undefined, undefined, true);
            setUiState(UiState.FormSended);
        } catch (err: any | AxiosError) {
            const durationSeconds = 10;
            if (axios.isAxiosError(err)) {
                if (err.response?.status === 401) {
                    message.error(t("signUp.invitationExpired"), durationSeconds);
                } else if (err.response?.status === 400) {
                    message.error(t("signUp.badRequest"), durationSeconds);
                } else {
                    message.error(t("unknownError"), durationSeconds);
                }
            }
        } finally {
            setIsLoading(false);
        }
    }

    function renderFormSended() {
        if (askForData) {
            return (
                <div style={{ textAlign: "center" }}>
                    <SmileTwoTone style={{ fontSize: "4rem" }} />
                    <h1>{t("signUp.userCreatedSuccess")}</h1>
                    <p>{t("signUp.thankYouForCreatingAUser")}</p>
                    <a href={agentUrl}>
                        <button className="opt-button opt-button--light">{t("continue")}</button>
                    </a>
                </div>
            );
        } else {
            return (
                <div style={{ textAlign: "center" }}>
                    <SmileTwoTone style={{ fontSize: "4rem" }} />
                    <h1>{t("requestSent")}</h1>
                    <p>{t("signUp.thankYouForCreatingAUser")}</p>
                    <p>{t("signUp.loginExistingUser")}</p>
                    <a href={agentUrl}>
                        <button className="opt-button opt-button--light">{t("continue")}</button>
                    </a>
                </div>
            );
        }
    }

    function renderUiAskForData() {
        return (
            <Form onFinish={onFormFinish}>
                <h1>{t("signUp.welcome")}</h1>
                <h3>{t("signUp.invitationTextWithData")}</h3>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="firstName"
                            rules={[{ required: true, message: t("required") }]}
                        >
                            <Input size="large" placeholder={t("signUp.yourName")} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            rules={[{ required: true, message: t("required") }]}
                            name="lastName"
                        >
                            <Input size="large" placeholder={t("signUp.yourLastName")} />
                        </Form.Item>
                    </Col>
                </Row>
                <Col xs={24} sm={24}>
                    <Form.Item name="password" rules={[{ required: true, message: t("required") }]}>
                        <Input type="password" size="large" placeholder={t("signUp.password")} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24}>
                    <Form.Item
                        name="passwordRepeated"
                        dependencies={["password"]}
                        rules={[
                            {
                                required: true,
                                message: t("required"),
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue("password") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(t("signUp.passwordsDoNotMatch"));
                                },
                            }),
                        ]}
                    >
                        <Input
                            type="password"
                            size="large"
                            placeholder={t("signUp.repeatPassword")}
                        />
                    </Form.Item>
                </Col>
                <Checkbox
                    className="signup__terms"
                    onChange={(e) => setCheckTerms(e.target.checked)}
                >
                    {t("signUp.iAcceptThe")}{" "}
                    <a href="https://optiwe.com/legal/terms.pdf">
                        {" "}
                        {t("signUp.termsAndConditions")}{" "}
                    </a>
                </Checkbox>
                <Button
                    disabled={!checkTerms}
                    htmlType="submit"
                    className="opt-button"
                    loading={isLoading}
                >
                    {t("signUp.signUp")}
                </Button>
            </Form>
        );
    }

    function renderUiNotAskForData() {
        return (
            <div style={{ textAlign: "center" }}>
                <h1>{t("signUp.welcome")}</h1>
                <h3>{t("signUp.invitationTextWithoutData")}</h3>

                <Button
                    htmlType="submit"
                    className="opt-button"
                    onClick={onClickAcceptInvitation}
                    loading={isLoading}
                >
                    {t("signUp.acceptInvitation")}
                </Button>
            </div>
        );
    }

    function renderUiState(): JSX.Element {
        switch (uiState) {
            case UiState.FormSended:
                return renderFormSended();
            case UiState.InitAskData:
                return renderUiAskForData();
            case UiState.InitNotAskData:
                return renderUiNotAskForData();
            default:
                throw Error("Not implemented");
        }
    }

    return (
        <div className="signup">
            <OptiweCard>{renderUiState()}</OptiweCard>
        </div>
    );
}
