import { useEffect, useReducer } from "react";
import reducer from "./reducer";

function getFromStorage(key, initialValue) {
    const savedValue = JSON.parse(localStorage.getItem(key));
    if (savedValue) {
        return savedValue;
    }
    if (initialValue instanceof Function) {
        return initialValue();
    }
    return initialValue;
}

export default function useFilter(key, initialValue, persist) {
    const [filterState, filterDispatch] = useReducer(reducer, initialValue, () => {
        if (persist) {
            return getFromStorage(key, initialValue);
        } else {
            return initialValue;
        }
    });
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(filterState));
    }, [key, filterState]);
    return [filterState, filterDispatch];
}
