import React, { useState, useEffect } from "react";
import api from "../../../requests/api";
import { useTranslation } from "react-i18next";
import { Modal, Table, Space, Button, Popconfirm, message, Input, Spin } from "antd";
import axios, { AxiosError } from "axios";
import ConversationGroup from "frontend-lib/DomainTypes/ConversationGroups";


interface UsersGroupsModalProps {
    chatGroups: ConversationGroup[],
    setChatGroups: (groups: ConversationGroup[]) => void
    isModalVisible: boolean,
    isModalVisibleCb: (visible: boolean) => void
}


enum ModalState {
    create = "create",
    list = "list",
    edit = "edit"
}

interface EmptyInterface { }

function UsersGroups({ chatGroups, setChatGroups, isModalVisible, isModalVisibleCb }: UsersGroupsModalProps) {
    const { t } = useTranslation();
    const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
    const [selectedGroupName, setSelectedGroupName] = useState<string>('');
    const [modalState, setModalState] = useState<ModalState>(ModalState.list);
    const [isLoading, setIsLoading] = useState(false);
    const columns = [
        {
            title: t("customers.customerGroups.name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("customers.customerGroups.actions"),
            dataIndex: "actions",
            key: "actions",
            render: (_: EmptyInterface, row: ConversationGroup) => (
                <Space>
                    <Button onClick={() => onSelectGroup(row)}> {t("edit")} </Button>
                    <Popconfirm
                        title={`${t("remove")}?`}
                        onConfirm={() => confirmDelete(row.id)}
                        okText={t("yes")}
                        cancelText={t("no")}
                    >
                        <Button danger> {t("remove")} </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    async function confirmDelete(groupId: number) {
        setIsLoading(true);
        try {
            await api.chat().deleteGroup({ id: groupId });
            message.success(t("users.groupDeleted"));
            loadGroups();
        } catch (error: any | AxiosError) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.data?.error?.key === "BOT_USING_CHATGROUP") {
                    message.error(t("users.groupUsedByBot"));
                } else if (error.response.data?.error?.key === "PERMISSION_DENIED") {
                    message.error(t("actionNotAllowed"));
                } else {
                    message.error(`${t("error")}: ${error.toString()}`);
                }
            } else {
                message.error(`${t("error")}: ${error.toString()}`);
            }
        }
        setIsLoading(false);
    }

    const resetState = () => {
        setModalState(ModalState.list);
        setSelectedGroupId(null);
        setSelectedGroupName('');
    };
    const closeModal = () => {
        isModalVisibleCb(false);
        resetState();
    };
    const viewNewGroup = () => {
        setModalState(ModalState.create);
    };

    function handleInput(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedGroupName(e.target.value);
    }

    async function saveChanges() {
        setIsLoading(true);
        const newName = selectedGroupName;
        const group = {
            id: selectedGroupId,
            name: newName,
        };
        try {
            if (selectedGroupId !== null) {
                await api.chat().updateGroup(group);
                message.success(t("users.groupUpdated"));
            } else {
                await api.chat().createGroup(group);
                message.success(t("users.groupCreated"));
            }
            loadGroups();
        } catch (error: any | AxiosError) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.data?.error?.key === "CHAT_GROUP_ALREADY_EXISTS") {
                    message.error(t("users.groupAlreadyExists"));
                } else {
                    message.error(`${t("error")}: ${error.toString()}`);
                }
            } else {
                message.error(`${t("error")}: ${error.toString()}`);
            }
        }
        resetState();
        setIsLoading(false);
    }

    function onSelectGroup(group: ConversationGroup | null) {
        if (group === null) {
            return;
        }
        setSelectedGroupId(group.id);
        setSelectedGroupName(group.name);
        setModalState(ModalState.edit);
    }

    async function loadGroups() {
        const res = await api.chat().groups();
        setChatGroups(res.data.chatGroups)
    }


    return (
        <Modal
            title={t("users.manageGroups")}
            visible={isModalVisible}
            onCancel={() => closeModal()}
            footer={[
                modalState === ModalState.edit || modalState === ModalState.create ? (
                    <>
                        <Button
                            onClick={() => {
                                setModalState(ModalState.list);
                                setSelectedGroupId(null);
                            }}
                        >
                            {" "}
                            {t("back")}{" "}
                        </Button>
                        <Button onClick={saveChanges} type="primary">
                            {" "}
                            {t("save")}{" "}
                        </Button>
                    </>
                ) : (
                    <Button onClick={closeModal}> {t("close")} </Button>
                ),
            ]}
        >
            <Spin spinning={isLoading}>
                {modalState === ModalState.edit || modalState === ModalState.create ? (
                    <>
                        <label>{t("name")}</label>
                        <Input
                            value={selectedGroupName}
                            placeholder={t("users.createGroupPlaceholder")}
                            style={{ marginBottom: "10px" }}
                            onChange={handleInput}
                        />
                    </>
                ) : (
                    <>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                type="primary"
                                style={{ marginBottom: "10px" }}
                                onClick={viewNewGroup}
                            >
                                {t("create")}
                            </Button>
                        </div>
                        <Table dataSource={chatGroups} columns={columns} pagination={false} />
                    </>
                )}
            </Spin>
        </Modal>
    );
}
export default UsersGroups;
