import React, { useState, useEffect } from "react";
import api from "../../../requests/api";
import { useTranslation } from "react-i18next";
import { Modal, Table, Space, Button, Popconfirm, message, Input, Select, Spin } from "antd";
const { Option } = Select;
function CustomerGroups({ isModalVisible, isModalVisibleCb }) {
    const { t } = useTranslation();
    const [customerGroups, setCustomerGroups] = useState([]);
    const [selectedCustomerGroup, setSelectedCustomerGroup] = useState(null);
    const [selectedCustomerGroupId, setSelectedCustomerGroupId] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [modalState, setModalState] = useState("list");
    const [customers, setCustomers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [customersPaginatorPage, setCustomersPaginatorPage] = useState(0);
    const [customersPaginatorHasNext, setCustomersPaginatorHasNext] = useState(false);
    const [customerSearchText, setCustomerSearchText] = useState(null);
    const columns = [
        {
            title: t("customers.customerGroups.name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("customers.customerGroups.customers"),
            dataIndex: "customers",
            key: "customers",
            render: (item) => (item ? item.length : 0),
        },
        {
            title: t("customers.customerGroups.actions"),
            dataIndex: "actions",
            key: "actions",
            render: (item, row) => (
                <Space>
                    <Button onClick={() => setSelectedCustomerGroupId(row.id)}>
                        {" "}
                        {t("customers.customerGroups.edit")}{" "}
                    </Button>
                    <Popconfirm
                        title={t("customers.customerGroups.deleteConfirmation")}
                        onConfirm={() => confirmDelete(row.id)}
                        okText={t("customers.customerGroups.yes")}
                        cancelText={t("customers.customerGroups.no")}
                    >
                        <Button danger> {t("customers.customerGroups.delete")} </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    const confirmDelete = (id) => {
        setIsLoading(true);
        api.customers()
            .deleteGroup(id)
            .then((res) => {
                setCustomerGroups(
                    customerGroups.filter((customerGroup) => customerGroup.id !== id)
                );
                setIsLoading(false);
                message.success("Deleted");
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const loadCustomerGroup = (id) => {
        setIsLoading(true);
        api.customers()
            .group(id)
            .then((res) => {
                const customerGroup = { ...res.data, customersId: res.data.customers };
                console.log(customerGroup);
                setCustomers(customerGroup.customers);
                setSelectedCustomers(customerGroup.customers.map((customer) => customer.id));
                setModalState("edit");
                setSelectedCustomerGroup(res.data);
                setIsLoading(false);
            });
    };
    const searchCustomer = (text) => {
        setCustomersPaginatorPage(0);
        setCustomerSearchText(text);
    };
    function handleCustomersPopupScroll(e) {
        e.persist();
        let target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (customersPaginatorHasNext) {
                setCustomersPaginatorPage((customersPaginatorPage) => customersPaginatorPage + 1);
            }
        }
    }
    const resetState = () => {
        setSelectedCustomerGroupId(null);
        setSelectedCustomerGroup(null);
        setSelectedCustomers([]);
        isModalVisibleCb(false);
    };
    const closeModal = () => {
        setModalState("list");
        resetState();
    };
    const viewNewGroup = () => {
        setModalState("create");
        setSelectedCustomerGroup({ name: "", customersId: [] });
    };
    const handleInput = (e) => {
        setSelectedCustomerGroup({
            ...selectedCustomerGroup,
            name: e.target.value,
        });
    };
    const handleSelect = (list) => {
        setSelectedCustomers(list);
    };
    const saveChanges = () => {
        const group = { ...selectedCustomerGroup, customersId: selectedCustomers };
        setIsLoading(true);
        if (modalState === "create") {
            api.customers()
                .createGroup(group)
                .then((res) => {
                    message.success(t("customers.customerGroups.groupCreated"));
                    console.log(res);
                })
                .catch((err) => {
                    message.error(err.response?.data?.error?.message);
                });
        } else if (modalState === "edit") {
            api.customers()
                .editGroup(group)
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
            message.success(t("customers.customerGroups.groupUpdated"));
        }
        closeModal();
    };
    useEffect(() => {
        setIsLoading(true);
        api.customers()
            .groups()
            .then((res) => {
                setCustomerGroups(res.data.customerGroups);
                setIsLoading(false);
            });
    }, [isModalVisible]);
    useEffect(() => {
        if (selectedCustomerGroupId) {
            // load Customer Group
            loadCustomerGroup(selectedCustomerGroupId);
        } else if (selectedCustomerGroupId === "create") {
            setSelectedCustomerGroup({ name: "", customersId: [] });
        }
    }, [selectedCustomerGroupId]);
    useEffect(() => {
        setIsLoading(true);
        api.customers()
            .list(customersPaginatorPage, customerSearchText, null, null, null)
            .then((res) => {
                if (customersPaginatorPage === 0) {
                    setCustomers(res.data.customers);
                } else {
                    setCustomers((customers) => [...customers, ...res.data.customers]);
                }
                setCustomersPaginatorHasNext(res.data.paginator.hasNext);
                setIsLoading(false);
            });
    }, [customersPaginatorPage, customerSearchText]);
    return (
        <Modal
            title={t("customers.customerGroups.manageCustomerGroups")}
            visible={isModalVisible}
            onCancel={() => closeModal()}
            footer={[
                modalState === "edit" || modalState === "create" ? (
                    <>
                        <Button
                            onClick={() => {
                                setModalState("list");
                                setSelectedCustomerGroupId(null);
                            }}
                        >
                            {" "}
                            {t("customers.customerGroups.back")}{" "}
                        </Button>
                        <Button
                            onClick={saveChanges}
                            type="primary"
                            disabled={selectedCustomerGroup?.name ? false : true}
                        >
                            {" "}
                            {t("customers.customerGroups.save")}{" "}
                        </Button>
                    </>
                ) : (
                    <Button onClick={closeModal}> {t("customers.customerGroups.close")} </Button>
                ),
            ]}
        >
            <Spin spinning={isLoading}>
                {(modalState === "edit" || modalState === "create") && selectedCustomerGroup ? (
                    <>
                        <label>{t("customers.customerGroups.name")}</label>
                        <Input
                            value={selectedCustomerGroup && selectedCustomerGroup.name}
                            placeholder={t("customers.customerGroups.customerGroupNamePlaceholder")}
                            style={{ marginBottom: "10px" }}
                            onChange={handleInput}
                        />
                        <label>{t("customers.customerGroups.customers")}</label>
                        <Select
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            style={{ width: "100%" }}
                            placeholder={t("customers.customerGroups.searchCustomersPlaceholder")}
                            value={selectedCustomers}
                            onSearch={searchCustomer}
                            onChange={handleSelect}
                            onPopupScroll={handleCustomersPopupScroll}
                        >
                            {customers &&
                                customers.map((item) => (
                                    <Option value={item.id}>
                                        {item.fullName} {item.phone}{" "}
                                    </Option>
                                ))}
                        </Select>
                    </>
                ) : (
                    <>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                type="primary"
                                style={{ marginBottom: "10px" }}
                                onClick={viewNewGroup}
                            >
                                {t("customers.customerGroups.create")}
                            </Button>
                        </div>
                        <Table dataSource={customerGroups} columns={columns} pagination={false} />
                    </>
                )}
            </Spin>
        </Modal>
    );
}
export default CustomerGroups;
