import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./ChatHistory.scss";
function ChatHistory(props) {
    const [filteredChat, setFilteredChat] = useState([]);
    const [selected, setSelected] = useState({});
    const { t } = useTranslation();
    const selectedClick = function (chat) {
        setSelected(chat);
        props.selectChatCb(chat);
    };
    useEffect(() => {
        // To show only the first time the messageFrom text
        props.chat.forEach((value, index) => {
            const prev = props.chat[index - 1];
            let newItem = { ...value };
            newItem.showMessageFrom = newItem.messageFrom;
            if (prev && prev.messageFrom === value.messageFrom) {
                newItem.showMessageFrom = "";
            }
            setFilteredChat((filteredChat) => [...filteredChat, newItem]);
        });
    }, [props.chat]);
    return (
        <div className="chat-history">
            {filteredChat.map((chat, key) => (
                <div
                    key={key}
                    className={
                        "chat-history__message " +
                        (chat.messageFrom === "END_USER"
                            ? "chat-history__message--end-user"
                            : "chat-history__message--assistant")
                    }
                >
                    <span className="chat-history__from">
                        {chat.showMessageFrom
                            ? t(`chatMessageFromType.${chat.showMessageFrom}`)
                            : ""}
                    </span>
                    <div
                        className={
                            "chat-history__chat-bubble " +
                            (chat === selected ? "chat-history__chat-bubble--selected" : "")
                        }
                        onClick={() => selectedClick(chat)}
                    >
                        {chat.messagePayload.text}
                    </div>
                </div>
            ))}
        </div>
    );
}
export default ChatHistory;
