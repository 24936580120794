import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faComments } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "./ProfileData.scss";
import { useTranslation } from "react-i18next";

export default function ProfileData({ customer, conversationData }) {
    const { t } = useTranslation();
    return (
        <div className="profile-box">
            <div className="profile-box__avatar">
                <div className="profile-box__icon-wrapper">
                    <FontAwesomeIcon icon={faUser} className="profile-box__icon" />
                </div>
                <div className="profile-box__info">
                    <p className="opt-title">
                        {customer?.fullName ? customer.fullName : t("clientForm.nameEmpty")}
                    </p>
                    <p className="opt-label">
                        {customer?.email ? customer.email : t("clientForm.emailEmpty")}
                    </p>
                </div>
                <div className="profile-box__last-update">
                    <p className="opt-subtitle">{t("clientForm.phone")}</p>
                    <p className="opt-label">
                        {customer?.phone ? customer.phone : t("notRegistered")}
                    </p>
                </div>
            </div>
            <div className="profile-box__more-info">
                <div className="profile-box__icon-wrapper">
                    <FontAwesomeIcon icon={faComments} className="profile-box__icon" />
                </div>
                <div className="profile-box__info">
                    <div className="profile-box__dates">
                        <p className="opt-subtitle">{t("profileData.conversationCreated")}</p>
                        <p className="opt-label">
                            {conversationData?.createdOn
                                ? moment(conversationData.createdOn).format("DD/MM/YY HH:mm")
                                : t("notRegistered")}
                        </p>
                    </div>
                    <div className="profile-box__dates">
                        <p className="opt-subtitle">{t("profileData.lastUpdate")}</p>
                        <p className="opt-label">
                            {conversationData?.updatedOn
                                ? moment(conversationData.updatedOn).format("DD/MM/YY HH:mm")
                                : t("notRegistered")}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
