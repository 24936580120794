import { Agent } from "frontend-lib/DomainTypes/User";
import { useCallback, useEffect, useState } from "react";
import api from "../../../requests/api";
interface Paginator {
    page: number;
    hasNext: boolean;
}

export default function useAgentsWithPaginator(
    paginate: boolean = true
): [Agent[], boolean, () => Promise<void>] {
    const [agents, setAgents] = useState<Agent[]>([]);
    const [paginator, setPaginator] = useState<Paginator>({
        page: 0,
        hasNext: false,
    });

    const loadAgents = useCallback(async (pageNumber: number) => {
        const res = await api.users().list(pageNumber, 32, paginate);
        setAgents((agents) => [...agents, ...res.data.users]);
        setPaginator({
            page: pageNumber,
            hasNext: res.data.paginator?.hasNext,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadMore() {
        if (!paginator.hasNext) {
            return;
        }
        const nextPageNumber = paginator.page + 1;
        loadAgents(nextPageNumber);
    }

    useEffect(() => {
        loadAgents(0);
    }, [loadAgents]);

    return [agents, paginator.hasNext, loadMore];
}
