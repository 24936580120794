import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

let api = {
    api_url() {
        return {
            get: () => {
                return apiUrl;
            },
        };
    },
    globalUser() {
        return {
            get: (userId) => {
                return axios.get(`${apiUrl}/globaluser/${userId}/`, { withCredentials: true });
            },
            create: ({
                email,
                password,
                passwordRepeated,
                firstName,
                lastName,
                hasAcceptedTermsAndCons,
            }) => {
                return axios.post(`${apiUrl}/globaluser/`, {
                    email,
                    password,
                    passwordRepeated,
                    firstName,
                    lastName,
                    hasAcceptedTermsAndCons,
                });
            },
            createWithFirebase: ({ token }) => {
                return axios.post(`${apiUrl}/globaluser/firebase/`, { token });
            },
        };
    },
    users() {
        return {
            create_admin_user: (params) =>
                axios.post(`${apiUrl}/users/`, {
                    firstName: params.firstName,
                    lastName: params.lastName,
                    email: params.email,
                    password: params.password,
                    passwordRepeated: params.passwordRepeated,
                    isAdmin: true,
                    workspace: {
                        id: params.workspaceId,
                    },
                    hasAcceptedTermsAndCons: params.hasAcceptedTermsAndCons,
                }),
            create_user: (user, widthCredentials) => {
                return axios.post(`${apiUrl}/users/`, user, {
                    withCredentials: widthCredentials,
                });
            },
            list: (page = 0, amount = 9, paginate = true) => {
                return axios.get(
                    `${apiUrl}/users/?page=${page}&amount=${amount}&paginate=${paginate}`,
                    {
                        withCredentials: true,
                    }
                );
            },
            edit: (user) => {
                return axios.put(`${apiUrl}/users/${user.id}/`, user, {
                    withCredentials: true,
                });
            },
            clearPassword: (email) => {
                return axios.put(`${apiUrl}/users/password-clearing/`, {
                    email: email,
                });
            },
            delete: (user) => {
                return axios.delete(`${apiUrl}/users/${user.id}/`, {
                    withCredentials: true,
                });
            },
        };
    },
    agent() {
        return {
            list: (agentId, startDate, endDate, page = 0, amount = 20) => {
                const objParams = {
                    page: page,
                    amount: amount,
                    startDate: startDate,
                    endDate: endDate,
                };
                const queryParams = new URLSearchParams(objParams);
                return axios.get(
                    `${apiUrl}/agents/${agentId}/historic-agent-state/?${queryParams}`,
                    { withCredentials: true }
                );
            },
        };
    },
    files() {
        return {
            list: (page) => {
                return axios.get(`${apiUrl}/file-metadata/?page=${page}&amount=32`, {
                    withCredentials: true,
                });
            },
            createFileMetadata: (fileMetadata) => {
                return axios.post(`${apiUrl}/file-metadata/`, fileMetadata, {
                    withCredentials: true,
                });
            },
            deleteFileMetadata: (id) => {
                return axios.delete(`${apiUrl}/file-metadata/${id}/`, {
                    withCredentials: true,
                });
            },
        };
    },
    customerImportFile() {
        return {
            list: () => {
                return axios.get(`${apiUrl}/customers/groups/import/`, { withCredentials: true });
            },
        };
    },
    workspaces() {
        return {
            get: (id) => {
                return axios.get(`${apiUrl}/workspaces/${id}/`, {
                    withCredentials: true,
                });
            },
            createWorkspace: (workspaceName, workspaceLanguage, firstUser) =>
                axios.post(
                    `${apiUrl}/workspaces/`,
                    {
                        name: workspaceName,
                        workspaceOwner: firstUser,
                        jsonConfigs: {
                            language: workspaceLanguage,
                        },
                    },
                    { withCredentials: true }
                ),
            createWorkspaceV2: (name, adminEmail, workspaceLanguage, timezoneOffset) => {
                return axios.post(`${apiUrl}/v2/workspaces/`, {
                    name,
                    adminEmail,
                    jsonConfigs: {
                        language: workspaceLanguage,
                        backend: {
                            automation: {
                                channels: {
                                    aiAgent: {
                                        tz: timezoneOffset,
                                    },
                                    humanAgent: {
                                        tz: timezoneOffset,
                                    },
                                },
                            },
                        },
                    },
                });
            },
            invite: (email, workspaceId) =>
                axios.post(
                    `${apiUrl}/workspaces/${workspaceId}/join-request/`,
                    {
                        email: email,
                    },
                    { withCredentials: true }
                ),
            deleteInvite: (joinRequestId, workspaceId) =>
                axios.delete(
                    `${apiUrl}/workspaces/${workspaceId}/join-requests/${joinRequestId}/`,
                    { withCredentials: true }
                ),
            listInvites: (workspaceId) =>
                axios.get(`${apiUrl}/workspaces/${workspaceId}/join-requests/`, {
                    withCredentials: true,
                }),
            inviteMany: (emails, workspaceId) => {
                // TODO: The backend should have an API to invite many. This is too inefficient
                let promises = [];
                for (let email of emails) {
                    let promise = api.workspaces().invite(email, workspaceId);
                    promises.push(promise);
                }
                return axios.all(promises);
            },
            generateToken: (workspaceId) => {
                return axios.post(
                    `${apiUrl}/workspaces/${workspaceId}/tokens/`,
                    {},
                    { withCredentials: true }
                );
            },
            counts: (workspaceId) =>
                axios.get(`${apiUrl}/workspaces/${workspaceId}/counts/`, {
                    withCredentials: true,
                }),
            channels: (workspaceId) =>
                axios.get(`${apiUrl}/workspaces/${workspaceId}/channels/`, {
                    withCredentials: true,
                }),
            updateConfig: (workspaceId, configs, mergeConfigs) =>
                axios.put(
                    `${apiUrl}/workspaces/${workspaceId}/configs/`,
                    {
                        jsonConfigs: configs,
                        mergeConfigs: mergeConfigs,
                    },
                    { withCredentials: true }
                ),
            join: (
                token,
                password,
                passwordRepeated,
                firstName,
                lastName,
                hasAcceptedTermsAndCons
            ) => {
                const payload = {
                    token,
                    password,
                    passwordRepeated,
                    firstName,
                    lastName,
                    hasAcceptedTermsAndCons,
                };

                return axios.post(`${apiUrl}/workspaces/join/`, payload);
            },
        };
    },
    file() {
        return {
            uploadFile: (workspaceId, file) => {
                return axios.post(`${apiUrl}/workspaces/${workspaceId}/files/`, file, {
                    withCredentials: true,
                });
            },
        };
    },
    login() {
        return {
            session_create: (params) =>
                axios.post(
                    `${apiUrl}/sessions/`,
                    {
                        email: params.email,
                        password: params.password,
                    },
                    { withCredentials: true }
                ),
            signIn: (email, password) =>
                axios.post(
                    `${apiUrl}/sessions/`,
                    {
                        email: email,
                        password: password,
                    },
                    { withCredentials: true }
                ),
            logout: () => axios.delete(`${apiUrl}/sessions/`, { withCredentials: true }),
            isLoggedIn: () => axios.get(`${apiUrl}/sessions/`, { withCredentials: true }),
            changeWorkspace(workspaceId) {
                const payload = {
                    workspaceId,
                };
                return axios.put(`${apiUrl}/sessions/`, payload, { withCredentials: true });
            },
            signInFirebase: (token) => {
                const payload = { token };
                return axios.post(`${apiUrl}/sessions/firebase/`, payload, {
                    withCredentials: true,
                });
            },
        };
    },
    content() {
        return {
            upload: (file, workspaceId) => {
                return axios.post(`${apiUrl}/workspaces/${workspaceId}/files/`, file, {
                    withCredentials: true,
                });
            },
            download: (fileName, workspaceId, responseType = "blob") => {
                let downloadUrl = `${apiUrl}/workspaces/${workspaceId}/files/?fileName=${fileName}`;
                return axios.get(downloadUrl, {
                    withCredentials: true,
                    responseType: responseType,
                });
            },
        };
    },
    assistant() {
        return {
            train: (workspaceId, question, answer) =>
                axios.post(
                    `${apiUrl}/workspaces/${workspaceId}/assistant/user/train/`,
                    {
                        question: question,
                        answer: answer,
                    },
                    { withCredentials: true }
                ),
            get: (workspaceId, question) =>
                axios.post(
                    `${apiUrl}/workspaces/${workspaceId}/assistant/predict/top/`,
                    { question: question, amt: 10 },
                    { withCredentials: true }
                ),
        };
    },
    chat() {
        return {
            list: (token, uuid) => {
                if (token) {
                    return axios.get(`${apiUrl}/chat/history/?user_uuid=${uuid}&page=0&amount=20`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                } else {
                    return axios.get(`${apiUrl}/chat/history/?user_uuid=${uuid}&page=0&amount=20`, {
                        withCredentials: true,
                    });
                }
            },
            create: (token, uuid, messagePayload, messageFrom) => {
                return axios.post(
                    `${apiUrl}/chat/messages/`,
                    {
                        userUuid: uuid,
                        messagePayload: messagePayload,
                        messageFrom: messageFrom,
                    },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
            },
            groups: () => {
                return axios.get(`${apiUrl}/chat/groups/`, { withCredentials: true });
            },
            createGroup: (newGroup) => {
                return axios.post(`${apiUrl}/chat/groups/`, newGroup, {
                    withCredentials: true,
                });
            },
            updateGroup: (group) => {
                return axios.put(`${apiUrl}/chat/groups/${group.id}/`, group, {
                    withCredentials: true,
                });
            },
            deleteGroup: (group) => {
                return axios.delete(`${apiUrl}/chat/groups/${group.id}/`, {
                    withCredentials: true,
                });
            },
        };
    },
    knowledge() {
        return {
            list: (page, amount, tagsId, searchText, searchKeywords, baseChatbotId, userId) => {
                if (!amount) {
                    amount = 9;
                }

                const queryParams = new URLSearchParams({
                    page: page,
                    amount: amount,
                });

                if (tagsId?.length) {
                    for (let i = 0; i < tagsId.length; i++) {
                        queryParams.append("tagsId", tagsId[i]);
                    }
                }

                if (searchText?.length) {
                    queryParams.append("searchText", searchText);
                }

                if (searchKeywords) {
                    searchKeywords.forEach((keyword) => {
                        queryParams.append("searchKeywords", keyword);
                    });
                }

                if (baseChatbotId) {
                    queryParams.append("baseChatbotId", baseChatbotId);
                }

                if (userId) {
                    queryParams.append("userId", userId);
                }

                return axios.get(`${apiUrl}/knowledge-records/?${queryParams}`, {
                    withCredentials: true,
                });
            },
            get: (knowledgeRecordId) => {
                return axios.get(`${apiUrl}/knowledge-records/${knowledgeRecordId}/`, {
                    withCredentials: true,
                });
            },
            create: (knowledge) => {
                return axios.post(`${apiUrl}/knowledge-records/`, knowledge, {
                    withCredentials: true,
                });
            },
            edit: (knowledge) => {
                return axios.put(`${apiUrl}/knowledge-records/${knowledge.id}/`, knowledge, {
                    withCredentials: true,
                });
            },
            delete: (knowledgeId) => {
                return axios.delete(`${apiUrl}/knowledge-records/${knowledgeId}/`, {
                    withCredentials: true,
                });
            },
            addNewWayOfAsk: (knowledgeId, question) => {
                return axios.put(
                    `${apiUrl}/knowledge-records/${knowledgeId}/questions/`,
                    question,
                    { withCredentials: true }
                );
            },
            removeWayOfAsk: (knowledgeId, questionId) => {
                return axios.delete(
                    `${apiUrl}/knowledge-records/${knowledgeId}/questions/${questionId}/`,
                    { withCredentials: true }
                );
            },
            getCategories: () => {
                return axios.get(`${apiUrl}/knowledge-records/categories/`, {
                    withCredentials: true,
                });
            },
            getTags: () => {
                return axios.get(`${apiUrl}/knowledge-records/tags/`, {
                    withCredentials: true,
                });
            },
            createTag: (value) => {
                return axios.post(
                    `${apiUrl}/knowledge-records/tags/`,
                    { value: value },
                    { withCredentials: true }
                );
            },
            deleteTag: (tagId) => {
                return axios.delete(`${apiUrl}/knowledge-records/tags/${tagId}/`, {
                    withCredentials: true,
                });
            },
        };
    },
    report() {
        return {
            get: (startDate, endDate) => {
                return axios.get(
                    `${apiUrl}/reports/conversations-insights/?start_date=${startDate}&end_date=${endDate}`,
                    { withCredentials: true }
                );
            },
        };
    },
    reports() {
        return {
            workspace: (startDate, endDate, frequency, dateField = null) => {
                const queryParams = new URLSearchParams({
                    startDate: startDate,
                    endDate: endDate,
                    frequency: frequency,
                    dateFilterField: dateField,
                });
                return axios.get(
                    `${apiUrl}/reports/conversations-stats/workspace/?${queryParams}`,
                    { withCredentials: true }
                );
            },
            agents: (startDate, endDate, frequency, agent, dateField = null) => {
                const queryParams = new URLSearchParams({
                    startDate: startDate,
                    endDate: endDate,
                    frequency: frequency,
                    usersId: agent,
                    dateFilterField: dateField,
                });
                return axios.get(`${apiUrl}/reports/conversations-stats/agents/?${queryParams}`, {
                    withCredentials: true,
                });
            },
            agentsAvgTimeInState: (startDate, endDate, agentId) => {
                const queryParams = new URLSearchParams({
                    startDate: startDate,
                    endDate: endDate,
                    agentsId: [agentId],
                });
                return axios.get(`${apiUrl}/reports/agents/average-time/?${queryParams}`, {
                    withCredentials: true,
                });
            },
            agentsTimeInState: (startDate, endDate, frequency, agentId) => {
                const queryParams = new URLSearchParams({
                    startDate: startDate,
                    endDate: endDate,
                    agentsId: [agentId],
                    frequency: frequency,
                });
                return axios.get(`${apiUrl}/reports/agents/sum-time/?${queryParams}`, {
                    withCredentials: true,
                });
            },
            tags: (startDate, endDate, frequency, tag, dateField = null) => {
                const queryParams = new URLSearchParams({
                    startDate: startDate,
                    endDate: endDate,
                    frequency: frequency,
                    knowledgeRecordTagsId: tag,
                    dateFilterField: dateField,
                });
                return axios.get(
                    `${apiUrl}/reports/conversations-stats/knowledge-record-tags/?${queryParams}`,
                    { withCredentials: true }
                );
            },
            interactions: (startDate, endDate, frequency, dateField = null) => {
                const queryParams = new URLSearchParams({
                    startDate: startDate,
                    endDate: endDate,
                    frequency: frequency,
                    dateFilterField: dateField,
                });
                return axios.get(
                    `${apiUrl}/reports/conversations-stats/interactions-distribution/?${queryParams}`,
                    { withCredentials: true }
                );
            },
            answers: (startDate, endDate, frequency, kr_ids, dateField = null) => {
                const queryParams = new URLSearchParams({
                    startDate: startDate,
                    endDate: endDate,
                    frequency: frequency,
                    dateFilterField: dateField,
                });
                for (let i = 0; i < kr_ids.length; i++) {
                    queryParams.append("knowledgeRecordId", kr_ids[i]);
                }
                return axios.get(
                    `${apiUrl}/reports/conversations-stats/knowledge-records/?${queryParams}`,
                    { withCredentials: true }
                );
            },
            labels: (startDate, endDate, frequency, cl_ids, dateField = null) => {
                const queryParams = new URLSearchParams({
                    startDate: startDate,
                    endDate: endDate,
                    frequency: frequency,
                    dateFilterField: dateField,
                });
                for (let i = 0; i < cl_ids.length; i++) {
                    queryParams.append("conversationLabelsId", cl_ids[i]);
                }
                return axios.get(
                    `${apiUrl}/reports/conversations-stats/conversation-labels/?${queryParams}`,
                    { withCredentials: true }
                );
            },
            export: (startDate, endDate, frequency, dateField = null) => {
                if (!startDate || !endDate || !frequency) {
                    return Promise.reject();
                }
                const queryParams = new URLSearchParams({
                    startDate: startDate,
                    endDate: endDate,
                    frequency: frequency,
                    dateFilterField: dateField,
                });
                return axios.get(`${apiUrl}/reports/export/?${queryParams}`, {
                    withCredentials: true,
                    responseType: "blob",
                });
            },
        };
    },
    monitoring() {
        return {
            list: (
                page,
                chatStatus,
                startDateTime,
                endDateTime,
                dateFieldFilter,
                customerChannel,
                customerId,
                participatingAgentId,
                customerStatus,
                searchText,
                tagsId,
                groupedTagsIds,
                sortBy,
                useSortConfig,
                owner
            ) => {
                const objParams = {
                    page: page,
                    status: chatStatus,
                    startDatetime: startDateTime,
                    endDatetime: endDateTime,
                    dateFieldFilter: dateFieldFilter,
                    searchText: searchText,
                    sortBy: sortBy,
                    useSortConfig: useSortConfig,
                    owner: owner,
                };
                if (customerChannel && customerChannel !== "ALL") {
                    if (customerChannel === "FB_COMMENT") {
                        objParams.customerChannel = "FACEBOOK_COMMENT";
                    } else {
                        objParams.customerChannel = customerChannel;
                    }
                }
                if (customerStatus) {
                    objParams.customerStatus =
                        customerStatus === "NOT_REGISTERED" ? "NOT_HAS_CUSTOMER" : customerStatus;
                }
                if (customerId) {
                    objParams.customerId = customerId;
                }
                if (participatingAgentId) {
                    objParams.participatingAgentId = participatingAgentId;
                }
                objParams.fetchLatestConversationPerCustomerUuid = false;
                const queryParams = new URLSearchParams(objParams);
                if (groupedTagsIds) {
                    const groupedTagIdsStr = JSON.stringify(groupedTagsIds);
                    queryParams.append("groupedChatLabels", groupedTagIdsStr);
                } else {
                    if (tagsId && tagsId.length > 0) {
                        for (let i = 0; i < tagsId.length; i++) {
                            queryParams.append("chatLabelsId", tagsId[i]);
                        }
                    }
                }
                return axios.get(`${apiUrl}/chat/conversation/?${queryParams}`, {
                    withCredentials: true,
                });
            },
            getConversation: (chatId) => {
                return axios.get(`${apiUrl}/monitoring/chats/${chatId}/insights/`, {
                    withCredentials: true,
                });
            },
            getByIds: (page, chatsIds) => {
                const queryParams = new URLSearchParams({
                    page: page,
                });
                for (let i = 0; i < chatsIds.length; i++) {
                    queryParams.append("id", chatsIds[i]);
                }
                return axios.get(`${apiUrl}/monitoring/chats/ids/?${queryParams}`, {
                    withCredentials: true,
                });
            },
        };
    },
    customers() {
        return {
            list: (
                page,
                searchText,
                createdStartDate,
                createdEndDate,
                has_whatsapp_opt_in,
                customersId,
                customerGroupsIds,
                conversationTagsIds,
                conversationGroupsIds,
                agentsIds,
                orCustomersAndGroups,
                lastActivityStartDate,
                lastActivityEndDate
            ) => {
                const objParams = {
                    page: page,
                    amount: 9,
                    sortBy: "LAST_ACTIVITY",
                };
                if (searchText) {
                    objParams.searchText = searchText;
                }
                if (createdStartDate) {
                    objParams.startDate = createdStartDate;
                }
                if (createdEndDate) {
                    objParams.endDate = createdEndDate;
                }
                if (has_whatsapp_opt_in) {
                    objParams.has_whatsapp_opt_in = has_whatsapp_opt_in;
                }
                if (customerGroupsIds) {
                    objParams.customerGroupsIds = JSON.stringify(customerGroupsIds);
                }
                if (conversationTagsIds) {
                    objParams.conversationLabelIds = JSON.stringify(conversationTagsIds);
                }
                if (customersId) {
                    objParams.customersId = JSON.stringify(customersId);
                }
                if (conversationGroupsIds) {
                    objParams.conversationGroupsIds = JSON.stringify(conversationGroupsIds);
                }
                if (agentsIds) {
                    objParams.affinityUsersIds = JSON.stringify(agentsIds);
                }
                if (orCustomersAndGroups) {
                    objParams.orCustomersAndGroups = JSON.stringify(orCustomersAndGroups);
                }
                if (lastActivityStartDate && lastActivityEndDate) {
                    objParams.lastActivityStart = lastActivityStartDate;
                    objParams.lastActivityEnd = lastActivityEndDate;
                }

                const queryParams = new URLSearchParams(objParams);
                return axios.get(`${apiUrl}/customers/?${queryParams}`, {
                    withCredentials: true,
                });
            },
            search: (
                page,
                searchText,
                createdStartDate,
                createdEndDate,
                has_whatsapp_opt_in,
                customersId,
                customerGroupsIds,
                conversationTagsIds,
                conversationGroupsIds,
                agentsIds,
                orCustomersAndGroups,
                lastActivityStartDate,
                lastActivityEndDate
            ) => {
                const filtersBody = {
                    page: page,
                    amount: 9,
                    sortBy: "LAST_ACTIVITY",
                    searchText: searchText,
                    startDate: createdStartDate,
                    endDate: createdEndDate,
                    has_whatsapp_opt_in: has_whatsapp_opt_in ? has_whatsapp_opt_in : null,
                    customersId: customersId,
                    customerGroupsIds: customerGroupsIds,
                    conversationLabelIds: conversationTagsIds,
                    conversationGroupsIds: conversationGroupsIds,
                    affinityUsersIds: agentsIds,
                    orCustomersAndGroups: orCustomersAndGroups,
                    lastActivityStart: lastActivityStartDate,
                    lastActivityEnd: lastActivityEndDate,
                };

                return axios.post(`${apiUrl}/customers/search/`, filtersBody, {
                    withCredentials: true,
                });
            },
            update: (customer) => {
                return axios.put(`${apiUrl}/customers/${customer.id}/`, customer, {
                    withCredentials: true,
                });
            },
            delete: (id) => {
                return axios.delete(`${apiUrl}/customers/${id}/`, {
                    withCredentials: true,
                });
            },
            exportCustomers: (
                searchText,
                startDate,
                endDate,
                hasWhatsappOptIn,
                conversationTagsIds,
                conversationGroupsIds,
                affinityUsersId,
                customersId,
                customerGroupsId,
                lastActivityStart,
                lastActivityEnd,
                includeHistory
            ) => {
                const payload = {
                    searchText,
                    startDate,
                    endDate,
                    hasWhatsappOptIn,
                    conversationTagsIds,
                    conversationGroupsIds,
                    affinityUsersId,
                    customersId,
                    customerGroupsId,
                    lastActivityStart,
                    lastActivityEnd,
                    includeHistory,
                };
                let payloadWithoutNull = Object.fromEntries(
                    Object.entries(payload).filter(([_, v]) => v != null)
                );

                return axios.post(`${apiUrl}/customers/export/`, payloadWithoutNull, {
                    withCredentials: true,
                    responseType: "blob",
                });
            },
            groups: () => {
                return axios.get(`${apiUrl}/customers/groups/`, {
                    withCredentials: true,
                });
            },
            group: (id) => {
                return axios.get(`${apiUrl}/customers/groups/${id}/`, {
                    withCredentials: true,
                });
            },
            deleteGroup: (id) => {
                return axios.delete(`${apiUrl}/customers/groups/${id}/`, {
                    withCredentials: true,
                });
            },
            editGroup: (edited) => {
                return axios.put(`${apiUrl}/customers/groups/${edited.id}/`, edited, {
                    withCredentials: true,
                });
            },
            createGroup: (group) => {
                return axios.post(`${apiUrl}/customers/groups/`, group, {
                    withCredentials: true,
                });
            },
            listCustomerField: () => {
                return axios.get(`${apiUrl}/customers/fields/`, {
                    withCredentials: true,
                });
            },
            createCustomerField: (newField) => {
                return axios.post(`${apiUrl}/customers/fields/`, newField, {
                    withCredentials: true,
                });
            },
            updateCustomerField: (id, editedField) => {
                return axios.put(`${apiUrl}/customers/fields/${id}/`, editedField, {
                    withCredentials: true,
                });
            },
            deleteCustomerField: (id) => {
                return axios.delete(`${apiUrl}/customers/fields/${id}/`, {
                    withCredentials: true,
                });
            },
            lastConvId: (customerId) => {
                return axios.get(`${apiUrl}/customers/${customerId}/lastchat/`, {
                    withCredentials: true,
                });
            },
        };
    },
    featureRequests() {
        return {
            create: (type) => {
                const data = { type: type };
                return axios.post(`${apiUrl}/feature-requests/`, data, {
                    withCredentials: true,
                });
            },
            getFacebookAuthUrl: (instagramConnection, currentPath) => {
                const data = {
                    type: "FACEBOOK_AUTH_URL",
                    instagramConnection: instagramConnection,
                    currentPath: currentPath,
                };
                return axios.post(`${apiUrl}/feature-requests/`, data, {
                    withCredentials: true,
                });
            },
        };
    },
    chatbots() {
        return {
            list: () => {
                return axios.get(`${apiUrl}/chatbots/`, {
                    withCredentials: true,
                });
            },
            create: (name, isActive, chatChannels) => {
                return axios.post(
                    `${apiUrl}/chatbots/`,
                    {
                        name: name,
                        isActive: isActive,
                        chatChannels: chatChannels,
                    },
                    { withCredentials: true }
                );
            },
            update: (baseChatBotId, name, isActive, chatChannels) => {
                return axios.put(
                    `${apiUrl}/chatbots/${baseChatBotId}/`,
                    {
                        name: name,
                        isActive: isActive,
                        chatChannels: chatChannels,
                    },
                    { withCredentials: true }
                );
            },
            delete: (baseChatBotId) => {
                return axios.delete(`${apiUrl}/chatbots/${baseChatBotId}/`, {
                    withCredentials: true,
                });
            },
            listBotChannels: () => {
                return axios.get(`${apiUrl}/chatbots/channels/`, {
                    withCredentials: true,
                });
            },
            getConfigs: (baseChatBotId) => {
                return axios.get(`${apiUrl}/chatbots/${baseChatBotId}/configs/`, {
                    withCredentials: true,
                });
            },
            createConfigs: (configs, baseChatBotId) => {
                return axios.post(
                    `${apiUrl}/chatbots/configs/`,
                    {
                        botConfigs: configs,
                        baseChatBotId: baseChatBotId,
                    },
                    {
                        withCredentials: true,
                    }
                );
            },
            updateConfigs: (configs) => {
                return axios.put(`${apiUrl}/chatbots/configs/`, configs, {
                    withCredentials: true,
                });
            },
            listFacebookMessengerInstagramBots: () => {
                return axios.get(`${apiUrl}/chatbots/aggregated-facebook-messenger-instagram/`, {
                    withCredentials: true,
                });
            },
            facebookConnect: (userAccessToken) => {
                const body = {
                    userAccessToken: userAccessToken,
                };
                return axios.post(
                    `${apiUrl}/chatbots/facebook-messenger-instagram/connection/`,
                    body,
                    { withCredentials: true }
                );
            },
            facebookUnsubscribe: (pageId) => {
                return axios.delete(
                    `${apiUrl}/chatbots/facebook-messenger-instagram/connection/${pageId}/`,
                    { withCredentials: true }
                );
            },
            facebookSubscribe: (pageId) => {
                return axios.put(
                    `${apiUrl}/chatbots/facebook-messenger-instagram/connection/${pageId}/`,
                    {},
                    { withCredentials: true }
                );
            },
            intentionsList: (baseChatbotId, searchKeywords) => {
                const objParams = {};
                if (baseChatbotId) {
                    objParams.baseChatbotId = baseChatbotId;
                }

                const queryParams = new URLSearchParams(objParams);
                if (searchKeywords) {
                    searchKeywords.forEach((keyword) => {
                        queryParams.append("searchKeywords", keyword);
                    });
                }

                return axios.get(`${apiUrl}/chatbots/intentions/?${queryParams}`, {
                    withCredentials: true,
                });
            },
            intentionCreate: (
                name,
                key,
                examples,
                description,
                xml,
                forAllChatbots,
                baseChatbotsId
            ) => {
                return axios.post(
                    `${apiUrl}/chatbots/intentions/`,
                    {
                        name: name,
                        key: key,
                        examples: examples,
                        description: description,
                        xml: xml,
                        forAllChatbots: forAllChatbots,
                        baseChatbotsId: baseChatbotsId,
                    },
                    { withCredentials: true }
                );
            },
            intentionUpdate: (
                intentionId,
                newName,
                newKey,
                newExamples,
                newDescription,
                newXml,
                newForAllChatbots,
                newBaseChatbots
            ) => {
                return axios.put(
                    `${apiUrl}/chatbots/intentions/${intentionId}/`,
                    {
                        name: newName,
                        key: newKey,
                        examples: newExamples,
                        description: newDescription,
                        xml: newXml,
                        forAllChatbots: newForAllChatbots,
                        baseChatbotsId: newBaseChatbots,
                    },
                    { withCredentials: true }
                );
            },
            intentionDelete: (intentionId) => {
                return axios.delete(`${apiUrl}/chatbots/intentions/${intentionId}/`, {
                    withCredentials: true,
                });
            },
            xmlValidation: (xml) => {
                return axios.post(
                    `${apiUrl}/chatbots/xml-validation/`,
                    {
                        xml: xml,
                    },
                    { withCredentials: true }
                );
            },
            promptsList: () => {
                return axios.get(`${apiUrl}/chatbots/prompts/`, {
                    withCredentials: true,
                });
            },
            promptCreate: (key, content) => {
                return axios.post(
                    `${apiUrl}/chatbots/prompts/`,
                    {
                        key: key,
                        content: content,
                    },
                    { withCredentials: true }
                );
            },
            systemPromptCreate: (systemPrompt) => {
                return axios.post(
                    `${apiUrl}/chatbots/prompts/system-prompts/`,
                    {
                        systemPrompt: systemPrompt,
                    },
                    { withCredentials: true }
                );
            },
            promptUpdate: (promptId, newContent) => {
                return axios.put(
                    `${apiUrl}/chatbots/prompts/${promptId}/`,
                    {
                        content: newContent,
                    },
                    { withCredentials: true }
                );
            },
            promptDelete: (promptId) => {
                return axios.delete(`${apiUrl}/chatbots/prompts/${promptId}/`, {
                    withCredentials: true,
                });
            },
        };
    },
    outbound() {
        return {
            getConfigs: () => {
                return axios.get(`${apiUrl}/whatsapp-outbound-config/`, {
                    withCredentials: true,
                });
            },
            getWhatsappTemplates: () => {
                return axios.get(`${apiUrl}/whatsapp-templates/`, {
                    withCredentials: true,
                });
            },
            createWhatsappTemplate: (template) => {
                return axios.post(`${apiUrl}/whatsapp-templates/`, template, {
                    withCredentials: true,
                });
            },
            deleteWhatsappTemplate: (template) => {
                return axios.delete(`${apiUrl}/whatsapp-templates/${template.id}/`, {
                    withCredentials: true,
                });
            },
            /**
             *
             * @param {'ONE_CUSTOMER' | 'MANY_CUSTOMERS' | null} type
             * @returns
             */

            getWhatsappCampaigns: (page, type) => {
                const objParams = {
                    paginate: true,
                    page: page,
                    amount: 9,
                    sortByCreationDate: true,
                };
                if (type != null) {
                    objParams.campaignType = type;
                }

                const queryParams = new URLSearchParams(objParams);
                return axios.get(`${apiUrl}/whatsapp-campaigns/?${queryParams}`, {
                    withCredentials: true,
                });
            },
            getWhatsappCampaign: (id) => {
                return axios.get(`${apiUrl}/whatsapp-campaigns/${id}/`, {
                    withCredentials: true,
                });
            },
            createWhatsappCampaign: (campaign) => {
                return axios.post(`${apiUrl}/whatsapp-campaigns/`, campaign, {
                    withCredentials: true,
                });
            },
            updateWhatsappCampaign: (campaign) => {
                return axios.put(`${apiUrl}/whatsapp-campaigns/${campaign.id}/`, campaign, {
                    withCredentials: true,
                });
            },
            sendCampaign: (id) => {
                return axios.put(
                    `${apiUrl}/whatsapp-campaigns/${id}/`,
                    { sendCampaign: true },
                    { withCredentials: true }
                );
            },
        };
    },
    tags() {
        return {
            list: () => {
                return axios.get(`${apiUrl}/chat-labels/`, { withCredentials: true });
            },
            listByConversation: (id) => {
                return axios.get(`${apiUrl}/chats-labeler/?chatId=${id}`, {
                    withCredentials: true,
                });
            },
            create: (tag) => {
                return axios.post(`${apiUrl}/chat-labels/`, tag, {
                    withCredentials: true,
                });
            },
            update: (id, value) => {
                return axios.put(
                    `${apiUrl}/chat-labels/${id}/`,
                    { value: value },
                    { withCredentials: true }
                );
            },
            delete: (id) => {
                return axios.delete(`${apiUrl}/chat-labels/${id}/`, {
                    withCredentials: true,
                });
            },
            listWithoutGroup: () => {
                return axios.get(`${apiUrl}/chat-labels/`, {
                    withCredentials: true,
                    params: { withoutGroup: true },
                });
            },
        };
    },
    tagsGroups() {
        return {
            list: () => {
                return axios.get(`${apiUrl}/chat-label-groups/`, {
                    withCredentials: true,
                });
            },
            createConversationLabel: (group) => {
                return axios.post(`${apiUrl}/chat-label-groups/`, group, {
                    withCredentials: true,
                });
            },
            get: (id) => {
                return axios.get(`${apiUrl}/chat-label-groups/${id}/`, {
                    withCredentials: true,
                });
            },
            edit: (group) => {
                return axios.put(
                    `${apiUrl}/chat-label-groups/${group.id}/`,
                    { name: group.name },
                    { withCredentials: true }
                );
            },
            delete: (id) => {
                return axios.delete(`${apiUrl}/chat-label-groups/${id}/`, {
                    withCredentials: true,
                });
            },
            removeLabelFromGroup: (groupId, labelId) => {
                return axios.delete(
                    `${apiUrl}/chat-label-groups/${groupId}/chat-labels/${labelId}/`,
                    { withCredentials: true }
                );
            },
            addLabelToGroup: (groupId, labelId) => {
                return axios.put(
                    `${apiUrl}/chat-label-groups/${groupId}/chat-labels/${labelId}/`,
                    {},
                    { withCredentials: true }
                );
            },
        };
    },
    integrations() {
        return {
            getSalesforceIntegration: () => {
                return axios.get(`${apiUrl}/integrations/saleforce/`, {
                    withCredentials: true,
                });
            },
            connectSalesforceIntegration: (clientId, clientSecret, username, password, isSandbox) => {
                return axios.post(
                    `${apiUrl}/integrations/saleforce/`,
                    { clientId, clientSecret, username, password, isSandbox },
                    { withCredentials: true }
                );
            },
        };
    },
    interactionsRecord() {
        return {
            exportInteractionsRecord: (conversationId) => {
                return axios.get(`${apiUrl}/interactions-record/conversation/${conversationId}/`, 
                    {withCredentials: true});
            },
        };
    },
};

export default api;
