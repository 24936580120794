import React from "react";
import { useTranslation } from "react-i18next";
import { Table, Button, Space, Popconfirm } from "antd";
function CrudManagerTable({ createCb, deleteCb, dataSource, columns }) {
    const { t } = useTranslation();
    const tableColumns = !columns
        ? []
        : [
              ...columns,
              ...[
                  {
                      title: t("customers.customerGroups.actions"),
                      dataIndex: "actions",
                      key: "actions",
                      render: (item, row) => (
                          <Space>
                              <Popconfirm
                                  title={t("confirmDeleteText")}
                                  onConfirm={() => deleteCb(row.id)}
                                  okText={t("yes")}
                                  cancelText={t("no")}
                              >
                                  <Button danger> {t("delete")} </Button>
                              </Popconfirm>
                          </Space>
                      ),
                  },
              ],
          ];
    return (
        <>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button type="primary" style={{ marginBottom: "10px" }} onClick={createCb}>
                    {t("create")}
                </Button>
            </div>
            <Table dataSource={dataSource} columns={tableColumns} pagination={false} />
        </>
    );
}
export default CrudManagerTable;
