import React from "react";
import { i18next } from "../../../../../../lang/i18n";
class TotalAmountChatsItem {
    constructor(name = null, date = null) {
        this.name = name;
        this.date = date;
        this.total = null;
        this.formatedHour = null;
    }
    setValues(date, total) {
        this.date = date;
        this.total = total;
    }
}
const totalAmountTexts = [
    {
        id: 1,
        name: i18next.t("reportTotalInteractions.total"),
        label: i18next.t("reportTotalInteractions.total"),
        description: i18next.t("reportTotalAmount.totalChatsDesc"),
        color: "#5B8FF9",
    },
    {
        id: 2,
        name: i18next.t("reportTotalAmount.handleByBot"),
        label: i18next.t("reportTotalAmount.handleByBot"),
        description: i18next.t("reportTotalAmount.handleByBotDesc"),
        color: "#83E1BD",
    },
    {
        id: 3,
        name: i18next.t("reportTotalAmount.handleByAgent"),
        label: i18next.t("reportTotalAmount.handleByAgent"),
        description: i18next.t("reportTotalAmount.handleByAgentDesc"),
        color: "#5D7092",
    },
    {
        id: 4,
        name: i18next.t("reportTotalAmount.notServed"),
        label: i18next.t("reportTotalAmount.notServed"),
        description: i18next.t("reportTotalAmount.notServedDesc"),
        color: "#FF544E",
    },
];
const totalAmountConfigs = {
    height: 300,
    xField: "date",
    yField: "total",
    isGroup: true,
    seriesField: "name",
    legend: {
        position: "top-left",
    },
    tooltip: {
        formatter: (data) => {
            let visibleData = {};
            visibleData.name = totalAmountTexts.find((item) => item.name === data.name).label;
            visibleData.value = data.total;
            return visibleData;
        },
        showMarkers: false,
    },
    color: (data) => {
        return totalAmountTexts.find((item) => item.name === data.name).color;
    },
};
const totalAmountModal = (
    <ul>
        {totalAmountTexts.map((item) => (
            <li>
                <h3>
                    <span
                        style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            marginRight: "5px",
                            background: item.color,
                        }}
                    ></span>
                    {item.name}
                </h3>
                <p>{item.description}</p>
            </li>
        ))}
    </ul>
);

export { TotalAmountChatsItem, totalAmountTexts, totalAmountConfigs, totalAmountModal };
