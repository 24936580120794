import React, { useState, useEffect, useCallback } from "react";
import api from "../../../requests/api";
import { Modal, Table, Space, Button, Popconfirm, message, Input, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
const { Option } = Select;

function TagsGroups({ isModalVisible, isModalVisibleCb }) {
    const { t } = useTranslation();
    const [tagsGroups, setTagsGroups] = useState([]);
    const [selectedTagGroup, setSelectedTagGroup] = useState(null);
    const [selectedTagGroupId, setSelectedTagGroupId] = useState(null);
    const [popDownTags, setPopDownTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [modalState, setModalState] = useState("list");
    const [isLoading, setIsLoading] = useState(false);
    const [tags, setTags] = useState([]);
    const [idTagsWithoutGroupCache, setIdTagsWithoutGroupCache] = useState([]);
    const columns = [
        {
            title: t("name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("actions"),
            dataIndex: "actions",
            key: "actions",
            render: (item, row) => (
                <Space>
                    <Button onClick={() => setSelectedTagGroupId(row.id)}> {t("edit")} </Button>
                    <Popconfirm
                        title={t("conversationTagGroups.confirmDelete")}
                        onConfirm={() => confirmDelete(row.id)}
                        okText={t("yes")}
                        cancelText={t("no")}
                    >
                        <Button danger> {t("delete")} </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const confirmDelete = (id) => {
        setIsLoading(true);
        api.tagsGroups()
            .delete(id)
            .then((res) => {
                setTagsGroups(tagsGroups.filter((tagsGroup) => tagsGroup.id !== id));
                message.success(t("conversationTagGroups.groupDeleted"));
            })
            .catch((err) => {
                message.error(err.response?.data?.error?.message);
            });
        setIsLoading(false);
    };

    const loadTagGroup = useCallback(
        (id) => {
            async function loadTagGroupAsync(id) {
                try {
                    setModalState("edit");
                    const res_list = await api.tags().listWithoutGroup();
                    const idLabelsWithoutGroup = res_list.data.conversationLabels.map((t) => t.id);

                    setIdTagsWithoutGroupCache(idLabelsWithoutGroup);
                    const res_get = await api.tagsGroups().get(id);
                    const group = res_get.data;
                    setSelectedTagGroup(group);
                    setSelectedTags(group.conversationLabels.map((t) => t.value));
                    setPopDownTags(
                        tags.filter(
                            (t) =>
                                idLabelsWithoutGroup.includes(t.id) ||
                                group.conversationLabels.map((label) => label.id).includes(t.id)
                        )
                    );
                } catch (err) {
                    console.log(err);
                    message.error(err.response?.data?.error?.message);
                }
            }

            setIsLoading(true);
            loadTagGroupAsync(id);
            setIsLoading(false);
        },
        [tags]
    );

    const searchCallback = (text) => {
        setPopDownTags(
            tags.filter(
                (t) =>
                    t.value.toLowerCase().includes(text.toLowerCase()) &&
                    idTagsWithoutGroupCache.includes(t.id)
            )
        );
    };

    const resetState = () => {
        setSelectedTagGroupId(null);
        setSelectedTagGroup(null);
        setSelectedTags([]);
        setPopDownTags([]);
        setTags([]);
        isModalVisibleCb(false);
    };
    const closeModal = () => {
        setModalState("list");
        resetState();
    };

    const viewNewGroup = () => {
        async function viewNewGroupAsync() {
            setModalState("create");
            setSelectedTags([]);
            await loadTags();
            const res = await api.tags().listWithoutGroup();
            const idLabelsWithoutGroup = res.data.conversationLabels.map((t) => t.id);
            setIdTagsWithoutGroupCache(idLabelsWithoutGroup);
            setPopDownTags(tags.filter((t) => idLabelsWithoutGroup.includes(t.id)));
            setSelectedTagGroup(null);
        }
        setIsLoading(true);
        viewNewGroupAsync();
        setIsLoading(false);
    };
    const handleInput = (e) => {
        setSelectedTagGroup({ ...selectedTagGroup, name: e.target.value });
    };
    const handleSelect = (list) => {
        setSelectedTags(list);
    };
    async function addRemoveLabels(actualTagGroup) {
        const actualTagsIds = actualTagGroup.conversationLabels.map((t) => t.id);
        const selectedTagsIds = selectedTags.map((value) => tags.find((t) => t.value === value).id);
        for (let tagId of selectedTagsIds) {
            if (!actualTagsIds.includes(tagId)) {
                await api.tagsGroups().addLabelToGroup(actualTagGroup.id, tagId);
            }
        }
        for (let tagId of actualTagsIds) {
            if (!selectedTagsIds.includes(tagId)) {
                await api.tagsGroups().removeLabelFromGroup(selectedTagGroup.id, tagId);
            }
        }
    }

    const saveChanges = () => {
        async function saveChangesAsync() {
            try {
                if (!selectedTagGroup || !selectedTagGroup.name) {
                    message.error(t("conversationTagGroups.nameCanNotBeEmpty"));
                    return;
                }

                if (modalState === "create") {
                    const res_create = await api
                        .tagsGroups()
                        .createConversationLabel(selectedTagGroup);
                    message.success(t("conversationTagGroups.groupCreated"));
                    const res_get = await api.tagsGroups().get(res_create.data.id);
                    setSelectedTagGroup(res_get.data);
                    await addRemoveLabels(res_get.data);
                    await loadTagsGroups();
                } else if (modalState === "edit") {
                    await addRemoveLabels(selectedTagGroup);
                    const tag_group = tagsGroups.find((tg) => tg.id === selectedTagGroupId);
                    if (tag_group.name !== selectedTagGroup.name) {
                        await api.tagsGroups().edit(selectedTagGroup);
                    }
                    message.success(t("conversationTagGroups.groupUpdated"));
                    await loadTagsGroups();
                }
                setModalState("list");
                setSelectedTagGroupId(null);
            } catch (err) {
                message.error(err.response?.data?.error?.message);
            }
        }
        setIsLoading(true);
        saveChangesAsync();
        setIsLoading(false);
    };

    async function loadTagsGroups() {
        api.tagsGroups()
            .list()
            .then((res) => {
                setTagsGroups(res.data.conversationLabelGroups);
            });
    }

    async function loadTags() {
        const res = await api.tags().list();
        setTags(res.data.conversationLabels);
    }

    useEffect(() => {
        setIsLoading(true);
        if (isModalVisible) {
            loadTagsGroups();
            loadTags();
        }
        setIsLoading(false);
    }, [isModalVisible]);

    useEffect(() => {
        if (selectedTagGroupId) {
            loadTagGroup(selectedTagGroupId);
        }
    }, [selectedTagGroupId, loadTagGroup]);

    return (
        <Modal
            title={t("conversationTagGroups.modalTitle")}
            visible={isModalVisible}
            onCancel={() => closeModal()}
            footer={[
                modalState === "edit" || modalState === "create" ? (
                    <>
                        <Button
                            onClick={() => {
                                setModalState("list");
                                setSelectedTagGroupId(null);
                                setSelectedTags([]);
                            }}
                        >
                            {t("back")}
                        </Button>
                        <Button onClick={saveChanges} type="primary">
                            {t("save")}
                        </Button>
                    </>
                ) : (
                    <Button onClick={closeModal}>{t("close")}</Button>
                ),
            ]}
        >
            <Spin spinning={isLoading}>
                {modalState === "edit" || modalState === "create" ? (
                    <>
                        <label>{t("name")}</label>
                        <Input
                            value={selectedTagGroup && selectedTagGroup.name}
                            placeholder={t("name")}
                            style={{ marginBottom: "10px" }}
                            onChange={handleInput}
                        />
                        <label>{t("tags")}</label>
                        <Select
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            style={{ width: "100%" }}
                            placeholder={t("search")}
                            value={selectedTags}
                            onSearch={searchCallback}
                            onChange={handleSelect}
                        >
                            {popDownTags &&
                                popDownTags.map((item) => (
                                    <Option value={item.value} key={item.id}>
                                        {item.value}{" "}
                                    </Option>
                                ))}
                        </Select>
                    </>
                ) : (
                    <>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button
                                type="primary"
                                style={{ marginBottom: "10px" }}
                                onClick={viewNewGroup}
                            >
                                {t("create")}
                            </Button>
                        </div>
                        <Table dataSource={tagsGroups} columns={columns} pagination={false} />
                    </>
                )}
            </Spin>
        </Modal>
    );
}

export default TagsGroups;
