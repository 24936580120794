import { Button, message, Modal } from "antd";
import React from "react";
import FileUploaderDragger from "../../../components/FileUploaderDragger/FileUploaderDragger";
import { useTranslation } from "react-i18next";
import api from "../../../requests/api";

const downloadExampleUrl =
    "https://optiwe-assistant-medias.s3.us-west-1.amazonaws.com/customer_with_optin_import_example.csv";

function ImportFromCsvModal({ isModaVisible, setModalVisibleCb, onSuccessCb, onFailCb }) {
    const { t } = useTranslation();
    const apiUrl = api.api_url().get();

    function onChangeCallBack(info) {
        if (!info?.fileList.length) {
            return;
        }
        let errorKey = info.file?.response?.error?.key;
        if (errorKey) {
            message.error(t("customers.import.errors.UPLOAD_ERROR"));
            onFailCb();
        }
        if (info.file?.response === "") {
            message.success(t("customers.uploadFileSuccess"));
            onSuccessCb();
        }
    }

    return (
        <Modal
            visible={isModaVisible}
            onCancel={() => setModalVisibleCb(false)}
            footer={[
                <>
                    <Button
                        onClick={() => {
                            window.open(downloadExampleUrl);
                        }}
                    >
                        {t("customers.downloadExample")}
                    </Button>
                    <Button onClick={() => setModalVisibleCb(false)}>{t("close")}</Button>
                </>,
            ]}
        >
            <FileUploaderDragger
                text={t("customers.uploadFileText")}
                hint={t("customers.uploadFileHint")}
                acceptedFileExtensions={".csv"}
                url={`${apiUrl}/customers/groups/import/`}
                onChangeCb={onChangeCallBack}
            />
        </Modal>
    );
}

export default ImportFromCsvModal;
