enum Channels {
    WHATSAPP = "whatsapp",
    FACEBOOK = "facebook",
    INSTAGRAM = "instagram",
    SMS = "sms",
}

type ChannelWarningMessage = {
    show: boolean;
    maxSize: number;
};

type ChannelWarningMessages = {
    [key in Channels]: ChannelWarningMessage;
};

export type { ChannelWarningMessages, ChannelWarningMessage };
export { Channels };
