import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { ViewMore, ViewMoreText } from "./LoadMore.module.scss";
function LoadMore({ style, onClick }) {
    return (
        <div className={ViewMore} style={{ ...style }} onClick={onClick}>
            <span className={ViewMoreText}>
                {" "}
                Load More <FontAwesomeIcon icon={faChevronDown} />
            </span>
        </div>
    );
}
export default LoadMore;
