import React from "react";
import "./Loader.scss";
function Loader(props) {
    if (props.isLoading) {
        return (
            <div className="opt-main-loader">
                <div className="opt-main-loader__load" style={props.style}></div>
            </div>
        );
    }
    return <></>;
}
export default Loader;
