import React from "react";
import { channelsMaxSizesInMb } from "frontend-lib/SizeChecker";
import { useTranslation } from "react-i18next";

function capitalizeFirstLetter(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

function ListChannelSizes() {
    const { t } = useTranslation();
    return (
        <>
            <p
                style={{
                    marginTop: "15px",
                    fontWeight: "bold",
                    borderBottom: "2px solid #fafafa",
                }}
            >
                {t("allowedSizes")}
            </p>
            <ul style={{ listStyleType: "bullet" }}>
                {Object.entries(channelsMaxSizesInMb).map(([name, list]) => (
                    <li>
                        <>
                            <span style={{ fontWeight: "bold" }}>
                                {capitalizeFirstLetter(name)}
                            </span>
                            <ul style={{ listStyleType: "bullet", paddingLeft: "15px" }}>
                                {Object.entries(list).map(([type, size]) => (
                                    <li>
                                        <span>{t(type)}:</span> {size} MB
                                    </li>
                                ))}
                            </ul>
                        </>
                    </li>
                ))}
            </ul>
        </>
    );
}
export default ListChannelSizes;
