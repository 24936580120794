import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Switch, InputNumber } from "antd";
import { default as deepmerge } from "deepmerge";
import TitleTooltip from "../../../components/TitleTooltip/TitleTooltip";
import { EditWorkspaceConfigsProps } from "./Types";

interface TimeToleranceAfterConversationClosed {
    backend: {
        timeToleranceAfterConversationClosed: {
            enabled: boolean;
            timeTolerance: number;
        };
    };
}
function TimeToleranceAfterConversationClosedSettings({
    configs,
    setConfig,
}: EditWorkspaceConfigsProps<TimeToleranceAfterConversationClosed>) {
    const enabled = useMemo(() => {
        return configs.backend.timeToleranceAfterConversationClosed.enabled;
    }, [configs.backend.timeToleranceAfterConversationClosed.enabled]);
    const timeTolerance = useMemo(() => {
        return configs.backend.timeToleranceAfterConversationClosed.timeTolerance;
    }, [configs.backend.timeToleranceAfterConversationClosed.timeTolerance]);
    const { t } = useTranslation();

    function onEnableTimeToleranceAfterConversationClosed(checked: boolean) {
        let mergedConfigs = deepmerge(configs, {
            backend: {
                timeToleranceAfterConversationClosed: {
                    enabled: checked,
                },
            },
        });
        setConfig(mergedConfigs);
    }

    function onChangeTimeTolerance(tolerance: string | number | undefined) {
        if (typeof tolerance !== "number") return;
        let mergedConfigs = deepmerge(configs, {
            backend: {
                timeToleranceAfterConversationClosed: {
                    timeTolerance: tolerance,
                },
            },
        });
        setConfig(mergedConfigs);
    }

    return (
        <>
            <div>
                <div style={{ display: "flex", marginBottom: "20px" }}>
                    <span style={{ fontWeight: "bold" }}>
                        {t("settings.timeToleranceAfterConversationClosed.title")}
                    </span>
                    <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                        <Switch
                            checked={enabled}
                            onChange={onEnableTimeToleranceAfterConversationClosed}
                        />
                    </div>
                </div>
                <span style={{ fontSize: 14, color: "#727272" }}>
                    {t("settings.timeToleranceAfterConversationClosed.description")}
                </span>
            </div>

            {enabled && (
                <div
                    style={{
                        marginLeft: "15px",
                        marginRight: "0px",
                        marginTop: "5px",
                        marginBottom: "2px",
                    }}
                >
                    <label
                        style={{
                            marginLeft: "auto",
                            marginRight: "0px",
                        }}
                    >
                        {t("settings.timeToleranceAfterConversationClosed.timeTolerance")}
                        <TitleTooltip
                            content={t(
                                "settings.timeToleranceAfterConversationClosed.timeToleranceTooltip"
                            )}
                        />
                    </label>
                    <div style={{ marginTop: "5px", marginLeft: "0px" }}>
                        <InputNumber
                            style={{ width: "100%" }}
                            size={"small"}
                            min={1}
                            value={timeTolerance}
                            onChange={onChangeTimeTolerance}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default TimeToleranceAfterConversationClosedSettings;
