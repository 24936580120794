import React, { useMemo, useState, useContext } from "react";
import { AppContext } from "../../../components/AppContext";
import { useTranslation } from "react-i18next";
import { Input, Button, message } from "antd";
import Loader from "../../../components/Loader/Loader";
import { EditWorkspaceConfigsProps } from "./Types";
import { default as deepmerge } from "deepmerge";
import api from "../../../requests/api";

interface LLMBot {
    backend: {
        llmBot: {
            openAiApiKey: string | undefined;
        };
    };
}

const openAiApiKeyInitialConfig: LLMBot = {
    backend: {
        llmBot: {
            openAiApiKey: undefined
        }
    }
};

function LLMBotSettings({
    configs,
    setConfig
}: EditWorkspaceConfigsProps<LLMBot>) {
    const appContext = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [openAiApiKeyConfig, setOpenAiApiKeyConfig] = useState(openAiApiKeyInitialConfig);
    const openAiApiKey = useMemo(() => {
        return configs?.backend?.llmBot?.openAiApiKey;
    }, [configs?.backend?.llmBot?.openAiApiKey]);
    const { t } = useTranslation();

    function onChangeOpenAiApiKey(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        let apiKeyConfig = {
            backend: {
                llmBot: {
                    openAiApiKey: e.target.value
                },
            },
        };
        setOpenAiApiKeyConfig(apiKeyConfig);

        let mergedConfigs = deepmerge(configs, {
            backend: {
                llmBot: {
                    openAiApiKey: e.target.value
                },
            },
        });
        setConfig(mergedConfigs)
    }

    const saveOpenAiApiKey = function () {
        setIsLoading(true);
        api.workspaces()
            //@ts-ignore
            .updateConfig(appContext.state.workspaceId, openAiApiKeyConfig, true)
            .then(function () {
                message.success(t('settings.openAiApiKey.successSavingSettings'))
                setIsLoading(false);
            })
            .catch((err) => {
                message.error(t("settings.openAiApiKey.errorSavingSettings"));
            })
            setIsLoading(false);
    };

    return (
        <>
            <Loader isLoading={isLoading} />
            <div>
                <div style={{ marginBottom: "20px" }}>
                    <span style={{ fontWeight: "bold" }}>
                        {t("settings.openAiApiKey.title")}
                    </span>
                </div>
                <span style={{ fontSize: 14, color: "#727272" }}>
                    {t("settings.openAiApiKey.description")}
                </span>
            </div>

            <div style={{ display: "flex", marginTop: "5px" }}>
                <div style={{ marginLeft: "0px", width: "85%"}}>
                    <Input
                        value={openAiApiKey}
                        onChange={onChangeOpenAiApiKey}
                        allowClear
                    />
                </div>
                <Button 
                    style={{ 
                        marginLeft: "15px", 
                        width: "10%", 
                        backgroundColor: "#fd6a56", 
                        fontSize: "16px", 
                        padding: "0.1em 0.5em", 
                        textAlign: "center", 
                        border: "none", 
                        color: "white", 
                        borderRadius: "6px" 
                    }}
                    onClick={saveOpenAiApiKey}
                >
                    {t("settings.openAiApiKey.set")}
                </Button>
            </div>
        </>
    );
}

export default LLMBotSettings;
