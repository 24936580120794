import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import React from "react";

const { Dragger } = Upload;

function FileUploaderDragger({ text, hint, acceptedFileExtensions, url, onChangeCb }) {
    const props = {
        name: "file",
        multiple: false,
        withCredentials: true,
        action: url,
        accept: acceptedFileExtensions,
        onChange(info) {
            onChangeCb(info);
        },
    };
    return (
        <Dragger {...props}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">{text}</p>
            <p className="ant-upload-hint">{hint}</p>
        </Dragger>
    );
}

export default FileUploaderDragger;
