import { Select, Switch } from "antd";
import { Option } from "antd/es/mentions";
import { default as deepmerge } from "deepmerge";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadMore from "../../../components/LoadMore/LoadMore";
import api from "../../../requests/api";
function AgentSettingsManager({ config, setConfigCallback, setIsLoadingCallback }) {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [usersPaginatorPage, setUsersPaginatorPage] = useState(0);
    const [usersPaginatorHasNext, setUsersPaginatorHasNext] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUserIdOrderingType, setSelectedUserIdOrderingType] = useState(null);

    const chatsOrderingTypeChanged = function (orderingType) {
        let mergedConfigs = deepmerge(config, {
            backend: {
                conversationOrderingType: orderingType,
            },
        });
        setConfigCallback(mergedConfigs);
    };

    const chatsOrderingByUserIdTypeChanged = function (orderingType) {
        setSelectedUserIdOrderingType(orderingType);
        if (
            !orderingType &&
            config.backend?.conversationOrderingTypeByUserId &&
            selectedUserId in config.backend?.conversationOrderingTypeByUserId
        ) {
            delete config.backend.conversationOrderingTypeByUserId[selectedUserId];
        } else {
            let newConfigs = {
                backend: {
                    conversationOrderingTypeByUserId: {},
                },
            };
            newConfigs.backend.conversationOrderingTypeByUserId[selectedUserId] = orderingType;
            let mergedConfigs = deepmerge(config, newConfigs);
            setConfigCallback(mergedConfigs);
        }
    };

    function changeUsersPage() {
        setIsLoadingCallback(true);
        api.users()
            .list(usersPaginatorPage + 1, 32)
            .then((res) => {
                setUsersPaginatorPage((usersPaginatorPage) => usersPaginatorPage + 1);
                setUsers((prev) => [...prev, ...res.data.users]);
                setUsersPaginatorHasNext(res.data.paginator.hasNext);
                setIsLoadingCallback(false);
            });
    }

    function selectedUserIdChanged(userId) {
        setSelectedUserId(userId);
        let userOrderingType = null;
        if (
            config.backend?.conversationOrderingTypeByUserId &&
            userId in config.backend?.conversationOrderingTypeByUserId
        ) {
            userOrderingType = config.backend.conversationOrderingTypeByUserId[userId];
        }
        setSelectedUserIdOrderingType(userOrderingType);
    }

    const agentsTabVisibilityChanged = function (checked) {
        let newConfigs = {
            agent: {
                hideAgentsTabForNonAdminUsers: checked,
            },
        };
        let mergedConfigs = deepmerge(config, newConfigs);
        setConfigCallback(mergedConfigs);
    };

    useEffect(() => {
        setIsLoadingCallback(true);
        api.users()
            .list(0, 32)
            .then((res) => {
                setUsers(res.data.users);
                setIsLoadingCallback(false);
            });
    }, [setIsLoadingCallback]);

    return (
        <>
            <div className="opt-settings-tab opt-settings-tab--general">
                <span className="opt-label-title">
                    {t("settings.agentSettings.conversationsOrdering")}
                </span>
                <div className="opt-card opt-card--settings">
                    <Select
                        defaultValue={config.backend.conversationOrderingType}
                        value={config.backend.conversationOrderingType}
                        style={{ width: "100%" }}
                        onChange={chatsOrderingTypeChanged}
                    >
                        <Option value="START_WAITING_ON">
                            {t("settings.agentSettings.waitingTime")}
                        </Option>
                        <Option value="LAST_END_USER_ACTIVITY">
                            {t("settings.agentSettings.userActivityTime")}
                        </Option>
                        <Option value="ASSIGNED_TO_HUMAN_AGENT_ON">
                            {t("settings.agentSettings.assignedToHumanAgentTime")}
                        </Option>


                    </Select>

                    <span className="opt-label-title">
                        {t("settings.agentSettings.conversationsOrderingByUser")}
                    </span>
                    <Select
                        defaultValue={t("settings.agentSettings.selectAUser")}
                        onChange={selectedUserIdChanged}
                        style={{ width: "100%" }}
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                {usersPaginatorHasNext && (
                                    <LoadMore
                                        onClick={changeUsersPage}
                                        style={{ marginTop: "0px", marginBottom: "15px" }}
                                    />
                                )}
                            </>
                        )}
                    >
                        {users.map((user) => (
                            <Select.Option value={user.id.toString()}>{user.email}</Select.Option>
                        ))}
                    </Select>
                    <Select
                        defaultValue={t("settings.agentSettings.selectAnOrderingType")}
                        value={selectedUserIdOrderingType}
                        onChange={chatsOrderingByUserIdTypeChanged}
                        style={{ width: "100%", marginTop: "20px" }}
                    >
                        <Option value="START_WAITING_ON">
                            {t("settings.agentSettings.waitingTime")}
                        </Option>
                        <Option value="LAST_END_USER_ACTIVITY">
                            {t("settings.agentSettings.userActivityTime")}
                        </Option>
                        <Option value={null}>
                            {t("settings.agentSettings.useWorkspaceOrderingType")}
                        </Option>
                    </Select>
                </div>
                <div className="opt-card opt-card--settings">
                    <span className="opt-label-title">
                        {t("settings.agentSettings.hideAgentsTabForNonAdminUsers")}
                    </span>
                    <Switch
                        checked={config.agent?.hideAgentsTabForNonAdminUsers}
                        onChange={agentsTabVisibilityChanged}
                    />
                </div>
            </div>
        </>
    );
}
export default AgentSettingsManager;
