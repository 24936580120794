import React from "react";
import "./FormComponents.scss";
function InputText(props) {
    return (
        <input
            className="opt-text-input"
            {...props}
            type={props.type !== undefined ? props.type : "text"}
        />
    );
}
export default InputText;
