import React from "react";
import { AppContext } from "../../components/AppContext";
// resources
import logo from "../../img/logo.svg";
import "./Payments.scss";

class SetupEs extends React.Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        var stripe = window.Stripe("pk_live_kwIdpEM2Jd5dZHiF4XINafHU00VDDmg2oX");

        var checkoutButton = document.getElementById(
            "checkout-button-price_1HDbm5CdguhNQPSQ5Famv71b"
        );

        checkoutButton.addEventListener("click", function () {
            // When the customer clicks on the button, redirect
            // them to Checkout.
            stripe
                .redirectToCheckout({
                    lineItems: [{ price: "price_1HDbm5CdguhNQPSQ5Famv71b", quantity: 1 }],
                    mode: "payment",
                    // Do not rely on the redirect to the successUrl for fulfilling
                    // purchases, customers may not always reach the success_url after
                    // a successful payment.
                    // Instead use one of the strategies described in
                    // https://stripe.com/docs/payments/checkout/fulfillment
                    successUrl: "http://app.optiwe.com/payments/success",
                    cancelUrl: "http://app.optiwe.com/payments/canceled",
                })
                .then(function (result) {
                    if (result.error) {
                        // If `redirectToCheckout` fails due to a browser or network
                        // error, display the localized error message to your customer.
                        // eslint-disable-next-line
                        var displayError = document.getElementById("error-message");
                        // displayError.textContent = result.error.message;
                    }
                });
        });
    }

    render() {
        return (
            <div className="section-login">
                <div className="section-login__logo">
                    <h1>
                        <img src={logo} alt="Optiwe logo" />
                        <span className="d-none">Optiwe</span>
                    </h1>
                    <p>Sistema de pagos de Optiwe, Inc.</p>
                </div>
                <div className="section-login__form">
                    <p>
                        Pagar usd 100 en concepto del setup inicial de la cuenta y la configuración
                        de WhatsApp Business API
                    </p>
                    <button
                        className="opt-button"
                        id="checkout-button-price_1HDbm5CdguhNQPSQ5Famv71b"
                        role="link"
                    >
                        Checkout
                    </button>
                </div>
            </div>
        );
    }
}
SetupEs.contextType = AppContext;
export default SetupEs;
