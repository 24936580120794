import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Space, Button, Tag, List, Col, Row, Typography, Select, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Route, useHistory, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TitleTooltip from "../../../components/TitleTooltip/TitleTooltip";
import CampaignForm from "./CampaignForm";
import SendCampaign from "./SendCampaign";
import api from "requests/api";
import styles from "./Campaign.module.scss";
import ShowTemplate from "./ShowTemplate";
import WithPaginator from "components/WithPaginator/WithPaginator";

const { Text } = Typography;

/**
 *
 * @param {Array<{id: number}> | undefined} maybeArray
 * @returns {Array<number>}
 */
const getIds = (maybeArray) => {
    if (!(maybeArray && maybeArray.length)) {
        return [];
    }
    return maybeArray.map((i) => i.id);
};

/**
 *
 * @param {Object} params
 * @param {import("frontend-lib/DomainTypes/Campaign").Campaign} params.campaign
 * @param {import("frontend-lib/DomainTypes/Template").Template | undefined} params.template
 */
const CampaignStatusTag = ({ campaign, template }) => {
    const { t } = useTranslation();
    /**
     * @type {"DISABLED" | "PAUSED" | "DELETED" | "DEACTIVATED" | "CREATED" | "AWAITING" | "SENDING" | "SENT" | "FAILED"}
     */
    const campaignStatusMerged = useMemo(() => {
        const doneStates = ["SENT", "FAILED", "CREATED", "CREATED"];
        if (doneStates.includes(campaign.status) || template === undefined) {
            return campaign.status;
        }

        const templateErrorStatus = ["DISABLED", "PAUSED", "DELETED", "DEACTIVATED"];
        if (templateErrorStatus.includes(template.status)) {
            return template.status;
        }
        return campaign.status;
    }, [campaign, template]);

    const colorTagForCampaignStatus = (status) => {
        const greenTags = ["SENT"];
        const yellowTags = ["AWAITING", "SENDING"];
        const redTags = ["FAILED", "DISABLED", "DEACTIVATED"];

        if (greenTags.includes(status)) {
            return "green";
        }
        if (yellowTags.includes(status)) {
            return "yellow";
        }
        if (redTags.includes(status)) {
            return "red";
        }
        return "blue";
    };

    const displayTextForStatus = (status) => {
        switch (status) {
            case "DISABLED":
                return t(`outbound.templateObj.statusText.${status}`);
            case "PAUSED":
                return t(`outbound.templateObj.statusText.${status}`);
            case "DELETED":
                return t(`outbound.templateObj.statusText.${status}`);
            case "DEACTIVATED":
                return t(`outbound.templateObj.statusText.${status}`);
            case "CREATED":
                return t(`outbound.campaignObj.created`);
            case "AWAITING":
                return t(`outbound.campaignObj.awaiting`);
            case "SENDING":
                return t(`outbound.campaignObj.sending`);
            case "SENT":
                return t(`outbound.campaignObj.sent`);
            case "FAILED":
                return t(`outbound.campaignObj.failed`);
            default:
                return t(`outbound.campaignObj.DEACTIVATED`);
        }
    };

    return (
        <Tag color={colorTagForCampaignStatus(campaignStatusMerged)} style={{ marginLeft: "1rem" }}>
            {displayTextForStatus(campaignStatusMerged)}
        </Tag>
    );
};

function CampaignCustomersAttribute({ campaign, tt, field }) {
    const keysWithoutFieldMessage = ["customers"];

    return (
        <>
            <Text type="secondary" style={{ marginRight: "1rem" }}>
                {tt(field)}

                {!keysWithoutFieldMessage.includes(field) && (
                    <TitleTooltip content={tt(`${field}FieldMessage`)} />
                )}
            </Text>

            <NavLink
                to={{
                    pathname: `/dashboard/customers/`,
                    state: {
                        customersIds: getIds(campaign[field]),
                    },
                }}
                className="opt-table__button"
            >
                {Object.keys(campaign[field]).length}
                <Space>
                    <div style={{ marginLeft: "8px" }}>
                        <Button
                            shape="circle"
                            size="small"
                            icon={
                                <SearchOutlined
                                    style={{
                                        position: "relative",
                                        top: "2px",
                                    }}
                                />
                            }
                        ></Button>
                    </div>
                </Space>
            </NavLink>
        </>
    );
}

/**
 *
 * @param {import("frontend-lib/DomainTypes/Campaign").Campaign | undefined} campaign
 * @param {import("frontend-lib/DomainTypes/Conversation").ConversationGroup[] | undefined} customersGroups
 * @returns {string[]}
 */
const customerGroupsName = (campaign, customersGroups) => {
    if (campaign === undefined || !customersGroups?.length) return [];

    const campaignCustomerGroupsId = campaign.customerGroups.map((c) => c.id);
    if (!campaignCustomerGroupsId) return [];

    const campaingCustomerGroups = customersGroups.filter((g) =>
        campaignCustomerGroupsId.includes(g.id)
    );

    return campaingCustomerGroups.map((g) => g.name);
};

/**
 * @param {Object} props
 * @param {import("frontend-lib/DomainTypes/Template").Template[]} props.templateList
 * @param {import("frontend-lib/DomainTypes/Campaign").Campaign[]} props.campaignList
 * @returns
 */
function Campaigns({
    templateList,
    campaignList,
    campaignPaginator,
    reLoadCb,
    loadMoreCb,
    configs,
}) {
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState();
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const { t } = useTranslation();

    /**
     * @type {import("frontend-lib/DomainTypes/Template").Template | undefined}
     */
    const [selectedTemplateForPreview, setSelectedTemplateForPreview] = useState();
    const [templatePreviewVisible, setTemplatePreviewVisible] = useState(false);

    /**
     * @type {import("frontend-lib/DomainTypes/Conversation").ConversationGroup[] | undefined}
     */
    const [customersGroups, setCustomersGroups] = useState(undefined);

    useEffect(() => {
        if (customersGroups !== undefined) return;
        api.customers()
            .groups()
            .then((res) => {
                const groups = res.data.customerGroups || [];
                setCustomersGroups(groups);
            });
    }, [customersGroups]);

    const tt = useCallback((key) => t(`outbound.campaignObj.${key}`), [t]);

    const newCampaign = () => {
        setSelectedCampaign(null);
        history.push("/dashboard/outbound/campaigns/form");
    };

    return (
        <>
            <Route exact path="/dashboard/outbound/campaigns">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "20px",
                    }}
                >
                    <Button type="primary" onClick={newCampaign}>
                        {t("outbound.campaignObj.createNewCampaign")}
                    </Button>
                </div>

                <Modal
                    title={`${t("outbound.templateObj.template")} ${
                        selectedTemplateForPreview && selectedTemplateForPreview.id
                    }`}
                    visible={templatePreviewVisible}
                    onOk={() => setTemplatePreviewVisible(false)}
                    onCancel={() => setTemplatePreviewVisible(false)}
                >
                    {selectedTemplateForPreview && (
                        <ShowTemplate template={selectedTemplateForPreview} />
                    )}
                </Modal>

                <div className="opt-card" style={{ padding: 0 }}>
                    <div className="opt-card opt-card--table opt-card--no-padding">
                        <WithPaginator
                            page={campaignPaginator.page}
                            hasNext={campaignPaginator.hasNext}
                            changePageCb={loadMoreCb}
                            showPage={true}
                        >
                            <List
                                itemLayout="vertical"
                                size="large"
                                dataSource={campaignList}
                                renderItem={(campaign) => (
                                    <List.Item key={campaign.id}>
                                        <List.Item.Meta
                                            title={
                                                <>
                                                    {campaign.name}
                                                    <CampaignStatusTag
                                                        campaign={campaign}
                                                        template={templateList?.find(
                                                            (t) => t.id === campaign.template.id
                                                        )}
                                                    />
                                                </>
                                            }
                                        />
                                        <Row>
                                            <Col span={8}>
                                                <CampaignCustomersAttribute
                                                    campaign={campaign}
                                                    tt={tt}
                                                    field="effectiveCustomers"
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <CampaignCustomersAttribute
                                                    campaign={campaign}
                                                    tt={tt}
                                                    field="messageOpenedCustomers"
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <CampaignCustomersAttribute
                                                    campaign={campaign}
                                                    tt={tt}
                                                    field="inboundCustomers"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={8}>
                                                <Text
                                                    type="secondary"
                                                    style={{
                                                        marginRight: "1rem",
                                                    }}
                                                >
                                                    {tt("form.customerGroups")}
                                                </Text>

                                                {customerGroupsName(campaign, customersGroups).map(
                                                    (name) => (
                                                        <span>
                                                            <span>{name} </span>
                                                        </span>
                                                    )
                                                )}
                                            </Col>
                                            <Col span={8}>
                                                <CampaignCustomersAttribute
                                                    campaign={campaign}
                                                    tt={tt}
                                                    field="failedCustomers"
                                                />
                                            </Col>
                                            <Col>
                                                <CampaignCustomersAttribute
                                                    campaign={campaign}
                                                    tt={tt}
                                                    field="unsubscribedCustomers"
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={8}>
                                                <Text
                                                    type="secondary"
                                                    style={{ marginRight: "1rem" }}
                                                >
                                                    {tt("template")}
                                                </Text>
                                                <span
                                                    className={styles["template-name-clickable"]}
                                                    onClick={() => {
                                                        setSelectedTemplateForPreview(
                                                            templateList?.find(
                                                                (t) => t.id === campaign.template.id
                                                            )
                                                        );
                                                        setTemplatePreviewVisible(true);
                                                    }}
                                                >
                                                    {
                                                        templateList?.find(
                                                            (t) => t.id === campaign.template.id
                                                        )?.name
                                                    }
                                                </span>
                                            </Col>
                                            <Col span={8}></Col>
                                            <Col span={8}>
                                                {campaign.status === "CREATED" && (
                                                    <Button
                                                        className="opt-button opt-button--light"
                                                        style={{ maxWidth: "50%" }}
                                                        onClick={() => {
                                                            setSelectedCampaign(campaign);
                                                            setIsModalVisible(true);
                                                        }}
                                                    >
                                                        {tt("send")}
                                                    </Button>
                                                )}
                                                {campaign.status === "FAILED" &&
                                                    campaign.failedCustomers?.length > 0 && (
                                                        <Button
                                                            className="opt-button opt-button--light"
                                                            style={{ maxWidth: "50%" }}
                                                            onClick={() => {
                                                                setSelectedCampaign(campaign);
                                                                setIsModalVisible(true);
                                                            }}
                                                        >
                                                            {t("outbound.campaignObj.resend")}
                                                        </Button>
                                                    )}
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                        </WithPaginator>
                    </div>
                </div>
            </Route>
            <Route exact path="/dashboard/outbound/campaigns/form">
                <CampaignForm
                    templateList={templateList}
                    selectedCampaign={selectedCampaign}
                    reloadCb={reLoadCb}
                    customerGroupsParam={customersGroups}
                />
            </Route>
            <SendCampaign
                configs={configs}
                isModalVisible={isModalVisible}
                setIsModalVisibleCb={setIsModalVisible}
                selectedCampaign={selectedCampaign}
                templateList={templateList}
                closeCb={reLoadCb}
            />
        </>
    );
}
export default Campaigns;
