import React from "react";
import { i18next } from "../../../../../../lang/i18n";
class MeanInteractionsItem {
    constructor(name = null, date = null) {
        this.name = name;
        this.date = date;
        this.interactions = null;
        this.formatedHour = null;
    }
    setValues(date, interactions) {
        this.date = date;
        this.interactions = interactions;
    }
}
const meanInteractionsTexts = [
    {
        id: 1,
        name: i18next.t("reportMeanInteractions.total"),
        label: i18next.t("reportMeanInteractions.total"),
        description: i18next.t("reportMeanInteractions.totalDesc"),
        color: "#5B8FF9",
    },
    {
        id: 2,
        name: i18next.t("reportMeanInteractions.bot"),
        label: i18next.t("reportMeanInteractions.bot"),
        description: i18next.t("reportMeanInteractions.botDesc"),
        color: "#83E1BD",
    },
    {
        id: 3,
        name: i18next.t("reportMeanInteractions.agent"),
        label: i18next.t("reportMeanInteractions.agent"),
        description: i18next.t("reportMeanInteractions.agentDesc"),
        color: "#5D7092",
    },
    {
        id: 4,
        name: i18next.t("reportMeanInteractions.user"),
        label: i18next.t("reportMeanInteractions.user"),
        description: i18next.t("reportMeanInteractions.userDesc"),
        color: "#F8CD50",
    },
    {
        id: 5,
        name: i18next.t("reportMeanInteractions.userToBot"),
        label: i18next.t("reportMeanInteractions.userToBot"),
        description: i18next.t("reportMeanInteractions.userToBotDesc"),
        color: "#7666F9",
    },
    {
        id: 6,
        name: i18next.t("reportMeanInteractions.userToAgent"),
        label: i18next.t("reportMeanInteractions.userToAgent"),
        description: i18next.t("reportMeanInteractions.userToAgentDesc"),
        color: "#74CBED",
    },
];

const meanInteractionsConfigs = {
    height: 300,
    xField: "date",
    yField: "interactions",
    isGroup: true,
    seriesField: "name",
    legend: {
        position: "top-left",
    },
    tooltip: {
        formatter: (data) => {
            let visibleData = {
                name: meanInteractionsTexts.find((item) => item.name === data.name).label,
                value: null,
            };
            if (data.interactions) {
                visibleData.value = parseFloat(data.interactions).toFixed(1);
            }
            return visibleData;
        },
        showMarkers: false,
    },
    color: (data) => {
        return meanInteractionsTexts.find((item) => item.name === data.name).color;
    },
};
const meanInteractionsModal = (
    <ul>
        {meanInteractionsTexts.map((item) => (
            <li>
                <h3>
                    <span
                        style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            marginRight: "5px",
                            background: item.color,
                        }}
                    ></span>
                    {item.name}
                </h3>
                <p>{item.description}</p>
            </li>
        ))}
    </ul>
);

export {
    MeanInteractionsItem,
    meanInteractionsTexts,
    meanInteractionsConfigs,
    meanInteractionsModal,
};
