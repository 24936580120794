export interface Prompt {
    id: number | undefined;
    workspaceId: number | undefined;
    key: string;
    content: string;
    createdOn: Date | undefined;
    updatedOn: Date | undefined;
    isSystemPrompt: boolean | undefined;
}

export enum PromptType {
    INTENTION_DETECTOR = "intention_detector",
    QUESTION_ANSWERING = "question_answering",
    CUSTOM = "custom"
}