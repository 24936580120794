import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import api from "../../../requests/api";
import {
    Alert,
    Steps,
    Modal,
    Button,
    Spin,
    Skeleton,
    Statistic,
    Row,
    Col,
    Card,
    message,
    Result,
} from "antd";
import { UsergroupAddOutlined, UserAddOutlined, TeamOutlined } from "@ant-design/icons";
const { Step } = Steps;
function SendCampaign({
    isModalVisible,
    setIsModalVisibleCb,
    selectedCampaign,
    templateList,
    configs,
    closeCb,
}) {
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [campaignToSend, setCampaignToSend] = useState({});
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [campaignSended, setIsCampaignSended] = useState(false);
    const { t } = useTranslation();
    const changeStep = (step) => setCurrentStep(step);
    const sendCampaign = () => {
        setIsModalLoading(true);

        api.outbound()
            .sendCampaign(campaignToSend.id)
            .then((res) => {
                closeCb();
                setIsModalLoading(false);
                setIsCampaignSended(true);
            })
            .catch((err) => {
                setIsModalLoading(false);
                const key = err.response.data.error.key ?? null;
                if (key) {
                    message.error(t(`outbound.campaignObj.sendCampaignErrorKeys.${key}`));
                } else {
                    message.error(t(`outbound.campaignObj.defaultErrorMessage`));
                }
            });
    };
    const closeModal = () => {
        setIsModalVisibleCb(false);
    };
    const countTotal = () => {
        return campaignToSend.effectiveCustomers.length;
    };
    useEffect(() => {
        if (Object.keys(selectedCampaign).length !== 0) {
            setIsModalLoading(true);
            api.outbound()
                .getWhatsappCampaign(selectedCampaign.id)
                .then((res) => {
                    setIsModalLoading(false);
                    setSelectedTemplate(
                        templateList.filter((item) => item.id === selectedCampaign.template.id)[0]
                    );
                    setCurrentStep(0);
                    setIsCampaignSended(false);
                    setCampaignToSend(res.data);
                });
        }
    }, [isModalVisible]);

    const newOutboundUsage = () => {
        return configs.limit - configs.usage - countTotal();
    };

    const footerNextButton = () => {
        if (campaignSended) return null;

        if (currentStep === 2) {
            return (
                <Button
                    disabled={newOutboundUsage() < 0}
                    type="primary"
                    onClick={() => (campaignSended ? closeModal() : sendCampaign())}
                >
                    {t("outbound.campaignObj.sendCampaign.buttonSend")}
                </Button>
            );
        }

        if (currentStep === 1) {
            return (
                <Button
                    disabled={newOutboundUsage() < 0}
                    onClick={() => changeStep(currentStep + 1)}
                >
                    {t("outbound.campaignObj.sendCampaign.buttonNext")}
                </Button>
            );
        }

        return (
            <Button onClick={() => changeStep(currentStep + 1)}>
                {t("outbound.campaignObj.sendCampaign.buttonNext")}
            </Button>
        );
    };

    if (selectedCampaign) {
        return (
            <Modal
                title={
                    <Steps size="small" current={currentStep}>
                        <Step title={t("outbound.campaignObj.sendCampaign.template")} />
                        <Step title={t("outbound.campaignObj.sendCampaign.customers")} />
                        <Step title={t("outbound.campaignObj.sendCampaign.send")} />
                    </Steps>
                }
                visible={isModalVisible}
                closable={false}
                onCancel={closeModal}
                footer={[
                    <>
                        {currentStep === 0 || campaignSended ? (
                            <Button danger onClick={closeModal}>
                                {t("outbound.campaignObj.sendCampaign.buttonClose")}
                            </Button>
                        ) : (
                            <Button onClick={() => changeStep(currentStep - 1)}>
                                {t("outbound.campaignObj.sendCampaign.buttonBack")}
                            </Button>
                        )}
                        {footerNextButton()}
                    </>,
                ]}
            >
                {currentStep === 0 &&
                    (selectedTemplate ? (
                        <div className="opt-view" style={{ padding: 0 }}>
                            <h1 className="opt-title">
                                {t("outbound.campaignObj.sendCampaign.name")}
                            </h1>
                            <p className="opt-text">{selectedCampaign.name}</p>
                            <h2 className="opt-title">
                                {t("outbound.campaignObj.sendCampaign.template")}
                            </h2>
                            <p className="opt-text">{selectedTemplate.name}</p>
                            <p className="opt-text">{selectedTemplate.text}</p>
                        </div>
                    ) : (
                        <Spin>
                            <Skeleton />
                        </Spin>
                    ))}
                {currentStep === 1 && (
                    <div className="opt-view" style={{ padding: 0 }}>
                        <h1 className="opt-title">
                            {t("outbound.campaignObj.sendCampaign.customerReached")}
                        </h1>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Card>
                                    <Statistic
                                        title={t(
                                            "outbound.campaignObj.sendCampaign.customerGroups"
                                        )}
                                        value={campaignToSend.customerGroups.length}
                                        prefix={<TeamOutlined />}
                                    />
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <Statistic
                                        title={t("outbound.campaignObj.sendCampaign.customers")}
                                        value={campaignToSend.customers.length}
                                        prefix={<UserAddOutlined />}
                                    />
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <Statistic
                                        title={t(
                                            "outbound.campaignObj.sendCampaign.totalCustomers"
                                        )}
                                        value={countTotal()}
                                        prefix={<UsergroupAddOutlined />}
                                    />
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <Statistic
                                        title={t(
                                            "outbound.campaignObj.sendCampaign.dailyLeftLimit"
                                        )}
                                        value={configs.limit - configs.usage}
                                    />
                                </Card>
                            </Col>
                        </Row>

                        {newOutboundUsage() < 0 && (
                            <Alert
                                message="Error"
                                description={
                                    countTotal() > configs.limit
                                        ? t(
                                              "outbound.campaignObj.sendCampaign.limitExceededDescription"
                                          )
                                        : t("outbound.campaignObj.sendCampaign.dailyLimitExceeded")
                                }
                                type="error"
                                showIcon
                            />
                        )}
                    </div>
                )}
                {currentStep === 2 && (
                    <div className="opt-view" style={{ padding: 0 }}>
                        <Spin spinning={isModalLoading}>
                            {!campaignSended ? (
                                <>
                                    <h1 className="opt-title">
                                        {t("outbound.campaignObj.sendCampaign.confirmAndSend")}
                                    </h1>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12}>
                                            <Card>
                                                <Statistic
                                                    title={t(
                                                        "outbound.campaignObj.sendCampaign.messagesToBeSent"
                                                    )}
                                                    value={countTotal()}
                                                />
                                            </Card>
                                        </Col>
                                        <Col span={12}>
                                            <Card>
                                                <Statistic
                                                    title={t(
                                                        "outbound.campaignObj.sendCampaign.messageLeft"
                                                    )}
                                                    value={newOutboundUsage()}
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <Result
                                    status="success"
                                    title={t("outbound.campaignObj.sendCampaign.successfullySent")}
                                    subTitle={t(
                                        "outbound.campaignObj.sendCampaign.youHaveSentCountMessages",
                                        { countTotal: countTotal() }
                                    )}
                                />
                            )}
                        </Spin>
                    </div>
                )}
            </Modal>
        );
    } else {
        return <></>;
    }
}
export default SendCampaign;
