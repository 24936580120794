
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader/Loader";
import "./IntentionsForm.scss";
import { Intention } from "frontend-lib/DomainTypes/Intentions";
import { Popover, Input, Switch, Select } from "antd";
import { Controlled as CodeMirror } from "react-codemirror2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { notification } from "antd";
import TitleTooltip from "components/TitleTooltip/TitleTooltip";
import useIntentions, { errorKeys } from "./useIntentions";
import api from "../../../requests/api";
import { Chatbot } from "frontend-lib/DomainTypes/Chatbot";

interface LocationState {
    intentionToEdit: Intention | undefined;
}

function IntentionsForm() {
    const location = useLocation<LocationState | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [, createIntention, updateIntention, validateXML, , errorKey,] =
        useIntentions(setIsLoading);
    const [intention, setIntention] = useState<Intention>(getIntentionInitialState());
    const [newExample, setNewExample] = useState("");
    const [chatbots, setChatbots] = useState<Chatbot[]>([]);
    const { TextArea } = Input;
    const { t } = useTranslation();

    function getIntentionInitialState() {
        let initialIntention: Intention = {
            id: null,
            name: "",
            key: "",
            examples: [],
            description: "",
            xml: "<root>\n\n</root>",
            baseChatbotsId: [],
            forAllChatbots: true
        };

        if (location.state && location.state.intentionToEdit) {
            initialIntention = location.state.intentionToEdit;
        }

        return initialIntention;
    }

    function handleIntentionChange(evt: any) {
        const value = evt.target.value;
        setIntention({
            ...intention,
            [evt.target.name]: value,
        });
    }

    function handleIntentionXMLChange(value: string) {
        setIntention({
            ...intention,
            xml: value,
        });
    }

    function buildIntentionKey(input: string): string {
        const cleanString = input.toLowerCase().replace(/[^\w\s]/g, "");
        const underscoreCleanString = cleanString.replace(/\s+/g, "_");

        return underscoreCleanString;
    }

    function handleNewExample(evt: any) {
        const value = evt.target.value;
        setNewExample(value);
    }

    function addNewExample() {
        if (newExample) {
            const newState = { ...intention };
            if (!newState.examples) {
                newState.examples = [];
            }
            newState.examples.push(newExample);
            setIntention(newState);
            setNewExample("");
        }
    }

    function handleExamplesChange(evt: any) {
        const value = evt.target.value;
        const newState = { ...intention };
        newState.examples[evt.target.name] = value;
        setIntention(newState);
    }

    function deleteExample(example: string, index: number) {
        const newState = { ...intention };
        newState.examples = newState.examples.filter((item, i) => i !== index);
        setIntention(newState);
    }

    async function sendForm(event: any) {
        event.preventDefault();
        event.stopPropagation();
        let xmlIsValid = true;

        if (event.keyCode === 13) {
            return false;
        }

        if (!intention.name) {
            parseIntenionErrorMessage(errorKeys.INTENTION_NAME_ERROR);
        } else if (!intention.description) {
            parseIntenionErrorMessage(errorKeys.INTENTION_DESCRIPTION_ERROR);
        } else if (intention.examples.length === 0) {
            parseIntenionErrorMessage(errorKeys.INTENTION_EXAMPLES_ERROR);
        } else {
            if (intention?.xml) {
                xmlIsValid = await validateXML(intention.xml);
            } else {
                xmlIsValid = true;
            }

            if (xmlIsValid || !intention.xml) {
                if (location.state && location.state.intentionToEdit) {
                    updateIntention(intention);
                } else {
                    createIntention(intention);
                }
            }
        }
    }

    function parseIntenionErrorMessage(errorKey: errorKeys) {
        const errorDuration = 4;
        let errorMessage = "";
        let errorDescription = "";

        if (errorKey === errorKeys.INTENTION_NAME_ERROR) {
            errorMessage = t("intentions.intentionsForm.errorIntentionName");
            errorDescription = t("intentions.intentionsForm.errorIntentionNameDescription");
        } else if (errorKey === errorKeys.INTENTION_DESCRIPTION_ERROR) {
            errorMessage = t("intentions.intentionsForm.errorIntentionDescription");
            errorDescription = t("intentions.intentionsForm.errorIntentionDescriptionDescription");
        } else if (errorKey === errorKeys.INTENTION_EXAMPLES_ERROR) {
            errorMessage = t("intentions.intentionsForm.errorIntentionExamples");
            errorDescription = t("intentions.intentionsForm.errorIntentionExamplesDescription");
        } else if (errorKey === errorKeys.XML_SYNTAX_ERROR) {
            errorMessage = t("intentions.intentionsForm.errorIntentionXMLSyntax");
            errorDescription = t("intentions.intentionsForm.errorIntentionXMLSyntaxDescription");
        } else if (errorKey === errorKeys.XML_UNKNOWN_ERROR) {
            errorMessage = t("intentions.intentionsForm.errorIntentionXMLUnknown");
            errorDescription = t("intentions.intentionsForm.errorIntentionXMLUnknownDescription");
        } else if (errorKey === errorKeys.INTENTION_ALREADY_EXISTS) {
            errorMessage = t("intentions.intentionsForm.errorIntentionAlreadyExists");
            errorDescription = t(
                "intentions.intentionsForm.errorIntentionAlreadyExistsDescription"
            );
        } else if (errorKey === errorKeys.INTENTION_CREATION_ERROR) {
            errorMessage = t("intentions.intentionsForm.errorIntentionCreation");
            errorDescription = t("intentions.intentionsForm.errorIntentionCreationDescription");
        } else if (errorKey === errorKeys.INTENTION_UPDATE_ERROR) {
            errorMessage = t("intentions.intentionsForm.errorIntentionUpdate");
            errorDescription = t("intentions.intentionsForm.errorIntentionUpdateDescription");
        } else {
            errorMessage = t("intentions.intentionsForm.errorUnknown");
            errorDescription = t("intentions.intentionsForm.errorUnknownDescription");
        }

        notification.error({
            message: errorMessage,
            description: errorDescription,
            duration: errorDuration,
        });
    }

    function handleChatbotsChange(baseChatbotsId: number[]) {
        const newState = { ...intention };
        newState.baseChatbotsId = baseChatbotsId
        setIntention(newState);
    }

    function handleForAllChatbotsChange(checked: boolean) {
        const newState = { ...intention };
        newState.forAllChatbots = checked
        if (checked) {
            newState.baseChatbotsId = []
        }
        setIntention(newState);
    }

    useEffect(() => {
        if (errorKey && !isLoading) {
            parseIntenionErrorMessage(errorKey);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorKey, isLoading]);

    useEffect(() => {
        setIsLoading(true);
        api.chatbots()
            .list()
            .then((res) => {
                setChatbots(res.data.chatBots);
                setIsLoading(false);
            });
    }, []);

    return (
        <div className="opt-view opt-view--intentions opt-view--form">
            <Loader isLoading={isLoading} />

            {/* HEADER */}
            <div className="intention-header">
                <h1 className="opt-title">
                    <NavLink to="/dashboard/intentions">← {t("intentions.goBack")}</NavLink>
                </h1>
                {location.state && location.state.intentionToEdit ? (
                    <input
                        type="button"
                        onClick={sendForm}
                        className="opt-button"
                        value={t("intentions.editIntention")}
                    />
                ) : (
                    <input
                        type="button"
                        onClick={sendForm}
                        className="opt-button"
                        value={t("intentions.createIntention")}
                    />
                )}
            </div>

            {/* NAME, KEY, DESCRIPTION */}
            <div className="card-wrapper">
                <div className="opt-card opt-card--form intention-card-info basic-info">
                    <div className="opt-form-group">
                        <label className="opt-label-title">
                            {t("intentions.intentionsForm.intentionName")}
                        </label>
                        <Popover
                            trigger="hover"
                            placement="rightTop"
                            content={
                                <div className="opt-popover-wrapper-content">
                                    <span className="opt-popover-text opt-form-text--grey">
                                        {t("intentions.intentionsForm.intentionNameDescription")}
                                    </span>
                                </div>
                            }
                        >
                            <Input
                                className="opt-text-input"
                                placeholder={t(
                                    "intentions.intentionsForm.intentionNamePlaceholder"
                                )}
                                name="name"
                                value={intention.name}
                                onChange={handleIntentionChange}
                                required
                            />
                        </Popover>
                        <label className="opt-label-title">
                            {t("intentions.intentionsForm.intentionKey")}
                            <TitleTooltip
                                content={t("intentions.intentionsForm.intentionKeyTooltip")}
                            />
                        </label>
                        <Input
                            className="opt-text-input disabled-input"
                            placeholder={t("intentions.intentionsForm.intentionKeyPlaceholder")}
                            name="key"
                            value={buildIntentionKey(intention.name)}
                            disabled={true}
                        />
                        <label className="opt-label-title">
                            {t("intentions.intentionsForm.intentionDescription")}
                        </label>
                        <Popover
                            trigger="hover"
                            placement="rightTop"
                            content={
                                <div className="opt-popover-wrapper-content">
                                    <span className="opt-popover-text opt-form-text--grey">
                                        {t(
                                            "intentions.intentionsForm.intentionDescriptionDescription"
                                        )}
                                    </span>
                                </div>
                            }
                        >
                            <TextArea
                                rows={4}
                                className="opt-text-input"
                                placeholder={t(
                                    "intentions.intentionsForm.intentionDescriptionPlaceholder"
                                )}
                                name="description"
                                value={intention.description}
                                onChange={handleIntentionChange}
                                required
                            />
                        </Popover>
                    </div>
                </div>

                {/* EXAMPLES, CHATBOTS */}
                <div className="opt-card opt-card--form opt-intention-examples intention-card-info basic-info">
                    <label className="opt-label-title">
                        {t("intentions.intentionsForm.intentionExamples")}
                    </label>
                    <div className="opt-form-group">
                        <Popover
                            trigger="hover"
                            placement="rightTop"
                            content={
                                <div className="opt-popover-wrapper-content opt-popover--example">
                                    <span className="opt-popover-text opt-form-text--grey">
                                        {t(
                                            "intentions.intentionsForm.intentionExamplesDescription"
                                        )}
                                    </span>
                                </div>
                            }
                        >
                            <div className="opt-input-button">
                                <Input
                                    className="opt-text-input"
                                    placeholder={t(
                                        "intentions.intentionsForm.intentionExamplesPlaceholder"
                                    )}
                                    name="examples"
                                    value={newExample}
                                    onChange={handleNewExample}
                                />
                                <button
                                    type="button"
                                    onClick={addNewExample}
                                    className="opt-button"
                                >
                                    {t("add")}
                                </button>
                            </div>
                        </Popover>
                    </div>
                    <div className="opt-intention-example-wrapper">
                        {intention.examples &&
                            intention.examples.map((example, index) => (
                                <div className="opt-form-group edit-examples" key={index}>
                                    <label>
                                        {t("intentions.intentionsForm.intentionExamples")}{" "}
                                        {index + 1}
                                    </label>
                                    <Input
                                        className="opt-text-input opt-text-input--icon"
                                        onChange={handleExamplesChange}
                                        name={String(index)}
                                        value={intention.examples[index]}
                                    />
                                    <div
                                        className="opt-delete-button"
                                        onClick={() => deleteExample(example, index)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} className="delete-icon" />
                                    </div>
                                </div>
                            ))}
                    </div>

                    <label className="opt-label-title">
                        {t("intentions.intentionsForm.choseWhatChatbotsCanUseThisAnswer")}
                    </label>
                    <div className="opt-form-group">
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <span style={{ marginTop: "10px" }}>
                                    {t("intentions.intentionsForm.forAllChatbots")}
                                </span>
                                <Switch
                                    style={{ marginLeft: "10px", marginTop: "10px" }}
                                    checked={intention.forAllChatbots}
                                    onChange={handleForAllChatbotsChange}
                                />
                            </div>
                            <Select
                                disabled={intention.forAllChatbots}
                                mode="multiple"
                                style={{ width: "100%", marginTop: "10px" }}
                                placeholder={t("intentions.intentionsForm.selectChatbotPlaceholder")}
                                onChange={handleChatbotsChange}
                                value={intention.baseChatbotsId}
                            >
                                {chatbots.map((chatbot) => (
                                    <Select.Option key={chatbot.id} value={chatbot.id}>
                                        {chatbot.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    </div>

                </div>

            </div>

            {/* XML */}
            <div className="card-wrapper">
                <div className="opt-card--xml">
                    <div className="opt-input-group">
                        <h2 className="opt-subtitle">
                            {t("intentions.intentionsForm.intentionFlow")}
                        </h2>
                        <CodeMirror
                            value={intention.xml} //@ts-ignore
                            name="xml"
                            style={{ minHeight: "600px" }}
                            options={{
                                mode: "xml",
                                theme: "material",
                                lineNumbers: true,
                            }}
                            onBeforeChange={(editor, data, value) => {
                                handleIntentionXMLChange(value);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IntentionsForm;
