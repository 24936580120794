export enum TemplateCategory {
    MARKETING = "MARKETING",
    UTILITY = "UTILITY",
    AUTHENTICATION = "AUTHENTICATION",
}

export enum TemplateType {
    TEXT = "TEXT",
    IMAGE = "IMAGE",
    VIDEO = "VIDEO",
    DOCUMENT = "DOCUMENT",
}
interface UrlButton {
    type: "URL";
    url: string;
    urlExample: string;
    text: string;
}
interface QuickReplyButton {
    type: "QUICK_REPLY";
    text: string;
}
interface CallButton {
    type: "PHONE_NUMBER";
    phoneNumber: string;
    text: string;
}

export enum TemplateDeniedReason {
    NUMBER_OF_APPLY_RETRIES_EXCEEDED = "NUMBER_OF_APPLY_RETRIES_EXCEEDED",
    NUMBER_OF_CHECK_STATUS_RETRIES_EXCEEDED = "NUMBER_OF_CHECK_STATUS_RETRIES_EXCEEDED",
    TEMPLATE_NOT_UPLOADED_IN_GUPSHUP = "TEMPLATE_NOT_UPLOADED_IN_GUPSHUP",
    TEMPLATE_ALREADY_EXISTS_IN_GUPSHUP = "TEMPLATE_ALREADY_EXISTS_IN_GUPSHUP",
    TEMPLATE_NOT_SUPPORTED_IN_GUPSHUP = "TEMPLATE_NOT_SUPPORTED_IN_GUPSHUP",
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export function isTemplateDeniedReason(input: string): boolean {
    return Object.values(TemplateDeniedReason).includes(input as TemplateDeniedReason);
}

export type TemplateButton = UrlButton | QuickReplyButton | CallButton;

export interface Template {
    id: number;
    key: string;
    name: string;
    text: string;
    createdOn: string;
    updatedOn: string;
    status: "IN_REVIEW" | "DENIED" | "APPROVED" | "DISABLED" | "PAUSED" | "DELETED" | "DEACTIVATED";
    category: TemplateCategory;
    templateType: TemplateType;
    language: "SPANISH" | "ENGLISH";
    deniedReason: string | TemplateDeniedReason;
    buttons?: TemplateButton[];
    quality: "PENDING" | "HIGH" | "LOW" | "MEDIUM";
    footer?: string;
    file?: {
        url: string;
    };
}
