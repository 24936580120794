import React, { useState, useMemo, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "components/AppContext";
import { message, Upload, Modal } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import FilePreview from "./FilePreview.tsx";
import { getFileChannelSizesWarnings } from "frontend-lib/SizeChecker/";
import { FileType } from "frontend-lib/SizeChecker/";

const uploadTypes =
    "image/png, image/jpeg, audio/mpeg, audio/ogg, ideo/mpeg, application/pdf, video/mp4";
function FileUpload({ isModalOpen, uploadCb, toggleModalCb }) {
    const [fileToPreview, setFileToPreview] = useState(null);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [multimediaType, setMultimediaType] = useState(null);

    const [channelSizeWarnings, setChannelSizeWarnings] = useState(null);
    const { t } = useTranslation();
    const appContext = useContext(AppContext);
    const fileSizeLimitConfig = useMemo(() => {
        return appContext.state.workspaceConfigs?.backend?.fileSizeLimit || 12 * 1000 * 1000;
    }, [appContext.state.workspaceConfigs?.backend?.fileSizeLimit]);

    function before(file) {
        const fileType = file.type;
        const allowedImageMIMETypes = ["image/png", "image/jpeg", "image/jpg"];
        const allowedVideoMIMETypes = ["video/mp4"];
        const allowedAudioMIMETypes = ["audio/mpeg", "audio/ogg"];
        const allowedDocMIMETypes = ["application/pdf"];
        if (file.size >= fileSizeLimitConfig) {
            message.error(
                `${t("files.excededError")} ${Number(fileSizeLimitConfig / 1000 / 1000)} MB.`
            );
            return;
        }
        if (allowedImageMIMETypes.includes(fileType)) {
            setMultimediaType(FileType.IMAGE);
            const channelConfigs = getFileChannelSizesWarnings(file.size, FileType.IMAGE);
            setChannelSizeWarnings(channelConfigs);
        } else if (allowedVideoMIMETypes.includes(fileType)) {
            setMultimediaType(FileType.VIDEO);
            const channelConfigs = getFileChannelSizesWarnings(file.size, FileType.VIDEO);
            setChannelSizeWarnings(channelConfigs);
        } else if (allowedAudioMIMETypes.includes(fileType)) {
            setMultimediaType(FileType.AUDIO);
            const channelConfigs = getFileChannelSizesWarnings(file.size, FileType.AUDIO);
            setChannelSizeWarnings(channelConfigs);
        } else if (allowedDocMIMETypes.includes(fileType)) {
            setMultimediaType(FileType.DOCUMENT);
            const channelConfigs = getFileChannelSizesWarnings(file.size, FileType.DOCUMENT);
            setChannelSizeWarnings(channelConfigs);
        } else {
            message.error(t("files.fileTypeNotAllowed"));
            return;
        }
        return new Promise((resolve) => {
            const reader = new FileReader();
            setFileToUpload(file);
            reader.readAsDataURL(file);
            reader.onload = () => {
                setFileToPreview(reader.result);
                resolve();
            };
        });
    }

    function onOk() {
        if (fileToPreview) {
            const formData = new FormData();
            formData.append("file", fileToUpload);
            formData.append("useAbsoluteUrl", true);
            if (fileToUpload.size >= fileSizeLimitConfig) {
                message.error(
                    `${t("files.excededError")} ${Number(fileSizeLimitConfig / 1024 / 1024)} MB.`
                );
                return;
            }
            uploadCb(formData, fileToUpload, multimediaType.toUpperCase());
        }
    }

    function onCancel() {
        if (fileToUpload) {
            setFileToUpload(null);
            setFileToPreview(null);
        } else {
            toggleModalCb();
        }
    }

    useEffect(() => {
        if (!isModalOpen) {
            setFileToPreview(null);
            setChannelSizeWarnings(null);
            setFileToUpload(null);
            setMultimediaType(null);
        }
    }, [isModalOpen]);

    return (
        <Modal
            okText={t("upload")}
            title={t("upload")}
            visible={isModalOpen}
            onCancel={onCancel}
            onOk={onOk}
        >
            {fileToPreview ? (
                <FilePreview
                    warnings={channelSizeWarnings}
                    name={fileToUpload.name}
                    file={fileToPreview}
                    type={multimediaType}
                />
            ) : (
                <Upload.Dragger
                    beforeUpload={before}
                    accept={uploadTypes}
                    maxCount={0}
                    showUploadList={false}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t("files.clickDrag")}</p>
                    <p className="ant-upload-hint">{t("files.support")}</p>
                </Upload.Dragger>
            )}
        </Modal>
    );
}

export default FileUpload;
