const INITIAL_STATE = {
    greetings: {
        welcomeGreetings: "-",
        multiplesGreetings: "-",
    },
    closing: {
        farewell: "-",
    },
    general: {
        yourWelcome: "-",
        couldNotBeUnderstood: "-",
        plainCouldNotBeUnderstood: "-",
        whatElseCanIDoForYou: "-",
        whatCanIDoForYou: "-",
        botOrHuman: "-",
        botOtherQuestion: "-",
        botName: "-",
        botAge: "-",
    },
    handOff: {
        isActive: false,
        helpDetected: "",
        helpDetectedWithoutAgents: "-",
        agentHandOverInOfficeHours: "-",
        agentHandOverOutOfOfficeHoursWithAsyncCapabilities: "-",
        agentHandOverOutOfOfficeHoursWithoutAsyncCapabilities: "-",
    },
    qa: {
        isActive: false,
        sendSimilarQuestions: false,
        askForFeedback: false,
        askForHumanHelpAfterNegativeFeedback: false,
        similarQuestions: "-",
    },
    customerData: {
        isMandatory: false,
        emailQuestion: "-",
        nameQuestion: "-",
        phoneQuestion: "-",
        askName: false,
        askEmail: false,
        askPhone: false,
    },
    appointment: {
        appointmentDetected: "-",
    },
    externalEventProcessor: {
        xml: "",
    },
    router: {
        isActive: false,
        definition: "",
        chooseOneOfTheOptions: "",
        searchKnowledgeByTagLimit: 0,
        askIfWantToGoBack: "",
    },
};
export { INITIAL_STATE };
