import { useLocation } from "react-router-dom";
import useFilter from "../views/Dashboard/Monitoring/filters/useFilter";

function useCustomerFilter() {
    const location = useLocation();
    const defaultState = {
        startDate: null,
        endDate: null,
        optIn: false,
        customersIds: null,
        customerGroupsIds: null,
        conversationTagsIds: null,
        conversationGroupsIds: null,
        agentsIds: null,
        searchText: null,
        orCustomersAndGroups: null,
        lastActivityStartDate: null,
        lastActivityEndDate: null,
    };
    const [filterState, filterDispatch] = useFilter(
        "opt-customers-filter",
        defaultState,
        location.state && location.state.filters
    );

    return [filterState, filterDispatch];
}

export default useCustomerFilter;
