import { Button, Checkbox, Divider, Input, Form, message, Spin } from "antd";
import { OptiweCard } from "components/OptiweCard";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FirebaseAuth from "./FirebaseAuth";
import api from "requests/api";
import axios, { AxiosError } from "axios";
import { useHistory } from "react-router-dom";


interface FormFields {
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    passwordRepeated: string,
}


export default function CreateUser() {
    const { t } = useTranslation();
    const [acceptTermsAndCons, setAcceptTermsAndCons] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    async function handleFormSubmit({ email, firstName, lastName, password, passwordRepeated }: FormFields) {
        setIsLoading(true)
        try {
            await api.globalUser().create({ email, firstName, lastName, password, passwordRepeated, hasAcceptedTermsAndCons: acceptTermsAndCons })
            history.push('/createworkspace', { adminEmail: email })
        } catch (err: any | AxiosError) {
            if (!axios.isAxiosError(err)) throw err
            if (err.response?.status === 400) {
                const errorData = err.response.data.error;
                const errorKey = errorData.key;
                if (errorKey === 'USER_ALREADY_EXISTS') {
                    message.info(t('signUp.errors.userAlreadyExists'))
                    history.push('/createworkspace', { adminEmail: email })
                    return
                }
            }
            message.error(t('unknownError'))
        } finally {
            setIsLoading(false)
        }
    }


    async function onFirebaseAuth(accessToken: string) {
        setIsLoading(true)
        try {
            const resCreateUser = await api.globalUser().createWithFirebase({ token: accessToken })
            const newUserEmail = resCreateUser.data.email
            history.push('/createworkspace', { adminEmail: newUserEmail })
        } catch (err: any | AxiosError) {
            if (!axios.isAxiosError(err)) throw err
            if (err.response?.status === 400) {
                const errorData = err.response.data.error;
                const errorKey = errorData.key;
                if (errorKey === 'USER_ALREADY_EXISTS') {
                    message.error(t('signUp.errors.userAlreadyExists'))
                    return
                }
            }
            message.error(t('unknownError'))
        } finally {
            setIsLoading(false)
        }

    }

    return (
        <OptiweCard
            title={t('signUp.createAUser')}
        >
            {isLoading ?
                <Spin style={{ justifyContent: "center", display: "flex", marginTop: '30%' }} size="large" /> :
                <>

                    <Form form={form} onFinish={handleFormSubmit} layout="vertical">
                        <Form.Item name="email" rules={[{ required: true, message: t("required") }]}>
                            <Input type="email" size="large" placeholder={t("signUp.yourEmail")} />
                        </Form.Item>
                        <Form.Item
                            name="firstName"
                            rules={[{ required: true, message: t("required") }]}
                        >
                            <Input size="large" placeholder={t("signUp.yourName")} />
                        </Form.Item>

                        <Form.Item
                            rules={[{ required: true, message: t("required") }]}
                            name="lastName"
                        >
                            <Input size="large" placeholder={t("signUp.yourLastName")} />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: t("required") }]}
                        >
                            <Input
                                type="password"
                                size="large"
                                placeholder={t("signUp.password")}
                            />
                        </Form.Item>
                        <Form.Item
                            name="passwordRepeated"
                            dependencies={["password"]}
                            rules={[
                                {
                                    required: true,
                                    message: t("required"),
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(t("signUp.passwordsDoNotMatch"));
                                    },
                                }),
                            ]}
                        >
                            <Input
                                type="password"
                                size="large"
                                placeholder={t("signUp.repeatPassword")}
                            />
                        </Form.Item>

                        <Checkbox
                            className="signup__terms"
                            onChange={(e) => setAcceptTermsAndCons(e.target.checked)}
                        >
                            {t("signUp.iAcceptThe")}{" "}
                            <a href="http://optiwe.com/legal/terms.pdf">
                                {" "}
                                {t("signUp.termsAndConditions")}{" "}
                            </a>
                        </Checkbox>


                        <Button
                            className="opt-button"
                            type="primary"
                            htmlType="submit"
                            disabled={!acceptTermsAndCons}
                        >
                            {t('submit')}
                        </Button>
                    </Form>
                    <Divider style={{ color: '#B4B4B4' }}>or</Divider>
                    <FirebaseAuth onAuthCb={onFirebaseAuth} googleText={t('firebaseAuth.googleSignUp')} />
                </>
            }
        </OptiweCard >
    )
}