import { AxiosError } from "axios";
import { Language } from "frontend-lib/DomainTypes/Languages";
import {
    Template,
    TemplateButton,
    TemplateCategory,
    TemplateType,
} from "frontend-lib/DomainTypes/Template";
import { Logger } from "frontend-lib/Log";
import React, { useEffect, useMemo, useState } from "react";

interface CreateTemplateProps {
    key: string;
    name: string;
    text: string;
    example: string;
    category: TemplateCategory;
    templateType: TemplateType;
    language: Language;
    fileData?: {
        name: string;
        mimeType: string;
        dataB64: string;
    };
    buttons?: TemplateButton[];
    footer?: string;
}
enum CreateTemplateError {
    TEMPLATE_ALREADY_EXISTS = "TEMPLATE_ALREADY_EXISTS",
    BAD_REQUEST = "BAD_REQUEST",
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

type Loading = boolean;
type Error = boolean;
type ReloadCb = () => void;
export type CreateTemplateFn = (
    newTemplate: CreateTemplateProps
) => Promise<void | CreateTemplateError>;

interface useTemplatesProps {
    // maybe this can be refactored to avoid repetition
    api: {
        api_url: () => {
            get: () => string | undefined;
        };
        outbound: () => {
            createWhatsappTemplate: (template: any) => Promise<any>;
            getWhatsappTemplates: () => Promise<any>;
        };
    };
}

export default function useTemplates({
    api,
}: useTemplatesProps): [Template[] | undefined, Loading, Error, ReloadCb, CreateTemplateFn] {
    const [templates, setTemplates] = useState<Template[]>();
    const [isLoading, setIsLoading] = useState<Loading>(false);
    const [isError, setIsError] = useState<Error>(false);
    const [dummyState, setDummyState] = useState(false);
    const logger = useMemo(() => {
        return new Logger("useTemplates", api.api_url().get());
    }, []);

    const createTemplate: CreateTemplateFn = async (newTemplate: CreateTemplateProps) => {
        try {
            setIsLoading(true);
            await api.outbound().createWhatsappTemplate(newTemplate);
            reload();
        } catch (err) {
            const error = err as AxiosError;
            if (error.response?.status === 400) {
                if (error.response?.data?.key === "TEMPLATE_ALREADY_EXISTS")
                    return CreateTemplateError.TEMPLATE_ALREADY_EXISTS;
                else return CreateTemplateError.BAD_REQUEST;
            } else {
                logger.error(
                    `Error creating new templates. Payload for creating templates is: 
                    ${JSON.stringify(newTemplate)} . Error is ${JSON.stringify(err)}`
                );
                return CreateTemplateError.UNKNOWN_ERROR;
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const loadTemplates = async () => {
            try {
                setIsLoading(true);
                setIsError(false);
                const templateResponse = await api.outbound().getWhatsappTemplates();
                const templates = templateResponse.data.templates as Template[];
                templates.sort(function (a, b) {
                    if (a.id < b.id) return 1;
                    if (a.id > b.id) return -1;
                    return 0;
                });
                setTemplates(templateResponse.data.templates);
            } catch (err) {
                logger.error(
                    "Error loading templates in useTemplates, error is: " + JSON.stringify(err)
                );
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        };
        loadTemplates();
    }, [dummyState, logger]);

    const reload = () => {
        setDummyState((s) => !s);
    };

    return [templates, isLoading, isError, reload, createTemplate];
}
