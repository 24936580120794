import React from "react";
import ChannelCharWarnings from "components/ChannelCharWarnings/ChannelCharWarnings";
interface WarningWrapperProps {
    text: string;
}
export default function WarningWrapper({ text }: WarningWrapperProps) {
    return (
        <div style={{ paddingLeft: "10px", borderLeft: "1px solid #d9d9d9" }}>
            <ChannelCharWarnings text={text} />
        </div>
    );
}
