import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faComments,
    faChevronRight,
    faAddressCard,
    faSms,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp, faFacebookMessenger, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../../components/AppContext";
import moment from "moment";
import TitleTooltip from "../../../components/TitleTooltip/TitleTooltip";
import api from "../../../requests/api";
import "./Home.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as queryString from "query-string";
import { Spin } from "antd";

function Home() {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [counts, setCounts] = useState({
        users: {
            total: 0,
        },
        conversations: {
            total: 0,
            humanAgent: 0,
            aiAgent: 0,
            both: 0,
        },
    });
    let location = useLocation();
    const history = useHistory();

    const openInANewTab = (url) => {
        const win = window.open(url, "_blank");
        win.focus();
    };

    const openDemo = () => {
        setIsLoading(true);
        api.featureRequests()
            .create("WEB_CHAT_URL")
            .then((response) => {
                setIsLoading(false);
                const win = window.open(response.data.url, "_blank");
                win.focus();
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        api.workspaces()
            .counts(appContext.state.workspaceId)
            .then((response) => {
                const counts = response.data.counts;
                setCounts(counts);
                setIsLoading(false);
            })
            .catch((errors) => {
                console.log(errors);
                setIsLoading(false);
            });
    }, [setIsLoading, appContext.state.workspaceId]);

    useEffect(() => {
        let parsedQueryParams = {};
        if (location.hash) {
            parsedQueryParams = queryString.parse(location.hash);
        } else if (location.search) {
            parsedQueryParams = queryString.parse(location.search);
        }

        if (parsedQueryParams.facebookConnection) {
            history.push("/dashboard/home/channels-config");
        }
    }, [history, location]);

    /**
     *
     * @param {JSX.Element} children
     * @returns {JSX.Element}
     */
    const spinIfLoading = (children) => {
        if (isLoading) {
            return <Spin />;
        }
        return children;
    };

    return (
        <div className="opt-view opt-view--home">
            <div className="opt-view-header">
                <h1 className="opt-title">
                    {" "}
                    {t("dashboard.Workspace info")} | {moment().format("MMMM")}{" "}
                    {moment().format("YYYY")}
                </h1>
            </div>
            <section className="opt-home-stats">
                <div className="opt-card opt-card--stat">
                    <h2 className="opt-card__stat-title">
                        {t("dashboard.Total Users")}{" "}
                        <TitleTooltip content={t("dashboard.totalNumberOfAgents")} />
                    </h2>
                    {spinIfLoading(<p className="opt-card__stat">{counts.users.total}</p>)}
                </div>
                <div className="opt-card opt-card--stat">
                    <h2 className="opt-card__stat-title">
                        {t("dashboard.Total conversations")}{" "}
                        <TitleTooltip content={t("dashboard.totalNumberOfConversations")} />
                    </h2>
                    {spinIfLoading(<p className="opt-card__stat">{counts.conversations.total}</p>)}
                </div>
                <div className="opt-card opt-card--stat">
                    <h2 className="opt-card__stat-title">
                        {t("dashboard.Agent conversations")}{" "}
                        <TitleTooltip content={t("dashboard.agentConversationsTooltip")} />
                    </h2>
                    {spinIfLoading(
                        <p className="opt-card__stat">{counts.conversations.humanAgent}</p>
                    )}
                </div>
                <div className="opt-card opt-card--stat">
                    <h2 className="opt-card__stat-title">
                        {t("dashboard.AI conversations")}{" "}
                        <TitleTooltip content={t("dashboard.aiAgentConversationTooltip")} />
                    </h2>
                    {spinIfLoading(
                        <p className="opt-card__stat">{counts.conversations.aiAgent}</p>
                    )}
                </div>
                <div className="opt-card opt-card--stat">
                    <h2 className="opt-card__stat-title">
                        {t("dashboard.Combined conversations")}{" "}
                        <TitleTooltip content={t("dashboard.combinedConversationsTooltip")} />
                    </h2>
                    {spinIfLoading(<p className="opt-card__stat">{counts.conversations.both}</p>)}
                </div>
            </section>
            <div className="opt-view-header">
                <h2 className="opt-title">
                    {t("dashboard.Onboarding")}{" "}
                    <TitleTooltip content="First steps to get started with our platform" />
                </h2>
            </div>
            <section className="opt-channels">
                <div className="opt-card opt-card--integration">
                    <div className="onboarding-step">1</div>
                    <div className="opt-card--channel-icon-wrapper">
                        <FontAwesomeIcon
                            className="opt-card__integration-icon chat"
                            icon={faAddressCard}
                        />
                    </div>
                    <h2 className="opt-card__integration-title">Live Agents App</h2>
                    <p className="opt-text">
                        {t("dashboard.Enter the agent application to start managing your clients")}
                    </p>
                    <div className="opt-card__actions-wrapper">
                        <button
                            className="opt-button"
                            onClick={() => openInANewTab("https://agent.optiwe.com")}
                        >
                            {t("dashboard.Log in")}
                        </button>
                    </div>
                </div>
                <div className="opt-card opt-card--integration">
                    <div className="onboarding-step">2</div>
                    <div className="opt-card--channel-icon-wrapper">
                        <FontAwesomeIcon
                            className="opt-card__integration-icon chat"
                            icon={faComments}
                        />
                    </div>
                    <h2 className="opt-card__integration-title">{t("dashboard.Try a demo")}</h2>
                    <p className="opt-text">
                        {t("dashboard.View how your users can access the chat in your website")}
                    </p>
                    <div className="opt-card__actions-wrapper">
                        <button className="opt-button opt-button--light" onClick={() => openDemo()}>
                            {t("dashboard.View demo")}
                            <FontAwesomeIcon
                                className="opt-button__link-icon"
                                icon={faChevronRight}
                            />
                        </button>
                    </div>
                </div>
                <div className="opt-card opt-card--integration">
                    <div className="onboarding-step">3</div>
                    <div className="opt-card--channel-icon-wrapper">
                        <FontAwesomeIcon
                            className={"opt-card__channel-icon facebook"}
                            icon={faFacebookMessenger}
                        />
                        <div className="instagram-container" style={{ marginLeft: "10px" }}>
                            <FontAwesomeIcon
                                className="opt-card__channel-icon"
                                icon={faInstagram}
                            />
                        </div>
                        <FontAwesomeIcon
                            style={{ marginLeft: "10px" }}
                            className={"opt-card__channel-icon whatsapp"}
                            icon={faWhatsapp}
                        />
                        <FontAwesomeIcon
                            style={{ marginLeft: "10px" }}
                            className={"opt-card__channel-icon webchat"}
                            icon={faCommentDots}
                        />
                        <FontAwesomeIcon
                            style={{ marginLeft: "10px" }}
                            className={"opt-card__channel-icon sms"}
                            icon={faSms}
                        />
                    </div>
                    <h2 className="opt-card__channel-title">
                        {t("dashboard.connectOptiweToChannels")}
                    </h2>
                    <p className="opt-text">{t("dashboard.useTheAppToServeYourClients")}</p>
                    <div className="opt-card__actions-wrapper">
                        <button
                            style={{ marginRight: "10px" }}
                            className={"opt-button"}
                            onClick={() => history.push("/dashboard/home/channels-config")}
                        >
                            {t("dashboard.Connect")}
                        </button>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
