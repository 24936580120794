import React from "react";
import { secondsToHms } from "../../../configs/formatters";
import { i18next } from "../../../../../../lang/i18n";
class ChatDurationsItem {
    constructor(name = null, date = null) {
        this.name = name;
        this.date = date;
        this.duration = null;
        this.formatedHour = null;
    }
    setValues(date, duration) {
        this.date = date;
        this.duration = duration;
    }
}
const chatDurationsTexts = [
    {
        id: 1,
        name: i18next.t("durations.totalTime"),
        label: i18next.t("durations.totalTime"),
        description: i18next.t("durations.totalTimeDesc"),
        color: "#5B8FF9",
    },
    {
        id: 2,
        name: i18next.t("durations.botServiceTime"),
        label: i18next.t("durations.botServiceTime"),
        description: i18next.t("durations.botServiceTimeDesc"),
        color: "#83E1BD",
    },
    {
        id: 3,
        name: i18next.t("durations.agentServiceTime"),
        label: i18next.t("durations.agentServiceTime"),
        description: i18next.t("durations.agentServiceTimeDesc"),
        color: "#5D7092",
    },
    {
        id: 4,
        name: i18next.t("durations.waitingTime"),
        label: i18next.t("durations.waitingTime"),
        description: i18next.t("durations.waitingTimeDesc"),
        color: "#F8CD50",
    },
];
const chatDurationsConfig = {
    height: 300,
    xField: "date",
    yField: "duration",
    isGroup: true,
    seriesField: "name",
    legend: {
        position: "top-left",
    },
    tooltip: {
        formatter: (data) => {
            let visibleData = {
                name: chatDurationsTexts.find((item) => item.name === data.name).label,
            };
            if (data.duration) {
                if (data.duration > 60) {
                    visibleData.value = secondsToHms(data.duration);
                } else {
                    visibleData.value = `${data.duration} minutes`;
                }
            } else {
                visibleData.value = "Not registered";
            }
            return visibleData;
        },
        showMarkers: false,
    },
    color: (data) => {
        return chatDurationsTexts.find((item) => item.name === data.name).color;
    },
};
const chatDurationsModal = (
    <ul>
        {chatDurationsTexts.map((item) => (
            <li>
                <h3>
                    <span
                        style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            marginRight: "5px",
                            background: item.color,
                        }}
                    ></span>
                    {item.name}
                </h3>
                <p>{item.description}</p>
            </li>
        ))}
    </ul>
);

export { ChatDurationsItem, chatDurationsTexts, chatDurationsConfig, chatDurationsModal };
