import React from "react";
import { AppContext } from "../../components/AppContext";
import Button from "../../components/FormComponents/Button";
// resources
import logo from "../../img/logo.svg";
import "./Payments.scss";

class PremiumPlanEs extends React.Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        var stripe = window.Stripe("pk_live_kwIdpEM2Jd5dZHiF4XINafHU00VDDmg2oX");

        var checkoutButton = document.getElementById(
            "checkout-button-price_1HIJ2MCdguhNQPSQs46h2gR9"
        );

        checkoutButton.addEventListener("click", function () {
            // When the customer clicks on the button, redirect
            // them to Checkout.
            stripe
                .redirectToCheckout({
                    lineItems: [{ price: "price_1HIJ2MCdguhNQPSQs46h2gR9", quantity: 1 }],
                    mode: "subscription",
                    // Do not rely on the redirect to the successUrl for fulfilling
                    // purchases, customers may not always reach the success_url after
                    // a successful payment.
                    // Instead use one of the strategies described in
                    // https://stripe.com/docs/payments/checkout/fulfillment
                    successUrl: "http://optiwe.com/success",
                    cancelUrl: "http://optiwe.com/canceled",
                })
                .then(function (result) {
                    if (result.error) {
                        // If `redirectToCheckout` fails due to a browser or network
                        // error, display the localized error message to your customer.
                        var displayError = document.getElementById("error-message");
                        displayError.textContent = result.error.message;
                    }
                });
        });
    }

    render() {
        return (
            <div className="section-login">
                <div className="section-login__logo">
                    <h1>
                        <img src={logo} alt="Optiwe logo" />
                        <span className="d-none">Optiwe</span>
                    </h1>
                    <p>Sistema de pagos de Optiwe, Inc.</p>
                </div>
                <div className="section-login__form">
                    <p>
                        Pagar USD 75 en concepto del Plan Premium que incluye 5 licencias para
                        agentes de atención y 10.000 conversaciones.
                    </p>
                    <Button id="checkout-button-price_1HIJ2MCdguhNQPSQs46h2gR9" role="link">
                        Checkout
                    </Button>
                </div>
            </div>
        );
    }
}
PremiumPlanEs.contextType = AppContext;
export default PremiumPlanEs;
