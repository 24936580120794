import React from "react";
import { AppContext } from "../../components/AppContext";
import { Link } from "react-router-dom";
import Button from "../../components/FormComponents/Button";
import api from "../../requests/api";
// resources
import logo from "../../img/logo.svg";
import "./Login.scss";
import { withTranslation } from "react-i18next";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
        this.state = {
            user: "",
            email: "",
            submitDisabled: true,
        };
        this.t = props.t;
    }

    /**
     * Check the target name and set the state value
     * @param {Object} evt
     */
    handleChange(evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    /**
     * Register user
     */
    submit() {
        this.context.actions.setUserData(this.state.user, "", this.state.email);
        let params = {
            name: this.state.user,
            email: this.state.email,
        };
        api.login()
            .session_create(params)
            .then((data) => {
                this.props.history.push("dashboard/monitoring");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        return (
            <div className="section-login">
                <div className="section-login__logo">
                    <h1>
                        <img src={logo} alt="Optiwe logo" />
                        <span className="d-none">Optiwe</span>
                    </h1>
                    <p>{this.t("login.title")}</p>
                </div>
                <div className="section-login__form">
                    <Button>
                        <Link to="signup">{this.t("login.createNewAccount")}</Link>
                    </Button>
                    <Button light="true">
                        <Link to="signin">{this.t("login.alreadyHaveAnAccount")}</Link>
                    </Button>
                </div>
            </div>
        );
    }
}
Login.contextType = AppContext;
export default withTranslation()(Login);

// const LoginFunctional = () => {
//     const handleChange = () => {

//     }
//     const submit = () => {

//     }
//     return (
//         <div className="section-login">
//             <div className="section-login__logo">
//                 <h1>
//                     <img
//                         src={logo}
//                         alt="Optiwe logo" />
//                     <span className="d-none">Optiwe</span>
//                 </h1>
//                 <p>On-demand knowledge and learning in your Workspace.</p>
//             </div>
//             <div className="section-login__form">
//                 <Button><Link to="signup">Create a new account</Link></Button>
//                 <Button light="true"><Link to="signin">Already have an account</Link></Button>
//             </div>
//         </div>
//     )
// };

// export default LoginFunctional;
