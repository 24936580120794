import { Form, Input, message, Modal } from 'antd';
import { AxiosError } from "axios";
import { AppContext } from "components/AppContext";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "requests/api";


interface InviteUserProps {
    isVisible: boolean,
    setIsVisible(visible: boolean): void,

}



export function InviteUser({ isVisible, setIsVisible }: InviteUserProps) {
    const { t } = useTranslation();
    const [form] = Form.useForm()
    const [formDisabled, setFormDisable] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const appContext = useContext(AppContext);

    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: t('required'),
        types: {
            email: t('invalidEmail'),
        },
    };

    async function onOk() {
        const { email } = form.getFieldsValue()

        setIsLoading(true)
        try {
            // @ts-ignore 
            await api.workspaces().invite(email, appContext.state.workspaceId)
            message.success(t('users.invite.ok'))

            form.resetFields()
            setIsVisible(false)
            setFormDisable(true)
        } catch (err: any | AxiosError) {
            message.error(t('users.invite.error'))
        } finally {
            setIsLoading(false)
        }

    }

    function handleSubmit(values: any) {
        const a = values
        console.log(a)

    }

    async function onChangeForm() {
        try {
            await form.validateFields()
            setFormDisable(false)
        } catch (err) {
            setFormDisable(true)
        }

    }


    return (
        <Modal
            visible={isVisible}
            onCancel={() => { setIsVisible(false) }}
            onOk={onOk}
            okText={t('users.invite.okText')}
            okButtonProps={{
                disabled: formDisabled,
                loading: isLoading
            }}
            title={t('users.invite.modalTitle')}
        >
            <p>{t('users.invite.description')}</p>
            <Form
                validateMessages={validateMessages}
                form={form}
                onFinish={handleSubmit}
                onChange={onChangeForm}
            >
                <Form.Item
                    name={['email']}
                    rules={[{ type: 'email' }]}
                >
                    <Input
                        placeholder="Email"
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}