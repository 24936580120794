import { Divider } from "antd";
import React, { PropsWithChildren, useState } from "react";



interface OptiweCardProps {
    title?: JSX.Element | string
}

export function OptiweCard({ children, title }: PropsWithChildren<OptiweCardProps>) {

    return (
        <div
            style={{
                borderRadius: '15px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 0px 5px #A9B4C0',
                minHeight: '525px',
                minWidth: '300px',
                width: '400px',
                paddingBottom: '20px'
            }}
        >
            <div
                style={{ display: 'flex', justifyContent: 'center', }}
            >
                <img src="optiwe_logo_with_text.svg" alt="Optiwe logo" />
            </div>
            <div
                style={{ margin: '5% 5% 0% 5%' }}

            >
                {title ? <>
                    <h1 > {title}</h1>
                    <Divider style={{ marginTop: '-20px' }} />
                </> : <></>}
                {children}
            </div>
        </div>
    )
}