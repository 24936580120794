import React from "react";

type Props = {
    style?: React.CSSProperties;
    url?: string;
};
export default function WhatsappDownloadIcon({ style, url }: Props) {
    return (
        <a href={url} style={style}>
            <svg
                viewBox="0 0 34 34"
                height="34"
                width="34"
                preserveAspectRatio="xMidYMid meet"
                version="1.1"
                x="0px"
                y="0px"
                enable-background="new 0 0 34 34"
                style={{ color: "#A4AEB3" }}
            >
                <title>media-download</title>
                <path
                    fill="currentColor"
                    d="M17,2c8.3,0,15,6.7,15,15s-6.7,15-15,15S2,25.3,2,17S8.7,2,17,2 M17,1C8.2,1,1,8.2,1,17 s7.2,16,16,16s16-7.2,16-16S25.8,1,17,1L17,1z"
                ></path>
                <path
                    fill="currentColor"
                    d="M22.4,17.5h-3.2v-6.8c0-0.4-0.3-0.7-0.7-0.7h-3.2c-0.4,0-0.7,0.3-0.7,0.7v6.8h-3.2 c-0.6,0-0.8,0.4-0.4,0.8l5,5.3c0.5,0.7,1,0.5,1.5,0l5-5.3C23.2,17.8,23,17.5,22.4,17.5z"
                ></path>
            </svg>
        </a>
    );
}
