import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, NavLink } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { Prompt, PromptType } from "frontend-lib/DomainTypes/Prompts";
import usePrompts, { errorKeys } from "./usePrompts";
import TitleTooltip from "components/TitleTooltip/TitleTooltip";
import { notification, Select, Input } from "antd";

function PromptsForm() {
    const location = useLocation<Prompt | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [, createPrompt, createSystemPrompt, updatePrompt, , errorKey] = usePrompts(setIsLoading);
    const [prompt, setPrompt] = useState<Prompt>(getPromptInitialState);
    const [promptType, setPromptType] = useState<PromptType>(PromptType.CUSTOM);
    const [isEditView, setIsEditView] = useState<boolean>(false);
    
    const { TextArea } = Input;
    const { t } = useTranslation();

    const promptTypeOptions = Object.values(PromptType).map(value => ({
        value,
        label: getPromptTypeLabel(value as PromptType)
    }));

    function getPromptInitialState() {
        let initialPrompt: Prompt = {
            id: undefined,
            workspaceId: undefined,
            key: "",
            content: "",
            createdOn: undefined,
            updatedOn: undefined,
            isSystemPrompt: undefined,
        };
    
        if (location.state) {
            initialPrompt = location.state;
        }
    
        return initialPrompt;
    }

    function parsePromptErrorMessage(errorKey: errorKeys) {
        const errorDuration = 4;
        let errorMessage = "";
        let errorDescription = "";

        if (errorKey === errorKeys.BAD_REQUEST_BODY) {
            errorMessage = t("prompts.promptsForm.errorBadRequestBody");
            errorDescription = t("prompts.promptsForm.errorBadRequestBodyDescription");
        } else if (errorKey === errorKeys.INVALID_PROMPT_KEY) {
            errorMessage = t("prompts.promptsForm.errorInvalidKey");
            errorDescription = t("prompts.promptsForm.errorInvalidKeyDescription");
        } else if (errorKey === errorKeys.PROMPT_ALREADY_EXISTS) {
            errorMessage = t("prompts.promptsForm.errorPromptAlreadyExist");
            errorDescription = t("prompts.promptsForm.errorPromptAlreadyExistDescription");
        } else if (errorKey === errorKeys.NOT_IMPLEMENTED_ERROR) {
            errorMessage = t("prompts.promptsForm.errorPromptTypeNotImplemented");
            errorDescription = t("prompts.promptsForm.errorPromptTypeNotImplementedDescription");
        } else if (errorKey === errorKeys.PROMPT_CREATION_ERROR) {
            errorMessage = t("prompts.promptsForm.errorPromptCreation");
            errorDescription = t("prompts.promptsForm.errorPromptCreationDescription");
        } else if (errorKey === errorKeys.PROMPT_UPDATE_ERROR) {
            errorMessage = t("prompts.promptsForm.errorPromptUpdate");
            errorDescription = t("prompts.promptsForm.errorPromptUpdateDescription");
        } else if (errorKey === errorKeys.PROMPT_DELETE_ERROR) {
            errorMessage = t("prompts.promptsForm.errorPromptDelete");
            errorDescription = t("prompts.promptsForm.errorPromptDeleteDescription");
        } else {
            errorMessage = t("prompts.promptsForm.errorUnknown");
            errorDescription = t("prompts.promptsForm.errorUnknownDescription");
        }

        notification.error({
            message: errorMessage,
            description: errorDescription,
            duration: errorDuration,
        });
    }

    function onChangePrompt (evt: any) {
        const value = evt.target.value;
        setPrompt({...prompt, [evt.target.name]: value,});
    }
    
    function onChangePromptType (evt: any) {
        setPromptType(evt)
        if ([PromptType.INTENTION_DETECTOR, PromptType.QUESTION_ANSWERING].includes(evt)) {
            setPrompt({...prompt, isSystemPrompt: true,})
        } else {
            setPrompt({...prompt, isSystemPrompt: false,})
        }
    }

    function parsePromptType () {
        if (promptType === PromptType.INTENTION_DETECTOR) {
            return getPromptTypeLabel(PromptType.INTENTION_DETECTOR)
        } else if (promptType === PromptType.QUESTION_ANSWERING) {
            return getPromptTypeLabel(PromptType.QUESTION_ANSWERING)
        } else {
            return getPromptTypeLabel(PromptType.CUSTOM)
        }
    }

    function getPromptTypeByPromptKey () {
        if (prompt.key === PromptType.INTENTION_DETECTOR) {
            return PromptType.INTENTION_DETECTOR
        } else if (promptType === PromptType.QUESTION_ANSWERING) {
            return PromptType.QUESTION_ANSWERING
        } else {
            return PromptType.CUSTOM
        }
    }
    
    function getPromptTypeLabel(promptType: PromptType): string {
        switch (promptType) {
            case PromptType.INTENTION_DETECTOR:
                return t("prompts.promptsForm.intentionDetector");
            case PromptType.QUESTION_ANSWERING:
                return t("prompts.promptsForm.questionAnswering");
            case PromptType.CUSTOM:
                return t("prompts.promptsForm.custom");
            default:
                return "";
        }
    }

    function buildPromptKey(input: string): string {
        const cleanString = input.toLowerCase().replace(/[^\w\s]/g, "");
        const underscoreCleanString = cleanString.replace(/\s+/g, "_");

        return underscoreCleanString;
    }

    async function sendForm(event: any) {
        event.preventDefault();
        event.stopPropagation();

        if (prompt.isSystemPrompt && promptType) {
            createSystemPrompt(promptType)
        } else {
            if (!prompt.key) {
                parsePromptErrorMessage(errorKeys.INVALID_PROMPT_KEY);
            } else if (!prompt.content) {
                parsePromptErrorMessage(errorKeys.BAD_REQUEST_BODY);
            } else if (!promptType) {
                parsePromptErrorMessage(errorKeys.BAD_REQUEST_BODY);
            } else {
                if (location.state) {
                    updatePrompt(prompt);
                } else {
                    createPrompt(prompt);
                }
            }
        }
    }

    useEffect(() => {
        if (errorKey && !isLoading) {
            parsePromptErrorMessage(errorKey);
        } 
        if (location.state) {
            setIsEditView(true)
            setPromptType(getPromptTypeByPromptKey())
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorKey, isLoading]);

    return (
        <div className="opt-view opt-view--form">
            <Loader isLoading={isLoading} />

            {/* HEADER */}
            <div className="opt-view-header">
                <h1 className="opt-title">
                    <NavLink to="/dashboard/prompts">← {t("prompts.title")}</NavLink>
                </h1>
                <div className="opt-view-header__actions">
                    {location.state ? (
                        <input
                            type="button"
                            onClick={sendForm}
                            className="opt-button"
                            value={t("prompts.editPrompt")}
                        />
                    ) : (
                        <input
                            type="button"
                            onClick={sendForm}
                            className="opt-button"
                            value={t("prompts.createPrompt")}
                        />
                    )}
                </div>
            </div>

            {/* PROMPT KEY */}
            <div>
                <div>
                    <span style={{ marginRight: "5px"}}>
                        {t("prompts.key")}
                    </span>
                    <TitleTooltip content={t("prompts.promptsForm.keyTooltip")} />
                </div>
                <div>
                    <Input
                        name="key"
                        style={{ width: "30%" }}
                        value={buildPromptKey(prompt.key)}
                        onChange={onChangePrompt}
                        disabled={isEditView || prompt.isSystemPrompt}
                    />
                </div>
            </div>

            {/* PROMPT TYPE SELECTOR */}
            <div style={{marginTop:"15px"}}>
                <p style={{ fontSize: "16px" }}>{t("prompts.promptsForm.promptType")}</p>
                <Select
                    style={{ width: "30%" }}
                    placeholder={t("prompts.promptsForm.promptTypePlaceholder")}
                    onChange={onChangePromptType}
                    value={parsePromptType()}
                    options={promptTypeOptions}
                    disabled={isEditView}
                />
            </div>

            {/* CONTENT */}
            <div style={{marginTop:"15px"}}>
                <p style={{ fontSize: "16px" }}>{t("prompts.promptsForm.promptContent")}</p>
                <TextArea
                    name="content"
                    rows={16}
                    className="opt-text-input"
                    value={prompt.content}
                    onChange={onChangePrompt}
                    disabled={!isEditView && prompt.isSystemPrompt}
                />
            </div>

        </div>
    )
}

export default PromptsForm;
