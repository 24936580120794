const ACTIONS = {
    CHANGE_STATUS: "change-status",
    CHANGE_DATE: "change-date",
    SET_PAGINATOR: "set-paginator",
    CHANGE_PAGE: "change-page",
    SET_ALL_FILTERS: "set-all-filters",
    CHANGE_CHANNEL: "change-channel",
    CHANGE_CUSTOMER: "change-customer",
    CHANGE_CUSTOMER_STATUS: "change-customer-status",
    CHANGE_AGENT: "change-agent",
    CHANGE_AGENT_TYPE: "change-agent-type",
    CHANGE_OWNER_TYPE: "change-owner-type",
    CHANGE_CUSTOMER_DATE: "change-customer-date",
    CHANGE_TAGS: "change-tags",
    CHANGE_SEARCH_TEXT: "change-search-text",
    CHANGE_TAGS_GROUPS: "change-tags-groups",
    CHANGE_OPTIN: "change-optin",
    CHANGE_CUSTOMERS_ID: "change-customers-id",
    CHANGE_CUSTOMER_GROUPS_IDS: "change-customer-groups-ids",
    CHANGE_CONVERSATION_TAGS_IDS: "change-conversation-tags-ids",
    CHANGE_CONVERSATION_GROUPS_IDS: "change-conversation-groups-ids",
    CHANGE_AGENTS_IDS: "change-agents-ids",
    CHANGE_OR_CUSTOMERS_AND_GROUPS: "change-or-customers-and-groups",
    CHANGE_LAST_ACTIVITY_DATE: "change-last-activity-date",
    CLEAR_FILTERS: "clear-filters",
};
export default ACTIONS;
