import { Prompt, PromptType } from "frontend-lib/DomainTypes/Prompts";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../requests/api";

export enum errorKeys {
    BAD_REQUEST_BODY = "BAD_REQUEST_BODY",
    INVALID_PROMPT_KEY = "INVALID_PROMPT_KEY",
    PROMPT_ALREADY_EXISTS = "PROMPT_ALREADY_EXISTS",
    NOT_IMPLEMENTED_ERROR = "NOT_IMPLEMENTED_ERROR",
    PROMPT_CREATION_ERROR = "PROMPT_CREATION_ERROR",
    PROMPT_UPDATE_ERROR = "PROMPT_UPDATE_ERROR",
    PROMPT_DELETE_ERROR = "PROMPT_DELETE_ERROR"
}

export default function usePrompts(setIsLoading: (isLoading: boolean) => void,): [
    Prompt[],
    (prompt: Prompt) => void,
    (systemPrompt: PromptType) => void,
    (prompt: Prompt) => void,
    (promptId: number) => void,
    errorKeys | undefined,
] {
    const history = useHistory();
    const [prompts, setPrompts] = useState<Prompt[]>([]);
    const [errorKey, setErrorKey] = useState<errorKeys | undefined>();

    const loadPrompts = useCallback(async () => {
        setIsLoading(true);
        const res = await api.chatbots().promptsList();
        setPrompts((prompts) => [...prompts, ...res.data.prompts]);
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function createPrompt(prompt: Prompt) {
        setIsLoading(true);
        api.chatbots()
            .promptCreate(
                prompt.key,
                prompt.content
            )
            .then((response) => {
                setIsLoading(false);
                history.push("/dashboard/prompts");
                setErrorKey(undefined);
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response.data.error.key === errorKeys.INVALID_PROMPT_KEY) {
                    setErrorKey(errorKeys.INVALID_PROMPT_KEY);
                } else if (error.response.data.error.key === errorKeys.PROMPT_ALREADY_EXISTS) {
                    setErrorKey(errorKeys.PROMPT_ALREADY_EXISTS);
                } else if (error.response.data.error.key === errorKeys.BAD_REQUEST_BODY) {
                    setErrorKey(errorKeys.BAD_REQUEST_BODY)
                } else {
                    setErrorKey(errorKeys.PROMPT_CREATION_ERROR);
                }

            });
    }

    function createSystemPrompt(systemPrompt: PromptType) {
        setIsLoading(true);
        api.chatbots()
            .systemPromptCreate(
                systemPrompt
            )
            .then((response) => {
                setIsLoading(false);
                history.push("/dashboard/prompts");
                setErrorKey(undefined);
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response.data.error.key === errorKeys.NOT_IMPLEMENTED_ERROR) {
                    setErrorKey(errorKeys.NOT_IMPLEMENTED_ERROR);
                } else if (error.response.data.error.key === errorKeys.PROMPT_ALREADY_EXISTS) {
                    setErrorKey(errorKeys.PROMPT_ALREADY_EXISTS);
                } else {
                    setErrorKey(errorKeys.PROMPT_CREATION_ERROR);
                }

            });
    }

    function updatePrompt(prompt: Prompt) {
        setIsLoading(false);
        api.chatbots()
            .promptUpdate(
                prompt.id,
                prompt.content
            )
            .then((response) => {
                setIsLoading(false);
                history.push("/dashboard/prompts");
                setErrorKey(undefined);
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.response.data.error.key === errorKeys.PROMPT_ALREADY_EXISTS) {
                    setErrorKey(errorKeys.PROMPT_ALREADY_EXISTS);
                } else if (error.response.data.error.key === errorKeys.BAD_REQUEST_BODY) {
                    setErrorKey(errorKeys.BAD_REQUEST_BODY)
                } else {
                    setErrorKey(errorKeys.PROMPT_UPDATE_ERROR);
                }
            });
    }

    function deletePrompt(promptId: number) {
        setIsLoading(true);
        api.chatbots()
            .promptDelete(promptId)
            .then((reponse) => {
                setIsLoading(false);
                setErrorKey(undefined);
            })
            .catch((error) => {
                setIsLoading(false);
                setErrorKey(errorKeys.PROMPT_DELETE_ERROR);
            });
        setPrompts((prompts) => prompts.filter((i) => i.id !== promptId));
    }

    useEffect(() => {
        loadPrompts();
    }, [loadPrompts]);

    return [
        prompts,
        createPrompt,
        createSystemPrompt,
        updatePrompt,
        deletePrompt,
        errorKey,
    ];
}
