import React from "react";
import { Col, Row } from "antd";
import logo from "../../img/dashboardlogo.png";
import "./ChannelsConfigView.scss";
import ChannelsConfig from "../../components/ChannelsConfig/ChannelsConfig";
import ConfigWrapper from "../../components/ConfigWrapper";
import { useTranslation } from "react-i18next";

function ChannelsConfigView(props) {
    const { t } = useTranslation();

    return (
        <ConfigWrapper>
            <Row>
                <Col span={24} style={{ backgroundColor: "#35209E" }}>
                    <div className="header">
                        <img src={logo} alt="dashboar logo" className="header-logo" />
                        <h1 className="header-title">{t("channelConfiguration.title")}</h1>
                    </div>
                </Col>
            </Row>
            <Row style={{ paddingBottom: "50px" }}>
                <Col span={24}>
                    <ChannelsConfig />
                </Col>
            </Row>
        </ConfigWrapper>
    );
}

export default ChannelsConfigView;
