import React, { useMemo, useState, useContext } from "react";
import { AppContext } from "../../../components/AppContext";
import { useTranslation } from "react-i18next";
import { Input, Button, message, Switch } from "antd";
import { EditWorkspaceConfigsProps } from "./Types";
import { default as deepmerge } from "deepmerge";

interface PushNotificationSettingsConfig {
    backend: {
        pushNotifications?: {
            conversationUpdated?: boolean;
            conversationCreated?: boolean;
        };
    };
}

export default function PushNotificationSettings({
    configs,
    setConfig,
}: EditWorkspaceConfigsProps<PushNotificationSettingsConfig>) {
    const { t } = useTranslation();
    const onChangeSwitch = (checked: boolean, fromSwitch: "CONV_CREATED" | "CONV_UPDATES") => {
        const newConfig = { ...configs };

        if (newConfig.backend.pushNotifications === undefined) {
            newConfig.backend.pushNotifications = {
                conversationCreated: true,
                conversationUpdated: true,
            };
        }

        if (fromSwitch === "CONV_CREATED") {
            newConfig.backend.pushNotifications.conversationCreated = checked;
        } else if (fromSwitch === "CONV_UPDATES") {
            newConfig.backend.pushNotifications.conversationUpdated = checked;
        }
        setConfig(newConfig);
    };

    return (
        <>
            <div>
                <div style={{ marginBottom: "20px" }}>
                    <span style={{ fontWeight: "bold" }}>
                        {t("settings.notifications.pushNotifications")}
                    </span>
                </div>
                <span style={{ fontSize: 14, color: "#727272" }}>
                    {t("settings.notifications.pushNotificationDescription")}
                </span>
            </div>

            <div style={{ display: "flex", marginTop: "20px", marginBottom: "20px" }}>
                <span>{t("settings.notifications.conversationCreated")}</span>
                <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                    <Switch
                        checked={
                            configs.backend.pushNotifications?.conversationCreated !== undefined
                                ? configs.backend.pushNotifications.conversationCreated
                                : true
                        }
                        onChange={(checked, e) => onChangeSwitch(checked, "CONV_CREATED")}
                    />
                </div>
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
                <span>{t("settings.notifications.conversationUpdated")}</span>
                <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                    <Switch
                        checked={
                            configs.backend.pushNotifications?.conversationUpdated !== undefined
                                ? configs.backend.pushNotifications.conversationUpdated
                                : true
                        }
                        onChange={(checked, e) => onChangeSwitch(checked, "CONV_UPDATES")}
                    />
                </div>
            </div>
        </>
    );
}
