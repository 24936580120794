import React from "react";
import { i18next } from "../../../../../../lang/i18n";
class TagUsesItem {
    constructor(name = null, date = null) {
        this.name = name;
        this.date = date;
        this.amount = null;
        this.formatedHour = null;
    }
    setValues(date, amount) {
        this.date = date;
        this.amount = amount;
        this.formatedDate = this.getFormatted(this.date);
    }
    getFormatted(date) {
        return date;
    }
}
const tagUsesTexts = [
    {
        id: 1,
        name: i18next.t("tagUses.total"),
        label: i18next.t("tagUses.total"),
        description: i18next.t("tagUses.totalDesc"),
        color: "#5B8FF9",
    },
    {
        id: 2,
        name: i18next.t("tagUses.positiveFeedback"),
        label: i18next.t("tagUses.positiveFeedback"),
        description: i18next.t("tagUses.positiveFeedbackDesc"),
        color: "#83E1BD",
    },
    {
        id: 3,
        name: i18next.t("tagUses.negativeFeedback"),
        label: i18next.t("tagUses.negativeFeedback"),
        description: i18next.t("tagUses.negativeFeedbackDesc"),
        color: "#5D7092",
    },
];
const tagUsesConfigs = {
    height: 300,
    xField: "date",
    yField: "amount",
    isGroup: true,
    seriesField: "name",
    legend: {
        position: "top-left",
    },
    tooltip: {
        formatter: (data) => {
            let visibleData = {};
            visibleData.name = tagUsesTexts.find((item) => item.name === data.name).label;
            visibleData.value = data.amount;
            return visibleData;
        },
        showMarkers: false,
    },
    color: (data) => {
        return tagUsesTexts.find((item) => item.name === data.name).color;
    },
};

const tagUsesModal = (
    <ul>
        {tagUsesTexts.map((item) => (
            <li>
                <h3>
                    <span
                        style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            marginRight: "5px",
                            background: item.color,
                        }}
                    ></span>
                    {item.name}
                </h3>
                <p>{item.description}</p>
            </li>
        ))}
    </ul>
);

export { TagUsesItem, tagUsesTexts, tagUsesConfigs, tagUsesModal };
