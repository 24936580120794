import type { ChannelWarningMessages } from "../types";
import { Channels } from "../types";

enum FileType {
    AUDIO = "audio",
    VIDEO = "video",
    IMAGE = "image",
    DOCUMENT = "document",
}

const channelsMaxSizesInMb = {
    facebook: {
        audio: 25,
        image: 25,
        video: 25,
        document: 25,
    },
    instagram: {
        audio: 25,
        image: 8,
        video: 25,
        document: 25,
    },
    whatsapp: {
        audio: 16,
        image: 16,
        video: 16,
        document: 100,
    },
    sms: {
        audio: 25,
        image: 25,
        video: 25,
        document: 25,
    },
};

const getFileChannelSizesWarnings = (byteSize: number, type: FileType): ChannelWarningMessages => {
    const mbSize = byteSize / 1000 / 1000;
    const warnings = {} as ChannelWarningMessages;
    Object.values(Channels).forEach((channel) => {
        warnings[channel] = {
            show: mbSize >= channelsMaxSizesInMb[channel][type],
            maxSize: channelsMaxSizesInMb[channel][type],
        };
    });
    return warnings;
};

export default getFileChannelSizesWarnings;
export { channelsMaxSizesInMb, FileType };
export type { ChannelWarningMessages };
