import React, { useState } from "react";
import { Column } from "@ant-design/charts";
import { Modal, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
function OptChart(props) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        <>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <p className="opt-title" style={{ textAlign: "center" }}>
                    <span style={{ marginRight: "6px" }}>{props.title}</span>
                </p>
                <div style={{ marginLeft: "auto", display: "flex" }}>
                    {props.extraData && (
                        <div style={{ marginRight: "15px" }}>{props.extraData}</div>
                    )}
                    {props.showModal && (
                        <>
                            <Button
                                onClick={() => setIsModalVisible(true)}
                                icon={<InfoCircleOutlined />}
                            />
                        </>
                    )}
                </div>
            </div>
            {props.type === "column" && <Column {...props.config} data={props.data} />}
            {props.modalContent && (
                <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    {props.modalContent}
                </Modal>
            )}
        </>
    );
}
export default OptChart;
