import React, { useState, useEffect } from "react";
import { Popover } from "antd";
import useWindowSize from "../../hooks/useWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
function TitleTooltip(props) {
    const size = useWindowSize();
    const [tooltipPacement, setTooltipPlacement] = useState("bottom");
    useEffect(() => {
        if (size.width > 1280) {
            setTooltipPlacement("rightTop");
        } else {
            setTooltipPlacement("bottom");
        }
    }, [size.width]);
    return (
        <span className="opt-title-tooltip" style={{ cursor: "pointer" }}>
            <Popover
                trigger="hover"
                placement={tooltipPacement}
                content={<span className="opt-title-tooltip-content">{props.content}</span>}
            >
                <FontAwesomeIcon style={{ fontSize: "10px" }} icon={faQuestionCircle} />
            </Popover>
        </span>
    );
}
export default TitleTooltip;
