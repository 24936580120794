import React from "react";
import { Select, Typography } from "antd";
import { Option } from "antd/es/mentions";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

const socketConfigIsEmpty = function (config) {
    return (
        config.socketConfiguration &&
        Array.isArray(config.socketConfiguration.transports) &&
        config.socketConfiguration.transports.length === 0
    );
};

function AgentSocketManager({ config, setConfigCallback }) {
    const { t } = useTranslation();

    const changeTransports = function (transportsSelected) {
        let configs = JSON.parse(JSON.stringify(config));
        configs.socketConfiguration.transports = transportsSelected;
        setConfigCallback(configs);
    };

    return (
        <>
            <div
                className="opt-settings-tab opt-settings-tab--general"
                style={{ marginLeft: "15px" }}
            >
                <span className="opt-label-title">
                    {t("settings.agentSettings.socketConfiguration")}
                </span>
                <div className="opt-card opt-card--settings">
                    <Select
                        mode="multiple"
                        defaultValue={
                            config.socketConfiguration?.transports || ["websocket", "polling"]
                        }
                        value={config.socketConfiguration?.transports}
                        placeholder={t("settings.agentSettings.socketConfiguration")}
                        style={{ width: "100%" }}
                        onChange={changeTransports}
                    >
                        <Option value="polling">Polling</Option>
                        <Option value="websocket">Websocket</Option>
                    </Select>
                    {socketConfigIsEmpty(config) && (
                        <Text type="danger">
                            {t("settings.agentSettings.emptySocketConfigError")}
                        </Text>
                    )}
                </div>
            </div>
        </>
    );
}
export default AgentSocketManager;
export { socketConfigIsEmpty };
