import React, { useState, useEffect } from "react";
import Templates from "./Templates";
import Campaigns from "./Campaigns";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Tabs, Spin, Statistic, Card, Row, Col } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import api from "../../../requests/api";
import { useTranslation } from "react-i18next";
import TitleTooltip from "../../../components/TitleTooltip/TitleTooltip";
import useTemplates from "../../../frontend-lib/Hooks/useTemplates";

const { TabPane } = Tabs;
function Outbound(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();
    const [page] = useState(0);
    const [campaignPaginator, setCampaignPaginator] = useState([]);
    const [templates, templatesLoading, templatesError, reloadTemplates, newTemplateCb] =
        useTemplates({ api });

    const [campaignList, setCampaignList] = useState([]);
    const [configs, setConfigs] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [activeKey, setActiveKey] = useState("templates");

    const onTabClick = (tab) => {
        setActiveKey(tab);
        history.push(`/dashboard/outbound/${tab}`);
    };
    const loadCampaignList = function () {
        setIsLoading(true);
        api.outbound()
            .getWhatsappCampaigns(page, "MANY_CUSTOMERS")
            .then((res) => {
                setCampaignPaginator(res.data.paginator);
                setCampaignList(res.data.campaigns);
                setIsLoading(false);
            });
    };
    const loadConfigData = function () {
        api.outbound()
            .getConfigs()
            .then((res) => {
                setConfigs(res.data);
            });
    };
    const loadSectionData = (section) => {
        loadConfigData();
        reloadTemplates();
        loadCampaignList();
    };

    const onCampaignChangePage = function (page) {
        setIsLoading(true);
        api.outbound()
            .getWhatsappCampaigns(page, "MANY_CUSTOMERS")
            .then((res) => {
                setCampaignPaginator(res.data.paginator);
                setCampaignList(res.data.campaigns);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadSectionData(params.section);
    }, [params.section]);

    useEffect(() => {
        if (params.section) {
            loadSectionData(params.section);
            setActiveKey(params.section);
        } else {
            reloadTemplates();
        }
    }, []);

    const XS_SPAN = 12;
    const MD_SPAN = 4;

    const renderViewTitle = () => {
        if (history.location.pathname.includes("outbound/templates/new")) {
            return (
                <NavLink to="/dashboard/outbound/templates">{`← ${t("outbound.title")}`}</NavLink>
            );
        } else {
            return <>{t("outbound.title")}</>;
        }
    };

    return (
        <Spin spinning={isLoading}>
            <div className="opt-view">
                <div className="opt-view-header">
                    <h1 className="opt-title">{renderViewTitle()}</h1>
                </div>
                <Row gutter={[16, 16]}>
                    <Col xs={XS_SPAN} md={MD_SPAN}>
                        <Card>
                            <Statistic
                                title={
                                    <>
                                        {t("outbound.whatsappTier")}
                                        <TitleTooltip content={t("outbound.whatsappTierText")} />
                                    </>
                                }
                                value={configs.tierName}
                                valueStyle={{ color: "#3f8600" }}
                                prefix={<WhatsAppOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col xs={XS_SPAN} md={MD_SPAN}>
                        <Card>
                            <Statistic
                                title={
                                    <>
                                        {t("outbound.messageLimit")}
                                        <TitleTooltip content={t("outbound.messageLimitText")} />
                                    </>
                                }
                                value={configs.limit}
                                valueStyle={{ color: "#cf1322" }}
                            />
                        </Card>
                    </Col>
                    <Col xs={XS_SPAN} md={MD_SPAN}>
                        <Card>
                            <Statistic
                                title={
                                    <>
                                        {t("outbound.messagesSent")}
                                        <TitleTooltip content={t("outbound.messagesSentText")} />
                                    </>
                                }
                                value={configs.usage}
                            />
                        </Card>
                    </Col>
                    <Col xs={XS_SPAN} md={MD_SPAN}>
                        <Card>
                            <Statistic
                                title={
                                    <>
                                        {t("outbound.amountBic")}
                                        <TitleTooltip content={t("outbound.amountBicText")} />
                                    </>
                                }
                                value={configs.amountBicSinceLastPayment}
                            />
                        </Card>
                    </Col>
                </Row>
                <Tabs defaultActiveKey="1" activeKey={activeKey} onTabClick={onTabClick}>
                    <TabPane tab={t("outbound.templates")} key="templates"></TabPane>
                    <TabPane tab={t("outbound.campaign")} key="campaigns"></TabPane>
                </Tabs>
                {activeKey === "templates" && (
                    <Templates
                        templateList={templates}
                        reloadCb={reloadTemplates}
                        templatesLoading={templatesLoading}
                        newTemplateCb={newTemplateCb}
                    />
                )}
                {activeKey === "campaigns" && (
                    <Campaigns
                        configs={configs}
                        templateList={templates}
                        campaignList={campaignList}
                        campaignPaginator={campaignPaginator}
                        reLoadCb={loadCampaignList}
                        loadMoreCb={onCampaignChangePage}
                    />
                )}
            </div>
        </Spin>
    );
}
export default Outbound;
