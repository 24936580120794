import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Form, Spin, Input, Select, Divider, message, Tooltip } from "antd";
import { AppContext } from "../../../components/AppContext";
import api from "../../../requests/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import LoadMore from "../../../components/LoadMore/LoadMore";

const { Option } = Select;
const convertObjectToArray = function (obj) {
    return Object.entries(obj).map((e) => ({ [e[0]]: e[1] }));
};
const convertArrayToObject = function (arr) {
    let obj = {};
    for (let item of arr) {
        let key = Object.keys(item)[0];
        obj[key] = item[key];
    }
    return obj;
};
const sortByorder = function (a, b) {
    if (a.order == null || b.order == null) {
        return 0;
    }
    if (a.order > b.order) {
        return 1;
    }
    if (a.order < b.order) {
        return -1;
    }
    return 0;
};
function EditCustomerModal({ onCloseCb, customer, editCustomerCb, visible }) {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const nativeKeys = ["fullName", "email", "phone", "notes"];
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [allAgents, setAllAgents] = useState([]);
    const [agentsPaginator, setAgentsPaginator] = useState({
        page: 0,
        hasNext: false,
    });
    const [customerFields, setCustomerFields] = useState([]);

    const workspaceConfig = appContext.state.workspaceConfigs;
    const disableAffinity =
        workspaceConfig.disableAffinity !== undefined ? workspaceConfig.disableAffinity : true;
    const currentAgent = appContext.state.userData;
    const [isLoading, setIsLoading] = useState(false);

    const handleFormFinish = function (data) {
        console.log(data);
    };
    const closeModal = function () {
        form.resetFields();
        resetAgents();
        onCloseCb();
    };
    const handleFormSubmit = function (data) {
        form.validateFields()
            .then((val) => {
                const editedNativeFieldsList = [];
                const editedExtraDataList = [];
                for (let item of convertObjectToArray(val)) {
                    const key = Object.keys(item)[0];
                    if (customerFields.length > 0) {
                        const customerField = customerFields.find((item) => item.key === key);
                        if (nativeKeys.includes(key) && customerField && customerField.isNative) {
                            editedNativeFieldsList.push(item);
                        } else {
                            editedExtraDataList.push(item);
                        }
                    } else {
                        editedNativeFieldsList.push(item);
                    }
                }
                const editedNativeFields = convertArrayToObject(editedNativeFieldsList);
                const editedExtraData = convertArrayToObject(editedExtraDataList);
                const editedCustomer = {
                    ...customer,
                    ...editedNativeFields,
                    extraData: { ...customer.extraData, ...editedExtraData },
                    ...(selectedAgent
                        ? {
                              affinityWithUserId: selectedAgent.id,
                              affinityAgent: selectedAgent,
                          }
                        : {
                              clearAffinity: true,
                              affinityAgent: null,
                              affinityWithUserId: null,
                          }),
                };
                editCustomerCb(editedCustomer);
                resetAgents();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    async function resetAgents() {
        setAllAgents([]);
        setAgentsPaginator({
            page: 0,
            hasNext: false,
        });
        setSelectedAgent(null);
    }

    async function loadAgents(paginator) {
        try {
            const res = await api.users().list(paginator.page, 32);
            const users = res.data.users;
            const new_paginator = res.data.paginator;

            setAgentsPaginator(new_paginator);
            setAllAgents([...allAgents, ...users]);
            if (customer.affinityAgent) {
                const agent = customer.affinityAgent;
                setSelectedAgent(agent);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function loadMoreAgents() {
        if (agentsPaginator.hasNext) {
            await loadAgents({ ...agentsPaginator, page: agentsPaginator.nextPage });
        }
    }

    function onSelectAffinityAgent(agentLabel) {
        if (selectedAgent == null || currentAgent.isAdmin) {
            const newAffinityAgent = allAgents.find((a) => a.id === agentLabel.value);
            if (!currentAgent.isAdmin && newAffinityAgent.id !== currentAgent.id) {
                message.error(t("clientForm.errorAffinity"));
                return;
            }
            setSelectedAgent(newAffinityAgent);
        } else {
            message.error(t("clientForm.errorAffinity"));
        }
    }

    function onClearAffinity() {
        if (currentAgent.isAdmin || customer.affinityAgent.id === currentAgent.id) {
            setSelectedAgent(null);
        } else {
            message.error(t("clientForm.errorAffinity"));
        }
    }

    async function loadCustomerFields() {
        const res = await api.customers().listCustomerField();
        setCustomerFields(res.data.customerFields);
    }

    useEffect(() => {
        form.resetFields();
        resetAgents();
        if (visible === true) {
            setIsLoading(true);
            loadAgents(agentsPaginator);
            loadCustomerFields();
            setIsLoading(false);
        }

        if (customer) {
            const customerForm = {};
            for (const key in customer) {
                if (nativeKeys.includes(key)) {
                    customerForm[key] = customer[key];
                }
            }
            for (const key in customer.extraData) {
                customerForm[key] = customer.extraData[key];
            }
            form.setFieldsValue(customerForm);
        }
    }, [customer, visible]);

    return (
        <Form.Provider onFormFinish={handleFormFinish}>
            <Modal
                visible={visible}
                title={t("info")}
                onCancel={closeModal}
                onOk={handleFormSubmit}
            >
                <Spin spinning={isLoading}>
                    <>
                        {customer ? (
                            <Form form={form} layout="vertical" onFinish={handleFormFinish}>
                                {customerFields.length > 0 &&
                                    customerFields.sort(sortByorder).map((item) => (
                                        <>
                                            <Form.Item
                                                key={item.key}
                                                label={
                                                    item.isNative
                                                        ? t(`clientForm.${item.key}`)
                                                        : item.label
                                                }
                                                name={item.key}
                                                rules={
                                                    item.key === "fullName" &&
                                                    customer[item.key] && [
                                                        { required: true, message: t("required") },
                                                    ]
                                                }
                                            >
                                                {item.key === "notes" ? (
                                                    <Input.TextArea
                                                        placeholder={t("clientForm.notes")}
                                                    />
                                                ) : (
                                                    <Input placeholder={`${item.label}`} />
                                                )}
                                            </Form.Item>
                                        </>
                                    ))}
                                {customerFields.length === 0 &&
                                    ["fullName", "email", "phone", "notes"].map((key) => (
                                        <>
                                            <Form.Item
                                                key={key}
                                                label={t(`clientForm.${key}`)}
                                                name={key}
                                                rules={
                                                    key === "fullName" &&
                                                    customer[key] && [
                                                        { required: true, message: "Required" },
                                                    ]
                                                }
                                            >
                                                {key === "notes" ? (
                                                    <Form.Item label={t("Notes")} name={"notes"}>
                                                        <Input.TextArea
                                                            placeholder={t("clientForm.notes")}
                                                        />
                                                    </Form.Item>
                                                ) : (
                                                    <Input placeholder={`Customer ${key}`} />
                                                )}
                                            </Form.Item>
                                        </>
                                    ))}
                                <Form.Item>
                                    {t("clientForm.affinityAgent")}
                                    <Tooltip
                                        title={
                                            t("clientForm.affinityTooltip") +
                                            (disableAffinity
                                                ? `\n${t("clientForm.affinityDisabled")}`
                                                : "")
                                        }
                                    >
                                        <FontAwesomeIcon
                                            style={{ fontSize: "10px", paddingLeft: "2px" }}
                                            icon={faQuestionCircle}
                                        />
                                    </Tooltip>
                                    <Select
                                        value={
                                            selectedAgent
                                                ? {
                                                      value: selectedAgent.id,
                                                      label: `${selectedAgent.firstName} ${selectedAgent.lastName}`,
                                                  }
                                                : { value: "", label: "" }
                                        }
                                        labelInValue
                                        onSelect={onSelectAffinityAgent}
                                        disabled={disableAffinity}
                                        allowClear
                                        onClear={onClearAffinity}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {agentsPaginator.hasNext && (
                                                    <>
                                                        <Divider />
                                                        <LoadMore onClick={loadMoreAgents} />
                                                    </>
                                                )}
                                            </>
                                        )}
                                    >
                                        {currentAgent.isAdmin
                                            ? allAgents.map((a) => (
                                                  <Option key={a.id} value={a.id}>
                                                      {a.firstName} {a.lastName}
                                                  </Option>
                                              ))
                                            : allAgents
                                                  .filter((a) => a.id === currentAgent.id)
                                                  .map((a) => (
                                                      <Option key={a.id} value={a.id}>
                                                          {a.firstName} {a.lastName}
                                                      </Option>
                                                  ))}
                                    </Select>
                                </Form.Item>
                            </Form>
                        ) : (
                            <></>
                        )}
                    </>
                </Spin>
            </Modal>
        </Form.Provider>
    );
}
export default EditCustomerModal;
