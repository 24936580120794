import React from "react";
import { AppContext } from "../../components/AppContext";
// resources
import logo from "../../img/logo.svg";
import "./Payments.scss";
import { withTranslation } from "react-i18next";

class PartnerLicense extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            licenses: 1,
        };
    }

    componentDidMount() {
        var stripe = window.Stripe("pk_live_kwIdpEM2Jd5dZHiF4XINafHU00VDDmg2oX");

        var checkoutButton = document.getElementById("checkout-button-plan_H92b6hl1fyRTY8");
        checkoutButton.addEventListener("click", () => {
            // When the customer clicks on the button, redirect
            // them to Checkout.
            console.log(this.state.licenses);
            stripe
                .redirectToCheckout({
                    items: [{ plan: "plan_H92b6hl1fyRTY8", quantity: 1 }],

                    // Do not rely on the redirect to the successUrl for fulfilling
                    // purchases, customers may not always reach the success_url after
                    // a successful payment.
                    // Instead use one of the strategies described in
                    // https://stripe.com/docs/payments/checkout/fulfillment
                    successUrl: "http://app.optiwe.com/payments/success",
                    cancelUrl: "http://app.optiwe.com/payments/canceled",
                })
                .then(function (result) {
                    if (result.error) {
                        // If `redirectToCheckout` fails due to a browser or network
                        // error, display the localized error message to your customer.
                        var displayError = document.getElementById("error-message");
                        displayError.textContent = result.error.message;
                    }
                });
        });
    }

    handleChange(evt) {
        console.log(evt.target.name);
        console.log(evt.target.value);
        this.setState({ [evt.target.name]: evt.target.value });
    }

    render() {
        return (
            <div className="section-login">
                <div className="section-login__logo">
                    <h1>
                        <img src={logo} alt="Optiwe logo" />
                        <span className="d-none">Optiwe</span>
                    </h1>
                    <p>{this.props.t("payments.paymentSystem")}</p>
                </div>
                <div className="section-login__form">
                    <p>{this.props.t("payments.partnerTitle")}</p>
                    <p>{this.props.t("payments.partnerBenefits")}</p>
                    <p>{this.props.t("payments.partnerBenefit1")}</p>
                    <p>{this.props.t("payments.partnerBenefit2")}</p>
                    <p>{this.props.t("payments.partnerBenefit3")}</p>
                    <p>{this.props.t("payments.partnerPrice")}</p>
                    <p>{this.props.t("payments.partnerCharge")}</p>
                    <button
                        className="opt-button"
                        id="checkout-button-plan_H92b6hl1fyRTY8"
                        role="link"
                    >
                        {this.props.t("payments.checkout")}
                    </button>
                </div>
            </div>
        );
    }
}
PartnerLicense.contextType = AppContext;
export default withTranslation()(PartnerLicense);
