import { useCallback, useEffect, useState } from "react";
import api from "../../../requests/api";

export interface BotChannel {
    channel: string;
    workspaceId: number;
}

export default function useBotChannels(): BotChannel[] {
    const [botChannels, setBotChannels] = useState<BotChannel[]>([]);
    const loadBotChannels = useCallback(async () => {
        const res = await api.chatbots().listBotChannels();
        setBotChannels((botChannels) => [...botChannels, ...res.data.botChannels]);
    }, []);

    useEffect(() => {
        loadBotChannels();
    }, [loadBotChannels]);

    return botChannels;
}
