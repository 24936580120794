const initialConfigState = {
    language: "-",
    loginEnabled: false,
    isCacheEnabled: false,
    enableFullScreenLogIn: false,
    forceLogin: false,
    renderHosts: [],
    embeddedStyles: {
        openChatIcon: {
            backgroundColor: "#8DC5F5",
            padding: "15px",
            minWidth: "84px",
            minHeight: "84px",
        },
        openChatIconFont: {
            fontSize: "45px",
        },
        whatsappIcon: {
            fontSize: "45px",
        },
        whatsappButton: {
            backgroundColor: "#25d366",
            padding: "15px",
            minWidth: "76px",
            minHeight: "76px",
        },
        header: {
            backgroundColor: "#8DC5F5",
        },
        loginSendButton: {
            backgroundColor: "#FD6A56",
        },
        collapsibleButton: {
            backgroundColor: "#FD6A56",
            padding: "15px",
            minWidth: "76px",
            minHeight: "76px",
            marginTop: "20px",
        },
        collapsibleIcon: {
            fontSize: "45px",
        },
    },
    customStyles: {
        bubbles: {
            userBubble: {
                backgroundColor: "#6929BE",
                color: "#FFFFFF",
            },
            agentBubble: {
                backgroundColor: "#fd6a56",
                color: "#FFFFFF",
                border: "none",
            },
            agentBubbleLink: {
                color: "#ffffff",
            },
        },
        sendButton: {
            backgroundColor: "#35209E",
        },
    },
    chatClient: {
        showCustomIcon: false,
        customIconUrl: "",
        containCustomIcon: false,
        collapsibleStartChatIcons: false,
        headerTitle: "Chat with us",
        showWebChatStartIcon: true,
        showWhatsappStartIcon: true,
        whatsappPhone: "",
        whatsappGreetingMessage: "",
        whatsappWebOnDesktop: false,
    },
    backend: {
        automation: {
            webchat: {
                humanAgent: {
                    enable: false,
                    from: "09:00:00",
                    to: "18:00:00",
                    days: [0, 1, 2, 3, 4],
                    tz: "-0000",
                },
                aiAgent: {
                    enable: false,
                    from: "00:00:00",
                    to: "23:59:59",
                    days: [0, 1, 2, 3, 4, 5, 6],
                    tz: "-0000",
                },
            },
            whatsapp: {
                handoverOnReferral: false,
                humanAgent: {
                    enable: false,
                    from: "09:00:00",
                    to: "18:00:00",
                    days: [0, 1, 2, 3, 4],
                    tz: "-0000",
                },
                aiAgent: {
                    enable: false,
                    from: "00:00:00",
                    to: "23:59:59",
                    days: [0, 1, 2, 3, 4, 5, 6],
                    tz: "-0000",
                },
            },
            facebook: {
                humanAgent: {
                    enable: false,
                    from: "09:00:00",
                    to: "18:00:00",
                    days: [0, 1, 2, 3, 4],
                    tz: "-0000",
                },
                aiAgent: {
                    enable: false,
                    from: "00:00:00",
                    to: "23:59:59",
                    days: [0, 1, 2, 3, 4, 5, 6],
                    tz: "-0000",
                },
            },
            instagram: {
                humanAgent: {
                    enable: false,
                    from: "09:00:00",
                    to: "18:00:00",
                    days: [0, 1, 2, 3, 4],
                    tz: "-0000",
                },
                aiAgent: {
                    enable: false,
                    from: "00:00:00",
                    to: "23:59:59",
                    days: [0, 1, 2, 3, 4, 5, 6],
                    tz: "-0000",
                },
            },
            sms: {
                humanAgent: {
                    enable: false,
                    from: "09:00:00",
                    to: "18:00:00",
                    days: [0, 1, 2, 3, 4],
                    tz: "-0000",
                },
                aiAgent: {
                    enable: false,
                    from: "00:00:00",
                    to: "23:59:59",
                    days: [0, 1, 2, 3, 4, 5, 6],
                    tz: "-0000",
                },
            },
        },
        emailConversationNotification: {
            enabled: false,
            new_message_enabled: false,
            new_message_email_delay: 10,
            users: [],
        },
        timeToleranceAfterConversationClosed: {
            enabled: true,
            timeTolerance: 10,
        },
        webhooks: {
            url: null,
            headers: null,
            events: {
                conversation: {
                    new_conversation: false,
                    conversation_updated: false,
                },
                whatsapp: {
                    sent: false,
                    read: false,
                    failed: false,
                },
            },
        },
    },
    socketConfiguration: {
        transports: ["websocket", "polling"],
    },
    notificationsEmails: {
        enabled: false,
        sendToCustom: false,
        emails: [],
    },
};

export { initialConfigState };
