import { useContext } from "react";
import { AppContext } from "../components/AppContext";
import { getAuth, signOut } from "firebase/auth";

import api from "../requests/api";


type deauthUserFunc = () => void
type logoutFunc = () => Promise<void>

function useAuth(): [deauthUserFunc, logoutFunc] {
  //@ts-ignore
  const { actions } = useContext(AppContext);

  const deauthenticateUser = () => {
    localStorage.clear();
    actions.setIsLoggedIn(false);
    signOut(getAuth());
  }

  const logout = async () => {
    try {
      await api.login().logout();
    } finally {
      deauthenticateUser();
    }
  }

  return [deauthenticateUser, logout];
}

export default useAuth;
