import { GoogleOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { firebaseConfig } from "frontend-lib/firebase/useFirebase";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

interface FirebaseSignInProps {
    googleText?: string;
    onAuthCb(accessToken: string): void;
    onLoadingCb?(loading: boolean): void;
}

export default function FirebaseAuth({ googleText, onAuthCb, onLoadingCb }: FirebaseSignInProps) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const app = initializeApp(firebaseConfig);

    const auth = useMemo(() => {
        return getAuth(app);
    }, [app]);

    async function onClickSignUp() {
        try {
            setIsLoading(true);
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);

            const user = result.user;
            const credential = GoogleAuthProvider.credentialFromResult(result);

            if (!credential) return;
            const accestoken = credential.accessToken;
            if (!accestoken) return;

            const token = await user.getIdToken();
            onAuthCb(token);
            setIsLoading(false);
        } catch {
            message.error("unknownError");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (onLoadingCb) onLoadingCb(isLoading);
    }, [isLoading, onLoadingCb]);

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Button
                style={{
                    fontSize: "large",
                    borderRadius: "10px",
                    width: "100%",
                    height: "50px",
                }}
                onClick={onClickSignUp}
                loading={isLoading}
            >
                <GoogleOutlined />
                <span>{googleText ?? t("firebaseAuth.googleSignIn")}</span>
            </Button>
        </div>
    );
}
