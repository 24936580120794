import React from "react";
import { Alert } from "antd";
import { ChannelWarningMessages } from "../types";
import styles from "./SizeWarnings.module.scss";
interface sizeWarnings {
    warnings?: ChannelWarningMessages;
    text: string;
}

export function capitalizeFirstLetter(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

function getChannelAndSizeText(text: string, channel: string, maxSize: string): string {
    return text.replace("channel", capitalizeFirstLetter(channel)).replace("maxSize", maxSize);
}

const { sizeWarningWrapper } = styles;

function SizeWarnings({ warnings, text }: sizeWarnings) {
    if (!warnings) return null;
    return (
        <>
            {Object.entries(warnings).map(
                (obj) =>
                    obj[1].show && (
                        <div className={sizeWarningWrapper} key={"warning-" + obj[0]}>
                            <Alert
                                message={getChannelAndSizeText(
                                    text,
                                    obj[0],
                                    String(obj[1].maxSize)
                                )}
                                type="warning"
                            />
                        </div>
                    )
            )}
        </>
    );
}

export default SizeWarnings;
