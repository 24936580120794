import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import FacebookInstagramClient from "../../clients/facebookInstagramClient";
import { NotificationManager } from "react-notifications";
import ApiError from "../../clients/apiError";
import * as queryString from "query-string";
import { Button, Card, Col, Modal, notification, Row, Space, Spin, Switch, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookMessenger, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { AppContext } from "../AppContext";
import { faCommentDots, faCopy } from "@fortawesome/free-solid-svg-icons";
import api from "../../requests/api";

function ChannelsConfig(props) {
    const { Text } = Typography;
    const { t } = useTranslation();
    const [facebookAuthUrl, setFacebookAuthUrl] = useState(null);
    const [facebookMessengerInstagramBots, setFacebookMessengerInstagramBots] = useState([]);

    const [webChatCode, setWebChatCode] = useState("");
    const [modal, setModal] = useState(false);

    const appContext = useContext(AppContext);
    const workspaceName = useMemo(() => {
        return appContext.state.workspaceName;
    }, [appContext.state.workspaceName]);

    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const greyCardStyle = useMemo(() => {
        return {
            backgroundColor: "#d9d9d9",
            borderStyle: "dotted",
            borderColor: "rgba(53, 32, 158, 80)",
            borderWidth: "3px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "219px",
            transition: "all .3s",
        };
    }, []);

    async function init() {
        setIsLoading(true);
        let facebookConnectionService = new FacebookInstagramClient();
        let queryString = new URLSearchParams({
            redirectPath: window.location.pathname,
        });
        let redirectPath = `/facebook-callback?${queryString}`;
        try {
            let facebookConnectionURL = await facebookConnectionService.getFacebookConnectionURL(
                redirectPath
            );
            setFacebookAuthUrl(facebookConnectionURL);

            let bots = await facebookConnectionService.listBots();
            setFacebookMessengerInstagramBots(bots);
            return bots;
        } catch (error) {
            NotificationManager.error(t("serverError"), undefined, 20000);
        } finally {
            setIsLoading(false);
        }
    }

    async function connect(facebookAccessToken, facebookMessengerInstagramBots) {
        setIsLoading(true);
        let facebookConnectionService = new FacebookInstagramClient();
        try {
            let bots = await facebookConnectionService.connect(
                facebookAccessToken,
                facebookMessengerInstagramBots
            );
            setFacebookMessengerInstagramBots(bots);
            history.push(window.location.pathname);
        } catch (error) {
            let message;
            if (error instanceof ApiError) {
                message = t("dashboard.errorTryingToConnectToFacebookPage");
            } else {
                message = t("serverError");
            }
            NotificationManager.error(message, undefined, 20000);
        } finally {
            setIsLoading(false);
        }
    }

    function onChange(pageId, checked) {
        if (checked) {
            subscribeFacebookPage(pageId);
        } else {
            unsubscribeFacebookPage(pageId);
        }
    }

    const unsubscribeFacebookPage = async function (pageId) {
        setIsLoading(true);
        let facebookConnectionService = new FacebookInstagramClient();
        try {
            const updatedFacebookMessengerInstagramBots =
                await facebookConnectionService.unsubscribeFacebookPage(
                    pageId,
                    facebookMessengerInstagramBots
                );
            setFacebookMessengerInstagramBots(updatedFacebookMessengerInstagramBots);
        } catch (error) {
            let message;
            if (
                error instanceof ApiError &&
                error.errorKey === "ERROR_TRYING_TO_DISCONNECT_FACEBOOK_PAGE"
            ) {
                message = t("dashboard.errorTryingToDisconnectToFacebookPage");
            } else {
                message = t("serverError");
            }
            NotificationManager.error(message, undefined, 20000);
        } finally {
            setIsLoading(false);
        }
    };

    const subscribeFacebookPage = async function (pageId) {
        setIsLoading(true);
        let facebookConnectionService = new FacebookInstagramClient();
        try {
            const updatedFacebookMessengerInstagramBots =
                await facebookConnectionService.subscribeFacebookPage(
                    pageId,
                    facebookMessengerInstagramBots
                );
            setFacebookMessengerInstagramBots(updatedFacebookMessengerInstagramBots);
        } catch (error) {
            let message;
            if (
                error instanceof ApiError &&
                error.errorKey === "ERROR_TRYING_TO_CONNECT_FACEBOOK_PAGE"
            ) {
                message = t("dashboard.errorTryingToConnectToFacebookPage");
            } else {
                message = t("serverError");
            }
            NotificationManager.error(message, undefined, 20000);
        } finally {
            setIsLoading(false);
        }
    };

    const copyToClipboard = (str) => {
        const el = document.createElement("textarea");
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        notification.open({
            message: t("copied"),
            description: t("contentCopied"),
        });
    };

    const openModal = async function () {
        setIsLoading(true);
        try {
            let response = await api.featureRequests().create("WEB_CHAT_CODE");
            setWebChatCode(response.data.code);
        } catch (error) {
            NotificationManager.error(t("serverError"), undefined, 20000);
            return;
        } finally {
            setIsLoading(false);
        }
        setModal(true);
    };

    useEffect(() => {
        async function asyncUseEffect() {
            const facebookMessengerInstagramBots = await init();

            const searchQueryString = queryString.parse(location.search);
            if (searchQueryString.facebookAccessToken) {
                connect(searchQueryString.facebookAccessToken, facebookMessengerInstagramBots);
            }
        }
        asyncUseEffect();
    }, []);

    return (
        <>
            <Spin size="large" spinning={isLoading}>
                <div style={{ padding: "20px" }}>
                    <div style={{ textAlign: "center" }}>
                        <h3 style={{ color: "rgb(53, 32, 158)" }}>
                            {t("channelConfiguration.facebookInstagramAndWhatsappAccounts")}
                        </h3>
                        <p>{t("channelConfiguration.connectFacebookInstagramAndWhatsapp")}</p>
                    </div>
                    <Row gutter={[16, 16]} justify="center">
                        {facebookMessengerInstagramBots.map(
                            (facebookMessengerInstagramBot, index) => (
                                <Col
                                    xs={{ span: 24 }}
                                    md={{ span: 8 }}
                                    key={facebookMessengerInstagramBot.id}
                                >
                                    <Card
                                        style={{
                                            borderColor: "#d9d9d9",
                                            borderWidth: "3px",
                                        }}
                                        title={
                                            t("channelConfiguration.account") +
                                            " " +
                                            String(index + 1)
                                        }
                                        extra={
                                            <Switch
                                                checked={facebookMessengerInstagramBot.connected}
                                                onChange={(checked) =>
                                                    onChange(
                                                        facebookMessengerInstagramBot.pageId,
                                                        checked
                                                    )
                                                }
                                            />
                                        }
                                    >
                                        <Space direction={"vertical"} size={"large"}>
                                            <Space
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                }}
                                                align={"center"}
                                            >
                                                <FontAwesomeIcon
                                                    style={{ color: "#3b5998", fontSize: "2.6rem" }}
                                                    icon={faFacebookMessenger}
                                                />
                                                <Text style={{ width: 100 }} ellipsis={true}>
                                                    {facebookMessengerInstagramBot.pageName}
                                                </Text>
                                            </Space>
                                            <Space>
                                                <div className="instagram-container">
                                                    <FontAwesomeIcon
                                                        icon={faInstagram}
                                                        style={{ fontSize: "2.6rem" }}
                                                    />
                                                </div>
                                                <Text style={{ width: 100 }} ellipsis={true}>
                                                    {
                                                        facebookMessengerInstagramBot.instagramUsername
                                                    }
                                                </Text>
                                            </Space>
                                        </Space>
                                    </Card>
                                </Col>
                            )
                        )}
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>
                            <Card style={greyCardStyle}>
                                <Space direction={"vertical"} size={"large"}>
                                    <Space style={{ display: "flex", justifyContent: "center" }}>
                                        <FontAwesomeIcon
                                            style={{ color: "#3b5998", fontSize: "2.6rem" }}
                                            icon={faFacebookMessenger}
                                        />
                                        <div className="instagram-container">
                                            <FontAwesomeIcon
                                                icon={faInstagram}
                                                style={{ fontSize: "2.6rem" }}
                                            />
                                        </div>
                                    </Space>
                                    <Button type="link" href={facebookAuthUrl}>
                                        {t("channelConfiguration.goToFacebookToAddAccounts") + " "}
                                        <i className="fa fa-external-link" aria-hidden="true" />
                                    </Button>
                                </Space>
                            </Card>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>
                            <Card style={greyCardStyle}>
                                <Space direction={"vertical"} size={"large"}>
                                    <Space style={{ display: "flex", justifyContent: "center" }}>
                                        <FontAwesomeIcon
                                            style={{ fontSize: "2.6rem", color: "#25D366" }}
                                            icon={faWhatsapp}
                                        />
                                    </Space>
                                    <a
                                        href={`https://nxuycltcnpn.typeform.com/to/bHBfGhcN#wn=${encodeURIComponent(
                                            workspaceName
                                        )}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {t("channelConfiguration.connectWhatsApp") + " "}
                                        <i className="fa fa-external-link" aria-hidden="true" />
                                    </a>
                                </Space>
                            </Card>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>
                            <Card style={greyCardStyle}>
                                <Space direction={"vertical"} size={"large"}>
                                    <Space style={{ display: "flex", justifyContent: "center" }}>
                                        <FontAwesomeIcon
                                            style={{ fontSize: "2.6rem", color: "#92D3FF" }}
                                            icon={faCommentDots}
                                        />
                                    </Space>
                                    <Button type="link" onClick={() => openModal()}>
                                        {t("channelConfiguration.addOurChatToYourWeb")}
                                    </Button>
                                </Space>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Spin>
            <Modal
                visible={modal}
                onCancel={() => setModal(false)}
                onOk={() => setModal(false)}
                footer={[
                    <>
                        <Button onClick={() => copyToClipboard(webChatCode)}>
                            <span>{t("channelConfiguration.copyToClipboard")}</span>
                            <FontAwesomeIcon icon={faCopy} style={{ marginLeft: "5px" }} />
                        </Button>
                        <Button onClick={() => setModal(false)}>{t("close")}</Button>
                    </>,
                ]}
            >
                <h1>{t("channelConfiguration.codeForYourWebPage")}</h1>
                <p>{t("channelConfiguration.placeThisCodeOnYourWeb")}</p>
                <pre className="opt-code-examples" style={{ maxHeight: "300px" }}>
                    {webChatCode}
                </pre>
            </Modal>
        </>
    );
}

export default ChannelsConfig;
