import React from "react";
import { useTranslation } from "react-i18next";
import WarningWrapper from "./WarningWrapper";
import { Form, Input } from "antd";
const FormItem = Form.Item;
const { TextArea } = Input;

interface AnswerTextAreaProps {
    label?: string;
    placeholder: string;
    value: string;
    disabled?: boolean;
    onChangeCb: (ev: object) => object;
}
function AnswerTextArea({
    label = "",
    placeholder,
    value,
    disabled = false,
    onChangeCb,
}: AnswerTextAreaProps) {
    const { t } = useTranslation();
    return (
        <div className="opt-input-group">
            <span className="opt-label-title">{t(label)}</span>
            <FormItem validateStatus={!value ? "error" : ""} help={!value && t("notBlank")}>
                <TextArea
                    rows={3}
                    placeholder={t(placeholder)}
                    value={value}
                    disabled={disabled}
                    onChange={(evt) => onChangeCb(evt)}
                ></TextArea>
            </FormItem>
            <WarningWrapper text={value || ""} />
        </div>
    );
}
export default AnswerTextArea;
