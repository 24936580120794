import React from "react";
import { withRouter } from "react-router-dom";

export const AppContext = React.createContext({});
export const AppConsumer = AppContext.Consumer;

const stateKey = "optiwe";
class Context extends React.Component {
    constructor(props) {
        super(props);
        this.state = JSON.parse(
            localStorage.getItem(stateKey) ||
                JSON.stringify({
                    userData: {
                        id: "",
                        firstName: "",
                        lastName: "",
                        email: "",
                        isAdmin: false,
                    },
                    workspaceId: null,
                    isLoggedIn: false,
                    demo: false,
                    workspaceConfigs: {},
                    workspaceName: null,
                    isInstagramEnabled: false,
                    globalUser: undefined
                })
        );
        this.updateState = this.updateState.bind(this);
        this.setUserData = this.setUserData.bind(this);
        this.setIsLoggedIn = this.setIsLoggedIn.bind(this);
        this.setDemoState = this.setDemoState.bind(this);
        this.setWorkspaceConfigs = this.setWorkspaceConfigs.bind(this);
        this.setWorkspaceName = this.setWorkspaceName.bind(this);
        this.setGlobalUser = this.setGlobalUser.bind(this);
    }

    updateState(newState, callback) {
        this.setState(newState, () => {
            localStorage.setItem(stateKey, JSON.stringify(this.state));
            if (typeof callback === "function") {
                callback(this.state);
            }
        });
    }

    setUserData(userData, workspaceId) {
        this.updateState({
            userData: userData,
            workspaceId: workspaceId,
        });
    }

    setIsLoggedIn(isLoggedIn) {
        this.updateState({
            isLoggedIn: isLoggedIn,
        });
    }

    setGlobalUser(globalUser) {
        this.updateState({
            globalUser,
        });
    }

    setDemoState(flag) {
        this.updateState({ demo: flag });
    }

    setWorkspaceConfigs(workspaceConfigs) {
        this.updateState({ workspaceConfigs: workspaceConfigs });
    }

    setWorkspaceName(workspaceName) {
        this.updateState({ isInstagramEnabled: true });
        this.updateState({ workspaceName: workspaceName });
    }

    render() {
        return (
            <AppContext.Provider
                value={{
                    state: this.state,
                    actions: {
                        setUserData: this.setUserData,
                        setIsLoggedIn: this.setIsLoggedIn,
                        setDemoState: this.setDemoState,
                        setWorkspaceConfigs: this.setWorkspaceConfigs,
                        setWorkspaceName: this.setWorkspaceName,
                        setGlobalUser: this.setGlobalUser,
                    },
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}
export const AppProvider = withRouter(Context);
