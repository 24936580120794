import React, { useEffect, useState, useContext } from "react";
import { Button, message, List } from "antd";
import api from "requests/api";
import { AppContext } from "components/AppContext";
import { RouteComponentProps } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { GlobalUser, User } from "frontend-lib/DomainTypes/GlobalUser";
import { Agent } from "frontend-lib/DomainTypes/User";
import { LoginOutlined } from "@ant-design/icons";
import "./workspaces.scss"
import { OptiweCard } from "components/OptiweCard";


export default function Workspaces({ history }: RouteComponentProps) {
    const [globalUser, setGlobalUser] = useState<GlobalUser | undefined>(undefined);
    const appContext = useContext(AppContext);
    const { t } = useTranslation();

    function parseAxiosError(err: AxiosError) {
        if (err.response?.status === 400) {
            message.error(`${t('workspaces.noUserFound')}`)
            return
        }
        else if (err.response?.status === 401) {
            message.error(`${t('workspaces.errorDisabledWorkspace')}`)
            return
        } else if (err.response?.status === 403) {
            message.error(`${t('workspaces.errorDisabledWorkspace')}`)
            return
        } else {
            message.error(t("serverError"))
            throw err
        }
    }

    async function changeWorkspace(user: User) {
        const desiredWorkspaceId = user.workspace.id
        // @ts-ignore
        const actualWorkspaceId = appContext.state.workspaceId

        if (user.workspace?.isDisabled) {
            message.error(`${t('workspaces.errorDisabledWorkspace')}`)
            return
        }

        if (actualWorkspaceId === desiredWorkspaceId) {
            history.push('/dashboard/home');
            return
        }

        try {
            var resUser = await api.login().changeWorkspace(desiredWorkspaceId)
            var agent: Agent = resUser.data

            const resWorkspace = await api.workspaces().get(desiredWorkspaceId)
            var workspaceResponse = resWorkspace.data
        } catch (err: any | AxiosError) {
            if (axios.isAxiosError(err)) {
                parseAxiosError(err)
            }
            throw err
        }

        // @ts-ignore
        appContext.actions.setDemoState(false);
        // @ts-ignore
        appContext.actions.setUserData(
            agent,
            resUser.data.workspace.id
        );
        // @ts-ignore
        appContext.actions.setIsLoggedIn(true);
        // @ts-ignore
        appContext.actions.setWorkspaceConfigs(
            workspaceResponse.jsonConfigs
        );

        history.push('/dashboard/home');
    }

    function parseListItemClassName(user: User) {
        if (user.workspace?.isDisabled) {
            return "disabled-list-item-change-ws"
        }
        return "list-item-change-ws"
    }

    function parseLoginButtonClassName(user: User) {
        if (user.workspace?.isDisabled) {
            return "disabled-login-icon"
        }
        return "login-icon"
    }


    useEffect(() => {
        async function loadGlobalUsers() {
            try {
                // @ts-ignore
                const response = await api.globalUser().get(appContext.state.userData.id)
                setGlobalUser(response.data)
                // @ts-ignore
                appContext.actions.setGlobalUser(response.data);
            } catch (err: any | AxiosError) {
                message.error(t('workspaces.errorLoading'))
            }
        }
        loadGlobalUsers()
    }, [])

    useEffect(() => {
        if (globalUser && globalUser.users.length === 1) {
            history.push('/dashboard/home');
        }
    }, [globalUser])

    return (
        <div className="signup">
            <OptiweCard
                title={
                    <div style={{ display: 'flex' }}>
                        {t('workspaces.title')}
                    </div >
                }
            >
                {globalUser &&
                    <List
                        style={{ overflowY: 'auto', maxHeight: '300px' }}
                    >
                        {globalUser?.users.map(user =>
                            <List.Item
                                className={parseListItemClassName(user)}
                                style={{
                                    padding: '5%',
                                    marginBottom: '10px',
                                    boxShadow: '0px 5px 5px #A9B4C0',
                                    width: '100%',
                                }}
                                onClick={() => changeWorkspace(user)}
                            >
                                <b style={{ paddingTop: '5px' }}>
                                    {user.workspace.name}
                                </b>
                                <Button className={parseLoginButtonClassName(user)}>
                                    <LoginOutlined/>
                                </Button>
                            </List.Item>
                        )}
                    </List>
                }
            </OptiweCard>
        </div>
    )
}