import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Skeleton, Empty } from "antd";
import OptChart from "../../../../components/OptChart/OptChart";
import { UISTATES } from "../configs/Constants";
import { useTranslation } from "react-i18next";
import { TranslatedConfigs } from "../configs/ChatReportsConfigs";
import moment from "moment";
function AgentReports({ layout, uistate, chartsRange, data, timeInState, avgTimeInState }) {
    const { t } = useTranslation();
    const [chatsData, setChatsData] = useState([]);
    const [timeInStateData, setTimeInStateData] = useState([]);
    const [avgTimeInStateData, setAvgTimeInStateData] = useState([]);
    const showChart = (title, type, config, data, description) => {
        if (uistate === UISTATES.LOADING) {
            return <Skeleton paragraph={{ rows: 8 }} />;
        }
        if (uistate === UISTATES.EMPTY) {
            return (
                <>
                    <p className="opt-title" style={{ textAlign: "center" }}>
                        {title}
                    </p>
                    <Empty />
                </>
            );
        }
        if (uistate === UISTATES.FINISHED) {
            return (
                <OptChart
                    title={title}
                    type={type}
                    config={config}
                    data={data}
                    showTooltip
                    tooltipText={description}
                />
            );
        }
    };

    const loadAvgTimeData = useCallback(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        const day = today.getDay();

        const hours = [...Array(24).keys()].map((i) => (i + 6) % 24);
        const avgTimesData = [];

        hours.forEach((hour) => {
            const utcDate = new Date(Date.UTC(year, month, day, hour, 0, 0));
            const localDate = utcDate.toLocaleTimeString([], { hour12: false });
            const chartDate = localDate.substring(0, localDate.length - 3);

            if (avgTimeInState && avgTimeInState.length) {
                const datasHour = avgTimeInState.filter((d) => d.hour.split(":")[0] === hour);

                const dataOnline = datasHour.find((d) => d.state === "ONLINE") || {
                    state: "ONLINE",
                    hour: localDate,
                    seconds: null,
                };
                const dataOffline = datasHour.find((d) => d.state === "OFFLINE") || {
                    state: "OFFLINE",
                    hour: localDate,
                    seconds: null,
                };

                const minutesOnline = dataOnline.seconds
                    ? Math.floor(dataOnline.seconds / 60)
                    : null;
                const minutesOffline = dataOffline.seconds
                    ? Math.floor(dataOffline.seconds / 60)
                    : null;

                const dataOnlinePoint = {
                    state: t("users.agentStates.online"),
                    minutes: minutesOnline,
                    hour: chartDate,
                };
                const dataOfflinePoint = {
                    state: t("users.agentStates.offline"),
                    minutes: minutesOffline,
                    hour: chartDate,
                };

                avgTimesData.push(dataOnlinePoint);
                avgTimesData.push(dataOfflinePoint);
            } else {
                avgTimesData.push({ hour: chartDate, minutes: null, state: null });
            }
        });
        setAvgTimeInStateData(avgTimesData);
    }, [avgTimeInState, t]);

    useEffect(() => {
        const chartData = [];
        const timesData = [];
        chartsRange.forEach((date) => {
            const filtered = data.filter(
                (item) => moment(item.date).utc().format("DD/MM/YYYY") === date
            );
            if (filtered.length > 0) {
                const chartItem = JSON.parse(JSON.stringify(filtered[0]));
                const parsedDate = moment(chartItem.date).utc().format("DD/MM/YYYY");
                chartItem.date = parsedDate;
                chartItem.amount = chartItem.amountChatServed || null;
                chartItem.interactions = chartItem.totalAmountInteractions || null;
                chartItem.averageInteractions = chartItem.meanAmountInteractions || null;
                chartItem.serviceTime = Math.floor(chartItem.meanServiceTime / 60) || null;

                chartData.push(chartItem);
            } else {
                const newItem = { date: date, name: null, total: null, duration: null };
                chartData.push(newItem);
            }

            const voidDataPoint = { date: date, minutes: null, state: null };
            if (timeInState && timeInState.length) {
                const statesByDate = timeInState.find(
                    (s) => moment(s.date).utc().format("DD/MM/YYYY") === date
                );
                if (statesByDate != null) {
                    const statesInADay = statesByDate.stats;

                    const offlineData = statesInADay.find((s) => s.state === "OFFLINE");
                    const onlineData = statesInADay.find((s) => s.state === "ONLINE");

                    const minutesOffline = offlineData
                        ? Math.floor(offlineData.seconds / 60)
                        : null;
                    const minutesOnline = onlineData ? Math.floor(onlineData.seconds / 60) : null;

                    if (onlineData) {
                        const dataPointOnline = {
                            date: date,
                            minutes: minutesOnline,
                            state: t("users.agentStates.online"),
                        };
                        timesData.push(dataPointOnline);
                    }
                    if (offlineData) {
                        const dataPointOffline = {
                            date: date,
                            minutes: minutesOffline,
                            state: t("users.agentStates.offline"),
                        };
                        timesData.push(dataPointOffline);
                    }
                } else {
                    timesData.push(voidDataPoint);
                }
            } else {
                timesData.push(voidDataPoint);
            }
        });
        loadAvgTimeData();
        setChatsData(chartData);
        setTimeInStateData(timesData);
    }, [data, chartsRange, timeInState, avgTimeInState, loadAvgTimeData, t]);
    const charts = [
        {
            title: t("agentReports.total"),
            type: "column",
            config: TranslatedConfigs().agentAmountChartConfig,
            data: chatsData,
            description: t("agentReports.totalDesc"),
        },
        {
            title: t("agentReports.totalInt"),
            type: "column",
            config: TranslatedConfigs().agentTotalInteractionsConfig,
            data: chatsData,
            description: t("agentReports.totalIntDesc"),
        },
        {
            title: t("agentReports.meanInt"),
            type: "column",
            config: TranslatedConfigs().agentAverageInteractionsConfig,
            data: chatsData,
            description: t("agentReports.meanIntDesc"),
        },
        {
            title: t("agentReports.serviceTime"),
            type: "column",
            config: TranslatedConfigs().agentServiceTimeConfig,
            data: chatsData,
            description: t("agentReports.serviceTimeDesc"),
        },
        {
            title: t("agentReports.timeInState"),
            type: "column",
            config: TranslatedConfigs().agentTimeInStateConfig,
            data: timeInStateData,
            description: t("agentReports.timeInStateDescription"),
        },
        {
            title: t("agentReports.percentageDistribuionInState"),
            type: "column",
            config: TranslatedConfigs().agentAvgTimeInStateConfig,
            data: avgTimeInStateData,
            description: t("agentReports.percentageDistribuionInStateDescription"),
        },
    ];
    return (
        <>
            <Row gutter={[50, 50]}>
                {charts.map((chart) => (
                    <Col span={layout === "grid" ? 12 : 24}>
                        {showChart(
                            chart.title,
                            chart.type,
                            chart.config,
                            chart.data,
                            chart.description
                        )}
                    </Col>
                ))}
            </Row>
        </>
    );
}
export default AgentReports;
