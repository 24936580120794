import { CloseOutlined } from "@ant-design/icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Checkbox, DatePicker, Input, Select, Tooltip, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../../../requests/api";
import ACTIONS from "../Monitoring/filters/actions";

const { Option } = Select;
const { RangePicker } = DatePicker;

function CustomerFilters({ filterDispatch, setIsLoadingCb, customersIdsFromCampaign }) {
    const { t } = useTranslation();
    const [customerGroups, setCustomerGroups] = useState([]);
    const [selectedCustomerGroups, setSelectedCustomerGroups] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [conversationGroups, setConversationGroups] = useState([]);
    const [selectedConversationGroups, setSelectedConversationGroups] = useState([]);
    const [agents, setAgents] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [agentPaginatorPage, setAgentPaginatorPage] = useState(0);
    const [agentPaginatorHasNext, setAgentPaginatorHasNext] = useState(false);
    const [createdOnDatesError, setCreatedOnDatesError] = useState(false);
    const [lastActivityOnDatesError, setLastActivityOnDatesError] = useState(false);

    const customerSearchTypingTimeoutRef = useRef(null);

    function onLastActivityDatesChange(dates, datesString) {
        if (dates && dates[0] && dates[1]) {
            const diff = dates[1].diff(dates[0], 'days');
            const isError = diff > 32
            setLastActivityOnDatesError(diff > 32);
            if (isError) {
                return
            }
        }

        const startDate = dates ? dates[0] : null;
        const endDate = dates ? dates[1] : null;
        filterDispatch({
            type: ACTIONS.CHANGE_LAST_ACTIVITY_DATE,
            payload: {
                lastActivityStartDate: startDate,
                lastActivityEndDate: endDate,
            },
        });
    }

    function onCreationDatesChange(dates, datesString) {
        if (dates && dates[0] && dates[1]) {
            const diff = dates[1].diff(dates[0], 'days');
            const isError = diff > 32
            setCreatedOnDatesError(diff > 32);
            if (isError) {
                return
            }
        }

        const startDate = dates ? dates[0] : null;
        const endDate = dates ? dates[1] : null;
        filterDispatch({
            type: ACTIONS.CHANGE_CUSTOMER_DATE,
            payload: {
                startDate: startDate,
                endDate: endDate,
            },
        });
    }

    async function loadCustomerGroups() {
        const res = await api.customers().groups();
        setCustomerGroups(res.data.customerGroups);
    }

    async function loadTags() {
        const res = await api.tags().list();
        setTags(res.data.conversationLabels);
    }

    async function loadGroups() {
        const res = await api.chat().groups();
        setConversationGroups(res.data.chatGroups);
    }

    function handleAgentPopUpScroll(e) {
        e.persist();
        let target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (agentPaginatorHasNext) {
                setAgentPaginatorPage((agentPaginatorPage) => agentPaginatorPage + 1);
            }
        }
    }

    async function loadAgents() {
        const res = await api.users().list(agentPaginatorPage);
        setAgents([...agents, ...res.data.users]);
        setAgentPaginatorHasNext(res.data.paginator.hasNext);
    }

    function onChangeOptIn(optIn) {
        filterDispatch({
            type: ACTIONS.CHANGE_OPTIN,
            payload: {
                hasWhatsappOptIn: optIn.target.checked,
            },
        });
    }

    function onChangeCustomerGroupsIds(customerGroups) {
        setSelectedCustomerGroups(customerGroups);
        const ids = customerGroups.map((cg) => cg.value);
        filterDispatch({
            type: ACTIONS.CHANGE_CUSTOMER_GROUPS_IDS,
            payload: {
                customerGroupsIds: ids,
            },
        });
    }

    function onChangeTagsIds(tagsLabels) {
        setSelectedTags(tagsLabels);
        const ids = tagsLabels.map((t) => t.value);
        filterDispatch({
            type: ACTIONS.CHANGE_CONVERSATION_TAGS_IDS,
            payload: {
                conversationTagsIds: ids,
            },
        });
    }

    function onChangeConversationGroups(groupsLabels) {
        setSelectedConversationGroups(groupsLabels);
        const ids = groupsLabels.map((g) => g.value);
        filterDispatch({
            type: ACTIONS.CHANGE_CONVERSATION_GROUPS_IDS,
            payload: {
                conversationGroupsIds: ids,
            },
        });
    }

    function onChangeAgents(agentsLabel) {
        setSelectedAgents(agentsLabel);
        const ids = agentsLabel.map((a) => a.value);
        filterDispatch({
            type: ACTIONS.CHANGE_AGENTS_IDS,
            payload: {
                agentsIds: ids,
            },
        });
    }

    function onChangeCustomerSelect(text) {
        clearTimeout(customerSearchTypingTimeoutRef.current);
        const { value } = text.target;
        customerSearchTypingTimeoutRef.current = setTimeout(() => {
            filterDispatch({
                type: ACTIONS.CHANGE_SEARCH_TEXT,
                payload: {
                    searchText: value ? value : null,
                },
            });
        }, [500]);
    }

    function onClickCampaignCustomersFilter() {
        filterDispatch({
            type: ACTIONS.CHANGE_CUSTOMERS_ID,
            payload: {
                customersId: null,
            },
        });
        filterDispatch({
            type: ACTIONS.CHANGE_CUSTOMER_GROUPS_IDS,
            payload: {
                customerGroupsIds: null,
            },
        });
        filterDispatch({
            type: ACTIONS.CHANGE_OR_CUSTOMERS_AND_GROUPS,
            payload: {
                orCustomersAndGroups: null,
            },
        });
        filterDispatch({
            type: ACTIONS.CHANGE_OPTIN,
            payload: {
                hasWhatsappOptIn: null,
            },
        });
        window.history.replaceState(null, "");
    }

    useEffect(() => {
        loadAgents();
    }, [agentPaginatorPage]);

    useEffect(() => {
        setIsLoadingCb(true);
        try {
            Promise.all([
                loadCustomerGroups(),
                loadTags(),
                loadGroups(),
                loadAgents(),
                //loadCustomers(),
            ]);
        } catch {
            message.error(t("chatSocketError"));
        }
        setIsLoadingCb(false);
    }, []);

    const filterStyle = {
        marginBottom: "15px",
    };

    return (
        <>
            <Card>
                <h1>
                    <FontAwesomeIcon icon={faFilter} />
                    {t("customers.filters.title")}
                </h1>
                {customersIdsFromCampaign && (
                    <div style={filterStyle}>
                        <Button
                            type="primary"
                            size="small"
                            icon={<CloseOutlined />}
                            onClick={onClickCampaignCustomersFilter}
                        >
                            {t("customers.filters.campaignCustomers")}
                        </Button>
                    </div>
                )}

                <div style={filterStyle}>
                    <span>
                        <Checkbox onChange={onChangeOptIn}>
                            {t("customers.filters.hasOptIn")}
                        </Checkbox>
                    </span>
                </div>

                <div style={filterStyle}>
                    <span>{t("customers.filters.customer")}</span>
                    <Input
                        allowClear
                        placeholder={t("customers.filters.customerLabel")}
                        onChange={onChangeCustomerSelect}
                    />
                </div>

                <div style={filterStyle}>
                    <span>{t("customers.filters.customerGroups")}</span>
                    <Select
                        labelInValue
                        placeholder={t("customers.filters.customerGroupsLabel")}
                        mode="multiple"
                        style={{ width: "100%" }}
                        value={selectedCustomerGroups}
                        onChange={onChangeCustomerGroupsIds}
                        showSearch={false}
                    >
                        {customerGroups.length
                            ? customerGroups.map((customerGroup) => (
                                  <Option value={customerGroup.id}>{customerGroup.name}</Option>
                              ))
                            : null}
                    </Select>
                </div>

                <div style={filterStyle}>
                    <span>{t("customers.filters.conversationTags")}</span>
                    <Select
                        labelInValue
                        placeholder={t("customers.filters.tagLabel")}
                        mode="multiple"
                        style={{ width: "100%" }}
                        value={selectedTags}
                        onChange={onChangeTagsIds}
                        showSearch={false}
                    >
                        {tags.length
                            ? tags.map((tag) => <Option value={tag.id}>{tag.value}</Option>)
                            : null}
                    </Select>
                </div>

                <div style={filterStyle}>
                    <span>{t("customers.filters.conversationGroups")}</span>
                    <Select
                        labelInValue
                        placeholder={t("customers.filters.groupLabel")}
                        mode="multiple"
                        style={{ width: "100%" }}
                        value={selectedConversationGroups}
                        onChange={onChangeConversationGroups}
                        showSearch={false}
                    >
                        {conversationGroups.length
                            ? conversationGroups.map((cg) => (
                                  <Option value={cg.id}>{cg.name}</Option>
                              ))
                            : null}
                    </Select>
                </div>

                <div style={filterStyle}>
                    <span>{t("customers.filters.withAffinityTo")}</span>
                    <Select
                        labelInValue
                        placeholder={t("customers.filters.agentLabel")}
                        mode="multiple"
                        style={{ width: "100%" }}
                        value={selectedAgents}
                        onChange={onChangeAgents}
                        onPopupScroll={handleAgentPopUpScroll}
                        showSearch={false}
                    >
                        {agents.length
                            ? agents.map((agent) => (
                                  <Option value={agent.id}>
                                      {agent.firstName} {agent.lastName}
                                  </Option>
                              ))
                            : null}
                    </Select>
                </div>

                <div style={filterStyle}>
                    <span>{t("customers.filters.lastActivity")}</span>
                    <Tooltip title={lastActivityOnDatesError ? t("monitoringList.datesError") : ""} visible={lastActivityOnDatesError}>    
                    <RangePicker onChange={onLastActivityDatesChange} />
                    </Tooltip>

                </div>

                <div style={filterStyle}>
                    <span>{t("customers.filters.createdOn")}</span>
                    <Tooltip title={createdOnDatesError ? t("monitoringList.datesError") : ""} visible={createdOnDatesError}>    
                    <RangePicker onChange={onCreationDatesChange} />
                    </Tooltip>

                </div>
            </Card>
        </>
    );
}

export default CustomerFilters;
