import React, { useState, useContext } from "react";
import { AppContext } from "../../../components/AppContext";
import api from "../../../requests/api";
import { NotificationManager } from "react-notifications";
import "./Profile.scss";
function Profile(props) {
    const appContext = useContext(AppContext);
    const [userData, setUserData] = useState(appContext.state.userData);
    function handleKnowledgeChange(evt) {
        const value = evt.target.value;
        setUserData({
            ...userData,
            [evt.target.name]: value,
        });
    }
    const updateData = function () {
        console.log(userData);
        const widthCredentials = true;
        api.users()
            .edit(userData, widthCredentials)
            .then((response) => {
                console.log(response);
                appContext.actions.setUserData(userData, userData.workspace.id);
                NotificationManager.success("User edited");
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    };
    return (
        <div className="opt-view opt-view--profile">
            <div className="opt-view-header">
                <h1 className="opt-title">My information</h1>
            </div>
            <div className="opt-card">
                <div className="opt-form-group">
                    <label className="opt-label-title">First Name</label>
                    <input
                        type="text"
                        className="opt-text-input"
                        placeholder="First name"
                        name="firstName"
                        value={userData.firstName}
                        onChange={handleKnowledgeChange}
                        required
                    />
                </div>
                <div className="opt-form-group">
                    <label className="opt-label-title">Last name</label>
                    <input
                        type="text"
                        className="opt-text-input"
                        placeholder="Last name"
                        name="lastName"
                        value={userData.lastName}
                        onChange={handleKnowledgeChange}
                        required
                    />
                </div>
                <div className="opt-form-group">
                    <label className="opt-label-title">Email</label>
                    <input
                        type="text"
                        className="opt-text-input"
                        placeholder="Last name"
                        name="email"
                        value={userData.email}
                        onChange={handleKnowledgeChange}
                        required
                    />
                </div>
                <button className="opt-button" onClick={updateData}>
                    Save changes
                </button>
            </div>
        </div>
    );
}
export default Profile;
