import { CustomerField } from "frontend-lib/DomainTypes/CustomerFields";
import { Logger } from "frontend-lib/Log";
import { useEffect, useMemo, useState } from "react";

type LoadingError = boolean;

type ReloadCb = () => Promise<void>;
type Loading = boolean;

interface useCustomerFieldsProps {
    api: {
        api_url: () => {
            get: () => string | undefined;
        };
        customers: () => {
            listCustomerField: () => Promise<any>;
        };
    };
}

function useCustomerFields({
    api,
}: useCustomerFieldsProps): [CustomerField[] | undefined, Loading, LoadingError, ReloadCb] {
    const [customerFields, setCustomerFields] = useState<CustomerField[]>();
    const [dummyState, setDummyState] = useState(false);
    const [isLoading, setIsLoading] = useState<Loading>(false);
    const [loadingError, setLoadingError] = useState<LoadingError>(false);
    const logger = useMemo(() => {
        return new Logger("useCustomerFields", api.api_url().get());
    }, []);

    const reload = async () => {
        setLoadingError(false);
        setDummyState((s) => !s);
    };

    useEffect(() => {
        const loadCustomerFields = async () => {
            try {
                const responseCustomerField = await api.customers().listCustomerField();
                setCustomerFields(responseCustomerField.data.customerFields);
            } catch (err: any) {
                logger.error(
                    `Error loading customer fields in useCustomerFields. 
                Error is ${JSON.stringify(err)}`
                );
                setLoadingError(true);
            } finally {
                setIsLoading(false);
            }
        };
        loadCustomerFields();
    }, [dummyState, logger]);

    return [customerFields, isLoading, loadingError, reload];
}
export default useCustomerFields;
