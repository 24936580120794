import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Modal, Row, Space, Switch, message } from "antd";
import TitleTooltip from "components/TitleTooltip/TitleTooltip";
import moment, { Moment } from "moment";
import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { momentToYYMMDD } from "./momentUtils";
import api from "../../../requests/api";
import axios, { AxiosError } from "axios";
import { downloadFromBlob } from '../../../../src/frontend-lib/Utils/FileUtils';


interface FilterState {
    startDate?: Moment,
    endDate?: Moment,
    optIn: boolean,
    customersId?: number[],
    conversationTagsIds?: number[],
    conversationGroupsIds?: number[],
    agentsIds?: number[],
    searchText: string,
    orCustomersAndGroups?: boolean,
    lastActivityStartDate: Moment | null,
    lastActivityEndDate: Moment | null,
    hasWhatsappOptIn?: boolean,
    customerGroupsIds: number[],
    customersStartDate?: Moment | null,
    customersEndDate?: Moment | null,
}

interface ExportCustomersProps {
    isModalVisible: boolean,
    setModalVisible: (visible: boolean) => void,
    selectedCustomersId: number[]
    filters: FilterState
    setIsLoading: (isSet: boolean) => void,
}

enum ExportType {
    Filters,
    SelectedCustomers,
}


function differenceInDays(startDate: Moment, endDate: Moment): number {
    const duration = moment.duration(endDate.diff(startDate))
    return duration.asDays()
}


export function ExportCustomers({ isModalVisible, setModalVisible, selectedCustomersId, filters, setIsLoading }: ExportCustomersProps) {
    const { t } = useTranslation();
    const [includeConversations, setIncludeConversations] = useState(false);
    const [exportType, setExportType] = useState(selectedCustomersId.length === 0 ? ExportType.Filters : ExportType.SelectedCustomers);
    const exportEnabled = useMemo(() => {
        const maxDaysDifference = 31;
        if (filters.customersStartDate && filters.customersEndDate) {
            if (differenceInDays(filters.customersStartDate, filters.customersEndDate) > maxDaysDifference) {
                return false
            }
        }
        if (filters.lastActivityStartDate && filters.lastActivityEndDate) {
            if (differenceInDays(filters.lastActivityStartDate, filters.lastActivityEndDate) > maxDaysDifference) {
                return false
            }
        }
        return true
    }, [filters.customersStartDate, filters.customersEndDate, filters.lastActivityStartDate, filters.lastActivityEndDate])

    async function exportCustomers() {
        setIsLoading(true);
        setModalVisible(false);
        let response
        message.loading(t('customers.export.exporting'), 60)
        try {
            switch (exportType) {
                case ExportType.SelectedCustomers:
                    response = await api.customers().exportCustomers(
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        selectedCustomersId,
                        undefined,
                        undefined,
                        undefined,
                        includeConversations
                    )
                    break;
                case ExportType.Filters:
                    const filtersCopy = { ...filters }
                    if (!((filters.customersStartDate && filters.customersEndDate) || (filters.lastActivityStartDate && filters.lastActivityEndDate))) {
                        filtersCopy.lastActivityEndDate = moment()
                        filtersCopy.lastActivityStartDate = moment().subtract(30, 'days')
                    }
                    response = await api.customers().exportCustomers(
                        filtersCopy.searchText,
                        filtersCopy.customersStartDate ? momentToYYMMDD(filtersCopy.customersStartDate) : undefined,
                        filtersCopy.customersEndDate ? momentToYYMMDD(filtersCopy.customersEndDate) : undefined,
                        filtersCopy.hasWhatsappOptIn,
                        filtersCopy.conversationTagsIds,
                        filtersCopy.conversationGroupsIds,
                        filtersCopy.agentsIds,
                        undefined,
                        filtersCopy.customerGroupsIds,
                        filtersCopy.lastActivityStartDate ? momentToYYMMDD(filtersCopy.lastActivityStartDate) : undefined,
                        filtersCopy.lastActivityEndDate ? momentToYYMMDD(filtersCopy.lastActivityEndDate) : undefined,
                        includeConversations
                    )
                    break;
                default:
                    throw Error(`Export type: ${exportType} not implemented`)
            }
            const fileName = response.headers["content-disposition"].split("filename=")[1];
            downloadFromBlob(new Blob([response.data]), fileName);
            message.destroy()
        } catch (error: any | AxiosError) {
            message.destroy()
            let isTimeout = false;
            if (axios.isAxiosError(error)) {
                const errorBody = JSON.parse(await error.response?.data.text())
                isTimeout = errorBody.error?.key === 'TIMEOUT'
            }
            if (isTimeout) message.error(t('customers.export.timeoutError'))
            else message.error(t('customers.export.exportError'));
        } finally {
            setIsLoading(false);
        }
    }
    const renderWarning = () => {
        if (!exportEnabled) {
            return (
                <>
                    <FontAwesomeIcon icon={faExclamationTriangle} color='red' style={{ marginRight: '10px' }} />
                    {t('customers.export.cannotExport')}
                </>
            )
        }
        const customersDateUndefined = (filters.customersStartDate == null && filters.customersEndDate == null)
        const lastActivityDateUndefined = filters.lastActivityStartDate == null && filters.lastActivityEndDate == null
        if (customersDateUndefined && lastActivityDateUndefined) {
            return (
                <>
                    <FontAwesomeIcon icon={faExclamationTriangle} color='#ffcc00' style={{ marginRight: '10px' }} />
                    {t('customers.export.noDateSelected')}
                </>
            )
        }
        return <></>
    }

    useEffect(() => {
        setExportType(selectedCustomersId.length === 0 ? ExportType.Filters : ExportType.SelectedCustomers)
    }, [selectedCustomersId])


    return (
        <Modal
            title={t("customers.export.title")}
            okText={t("customers.export.title")}
            visible={isModalVisible}
            onOk={exportCustomers}
            onCancel={() => setModalVisible(false)}
            okButtonProps={{ disabled: !exportEnabled }}
        >
            <Space direction="vertical" style={{ width: '100%' }} size="large">
                <Row>
                    <Col span={22}> {t('customers.export.usingFilters')}</Col>
                    <Col span={2}>
                        <Switch
                            disabled={selectedCustomersId.length === 0}
                            checked={exportType === ExportType.Filters}
                            onChange={checked => checked ? setExportType(ExportType.Filters) : setExportType(ExportType.SelectedCustomers)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={22}>
                        {t('customers.export.usingSelectedCustomers')}
                        {
                            (selectedCustomersId.length === 0) ?
                                <TitleTooltip
                                    content={t('customers.export.selectedCustomersHelp')}
                                /> : ''
                        }
                    </Col>
                    <Col span={2} >
                        <Switch
                            disabled={selectedCustomersId.length === 0}
                            checked={exportType === ExportType.SelectedCustomers}
                            onChange={checked => checked ? setExportType(ExportType.SelectedCustomers) : setExportType(ExportType.Filters)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={22}>{t("customers.export.includeConversations")}</Col>
                    <Col span={2}>
                        <Switch onChange={checked => setIncludeConversations(checked)} />
                    </Col>
                </Row>
                {renderWarning()}
            </Space>
        </Modal>
    )
}