import postLog from "./postLog";

class Logger {
    /**
     *
     * @param {string} logName
     */
    constructor(logName, serverUrl) {
        this.isDev = process.env?.NODE_ENV === "development";
        this.logName = logName;
        this.serverUrl = serverUrl;
    }

    debug(...message) {
        if (this.isDev) {
            console.log(message);
        }
    }

    info(message) {
        if (this.isDev) {
            console.info(message);
        }
        postLog(this.serverUrl, this.logName, "INFO", message);
    }

    warning(message) {
        if (this.isDev) {
            console.warn(message);
        }
        postLog(this.serverUrl, this.logName, "WARNING", message);
    }

    error(message) {
        if (this.isDev) {
            console.error(message);
        }
        if (this.serverUrl) postLog(this.serverUrl, this.logName, "ERROR", message);
    }
}

export default Logger;
