import type { ChannelWarningMessages } from "../types";
import { Channels } from "../types";
const channelMaxChars = {
    facebook: 2000,
    instagram: 1000,
    whatsapp: 4096,
    sms: 320,
};
const getCharChannelSizesWarnings = (text: string): ChannelWarningMessages => {
    const warnings = {} as ChannelWarningMessages;
    Object.values(Channels).forEach((channel) => {
        warnings[channel] = {
            show: text.length >= channelMaxChars[channel],
            maxSize: channelMaxChars[channel],
        };
    });
    return warnings;
};

export default getCharChannelSizesWarnings;
