import { InputNumber, Select, Divider, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { EditWorkspaceConfigsProps } from "./Types";
import { useTranslation } from "react-i18next";
import useAgentsWithPaginator from "./useAgentsWithPaginator";
import LoadMore from "../../../components/LoadMore/LoadMore";

interface ChatAutoAssignConfig {
    backend: {
        automation: {
            autoAssignConversationConfig?: {
                enabled: boolean;
                maxConversationsPerAgent?: number;
                excludeAgentsId?: number[];
                secondsToReassign?: number;
                assignToAgents?: "ONLINE" | "ALL";
            };
        };
    };
}

enum RadioValues {
    OnlineAgents,
    AllAgents,
}

enum ConfigurationsStates {
    Custom = "custom",
    Sales = "sales",
    Support = "support",
}

function AutoAssignChatsSettings({
    configs,
    setConfig,
}: EditWorkspaceConfigsProps<ChatAutoAssignConfig>) {
    const enabled = configs.backend.automation.autoAssignConversationConfig?.enabled;
    const secondsToReassign =
        configs.backend.automation.autoAssignConversationConfig?.secondsToReassign;
    const maxConversationsPerAgent =
        configs.backend.automation.autoAssignConversationConfig?.maxConversationsPerAgent;
    const assignToAgents = configs.backend.automation.autoAssignConversationConfig?.assignToAgents!;

    const [agents, agentPaginatorHasNext, loadMoreAgents] = useAgentsWithPaginator();
    const { t } = useTranslation();
    const [selectedAgentsLabel, setSelectedAgentsLabel] = useState<string[]>();
    const [selectedConfigType, setSelectedConfigType] = useState<string>(
        ConfigurationsStates.Custom
    );

    function applySupportDefaultConfig() {
        const newConfigs = addAutoAssignConversationConfig(configs);
        newConfigs.backend.automation.autoAssignConversationConfig!.enabled = true;
        newConfigs.backend.automation.autoAssignConversationConfig!.excludeAgentsId = undefined;
        newConfigs.backend.automation.autoAssignConversationConfig!.maxConversationsPerAgent = 10;
        newConfigs.backend.automation.autoAssignConversationConfig!.secondsToReassign = 10 * 60;
        setConfig(newConfigs);
    }

    function applySalesDefaultConfig() {
        const newConfigs = addAutoAssignConversationConfig(configs);

        newConfigs.backend.automation.autoAssignConversationConfig!.enabled = true;
        newConfigs.backend.automation.autoAssignConversationConfig!.maxConversationsPerAgent =
            undefined;
        newConfigs.backend.automation.autoAssignConversationConfig!.secondsToReassign = undefined;
        newConfigs.backend.automation.autoAssignConversationConfig!.excludeAgentsId = [];

        setConfig(newConfigs);
    }

    function addAutoAssignConversationConfig(c: ChatAutoAssignConfig): ChatAutoAssignConfig {
        const newConfig = { ...c };
        if (newConfig.backend.automation.autoAssignConversationConfig === undefined) {
            newConfig.backend.automation.autoAssignConversationConfig = {
                enabled: false,
            };
        }
        return newConfig;
    }

    function onEnableAutoAssign(checked: boolean) {
        const newConfig = addAutoAssignConversationConfig(configs);
        newConfig.backend.automation.autoAssignConversationConfig!.enabled = checked;
        setConfig(newConfig);
    }

    function onChangeAssignToAgent(value: RadioValues) {
        const newConfig = addAutoAssignConversationConfig(configs);
        if (value === RadioValues.OnlineAgents) {
            newConfig.backend.automation.autoAssignConversationConfig!.assignToAgents = "ONLINE";
        } else {
            newConfig.backend.automation.autoAssignConversationConfig!.assignToAgents = "ALL";
        }
        setConfig(newConfig);
    }

    function onEnableSecondsToReassign(checked: boolean) {
        const newConfigs = addAutoAssignConversationConfig(configs);
        if (checked) {
            newConfigs.backend.automation.autoAssignConversationConfig!.secondsToReassign = 10 * 60;
        } else {
            newConfigs.backend.automation.autoAssignConversationConfig!.secondsToReassign =
                undefined;
        }
        setConfig(newConfigs);
    }

    function onChangeSecondsToReassign(valueMinutes: number) {
        const valueSeconds = valueMinutes * 60;
        const newConfigs = addAutoAssignConversationConfig(configs);
        newConfigs.backend.automation.autoAssignConversationConfig!.secondsToReassign =
            valueSeconds;
        setConfig(newConfigs);
    }

    function onEnableMaxConversationsPerAgent(checked: boolean) {
        const newConfigs = addAutoAssignConversationConfig(configs);
        if (checked) {
            newConfigs.backend.automation.autoAssignConversationConfig!.maxConversationsPerAgent = 5;
        } else {
            newConfigs.backend.automation.autoAssignConversationConfig!.maxConversationsPerAgent =
                undefined;
        }
        setConfig(newConfigs);
    }

    function onChangeMaxConversationsPerAgent(value: string | number | undefined) {
        if (typeof value !== "number") return;
        const newConfigs = addAutoAssignConversationConfig(configs);
        configs.backend.automation.autoAssignConversationConfig!.maxConversationsPerAgent = value;
        setConfig(newConfigs);
    }

    function onChangeSelectAgents(agentsLabel: any[]) {
        const selectedAgentsId = agentsLabel.map((a) => a.key);
        const newConfigs = addAutoAssignConversationConfig(configs);
        newConfigs.backend.automation.autoAssignConversationConfig!.excludeAgentsId =
            selectedAgentsId;
        setConfig(newConfigs);
    }

    function onChangeApplyConfiguration(config: string) {
        const selectedConfigState = config as ConfigurationsStates;
        setSelectedConfigType(config);
        switch (selectedConfigState) {
            case ConfigurationsStates.Support:
                applySupportDefaultConfig();
                break;
            case ConfigurationsStates.Sales:
                applySalesDefaultConfig();
                break;
            default:
        }
    }

    useEffect(() => {
        const newSelectedAgents =
            configs.backend.automation.autoAssignConversationConfig?.excludeAgentsId?.map((id) => {
                const agent = agents.find((a) => a.id === id);
                if (agent) {
                    return { key: id, label: `${agent.firstName} ${agent.lastName}` };
                }
                return undefined;
            });
        const definedAgents: string[] = newSelectedAgents?.filter((a) => a !== undefined) as any[];
        setSelectedAgentsLabel(definedAgents || []);
    }, [configs, agents]);

    return (
        <>
            <div style={{ display: "flex" }}>
                <h2 style={{ fontWeight: "bold" }}>{t("settings.autoAssignChats.title")}</h2>
                <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                    <Switch checked={enabled} onChange={onEnableAutoAssign} />
                </div>
            </div>

            <span style={{ fontSize: 14, color: "#727272" }}>
                {t("settings.autoAssignChats.description")}
            </span>
            {enabled && (
                <>
                    <div style={{ marginTop: "5px" }}>
                        <Select
                            onChange={onChangeApplyConfiguration}
                            disabled={!enabled}
                            value={selectedConfigType}
                            style={{ width: "100%" }}
                        >
                            {Object.keys(ConfigurationsStates).map((k) => (
                                <Select.Option key={k} value={k.toLowerCase()}>
                                    {t(`settings.autoAssignChats.defaultConfig.${k.toLowerCase()}`)}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <Divider />
                    <div style={{ display: "flex" }}>
                        <span style={{ fontWeight: "bold" }}>
                            {t("settings.autoAssignChats.reassignByTime")}
                        </span>
                        <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                            <Switch
                                checked={secondsToReassign !== undefined}
                                disabled={
                                    !enabled || selectedConfigType !== ConfigurationsStates.Custom
                                }
                                onChange={onEnableSecondsToReassign}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                        <span>{t("settings.autoAssignChats.timeToReassign")}</span>
                        <InputNumber
                            placeholder="0"
                            style={{ width: "100%" }}
                            min={1}
                            max={300}
                            disabled={
                                !enabled ||
                                secondsToReassign === undefined ||
                                selectedConfigType !== ConfigurationsStates.Custom
                            }
                            value={secondsToReassign ? secondsToReassign / 60 : 1}
                            onChange={(val) =>
                                val ? onChangeSecondsToReassign(Number(val)) : null
                            }
                        />
                    </div>
                    <Divider />
                    <div style={{ display: "flex" }}>
                        <span style={{ fontWeight: "bold" }}>
                            {t("settings.autoAssignChats.limitConvPerAgent")}
                        </span>
                        <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                            <Switch
                                checked={maxConversationsPerAgent !== undefined}
                                disabled={
                                    !enabled || selectedConfigType !== ConfigurationsStates.Custom
                                }
                                onChange={onEnableMaxConversationsPerAgent}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
                        <span>{t("settings.autoAssignChats.maxConvPerAgent")}</span>
                        <InputNumber
                            style={{ width: "100%" }}
                            min={1}
                            max={300}
                            disabled={
                                !enabled ||
                                maxConversationsPerAgent === undefined ||
                                selectedConfigType !== ConfigurationsStates.Custom
                            }
                            value={maxConversationsPerAgent}
                            onChange={onChangeMaxConversationsPerAgent}
                        />
                    </div>
                    <Divider />

                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ fontWeight: "bold" }}>
                            {t("settings.autoAssignChats.assignToAgents")}
                        </span>
                        <div style={{ marginTop: "5px" }}>
                            <Select
                                style={{ width: "100%" }}
                                onChange={onChangeAssignToAgent}
                                disabled={!enabled}
                                value={
                                    assignToAgents === "ALL"
                                        ? RadioValues.AllAgents
                                        : RadioValues.OnlineAgents
                                }
                            >
                                <Select.Option value={RadioValues.OnlineAgents}>
                                    {t("settings.autoAssignChats.onlineAgents")}
                                </Select.Option>
                                <Select.Option value={RadioValues.AllAgents}>
                                    {t("settings.autoAssignChats.allAgents")}
                                </Select.Option>
                            </Select>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <span>{t("settings.autoAssignChats.excludeAgents")}</span>
                            <Select
                                mode="multiple"
                                disabled={!enabled}
                                style={{ width: "100%" }}
                                onChange={onChangeSelectAgents}
                                value={selectedAgentsLabel}
                                showSearch={false}
                                labelInValue
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        {agentPaginatorHasNext && (
                                            <LoadMore
                                                onClick={loadMoreAgents}
                                                style={{ marginTop: "0px", marginBottom: "15px" }}
                                            />
                                        )}
                                    </>
                                )}
                            >
                                {agents.map((a) => (
                                    <Select.Option key={a.id} value={a.id}>
                                        {a.firstName} {a.lastName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default AutoAssignChatsSettings;
