import { CheckOutlined, WarningOutlined } from '@ant-design/icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import TitleTooltip from 'components/TitleTooltip/TitleTooltip';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import api from 'requests/api';
import ImportFromCsvModal from "./ImportFromCsvModal";


enum FileStatus {
    Processing = 'PROCESSING',
    Processed = 'PROCESSED',
    Error = 'ERROR',
}

interface CustomerImportFile {
    id: number,
    uploadedOn: string,
    updatedOn: string,
    state: FileStatus,
    name: string,
    workspaceId: number,
    filePath: string,
    processedFilePath?: string,
    countOk?: number,
    countError?: number,
    csvErrorKey?: string,
    lines?: number,
}



export default function CustomersImport() {
    const { t } = useTranslation();
    const [files, setFiles] = useState<CustomerImportFile[]>([]);
    const [isImportModalVisible, setIsImportModalVisible] = useState(false);
    const [reloadFiles, setReloadFiles] = useState(1); // Dummy value to force file reload

    const columns: ColumnsType<CustomerImportFile> = [
        {
            title: t('customers.import.filename'),
            dataIndex: 'name',
            key: 'name',
            width: '35%',
            fixed: 'left',
            render: text => text,
        },
        {
            title: t('customers.import.uploadedOn'),
            dataIndex: 'uploadedOn',
            key: 'uploadedOn',
            render: date => moment(date).format("DD/MM/YY"),
        },
        {
            title: t('customers.import.statusLabel'),
            dataIndex: 'status',
            key: 'status',
            render: (_, { state, csvErrorKey }) => {
                switch (state) {
                    case FileStatus.Processed:
                        return <Badge status={'success'} text={t(`customers.import.status.${FileStatus.Processed}`)} />
                    case FileStatus.Processing:
                        return <Badge status={'processing'} text={t(`customers.import.status.${FileStatus.Processing}`)} />
                    case FileStatus.Error:
                        return (
                            <>
                                <Badge status={'error'} text={t(`customers.import.status.${FileStatus.Error}`)} />
                                <TitleTooltip content={t(`customers.import.errors.${csvErrorKey}`)} />
                            </>
                        )
                    default:
                        throw new Error('not implemented')
                }
            }

        },
        {
            title: t('customers.import.results'),
            key: 'results',
            render: (_, record) => (
                <>
                    {record.countOk ? <Tag color={'green'}> <CheckOutlined />{record.countOk}</Tag> : ''}
                    {record.countError ? <Tag color={'red'}> <WarningOutlined />{record.countError}</Tag> : ''}
                </>
            ),
        },
        {
            title: t('customers.import.actions'),
            key: 'action',
            render: (_, record) => (
                <>
                    {record.filePath ?
                        <a href={`${api.api_url().get()}/customers/groups/import/${record.id}/?fileType=ORIGINAL`}> <FontAwesomeIcon icon={faDownload} />
                            {t('customers.import.original')}
                        </a>
                        : ''
                    }
                    {record.processedFilePath ?
                        <a href={`${api.api_url().get()}/customers/groups/import/${record.id}/?fileType=PROCESSED`}><br /><FontAwesomeIcon icon={faDownload} />
                            {t('customers.import.processed')}
                        </a>
                        : ''
                    }

                </>
            ),
        },
    ];

    useEffect(() => {
        async function loadFiles() {
            const response = await api.customerImportFile().list()
            const newFiles = response.data.files
            if (newFiles && newFiles.length) {
                setFiles(newFiles)
            }
        }
        loadFiles()
    }, [reloadFiles])

    useEffect(() => {
        const interval = setInterval(() => {
            setReloadFiles(r => r + 1)
        }, 30 * 1000);

        return () => clearInterval(interval);
    }, []);


    return (
        <div className="opt-view">
            <div className="opt-view-header">
                <h1 className="opt-title">
                    <NavLink to='/dashboard/customers/'>
                        ← {t('customers.title')}
                    </NavLink>
                </h1>
                <div className="opt-view-header__actions">
                    <Button
                        className="opt-button opt-button--light"
                        onClick={(e) => setIsImportModalVisible(true)}
                    >
                        {t("customers.importCsv")}
                    </Button>
                </div>
            </div>

            <h2><b>{t('customers.import.tableName')}</b></h2>

            <ImportFromCsvModal
                isModaVisible={isImportModalVisible}
                setModalVisibleCb={setIsImportModalVisible}
                onFailCb={() => { }}
                onSuccessCb={() => { setReloadFiles(r => r + 1) }}
            />
            <Table
                columns={columns}
                dataSource={files}
                pagination={false}
                scroll={{ x: 100 }}
            />
        </div>
    )
}