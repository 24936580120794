import React from "react";
import postLog from "./postLog";

export default class ErrorBoundaryWithLogger extends React.Component {
    constructor(props) {
        super(props);

        this.state = { error: null, errorInfo: null };
        this.badRenderBody = props.badRenderBody;
        this.renderErrorCb = props.renderErrorCb;
        this.serverUrl = props.serverUrl;

        this.errorLevel = props.errorLevel ? props.errorLevel : "ERROR";
        this.logName = props.logName ? props.logName : "Frontend";
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        const bodyToLog = {
            errorMessage: error.toString(),
            callStack: errorInfo.componentStack,
        };
        postLog(this.serverUrl, this.logName, this.errorLevel, JSON.stringify(bodyToLog));
        if (this.renderErrorCb) {
            this.renderErrorCb();
        }
    }

    render() {
        if (this.state.errorInfo) {
            return this.badRenderBody ? this.badRenderBody() : this.props.children;
        }
        return this.props.children;
    }
}
