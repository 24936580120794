import React, { useState, useEffect, useCallback } from "react";
import { Tag, Table, Collapse, Divider } from "antd";
import { Column } from "@ant-design/charts";
import { useTranslation } from "react-i18next";
import moment from "moment";

function AnswersReports({ selected, chartsRange, data }) {
    const { Panel } = Collapse;
    const { t } = useTranslation();
    const [totalDataSource, setTotalDataSource] = useState([]);
    const [tableColumns, setTableColumns] = useState([
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
    ]);
    const [positiveDataSource, setPositiveDataSource] = useState([]);
    const [negativeDataSource, setNegativeDataSource] = useState([]);
    const [totalLineChartData, setTotalLineChartData] = useState([]);
    const [negativeLineChartData, setNegativeLineChartData] = useState([]);
    const [positiveLineChartData, setPositiveLineChartData] = useState([]);

    const getAnswerTitleById = useCallback(
        (id) => {
            let currentAnswer = selected.find((answer) => parseInt(answer.id) === parseInt(id));
            if (currentAnswer && currentAnswer.title) {
                return currentAnswer.title;
            }
            return id;
        },
        [selected]
    );

    const getTotalTableCols = useCallback((colsRange) => {
        let newCols = [
            {
                title: "Name",
                dataIndex: "name",
                key: "name",
            },
        ];
        colsRange.forEach((date) => {
            newCols.push({
                title: date,
                dataIndex: "date" + date,
                key: "date" + date,
            });
        });
        return newCols;
    }, []);

    const getTotalTableRows = useCallback(
        (tableRowData, dataPointProp) => {
            let rows = [];
            if (!tableRowData.knowledgeRecordStats) {
                return [];
            }
            tableRowData.knowledgeRecordStats.forEach((answer) => {
                let rowToAdd = { name: getAnswerTitleById(answer.knowledgeRecordId) };
                chartsRange.forEach((rangeDate) => {
                    let dataPoint = answer.dataPoints.find(
                        (dataPoint) =>
                            moment(dataPoint.date).utc().format("DD/MM/YYYY") === rangeDate
                    );
                    if (dataPoint) {
                        rowToAdd["date" + rangeDate] = dataPoint[dataPointProp];
                    } else {
                        rowToAdd["date" + rangeDate] = "-";
                    }
                });
                rows.push(rowToAdd);
            });
            return rows;
        },
        [chartsRange, getAnswerTitleById]
    );

    let totalConfig = {
        data: totalLineChartData,
        xField: "date",
        yField: "total",
        seriesField: "name",
        isGroup: true,
    };
    let negativeConfig = {
        data: negativeLineChartData,
        xField: "date",
        yField: "total",
        seriesField: "name",
    };
    let positiveConfig = {
        data: positiveLineChartData,
        xField: "date",
        yField: "total",
        seriesField: "name",
    };

    const getLineChartData = useCallback(
        (dates, data, dataProp) => {
            if (!data.knowledgeRecordStats) {
                return;
            }
            let chartLines = [];
            // answer1{} answer2{}
            data.knowledgeRecordStats.forEach((answer) => {
                let lineToAdd = {
                    name: getAnswerTitleById(answer.knowledgeRecordId),
                    id: answer.knowledgeRecordId,
                };
                dates.forEach((date) => {
                    lineToAdd["date"] = date;
                    let dataPoint = answer.dataPoints.find(
                        (dataPoint) => moment(dataPoint.date).utc().format("DD/MM/YYYY") === date
                    );
                    if (dataPoint) {
                        lineToAdd["total"] = dataPoint[dataProp];
                    } else {
                        lineToAdd["total"] = 0;
                    }
                    chartLines.push({ ...lineToAdd });
                });
            });
            return chartLines;
        },
        [getAnswerTitleById]
    );

    useEffect(() => {
        let cols = getTotalTableCols(chartsRange);
        let totalRows = getTotalTableRows(data, "amountQuestionsReplied");
        let positiveRows = getTotalTableRows(data, "amountQuestionsRepliedWithPositiveFeedback");
        let negativeRows = getTotalTableRows(data, "amountQuestionsRepliedWithNegativeFeedback");

        let totalChartData = getLineChartData(chartsRange, data, "amountQuestionsReplied");
        let negativeChartData = getLineChartData(
            chartsRange,
            data,
            "amountQuestionsRepliedWithNegativeFeedback"
        );
        let positiveCHartData = getLineChartData(
            chartsRange,
            data,
            "amountQuestionsRepliedWithPositiveFeedback"
        );

        setTotalLineChartData(totalChartData);
        setNegativeLineChartData(negativeChartData);
        setPositiveLineChartData(positiveCHartData);

        setTableColumns(cols);
        setTotalDataSource(totalRows);
        setPositiveDataSource(positiveRows);
        setNegativeDataSource(negativeRows);
    }, [chartsRange, data, getTotalTableCols, getTotalTableRows, getLineChartData]);
    return (
        <>
            {selected.length ? (
                <p className="opt-title" style={{ textAlign: "left" }}>
                    <span style={{ marginRight: "6px" }}> {t("answerReports.answers")} </span>
                </p>
            ) : (
                <p className="opt-title" style={{ textAlign: "left" }}>
                    <span style={{ marginRight: "6px" }}> {t("answerReports.totalUses")} </span>
                </p>
            )}
            {selected.map((item) => (
                <Tag color="blue" key={item.id}>{item.title}</Tag>
            ))}
            <p className="opt-title" style={{ textAlign: "left", marginTop: "20px" }}>
                <span style={{ marginRight: "6px" }}> {t("answerReports.totalUses")} </span>
            </p>
            <Table
                columns={tableColumns}
                dataSource={totalDataSource}
                style={{ overflowY: "auto", maxHeight: "600px", overflowX: "auto" }}
                pagination={false}
            />
            <Collapse ghost>
                <Panel header={t("reports.viewChart")} key="1">
                    <Column {...totalConfig} />
                </Panel>
            </Collapse>
            <Divider />
            <p className="opt-title" style={{ textAlign: "left" }}>
                <span style={{ marginRight: "6px" }}>{t("answerReports.negativeFeedback")}</span>
            </p>
            <Table
                columns={tableColumns}
                dataSource={negativeDataSource}
                style={{ overflowY: "auto", maxHeight: "600px", overflowX: "auto" }}
                pagination={false}
            />
            <Collapse ghost>
                <Panel header={t("reports.viewChart")} key="1">
                    <Column {...negativeConfig} />
                </Panel>
            </Collapse>
            <Divider />
            <p className="opt-title" style={{ textAlign: "left" }}>
                <span style={{ marginRight: "6px" }}>{t("answerReports.positiveFeedback")} </span>
            </p>
            <Table
                columns={tableColumns}
                dataSource={positiveDataSource}
                style={{ overflowY: "auto", maxHeight: "600px", overflowX: "auto" }}
                pagination={false}
            />
            <Collapse ghost>
                <Panel header={t("reports.viewChart")} key="1">
                    <Column {...positiveConfig} />
                </Panel>
            </Collapse>
            <Divider />
        </>
    );
}
export default AnswersReports;
