import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faRobot } from "@fortawesome/free-solid-svg-icons";
import "./AgentData.scss";
import { useTranslation } from "react-i18next";

export default function AgentData(props) {
    const { t } = useTranslation();
    useEffect(() => {
        console.log(props);
    }, [props]);
    return (
        <div className="agent-box">
            {props.agents && props.agents.length > 0 && (
                <>
                    {props.agents.map((agent) => {
                        return (
                            <>
                                {agent.type === "ASSISTANT" && (
                                    <div className="agent-box__data">
                                        <div className="profile-box">
                                            <div className="profile-box__avatar">
                                                <div className="profile-box__icon-wrapper">
                                                    <FontAwesomeIcon
                                                        icon={faUser}
                                                        className="profile-box__icon"
                                                    />
                                                </div>
                                                <div className="profile-box__info">
                                                    <p className="opt-title">
                                                        {agent.assistantUser.email}
                                                    </p>
                                                    <p className="opt-label">
                                                        {agent.assistantUser.firstName &&
                                                            agent.assistantUser.firstName + " "}
                                                        {agent.assistantUser.lastName &&
                                                            agent.assistantUser.lastName}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {agent.type === "OPTIWE_CHAT_BOT" && (
                                    <div className="agent-box__data">
                                        <div className="profile-box">
                                            <div className="profile-box__avatar">
                                                <div className="profile-box__icon-wrapper">
                                                    <FontAwesomeIcon
                                                        icon={faRobot}
                                                        className="profile-box__icon"
                                                    />
                                                </div>
                                                <div className="profile-box__info">
                                                    <p className="opt-title">
                                                        {t("agentData.websiteBot.title")}
                                                    </p>
                                                    <p className="opt-label">
                                                        {t("agentData.websiteBot.description")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        );
                    })}
                </>
            )}
        </div>
    );
}
