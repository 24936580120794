import React, { useState, useEffect } from "react";
import api from "../../../requests/api";
import { Table, Card, Space, Button, Modal, message, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../components/Loader/Loader";
import { useTranslation } from "react-i18next";
import TagsGroups from "./TagsGroups";

export default function Files() {
    const { t } = useTranslation();
    const [tags, setTags] = useState([]);
    const [tagInput, setTagInput] = useState("");
    const [page, setPage] = useState(0);
    const [hasNext, setHasNext] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editModalId, setEditModalId] = useState(null);
    const [isGroupsModalVisible, setIsGroupsModalVisible] = useState(false);
    const columns = [
        {
            title: "Name",
            dataIndex: "value",
            key: 1,
        },
        {
            title: "Actions",
            fixed: "right",
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => handleEditModal(record)}> {t("update")} </Button>
                    <Button danger onClick={() => handleDelete(record)}>
                        {t("delete")}
                    </Button>
                </Space>
            ),
        },
    ];
    function loadMoreItems() {
        setIsLoading(true);
        setPage(page + 1);
        // Api no paginada, esto no está en uso
        api.files()
            .tags(page)
            .then((res) => {
                setTags([...tags, ...res.data.conversationLabels]);
                setHasNext(res.data.paginator.hasNext);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                message.error(err.response?.data);
            });
    }
    function updateTagFromlist(id, value) {
        let updatedTags = tags.map((tag) => {
            if (tag.id === id) {
                tag.value = value;
            }
            return tag;
        });
        setTags(updatedTags);
    }
    function loadItems() {
        setIsLoading(true);
        api.tags()
            .list(0)
            .then((res) => {
                setTagInput("");
                setIsLoading(false);
                setTags(res.data.conversationLabels);
                // setHasNext(res.data.paginator.hasNext);
            })
            .catch((err) => {
                setIsLoading(false);
                message.error(err.response?.data);
            });
    }
    function handleError(key) {
        let msg = t("conversationTag.defaultCreateConversationLabelError");
        if (key === "CONVERSATION_LABEL_ALREADY_EXISTS") {
            msg = t("conversationTag.createConversationTagExistError");
        }
        message.error(msg);
        setIsLoading(false);
    }
    function onOk(selected) {
        if (!tagInput) {
            message.error("Name cannot be empty");
            return;
        }

        setIsLoading(true);
        let tag = { value: tagInput };
        if (editModalId) {
            api.tags()
                .update(editModalId, tagInput)
                .then((res) => {
                    updateTagFromlist(editModalId, tagInput);
                    setIsModalOpen(false);
                    setIsLoading(false);
                })
                .catch((err) => {
                    handleError(err.response?.data?.error?.key);
                });
        } else {
            api.tags()
                .create(tag)
                .then((res) => {
                    const updatedTags = [...tags];
                    updatedTags.push(res.data);
                    setTags(updatedTags);
                    setIsModalOpen(false);
                    setIsLoading(false);
                })
                .catch((err) => {
                    handleError(err.response?.data?.error?.key);
                });
        }
    }
    function onCancel() {
        setIsModalOpen(false);
    }
    function deleteTagFromList(item) {
        let updatedTags = tags.filter((tag) => tag.id !== item.id);
        setTags(updatedTags);
    }
    function handleDelete(item) {
        setIsLoading(true);
        api.tags()
            .delete(item.id)
            .then((res) => {
                setIsLoading(false);
                deleteTagFromList(item);
            })
            .catch((err) => {
                message.error(err.response.data?.error?.message);
                setIsLoading(false);
            });
    }
    function handleCreateModal() {
        setTagInput("");
        setIsModalOpen(true);
        setEditModalId(null);
    }

    function handleEditModal(record) {
        setEditModalId(record.id);
        setIsModalOpen(true);
        setTagInput(record.value);
    }
    useEffect(() => {
        loadItems();
    }, []);
    return (
        <div className="opt-view opt-view--files">
            <Loader isLoading={isLoading} />
            <div className="opt-view-header">
                <h1 className="opt-title">{t("conversationTag.title")}</h1>
                <div className="opt-view-header__actions">
                    <button
                        className="opt-button opt-button--light"
                        onClick={() => setIsGroupsModalVisible(true)}
                    >
                        {" "}
                        {t("conversationTag.groups")}{" "}
                    </button>
                    <div className="filter">
                        <Button className={"opt-button"} onClick={handleCreateModal}>
                            {" "}
                            {t("create")}{" "}
                        </Button>
                    </div>
                </div>
            </div>
            <Card bodyStyle={{ padding: 0 }}>
                <Table
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={tags}
                    pagination={false}
                />
                {hasNext && (
                    <div className="view-more" style={{ margin: "20px" }} onClick={loadMoreItems}>
                        <span className="view-more__text">
                            {t("viewMore")} <FontAwesomeIcon icon={faChevronDown} />
                        </span>
                    </div>
                )}
            </Card>
            <Modal
                okText={editModalId ? t("update") : t("create")}
                title={editModalId ? t("conversationTag.edit") : t("conversationTag.create")}
                visible={isModalOpen}
                onCancel={onCancel}
                onOk={onOk}
            >
                <p>{editModalId ? t() : t("name")}</p>
                <Input
                    placeholder={t("conversationTag.conversationTagInputPlaceholder")}
                    value={tagInput}
                    onChange={(e) => setTagInput(e.target.value)}
                />
            </Modal>
            <TagsGroups
                isModalVisible={isGroupsModalVisible}
                isModalVisibleCb={setIsGroupsModalVisible}
            />
        </div>
    );
}
