import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import ProfileData from "./Info/ProfileData";
import AgentData from "./Info/AgentData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { Button, Spin, Tag, message } from "antd";
import "./Monitoring.scss";
import api from "../../../requests/api";
import { useTranslation } from "react-i18next";
import ChatHistory from "./ChatHistory";

const agentUrl = process.env?.REACT_APP_AGENT_URL;

function Monitoring(props) {
    const { t } = useTranslation();
    const { chatId } = useParams();
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    const [redirectBack, setRedirectBack] = useState(false);
    const [conversationTags, setConverstionTags] = useState([]);
    const [data, setData] = useState({
        conversation: {
            messages: [],
            agents: [],
        },
    });

    const fetchConversation = async (chatId) => {
        setIsLoading(true);

        try {
            const promiseTags = api.tags().listByConversation(chatId);
            const promiseConv = api.monitoring().getConversation(chatId);
            const [resTags, resConv] = await Promise.all([promiseTags, promiseConv]);

            const labels = resTags.data.conversationsLabeler;

            setData(resConv.data);
            setConverstionTags(labels);
        } catch {
            message.error(t("unknownError"));
        } finally {
            setIsLoading(false);
        }
    };

    /**
     *
     * @param {JSX.Element} children
     * @returns  {JSX.Element}
     */
    const wrapInSpinLoading = (children) => {
        if (isLoading) {
            return <Spin>{children}</Spin>;
        }
        return children;
    };

    function goBack() {
        setRedirectBack(true);
    }

    useEffect(() => {
        fetchConversation(chatId);
    }, []);

    if (redirectBack) {
        return (
            <Redirect
                to={{
                    pathname: "/dashboard/monitoring",
                    state: { filters: true },
                }}
            />
        );
    }

    return (
        <div className="opt-view opt-view--monitoring">
            <h1 className="opt-title opt-title--link" onClick={goBack}>
                ← {t("monitoringList.title")}
            </h1>
            <div className="chat-history-row">
                <div className="chat-history-col">
                    <h2 className="opt-subtitle">{t("monitoring.historyLabel")}</h2>
                    <div className="opt-card chat-history-wrapper">
                        <ChatHistory
                            chat={data.conversation.messages.reverse()}
                            selectChatCb={() => {}}
                        />
                    </div>
                </div>
                <div className="filters-row">
                    <div className="filters-col">
                        <h2 className="opt-subtitle">{t("monitoring.informationLabel")}</h2>
                        <div className="opt-card">
                            {wrapInSpinLoading(
                                <>
                                    <ProfileData
                                        customer={data.conversation.customer}
                                        conversationData={{
                                            createdOn: data.conversation.createdOn,
                                            updatedOn: data.conversation.updatedOn,
                                        }}
                                    />
                                    <div className="profile-box profile-box--tags">
                                        <div className="profile-box__avatar">
                                            <div className="profile-box__icon-wrapper">
                                                <FontAwesomeIcon
                                                    icon={faTags}
                                                    className="profile-box__icon"
                                                />
                                            </div>
                                            <div className="profile-box__info">
                                                <p className="opt-title">
                                                    {t("monitoring.conversationTagsLabel")}
                                                </p>
                                                <p className="opt-label">
                                                    {conversationTags.length > 0 ? (
                                                        conversationTags.map((tag) => (
                                                            <Tag>
                                                                {" "}
                                                                {tag.conversationLabel.value}{" "}
                                                            </Tag>
                                                        ))
                                                    ) : (
                                                        <p>-</p>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {wrapInSpinLoading(
                        <>
                            {data.conversation.agents && data.conversation.agents.length > 0 && (
                                <div className="filters-col">
                                    <h2 className="opt-subtitle">
                                        {t("monitoringList.agentsTitle")}
                                    </h2>
                                    <div className="opt-card">
                                        <AgentData agents={data.conversation.agents} />
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
export default Monitoring;
