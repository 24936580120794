import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Select, message } from "antd";
import CrudManagerButton from "components/CrudManager/CrudManagerButton";
import LoadMore from "components/LoadMore/LoadMore";
import Loader from "components/Loader/Loader";
import TitleTooltip from "components/TitleTooltip/TitleTooltip";
import notFoundImg from "img/question-not-found.png";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useTranslation } from "react-i18next";
import { NavLink, Redirect } from "react-router-dom";
import api from "requests/api";
import "./Knowledge.scss";
import KnowledgeBox from "./KnowledgeBox/KnowledgeBox";
const { Search } = Input;

function Knowledge() {
    const { t } = useTranslation();
    const [emptyMessage, setEmptyMessage] = useState(t("knowledge.noData"));
    const [isLoading, setIsLoading] = useState(false);
    const [editView, setEditView] = useState(false);
    const [page, setPage] = useState(0);
    const [nextPage, setNextPage] = useState(false);
    const [knowledgeList, setKnowledgeList] = useState([]);
    const [tags, setTags] = useState([]);
    const [isLoadingTags, setIsLoadingTags] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);
    const [searchTagsId, setSearchTagsId] = useState([]);
    const [chatbots, setChatbots] = useState([]);
    const [searchKeywords, setSearchKeywords] = useState([]);
    const [baseChatbotId, setBaseChatbotId] = useState();
    const [userId, setUserId] = useState();
    const [users, setUsers] = useState([]);
    const [usersPaginatorPage, setUsersPaginatorPage] = useState(0);
    const [usersPaginatorHasNext, setUsersPaginatorHasNext] = useState(false);

    const columns = [
        {
            title: "name",
            dataIndex: "value",
            key: "value",
        },
    ];

    const deleteKnowledge = function (knowledge) {
        api.knowledge()
            .delete(knowledge.id)
            .then((response) => {
                setKnowledgeList(
                    knowledgeList.filter((kr) => {
                        return Boolean(kr.id !== knowledge.id);
                    })
                );
            })
            .catch((error) => {
                console.log(error.response.data);
                if (error.response.data.error.key === "BOT_USING_KNOWLEDGE_RECORD") {
                    message.error(t("knowledge.BOT_USING_KNOWLEDGE_RECORD"));
                } else {
                    message.error(error.response?.data?.error?.message);
                }
            });
    };

    const deleteKnowledgeConfirm = function (knowledge) {
        confirmAlert({
            title: t("confirmDelete"),
            message: t("confirmDeleteText"),
            buttons: [
                {
                    label: t("yes"),
                    onClick: () => deleteKnowledge(knowledge),
                },
                {
                    label: t("no"),
                    onClick: () => console.log("Cancel"),
                },
            ],
        });
    };

    const editKnowledge = function (knowledgeId) {
        setEditView(knowledgeId);
    };

    const parseKnowledge = function (list) {
        return list.map((knowledge, index) => {
            const summary = knowledge.answer.summary
                ? knowledge.answer.summary
                : knowledge.answer.text
                    ? knowledge.answer?.text?.slice(0, 200) + " ..."
                    : "-";
            const title = knowledge.title ? knowledge.title : knowledge.questions[0].text;
            return {
                id: knowledge.id,
                title: title,
                answer: { summary: summary },
            };
        });
    };

    const loadItems = function (loadsItemsInPage) {
        loadsItemsInPage = loadsItemsInPage ? loadsItemsInPage : page
        setIsLoading(true);
        api.knowledge()
            .list(loadsItemsInPage, 32, searchTagsId, null, searchKeywords, baseChatbotId, userId)
            .then((response) => {
                let krs = response.data.knowledgeRecords;
                let mapped_krs = parseKnowledge(krs);
                if (mapped_krs.length === 0) {
                    setEmptyMessage(t("knowledge.noDataFilter"));
                }
                setNextPage(response.data.paginator.hasNext);
                if (loadsItemsInPage > 0) {
                    setKnowledgeList([...knowledgeList, ...mapped_krs]);
                } else {
                    setKnowledgeList([...mapped_krs]);
                }
            })
            .then(() => setIsLoading(false));
    };

    const loadMoreItems = function () {
        setPage(page + 1);
    };

    function showKnowledgeTagForm() {
        if (selectedTag) {
            return (
                <>
                    <label>{t("knowledge.createNewKnowledgeTagTitle")}</label>
                    <Input
                        value={selectedTag.value}
                        onChange={handleSelectedKnowledgeTagValue}
                        placeholder={t("knowledgeTagForm.knowledgeTagPlaceholder")}
                    />
                </>
            );
        }
        return null;
    }

    function closeKnowledgeTag() {
        setSelectedTag(null);
    }

    function saveKnowledgeTag() {
        if (!selectedTag.value) {
            message.error(t("knowledgeTagForm.knowledgeTagEmpty"));
            return;
        }
        setIsLoadingTags(true);
        api.knowledge()
            .createTag(selectedTag.value)
            .then((response) => {
                setTags([...tags, response.data]);
                setIsLoadingTags(false);
                setSelectedTag(null);
                message.success(t("knowledgeTagForm.knowledgeTagCreated"));
            })
            .catch((err) => {
                setIsLoadingTags(false);
                setSelectedTag(null);
                console.log(err?.response?.data?.error?.key);
                message.error(t("knowledgeTagForm.knowledgeTagError"));
            });
    }

    function handleSelectedKnowledgeTagValue(e) {
        const selected = { ...selectedTag };
        selected.value = e.target.value;
        setSelectedTag(selected);
    }

    function showCreateKnowledgeTagForm() {
        setSelectedTag({ value: "" });
    }

    function deleteKnowledgeTag(id) {
        setIsLoadingTags(true);
        api.knowledge()
            .deleteTag(id)
            .then(() => {
                setIsLoadingTags(false);
                const newList = tags.filter((tag) => tag.id !== id);
                setTags(newList);
            })
            .catch((error) => {
                setIsLoadingTags(false);
                if (error.response.data.error.key === "BOT_USING_KNOWLEDGE_RECORD_TAG") {
                    message.error(t("knowledgeTagForm.BOT_USING_KNOWLEDGE_RECORD_TAG"));
                } else {
                    message.error(error.response?.data?.error?.message);
                }
            });
    }

    useEffect(() => {
        setIsLoading(true);
        const promises = []
        let promise = api.knowledge()
            .getTags()
            .then((response) => {
                let tags = response.data.tags;
                setTags(tags);
            });
        promises.push(promise);

        promise = api.chatbots()
            .list()
            .then((res) => {
                setChatbots(res.data.chatBots);
            });
        promises.push(promise);

        promise = api
            .users()
            .list(usersPaginatorPage, 32)
            .then((res) => {
                setUsers([...res.data.users]);
                setUsersPaginatorHasNext(res.data.paginator.hasNext);
            });
        promises.push(promise);

        Promise.all(promises).then(() => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (page !== null) {
            loadItems();
        }
    }, [page]);

    useEffect(() => {
        setPage(0);
        loadItems(0);
    }, [searchTagsId, searchKeywords, baseChatbotId, userId]);

    useEffect(() => {
        if (usersPaginatorPage === 0) {
            return
        }

        setIsLoading(true);
        api.users()
            .list(usersPaginatorPage, 32)
            .then((res) => {
                const newUsers = [...users, ...res.data.users];
                setUsers(newUsers);
                setUsersPaginatorHasNext(res.data.paginator.hasNext);
                setIsLoading(false);
            });
    }, [usersPaginatorPage]);

    if (editView) {
        return (
            <Redirect
                to={{
                    pathname: "/dashboard/knowledge/form",
                    state: { edit: editView },
                }}
            />
        );
    }

    return (
        <div className="opt-view opt-view--knowledge">
            <Loader isLoading={isLoading} />
            <div className="opt-view-header">
                <div className="opt-view-header__title-wrapper">
                    <h1 className="opt-title">
                        {t("knowledge.answers")}
                        <TitleTooltip content={t("knowledge.titleTooltip")} />
                    </h1>
                    <div className="opt-view-filters">
                        <div className="opt-view-tags-filter">
                            <Select
                                mode="tags"
                                placeholder={t("knowledge.keywords")}
                                onChange={(values) => setSearchKeywords(values)}
                            >
                                {searchKeywords.map((searchKeyword, index) => {
                                    return (
                                        <Select.Option
                                            key={searchKeyword}
                                            value={searchKeyword}
                                        >
                                            {searchKeyword}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="opt-view-tags-filter">
                            <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder={t("knowledge.tags")}
                                onChange={(tagsId) => setSearchTagsId(tagsId)}
                            >
                                {tags.map((tag, index) => {
                                    return (
                                        <Select.Option key={index} value={tag.id}>
                                            {tag.value}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <div className="opt-view-tags-filter">
                            <Select
                                style={{ width: "100%" }}
                                placeholder={t("knowledge.chatbots")}
                                onChange={(value) => setBaseChatbotId(Number(value))}
                                options={chatbots.map((chatbot) => {
                                    return { value: chatbot.id, label: chatbot.name };
                                })}
                                allowClear
                            />
                        </div>
                        <div className="opt-view-tags-filter">
                            <Select
                                style={{ width: "100%" }}
                                placeholder={t("knowledge.users")}
                                onChange={(value) => setUserId(Number(value))}
                                options={users.map((user) => {
                                    return { value: user.id, label: user.email };
                                })}
                                allowClear
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        {usersPaginatorHasNext && (
                                            <LoadMore
                                                onClick={() => setUsersPaginatorPage((usersPaginatorPage) => usersPaginatorPage + 1)}
                                                style={{
                                                    marginTop: "0px",
                                                    marginBottom: "15px",
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="opt-view-header__actions">
                    <button className="opt-button">
                        <NavLink to="/dashboard/knowledge/form">
                            {t("knowledge.addNewAnswer")}
                        </NavLink>
                    </button>
                    <CrudManagerButton
                        light
                        buttonText={t("knowledgeTagForm.admin")}
                        modalTitle={t("knowledgeTagForm.knowledgeTags")}
                        isLoading={isLoadingTags}
                        dataSource={tags}
                        columns={columns}
                        formContent={showKnowledgeTagForm}
                        closeCb={closeKnowledgeTag}
                        saveCb={saveKnowledgeTag}
                        createCb={showCreateKnowledgeTagForm}
                        deleteCb={(id) => deleteKnowledgeTag(id)}
                    />
                </div>
            </div>
            {knowledgeList.length === 0 && (
                <div className="opt-view opt-view--not-found">
                    <div className="not-found">
                        <img src={notFoundImg} alt="Not found" />
                        <span className="not-found-text">{emptyMessage}</span>
                    </div>
                </div>
            )}
            <div className="knowledge-wrapper">
                {knowledgeList.map((knowledge, index) => (
                    <KnowledgeBox
                        key={index}
                        title={knowledge.title}
                        text={knowledge.answer.summary}
                        knowledgeId={knowledge.id}
                        deleteCb={() => deleteKnowledgeConfirm(knowledge)}
                        editCb={() => editKnowledge(knowledge.id)}
                    />
                ))}
            </div>
            {nextPage && (
                <div className="view-more" onClick={loadMoreItems}>
                    <span className="view-more__text">
                        {t("viewMore")}
                        <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                </div>
            )}
        </div>
    );
}
export default Knowledge;
