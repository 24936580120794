import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import api from "../../../requests/api";
import { AppContext } from "../../../components/AppContext";
import "./Settings.scss";
import Loader from "../../../components/Loader/Loader";
import TitleTooltip from "../../../components/TitleTooltip/TitleTooltip";
import InputColor from "react-input-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UploadOutlined } from "@ant-design/icons";
import {
    faCommentDots,
    faChevronRight,
    faComments,
    faTimes,
    faSms,
    faAnchor,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp, faFacebookMessenger, faInstagram } from "@fortawesome/free-brands-svg-icons";
import moment from "moment";
import {
    Tabs,
    Checkbox,
    Select,
    TimePicker,
    InputNumber,
    Input,
    Slider,
    Button,
    Upload,
    message,
    Col,
    Row,
    Card,
    Switch,
    Popover,
    Typography,
} from "antd";
import CustomerFieldsManager from "./CustomerFieldsManager";
import { useTranslation } from "react-i18next";
import i18n from "../../../lang/i18n";
import "moment/locale/es";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import esES from "antd/lib/locale/es_ES";

import { default as deepmerge } from "deepmerge";
import AgentSettingsManager from "./AgentSettingsManager";
import ErrorMailSettings from "./ErrorMailSettings";
import ConversationNotificationEmailSettings from "./ConversationNotificationEmailSettings";
import AgentSocketManager, { socketConfigIsEmpty } from "./AgentSocketManager";
import AutoAssignChatsSettings from "./AutoAssignSettings";
import IntegrationsSettings from "./IntegrationsSettings";
import TimeToleranceAfterConversationClosedSettings from "./TimeToleranceAfterConversationClosed.tsx";
import LLMBotSettings from "./LLMBot.tsx";
import { initialConfigState } from "./initialState";
import PushNotificationSettings from "./PushNotificationSettings";

const { RangePicker } = TimePicker;
const { Option } = Select;
const { TabPane } = Tabs;
const apiUrl = process.env.REACT_APP_API_URL;
const { Text } = Typography;

function Settings() {
    const { t } = useTranslation();
    const appContext = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [uploadedChatIcon, setUploadedChatIcon] = useState([]);
    const [antdLang, setAntdLang] = useState(enUS);
    const [config, setConfig] = useState(initialConfigState);
    const [isValidJson, setIsValidJson] = useState(true);
    const [saveButtonVisible, setSaveButtonVisible] = useState(true);
    const integrationsTabKey = "10";
    const whatsappHandoverOnReferral = useMemo(() => {
        const enabled = config.backend?.automation?.whatsapp?.handoverOnReferral;
        return enabled;
    }, [config]);
    const webhooksUrl = useMemo(() => {
        const enabled = config.backend?.webhooks?.url;
        return enabled;
    }, [config]);
    const webhooksHeaders = useMemo(() => {
        const enabled = config.backend?.webhooks?.headers;
        return enabled;
    }, [config]);
    const newConversationWebhook = useMemo(() => {
        const enabled = config.backend?.webhooks?.events?.conversation?.newConversation;
        return enabled;
    }, [config]);
    const conversationUpdatedWebhook = useMemo(() => {
        const enabled = config.backend?.webhooks?.events?.conversation?.conversationUpdated;
        return enabled;
    }, [config]);
    const gupshupSentWebhook = useMemo(() => {
        const enabled = config.backend?.webhooks?.events?.whatsapp?.sent;
        return enabled;
    }, [config]);
    const gupshupReadWebhook = useMemo(() => {
        const enabled = config.backend?.webhooks?.events?.whatsapp?.read;
        return enabled;
    }, [config]);
    const gupshupFailedWebhook = useMemo(() => {
        const enabled = config.backend?.webhooks?.events?.whatsapp?.failed;
        return enabled;
    }, [config]);

    const whatsappCampaignOnFinish = useMemo(() => {
        const enabled = config.backend?.webhooks?.events?.campaign?.finish;
        return enabled;
    }, [config]);

    const listDays = [
        {
            value: 0,
            label: t("days.monday"),
        },
        {
            value: 1,
            label: t("days.tuesday"),
        },
        {
            value: 2,
            label: t("days.wednesday"),
        },
        {
            value: 3,
            label: t("days.thursday"),
        },
        {
            value: 4,
            label: t("days.friday"),
        },
        {
            value: 5,
            label: t("days.saturday"),
        },
        {
            value: 6,
            label: t("days.sunday"),
        },
    ];

    const callback = function (key) {
        if (key === integrationsTabKey) {
            setSaveButtonVisible(false);
        } else {
            setSaveButtonVisible(true);
        }
    };

    const changeLanguage = function (language) {
        setConfig({ ...config, language: language });
    };

    const changeColor = function (color, key) {
        setConfig({
            ...config,
            embeddedStyles: {
                ...config.embeddedStyles,
                [key]: { ...config.embeddedStyles[key], backgroundColor: color },
            },
        });
    };

    const changeFontSize = function (value) {
        setConfig({
            ...config,
            embeddedStyles: {
                ...config.embeddedStyles,
                openChatIconFont: {
                    fontSize: value + "px",
                },
                whatsappIcon: {
                    fontSize: value + "px",
                },
            },
        });
    };

    const changeButtonSize = function (value) {
        setConfig((state) => {
            const newState = JSON.parse(JSON.stringify(state));
            newState.embeddedStyles.openChatIcon["minWidth"] = value + "px";
            newState.embeddedStyles.openChatIcon["minHeight"] = value + "px";
            newState.embeddedStyles.whatsappButton["minWidth"] = value + "px";
            newState.embeddedStyles.whatsappButton["minHeight"] = value + "px";
            newState.embeddedStyles.collapsibleButton["minWidth"] = value + "px";
            newState.embeddedStyles.collapsibleButton["minHeight"] = value + "px";
            return newState;
        });
    };

    const changeMargin = function (value) {
        setConfig((state) => {
            const newState = JSON.parse(JSON.stringify(state));
            newState.embeddedStyles.openChatIcon.marginLeft = value + "px";
            return newState;
        });
    };

    const handleChatClientConfig = function (key, value) {
        setConfig((oldConfig) => {
            const newConfig = { ...oldConfig };
            newConfig.chatClient[key] = value;
            return newConfig;
        });
    };

    const changeColorChat = function (color, cssProp, key) {
        const newConfig = { ...config };
        newConfig.customStyles.bubbles[key] = {
            ...newConfig.customStyles.bubbles[key],
            [cssProp]: color,
        };
        setConfig(newConfig);
    };

    const changeChannelHours = function ([startValue, endValue], channel, type) {
        const newConfig = { ...config };
        newConfig.backend.automation[channel][type].from = startValue.format("HH:mm:ss");
        newConfig.backend.automation[channel][type].to = endValue.format("HH:mm:ss");
        setConfig(newConfig);
    };

    const selectDays = function (values, channel, type) {
        const newConfig = { ...config };
        newConfig.backend.automation[channel][type].days = values;
        setConfig(newConfig);
    };

    const changeTimezone = function (value, channel, type) {
        const newConfig = { ...config };
        newConfig.backend.automation[channel][type].tz = value;
        setConfig(newConfig);
    };

    const getDefaultDate = function (channel, type) {
        const from = config.backend.automation[channel][type].from.split(":");
        const to = config.backend.automation[channel][type].to.split(":");
        const hours = [
            moment().set({ hour: from[0], minute: from[1], second: from[2] }),
            moment().set({ hour: to[0], minute: to[1], second: to[2] }),
        ];
        return hours;
    };

    const saveChanges = function () {
        setIsLoading(true);

        if (socketConfigIsEmpty(config)) {
            message.error(t("settings.agentSettings.emptySocketConfigError"));
            setIsLoading(false);
            return;
        }

        let copiedConfig = { ...config };

        if (copiedConfig.backend?.llmBot?.openAiApiKey) {
            delete copiedConfig.backend.llmBot.openAiApiKey;
        }

        if (!isValidJson) {
            message.error(t("settings.webhooks.headersFormatError"));
            setIsLoading(false);
            return;
        }

        api.workspaces()
            .updateConfig(appContext.state.workspaceId, copiedConfig, true)
            .then((response) => {})
            .catch((err) => {
                console.log(err);
                message.error(t("settings.saveError"));
            })
            .then(function () {
                if (copiedConfig.language === "SPANISH") {
                    i18n.changeLanguage("es");
                    moment.locale("es");
                    setAntdLang(esES);
                }
                if (copiedConfig.language === "ENGLISH") {
                    i18n.changeLanguage("en");
                    setAntdLang(enUS);
                }
                setIsLoading(false);
                message.success(t("settings.saveSuccess"));
            });
    };

    const setWelcomeMessage = function (val, channel) {
        const newConfig = { ...config };
        if (newConfig.backend?.automation[channel]["humanAgent"]) {
            newConfig.backend.automation[channel]["humanAgent"].welcomeMessage = val;
        }
        setConfig(newConfig);
    };

    const setAbsentMessage = function (val, channel) {
        const newConfig = { ...config };
        if (newConfig.backend?.automation[channel]["humanAgent"]) {
            newConfig.backend.automation[channel]["humanAgent"].absentMessage = val;
        }
        setConfig(newConfig);
    };

    const setWebhooksUrl = function (val) {
        const newConfig = { ...config };
        if (newConfig?.backend?.webhooks) {
            newConfig.backend.webhooks.url = val;
        }
        setConfig(newConfig);
    };

    function checkJSON(input) {
        if (input.length === 0) {
            return setIsValidJson(true);
        } else {
            try {
                const headerInput = JSON.parse(input);
                if (typeof headerInput !== "object" || headerInput === null) {
                    return setIsValidJson(false);
                }
                for (let key in headerInput) {
                    if (typeof headerInput[key] !== "string") {
                        return setIsValidJson(false);
                    }
                }
                return setIsValidJson(true);
            } catch (error) {
                return setIsValidJson(false);
            }
        }
    }

    const setWebhooksHeaders = function (val) {
        checkJSON(val);
        const newConfig = { ...config };
        if (newConfig?.backend?.webhooks) {
            newConfig.backend.webhooks.headers = val;
        }
        setConfig(newConfig);
    };

    const onChangeNewConversationEventsSetting = function (checked) {
        const newConfig = { ...config };
        if (newConfig.backend?.webhooks?.events?.conversation) {
            newConfig.backend.webhooks.events.conversation.newConversation = checked;
        }
        setConfig(newConfig);
    };

    const onChangeConversationUpdatedEventsSetting = function (checked) {
        const newConfig = { ...config };
        if (newConfig.backend?.webhooks?.events?.conversation) {
            newConfig.backend.webhooks.events.conversation.conversationUpdated = checked;
        }
        setConfig(newConfig);
    };

    const onChangeGupshupSentEventsSetting = function (checked) {
        const newConfig = { ...config };
        if (newConfig.backend?.webhooks?.events?.whatsapp) {
            newConfig.backend.webhooks.events.whatsapp.sent = checked;
        }
        setConfig(newConfig);
    };

    const onChangeGupshupReadEventsSetting = function (checked) {
        const newConfig = { ...config };
        if (newConfig.backend?.webhooks?.events?.whatsapp) {
            newConfig.backend.webhooks.events.whatsapp.read = checked;
        }
        setConfig(newConfig);
    };

    const onChangeGupshupFailedEventsSetting = function (checked) {
        const newConfig = { ...config };
        if (newConfig.backend?.webhooks?.events?.whatsapp) {
            newConfig.backend.webhooks.events.whatsapp.failed = checked;
        }
        setConfig(newConfig);
    };

    const onChangeCampaignOnFinishSetting = (checked) => {
        const newConfig = { ...config };

        let defaultConfig = {
            backend: {
                webhooks: {
                    events: {
                        campaign: {
                            finish: checked,
                        },
                    },
                },
            },
        };
        const mergedConfigs = deepmerge(newConfig, defaultConfig);

        setConfig(mergedConfigs);
    };

    const getExternalButtonStyles = function (icon) {
        return {
            ...config.embeddedStyles[icon],
            marginLeft: !config.chatClient.collapsibleStartChatIcons
                ? config.embeddedStyles.openChatIcon.marginLeft
                : "0px",
            marginTop: config.chatClient.collapsibleStartChatIcons
                ? config.embeddedStyles.openChatIcon.marginLeft
                : "0px",
        };
    };

    const getCustomImageUrlStyles = function () {
        return {
            marginLeft: !config.chatClient.collapsibleStartChatIcons
                ? parseInt(config.embeddedStyles.openChatIcon.marginLeft) - 9 + "px"
                : "0px",
            marginTop: config.chatClient.collapsibleStartChatIcons
                ? parseInt(config.embeddedStyles.openChatIcon.marginLeft) - 9 + "px"
                : "0px",
            maxWidth: parseInt(config.embeddedStyles.openChatIcon?.minWidth) + 10 + "px",
        };
    };

    const uploadFile = function ({ file, filename, onError, onSuccess }) {
        const formData = new FormData();
        formData.append("file", file);
        api.file()
            .uploadFile(appContext.state.workspaceId, formData)
            .then((res) => {
                handleChatClientConfig("customIconUrl", res.data.url);
                onSuccess();
            })
            .catch((err) => {
                onError();
                console.log(err);
            });
    };

    const onFileUploadChange = function (info) {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const transformURL = function (url) {
        let r = new RegExp("^(?:[a-z]+:)?//", "i");
        if (!r.test(url)) {
            url = apiUrl + url;
        }
        return url;
    };

    const setConfigCallback = function (newConfig) {
        setConfig({ ...newConfig });
    };

    const setIsLoadingCallback = useCallback((isLoading) => {
        setIsLoading(isLoading);
    }, []);

    const outboundAnswerHandlerChanged = function (handlerConfig) {
        let newConfigs = {
            backend: {
                automation: {
                    whatsapp: {
                        outboundAnswerHandler: handlerConfig,
                    },
                },
            },
        };
        let mergedConfigs = deepmerge(config, newConfigs);
        setConfigCallback(mergedConfigs);
    };

    function handleEnableHandoverOnWhatsappReferral(e) {
        const enabled = e.target.checked;
        const newConfig = {
            backend: {
                automation: {
                    whatsapp: {
                        handoverOnReferral: enabled,
                    },
                },
            },
        };
        const mergedConfigs = deepmerge(config, newConfig);
        setConfigCallback(mergedConfigs);
    }

    const instagramStoryMentionHandlerChanged = function (handlerConfig) {
        let newConfigs = {
            backend: {
                automation: {
                    instagram: {
                        storyMentionHandler: handlerConfig,
                    },
                },
            },
        };
        let mergedConfigs = deepmerge(config, newConfigs);
        setConfigCallback(mergedConfigs);
    };

    const instagramStoryReplyHandlerChanged = function (handlerConfig) {
        let newConfigs = {
            backend: {
                automation: {
                    instagram: {
                        storyReplyHandler: handlerConfig,
                    },
                },
            },
        };
        let mergedConfigs = deepmerge(config, newConfigs);
        setConfigCallback(mergedConfigs);
    };

    useEffect(() => {
        setIsLoading(true);
        api.workspaces()
            .get(appContext.state.workspaceId)
            .then((response) => {
                let mergedConfigs = deepmerge(config, response.data.jsonConfigs, {
                    arrayMerge: (destinationArray, sourceArray, options) => sourceArray,
                });

                if (mergedConfigs.chatClient?.customIconUrl) {
                    setUploadedChatIcon([
                        {
                            uid: "1",
                            name: t("settings.icons.customIcon"),
                            status: "done",
                            url: transformURL(mergedConfigs.chatClient.customIconUrl),
                        },
                    ]);
                }
                setConfig(mergedConfigs);
            })
            .catch((err) => {
                console.log(err);
            })
            .then(function () {
                setIsLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ConfigProvider locale={antdLang}>
            <div className="opt-view opt-view--settings">
                <Loader isLoading={isLoading} />
                <div className="opt-view-header">
                    <h1 className="opt-title">{t("settings.Settings")}</h1>
                    <div className="opt-view-header__actions">
                        {saveButtonVisible && (
                            <button className="opt-button" onClick={saveChanges}>
                                {t("settings.Save Changes")}
                            </button>
                        )}
                    </div>
                </div>
                {/* Tabs control */}
                <Tabs defaultActiveKey="1" onChange={callback} tabPosition="left">
                    <TabPane tab={t("settings.general")} key="1">
                        <div>
                            <Row gutter={[16, 16]} justify="center">
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Card size="small">
                                        <div className="opt-card opt-card--settings">
                                            <span style={{ fontWeight: "bold" }}>
                                                {t("settings.Language")}
                                            </span>
                                            <Select
                                                defaultValue={config.language}
                                                value={config.language}
                                                style={{ width: "80%" }}
                                                onChange={changeLanguage}
                                            >
                                                <Option value="ENGLISH">
                                                    {t("settings.English")}
                                                </Option>
                                                <Option value="SPANISH">
                                                    {t("settings.Spanish")}
                                                </Option>
                                            </Select>
                                        </div>
                                    </Card>
                                    <Card size="small">
                                        <div className="opt-card opt-card--settings">
                                            <TimeToleranceAfterConversationClosedSettings
                                                configs={config}
                                                setConfig={setConfig}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <Card size="small">
                                        <div className="opt-card opt-card--settings">
                                            <AutoAssignChatsSettings
                                                configs={config}
                                                setConfig={setConfig}
                                            />
                                        </div>
                                    </Card>
                                    <Card size="small">
                                        <div className="opt-card opt-card--settings">
                                            <LLMBotSettings
                                                configs={config}
                                                setConfig={setConfig}
                                            />
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </TabPane>
                    <TabPane tab={t("settings.customerFieldsTabName")} key="customerProperties">
                        <div className="">
                            <div className="opt-card opt-card--settings chat-settings">
                                <CustomerFieldsManager />
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={t("settings.agent")} key="agent">
                        <div className="opt-card opt-card--settings chat-settings">
                            <div style={{ display: "flex" }}>
                                <AgentSettingsManager
                                    config={config}
                                    setConfigCallback={setConfigCallback}
                                    setIsLoadingCallback={setIsLoadingCallback}
                                />
                                <AgentSocketManager
                                    config={config}
                                    setConfigCallback={setConfigCallback}
                                    setIsLoadingCallback={setIsLoadingCallback}
                                />
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={t("settings.notifications.title")} key="notifications">
                        <Row gutter={[16, 16]} justify="center">
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Card size="small">
                                    <div className="opt-card opt-card--settings">
                                        <PushNotificationSettings
                                            configs={config}
                                            setConfig={setConfig}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Card size="small">
                                    <div className="opt-card opt-card--settings">
                                        <ConversationNotificationEmailSettings
                                            configs={config}
                                            setConfig={setConfig}
                                        />
                                    </div>
                                </Card>

                                <Card size="small">
                                    <div className="opt-card opt-card--settings">
                                        <ErrorMailSettings configs={config} setConfig={setConfig} />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab={t("settings.webIcons")} key="2">
                        <div className="opt-settings-tab opt-settings-tab--icons">
                            <div className="opt-icon-settings">
                                <span className="opt-label-title">
                                    {t("settings.icons.iconsSettings")}
                                </span>
                                <div className="opt-card opt-card--settings chat-settings">
                                    <span className="opt-label-title">
                                        {t("settings.icons.webChat")}
                                    </span>
                                    <div className="opt-input-group">
                                        <Checkbox
                                            checked={config.chatClient.showWebChatStartIcon}
                                            onChange={(e) =>
                                                handleChatClientConfig(
                                                    "showWebChatStartIcon",
                                                    e.target.checked
                                                )
                                            }
                                        >
                                            {t("settings.icons.enableWebChatIcon")}
                                        </Checkbox>{" "}
                                        <br />
                                        <Checkbox
                                            checked={config.loginEnabled}
                                            onChange={(e) =>
                                                setConfig({
                                                    ...config,
                                                    loginEnabled: e.target.checked,
                                                })
                                            }
                                        >
                                            {t("settings.icons.enableLogIn")}
                                        </Checkbox>{" "}
                                        <br />
                                        <Checkbox
                                            checked={config.isCacheEnabled}
                                            onChange={(e) =>
                                                setConfig({
                                                    ...config,
                                                    isCacheEnabled: e.target.checked,
                                                })
                                            }
                                        >
                                            {t("settings.icons.enableCache")}
                                        </Checkbox>
                                    </div>
                                    <span className="opt-label-title">WhatsApp</span>
                                    <div className="opt-input-group">
                                        <Checkbox
                                            checked={config.chatClient.showWhatsappStartIcon}
                                            onChange={(e) =>
                                                handleChatClientConfig(
                                                    "showWhatsappStartIcon",
                                                    e.target.checked
                                                )
                                            }
                                        >
                                            {t("settings.icons.enableWhatsAppIcon")}
                                        </Checkbox>
                                    </div>
                                    <div className="opt-input-group">
                                        <span>{t("settings.icons.businessWhatsAppNumber")}</span>
                                        <Input
                                            placeholder="Business whatsapp number"
                                            disabled={!config.chatClient.showWhatsappStartIcon}
                                            value={config.chatClient.whatsappPhone}
                                            onChange={(e) =>
                                                handleChatClientConfig(
                                                    "whatsappPhone",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="opt-input-group">
                                        <span>{t("settings.icons.firstCustomerMessage")}</span>
                                        <Input
                                            placeholder="Greeting message."
                                            disabled={!config.chatClient.showWhatsappStartIcon}
                                            value={config.chatClient.whatsappGreetingMessage}
                                            onChange={(e) =>
                                                handleChatClientConfig(
                                                    "whatsappGreetingMessage",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="opt-input-group">
                                        <Checkbox
                                            checked={config.chatClient.whatsappWebOnDesktop}
                                            onChange={(e) =>
                                                handleChatClientConfig(
                                                    "whatsappWebOnDesktop",
                                                    e.target.checked
                                                )
                                            }
                                        >
                                            <span
                                                style={{
                                                    marginRight: "5px",
                                                    marginBottom: "5px",
                                                }}
                                            >
                                                {t("settings.icons.useWhatsAppOnDesktop")}
                                            </span>
                                            <TitleTooltip
                                                content={t(
                                                    "settings.icons.useWhatsAppOnDesktopInfo"
                                                )}
                                            />
                                        </Checkbox>
                                    </div>
                                    <span className="opt-label-title">Facebook</span>
                                    <div className="opt-input-group">
                                        <Checkbox
                                            checked={
                                                config.chatClient.showFacebookMessengerStartIcon
                                            }
                                            onChange={(e) =>
                                                handleChatClientConfig(
                                                    "showFacebookMessengerStartIcon",
                                                    e.target.checked
                                                )
                                            }
                                        >
                                            {t("settings.icons.enableFacebookIcon")}
                                        </Checkbox>
                                    </div>
                                    <div className="opt-input-group">
                                        <span>{t("settings.icons.yourFacebookPageId")}</span>
                                        <Input
                                            placeholder="Your Facebook page ID"
                                            disabled={
                                                !config.chatClient.showFacebookMessengerStartIcon
                                            }
                                            value={config.chatClient.facebookPageId}
                                            onChange={(e) =>
                                                handleChatClientConfig(
                                                    "facebookPageId",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <span className="opt-label-title">
                                    {t("settings.icons.uploadCustomIcons")}
                                </span>
                                <div
                                    className="opt-card opt-card--settings chat-settings"
                                    style={{ minWidth: "250px" }}
                                >
                                    <div className="opt-input-group">
                                        <Checkbox
                                            checked={config.chatClient.showCustomIcon}
                                            onChange={(e) =>
                                                handleChatClientConfig(
                                                    "showCustomIcon",
                                                    e.target.checked
                                                )
                                            }
                                        >
                                            <span
                                                style={{
                                                    marginRight: "5px",
                                                    marginBottom: "5px",
                                                }}
                                            >
                                                {t("settings.icons.customIcon")}
                                            </span>
                                            <TitleTooltip
                                                content={t("settings.icons.uploadACustomIcon")}
                                            />
                                        </Checkbox>
                                    </div>
                                    {config.chatClient.showCustomIcon && (
                                        <>
                                            <div className="opt-input-group">
                                                <Checkbox
                                                    checked={config.chatClient.containCustomIcon}
                                                    onChange={(e) =>
                                                        handleChatClientConfig(
                                                            "containCustomIcon",
                                                            e.target.checked
                                                        )
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            marginRight: "5px",
                                                            marginBottom: "5px",
                                                        }}
                                                    >
                                                        {t("settings.icons.iconShouldBeContained")}
                                                    </span>
                                                    <TitleTooltip
                                                        content={t(
                                                            "settings.icons.allowIconToBeContained"
                                                        )}
                                                    />
                                                </Checkbox>
                                            </div>
                                            <div className="opt-input-group">
                                                <Upload
                                                    defaultFileList={uploadedChatIcon}
                                                    customRequest={uploadFile}
                                                    listType="picture"
                                                    onChange={onFileUploadChange}
                                                    accept="image/png, image/jpeg"
                                                    maxCount={1}
                                                >
                                                    <Button icon={<UploadOutlined />}>
                                                        {t("settings.icons.clickToUpload")}
                                                    </Button>
                                                </Upload>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="opt-icon-settings">
                                <span className="opt-label-title">
                                    {t("settings.icons.iconsLayout")}
                                </span>
                                <div className="opt-card opt-card--settings chat-settings">
                                    <span className="opt-label-title">
                                        {t("settings.icons.presentation")}
                                    </span>
                                    <Select
                                        defaultValue={config.chatClient.collapsibleStartChatIcons}
                                        value={config.chatClient.collapsibleStartChatIcons}
                                        style={{ width: "100%" }}
                                        onChange={(value) =>
                                            handleChatClientConfig(
                                                "collapsibleStartChatIcons",
                                                value
                                            )
                                        }
                                    >
                                        <Option value={false}>
                                            {t("settings.icons.horizontal")}
                                        </Option>
                                        <Option value={true}>
                                            {t("settings.icons.collapsable")}
                                        </Option>
                                    </Select>
                                    {config.chatClient.collapsibleStartChatIcons && (
                                        <>
                                            <span className="opt-label-title">
                                                {t("settings.icons.buttonColor")}
                                            </span>
                                            <div className="opt-input-color-picker">
                                                <input
                                                    readOnly
                                                    disabled
                                                    value={
                                                        config.embeddedStyles.collapsibleButton
                                                            .backgroundColor
                                                    }
                                                    type="text"
                                                    className="opt-text-input"
                                                />
                                                <InputColor
                                                    className="opt-color-picker"
                                                    initialValue={
                                                        config.embeddedStyles.collapsibleButton
                                                            .backgroundColor
                                                    }
                                                    onChange={(e) =>
                                                        changeColor(e.hex, "collapsibleButton")
                                                    }
                                                    placement="bottom"
                                                />
                                            </div>
                                        </>
                                    )}
                                    <span className="opt-label-title">
                                        {t("settings.icons.webChatIconColor")}
                                    </span>
                                    <div className="opt-input-color-picker">
                                        <input
                                            readOnly
                                            disabled
                                            value={
                                                config.embeddedStyles.openChatIcon.backgroundColor
                                            }
                                            type="text"
                                            className="opt-text-input"
                                        />
                                        <InputColor
                                            className="opt-color-picker"
                                            initialValue={
                                                config.embeddedStyles.openChatIcon.backgroundColor
                                            }
                                            onChange={(e) => changeColor(e.hex, "openChatIcon")}
                                            placement="bottom"
                                        />
                                    </div>
                                    <span className="opt-label-title">
                                        {t("settings.icons.logoSize")}
                                    </span>
                                    <Slider
                                        min={30}
                                        max={100}
                                        value={parseInt(
                                            config.embeddedStyles.openChatIconFont.fontSize
                                        )}
                                        onChange={(e) => changeFontSize(e, "openChatIconFont")}
                                    />
                                    <span className="opt-label-title">
                                        {t("settings.icons.buttonSize")}
                                    </span>

                                    <Slider
                                        min={72}
                                        max={120}
                                        value={parseInt(
                                            config.embeddedStyles.openChatIcon.minWidth
                                        )}
                                        onChange={(val) => changeButtonSize(val)}
                                    />
                                    <span className="opt-label-title">
                                        {t("settings.icons.gapBetweenButtons")}
                                    </span>
                                    <Slider
                                        min={5}
                                        max={50}
                                        value={parseInt(
                                            config.embeddedStyles.openChatIcon.marginLeft
                                        )}
                                        onChange={(val) => changeMargin(val)}
                                    />
                                </div>
                                <div
                                    className={`opt-icons-wrapper ${
                                        config.chatClient.collapsibleStartChatIcons &&
                                        "opt-icons--collapsible"
                                    } ${isCollapsed && "opt-icons--collapsible-open"}`}
                                >
                                    {config.chatClient.showFacebookMessengerStartIcon && (
                                        <button
                                            className="opt-start-chat  opt-chat-icon opt-start-chat--facebook"
                                            style={config.embeddedStyles.whatsappButton}
                                        >
                                            <FontAwesomeIcon
                                                style={config.embeddedStyles.openChatIconFont}
                                                className="opt-start-chat__icon"
                                                icon={faFacebookMessenger}
                                            />
                                        </button>
                                    )}
                                    {config.chatClient.showWhatsappStartIcon && (
                                        <button
                                            className="opt-start-chat opt-chat-icon"
                                            style={getExternalButtonStyles("whatsappButton")}
                                        >
                                            <FontAwesomeIcon
                                                style={config.embeddedStyles.openChatIconFont}
                                                className="opt-start-chat__icon"
                                                icon={faWhatsapp}
                                            />
                                        </button>
                                    )}
                                    {config.chatClient.showWebChatStartIcon &&
                                        (config.chatClient.showCustomIcon &&
                                        config.chatClient.customIconUrl &&
                                        !config.chatClient.containCustomIcon ? (
                                            <img
                                                src={transformURL(config.chatClient.customIconUrl)}
                                                alt={t("settings.icons.chatWithUs")}
                                                style={getCustomImageUrlStyles()}
                                                className="opt-chat-custom-icon"
                                            />
                                        ) : (
                                            <button
                                                className="opt-start-chat opt-chat-icon"
                                                style={getExternalButtonStyles("openChatIcon")}
                                            >
                                                {config.chatClient.showCustomIcon &&
                                                config.chatClient.customIconUrl ? (
                                                    <img
                                                        src={transformURL(
                                                            config.chatClient.customIconUrl
                                                        )}
                                                        alt={t("settings.icons.chatWithUs")}
                                                        style={{
                                                            maxWidth:
                                                                config.embeddedStyles
                                                                    .openChatIconFont.fontSize,
                                                        }}
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        style={
                                                            config.embeddedStyles.openChatIconFont
                                                        }
                                                        className="opt-start-chat__icon"
                                                        icon={faCommentDots}
                                                    />
                                                )}
                                            </button>
                                        ))}
                                    {config.chatClient.collapsibleStartChatIcons && (
                                        <button
                                            className="opt-start-chat opt-start-chat-toggle"
                                            style={config.embeddedStyles.collapsibleButton}
                                            onClick={() => setIsCollapsed((state) => !state)}
                                        >
                                            <FontAwesomeIcon
                                                style={config.embeddedStyles.openChatIconFont}
                                                className="opt-start-chat__icon"
                                                icon={!isCollapsed ? faComments : faTimes}
                                            />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={t("settings.styles")} key="3">
                        <div className="opt-settings-tab opt-settings-tab--chat-ui">
                            <div className="opt-card opt-card--settings chat-settings">
                                <h2 className="opt-subtitle">{t("settings.stylesTab.colors")}</h2>
                                <span className="opt-label-title">
                                    {t("settings.stylesTab.headerColor")}
                                </span>
                                <div className="opt-input-color-picker">
                                    <input
                                        readOnly
                                        disabled
                                        value={config.embeddedStyles.header.backgroundColor}
                                        type="text"
                                        className="opt-text-input"
                                    />
                                    <InputColor
                                        className="opt-color-picker"
                                        initialValue={config.embeddedStyles.header.backgroundColor}
                                        onChange={(e) => changeColor(e.hex, "header")}
                                        placement="bottom"
                                    />
                                </div>
                                <div className="opt-embedded-content">
                                    <span className="opt-label-title">
                                        {t("settings.stylesTab.headerText")}
                                    </span>
                                    <input
                                        type="text"
                                        value={config.chatClient.headerTitle}
                                        onChange={(e) =>
                                            handleChatClientConfig("headerTitle", e.target.value)
                                        }
                                        className="opt-text-input"
                                        placeholder="Chat with us"
                                    />
                                </div>
                                <span className="opt-label-title">
                                    {t("settings.stylesTab.loginButton")}
                                </span>
                                <div className="opt-input-color-picker">
                                    <input
                                        readOnly
                                        disabled
                                        value={
                                            config.embeddedStyles.loginSendButton.backgroundColor
                                        }
                                        type="text"
                                        className="opt-text-input"
                                    />
                                    <InputColor
                                        className="opt-color-picker"
                                        initialValue={
                                            config.embeddedStyles.loginSendButton.backgroundColor
                                        }
                                        onChange={(e) => changeColor(e.hex, "loginSendButton")}
                                        placement="bottom"
                                    />
                                </div>
                                <span className="opt-label-title">
                                    {t("settings.stylesTab.userMessageBackgroundColor")}
                                </span>
                                <div className="opt-input-color-picker">
                                    <input
                                        readOnly
                                        disabled
                                        value={
                                            config.customStyles &&
                                            config.customStyles.bubbles &&
                                            config.customStyles.bubbles.userBubble.backgroundColor
                                        }
                                        type="text"
                                        className="opt-text-input"
                                    />
                                    <InputColor
                                        className="opt-color-picker"
                                        initialValue={
                                            config.customStyles &&
                                            config.customStyles.bubbles &&
                                            config.customStyles.bubbles.userBubble.backgroundColor
                                        }
                                        onChange={(e) =>
                                            changeColorChat(e.hex, "backgroundColor", "userBubble")
                                        }
                                        placement="bottom"
                                    />
                                </div>
                                <span className="opt-label-title">
                                    {t("settings.stylesTab.userMessageTextColor")}
                                </span>
                                <div className="opt-input-color-picker">
                                    <input
                                        readOnly
                                        disabled
                                        value={config.customStyles.bubbles.userBubble.color}
                                        type="text"
                                        className="opt-text-input"
                                    />
                                    <InputColor
                                        className="opt-color-picker"
                                        initialValue={config.customStyles.bubbles.userBubble.color}
                                        onChange={(e) =>
                                            changeColorChat(e.hex, "color", "userBubble")
                                        }
                                        placement="bottom"
                                    />
                                </div>
                                <span className="opt-label-title">
                                    {t("settings.stylesTab.linkColor")}
                                </span>
                                <div className="opt-input-color-picker">
                                    <input
                                        readOnly
                                        disabled
                                        value={config.customStyles.bubbles.agentBubbleLink.color}
                                        type="text"
                                        className="opt-text-input"
                                    />
                                    <InputColor
                                        className="opt-color-picker"
                                        initialValue={
                                            config.customStyles.bubbles.agentBubbleLink.color
                                        }
                                        onChange={(e) =>
                                            changeColorChat(e.hex, "color", "agentBubbleLink")
                                        }
                                        placement="bottom"
                                    />
                                </div>
                                <span className="opt-label-title">
                                    {t("settings.stylesTab.agentMessageBackgroundColor")}
                                </span>
                                <div className="opt-input-color-picker">
                                    <input
                                        readOnly
                                        disabled
                                        value={
                                            config.customStyles.bubbles.agentBubble.backgroundColor
                                        }
                                        type="text"
                                        className="opt-text-input"
                                    />
                                    <InputColor
                                        className="opt-color-picker"
                                        initialValue={
                                            config.customStyles.bubbles.agentBubble.backgroundColor
                                        }
                                        onChange={(e) =>
                                            changeColorChat(e.hex, "backgroundColor", "agentBubble")
                                        }
                                        placement="bottom"
                                    />
                                </div>
                                <span className="opt-label-title">
                                    {t("settings.stylesTab.agentMessageTextColor")}
                                </span>
                                <div className="opt-input-color-picker">
                                    <input
                                        readOnly
                                        disabled
                                        value={config.customStyles.bubbles.agentBubble.color}
                                        type="text"
                                        className="opt-text-input"
                                    />
                                    <InputColor
                                        className="opt-color-picker"
                                        initialValue={config.customStyles.bubbles.agentBubble.color}
                                        onChange={(e) =>
                                            changeColorChat(e.hex, "color", "agentBubble")
                                        }
                                        placement="bottom"
                                    />
                                </div>
                                <span className="opt-label-title">
                                    {t("settings.stylesTab.sendButton")}
                                </span>
                                <div className="opt-input-color-picker">
                                    <input
                                        readOnly
                                        disabled
                                        value={config.customStyles.sendButton.backgroundColor}
                                        type="text"
                                        className="opt-text-input"
                                    />
                                    <InputColor
                                        className="opt-color-picker"
                                        initialValue={
                                            config.customStyles.sendButton.backgroundColor
                                        }
                                        onChange={(e) =>
                                            setConfig({
                                                ...config,
                                                customStyles: {
                                                    ...config.customStyles,
                                                    sendButton: {
                                                        backgroundColor: e.hex,
                                                    },
                                                },
                                            })
                                        }
                                        placement="bottom"
                                    />
                                </div>
                            </div>
                            {/************* *
                             * UI Examples *
                             * *********** */}
                            <div className="opt-ui-example" style={{ display: "flex" }}>
                                <div className="opt-ui-example--container">
                                    <span className="opt-label-title">
                                        {t("settings.stylesTab.preview")}
                                    </span>
                                    <div className={"opt-embedded-views"}>
                                        <header
                                            className="opt-embedded-views__header"
                                            style={config.embeddedStyles.header}
                                        >
                                            <FontAwesomeIcon
                                                className="opt-embedded-views__header-icon"
                                                icon={faCommentDots}
                                            />
                                            <p className="opt-embedded-views__title">
                                                {config.chatClient.headerTitle}
                                            </p>
                                        </header>
                                        <div className="opt-embedded-content">
                                            <span>{t("settings.stylesTab.logInView")}</span>
                                            <input
                                                className="opt-text-input"
                                                placeholder={t(
                                                    "settings.stylesTab.exampleLoginForm"
                                                )}
                                            />
                                            <button
                                                className="opt-button"
                                                style={config.embeddedStyles.loginSendButton}
                                            >
                                                {t("settings.stylesTab.exampleButton")}n
                                            </button>
                                        </div>
                                        <div className="opt-chat-content chat-history">
                                            <span>{t("settings.stylesTab.chatView")}</span>
                                            <div className="chat-history__message chat-history__message--end-user">
                                                <div
                                                    style={config.customStyles.bubbles.userBubble}
                                                    className="chat-history__chat-bubble chat-history__chat-bubble--selected"
                                                >
                                                    {t("settings.stylesTab.exampleMessage")}{" "}
                                                    <span role="img" aria-labelledby="emoji">
                                                        😀
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="chat-history__message chat-history__message--assistant">
                                                <div
                                                    style={config.customStyles.bubbles.agentBubble}
                                                    className="chat-history__chat-bubble"
                                                >
                                                    {
                                                        //eslint-disable-next-line
                                                    }
                                                    {t("settings.stylesTab.checkThis")}{" "}
                                                    <span
                                                        style={
                                                            config.customStyles.bubbles
                                                                .agentBubbleLink
                                                        }
                                                        href="#"
                                                    >
                                                        {t("settings.stylesTab.link")}
                                                    </span>
                                                    !
                                                </div>
                                            </div>
                                            <div className="chat-send-messages">
                                                <input
                                                    type="text"
                                                    className="chat-send-input"
                                                    placeholder={t(
                                                        "settings.stylesTab.yourMessage"
                                                    )}
                                                />
                                                <div
                                                    className="chat-send-button"
                                                    style={config.customStyles.sendButton}
                                                >
                                                    <FontAwesomeIcon
                                                        className="chat-send-icon"
                                                        icon={faChevronRight}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tab={t("settings.webChat")} key="4">
                        <div className="opt-settings-tab opt-settings-tab--integrations">
                            {/*
                                Web Chat
                            */}
                            <div className="opt-card opt-card--settings">
                                <div className="opt-card__header">
                                    <FontAwesomeIcon
                                        className={"opt-card-icon-settings whatsapp"}
                                        icon={faCommentDots}
                                    />
                                    <h2 className="opt-subtitle">
                                        {t("settings.channelsSettings.webChat")}
                                    </h2>
                                </div>
                                <span className="opt-label-title">
                                    {t("settings.channelsSettings.humanAgent")}
                                </span>
                                <br />
                                <div className="opt-time-settings">
                                    <div className="opt-hours-settings">
                                        <span>{t("settings.channelsSettings.enabledHours")}</span>
                                        <RangePicker
                                            defaultValue={getDefaultDate("webchat", "humanAgent")}
                                            onChange={(e) =>
                                                changeChannelHours(e, "webchat", "humanAgent")
                                            }
                                        />
                                    </div>
                                    <div className="opt-timezone-settings">
                                        <span>{t("settings.channelsSettings.timezone")}</span>
                                        <InputNumber
                                            defaultValue={
                                                config.backend.automation.whatsapp.humanAgent.tz
                                            }
                                            onChange={(e) =>
                                                changeTimezone(e, "webchat", "humanAgent")
                                            }
                                        />
                                    </div>
                                </div>
                                <span>{t("settings.channelsSettings.enabledDays")}</span>
                                <Select
                                    placeholder={t("settings.channelsSettings.selectDays")}
                                    defaultValue={[
                                        ...new Set(
                                            config.backend.automation.webchat.humanAgent.days
                                        ),
                                    ]}
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    onChange={(val) => selectDays(val, "webchat", "humanAgent")}
                                >
                                    {listDays.map((day, index) => (
                                        <Option key={index} value={day.value}>
                                            {day.label}
                                        </Option>
                                    ))}
                                </Select>
                                <span>{t("settings.channelsSettings.welcomeMessage")}</span>
                                <Input.TextArea
                                    defaultValue={
                                        config.backend.automation.webchat.humanAgent.welcomeMessage
                                    }
                                    onChange={(e) => setWelcomeMessage(e.target.value, "webchat")}
                                />
                                <span>
                                    {t("settings.channelsSettings.outsideOfficeHoursMessage")}
                                </span>
                                <Input.TextArea
                                    defaultValue={
                                        config.backend.automation.webchat.humanAgent.absentMessage
                                    }
                                    onChange={(e) => setAbsentMessage(e.target.value, "webchat")}
                                />
                                <span className="opt-label-title checkbox-title">
                                    {t("settings.channelsSettings.aiAgent")}
                                </span>

                                <div className="opt-time-settings">
                                    <div className="opt-hours-settings">
                                        <span>{t("settings.channelsSettings.enabledHours")}</span>
                                        <RangePicker
                                            defaultValue={getDefaultDate("webchat", "aiAgent")}
                                            onChange={(e) =>
                                                changeChannelHours(e, "webchat", "aiAgent")
                                            }
                                        />
                                    </div>
                                    <div className="opt-timezone-settings">
                                        <span>{t("settings.channelsSettings.timezone")}</span>
                                        <InputNumber
                                            defaultValue={
                                                config.backend.automation.webchat.aiAgent.tz
                                            }
                                            onChange={(e) =>
                                                changeTimezone(e, "webchat", "aiAgent")
                                            }
                                        />
                                    </div>
                                </div>
                                <span>{t("settings.channelsSettings.enabledDays")}</span>
                                <Select
                                    placeholder={t("settings.channelsSettings.selectDays")}
                                    defaultValue={[
                                        ...new Set(config.backend.automation.webchat.aiAgent.days),
                                    ]}
                                    mode="multiple"
                                    style={{ width: "100%", fontSize: "16px" }}
                                    onChange={(val) => selectDays(val, "webchat", "aiAgent")}
                                >
                                    {listDays.map((day, index) => (
                                        <Option key={index} value={day.value}>
                                            {day.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={t("settings.whatsapp")} key="5">
                        <div className="opt-settings-tab opt-settings-tab--integrations">
                            {/*
                                WHATSAPP
                            */}
                            <div className="opt-card opt-card--settings">
                                <div className="opt-card__header">
                                    <FontAwesomeIcon
                                        className={"opt-card-icon-settings whatsapp"}
                                        icon={faWhatsapp}
                                    />
                                    <h2 className="opt-subtitle">WhatsApp</h2>
                                </div>
                                <div style={{ padding: ".7rem 0rem 0.7rem 0rem" }}>
                                    <Checkbox
                                        checked={whatsappHandoverOnReferral}
                                        onClick={handleEnableHandoverOnWhatsappReferral}
                                    >
                                        {t("settings.whatsappSettings.enableHandoverOnReferral")}
                                    </Checkbox>
                                    <TitleTooltip
                                        content={t(
                                            "settings.whatsappSettings.handoverOnReferralTooltip"
                                        )}
                                    />
                                </div>
                                <span className="opt-label-title">
                                    {t("settings.channelsSettings.humanAgent")}
                                </span>
                                <br />
                                <div className="opt-time-settings">
                                    <div className="opt-hours-settings">
                                        <span>{t("settings.channelsSettings.enabledHours")}</span>
                                        <RangePicker
                                            defaultValue={getDefaultDate("whatsapp", "humanAgent")}
                                            onChange={(e) =>
                                                changeChannelHours(e, "whatsapp", "humanAgent")
                                            }
                                        />
                                    </div>
                                    <div className="opt-timezone-settings">
                                        <span>{t("settings.channelsSettings.timezone")}</span>
                                        <InputNumber
                                            defaultValue={
                                                config.backend.automation.whatsapp.humanAgent.tz
                                            }
                                            onChange={(e) =>
                                                changeTimezone(e, "whatsapp", "humanAgent")
                                            }
                                        />
                                    </div>
                                </div>
                                <span>{t("settings.channelsSettings.enabledDays")}</span>
                                <Select
                                    placeholder={t("settings.channelsSettings.selectDays")}
                                    defaultValue={[
                                        ...new Set(
                                            config.backend.automation.whatsapp.humanAgent.days
                                        ),
                                    ]}
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    onChange={(val) => selectDays(val, "whatsapp", "humanAgent")}
                                >
                                    {listDays.map((day, index) => (
                                        <Option key={index} value={day.value}>
                                            {day.label}
                                        </Option>
                                    ))}
                                </Select>
                                <span>{t("settings.channelsSettings.welcomeMessage")}</span>
                                <Input.TextArea
                                    defaultValue={
                                        config.backend.automation.whatsapp.humanAgent.welcomeMessage
                                    }
                                    onChange={(e) => setWelcomeMessage(e.target.value, "whatsapp")}
                                />
                                <span>
                                    {t("settings.channelsSettings.outsideOfficeHoursMessage")}
                                </span>
                                <Input.TextArea
                                    defaultValue={
                                        config.backend.automation.whatsapp.humanAgent.absentMessage
                                    }
                                    onChange={(e) => setAbsentMessage(e.target.value, "whatsapp")}
                                />
                                <span className="opt-label-title checkbox-title">
                                    {t("settings.channelsSettings.aiAgent")}
                                </span>

                                <div className="opt-time-settings">
                                    <div className="opt-hours-settings">
                                        <span>{t("settings.channelsSettings.enabledHours")}</span>
                                        <RangePicker
                                            defaultValue={getDefaultDate("whatsapp", "aiAgent")}
                                            onChange={(e) =>
                                                changeChannelHours(e, "whatsapp", "aiAgent")
                                            }
                                        />
                                    </div>
                                    <div className="opt-timezone-settings">
                                        <span>{t("settings.channelsSettings.timezone")}</span>
                                        <InputNumber
                                            defaultValue={
                                                config.backend.automation.whatsapp.aiAgent.tz
                                            }
                                            onChange={(e) =>
                                                changeTimezone(e, "whatsapp", "aiAgent")
                                            }
                                        />
                                    </div>
                                </div>
                                <span>{t("settings.channelsSettings.enabledDays")}</span>
                                <Select
                                    placeholder={t("settings.channelsSettings.selectDays")}
                                    defaultValue={[
                                        ...new Set(config.backend.automation.whatsapp.aiAgent.days),
                                    ]}
                                    mode="multiple"
                                    style={{ width: "100%", fontSize: "16px" }}
                                    onChange={(val) => selectDays(val, "whatsapp", "aiAgent")}
                                >
                                    {listDays.map((day, index) => (
                                        <Option key={index} value={day.value}>
                                            {day.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="opt-settings-tab opt-settings-tab--integrations">
                            <div className="opt-card opt-card--settings">
                                <div className="opt-card__header">
                                    <h2 className="opt-subtitle">
                                        {t(
                                            "settings.whatsappSettings.behaviorInResponseToOutbound"
                                        )}
                                    </h2>
                                </div>
                                <Select
                                    defaultValue={"AGENT"}
                                    value={
                                        config.backend?.automation?.whatsapp?.outboundAnswerHandler
                                    }
                                    style={{ width: "100%" }}
                                    onChange={outboundAnswerHandlerChanged}
                                >
                                    <Option value="AGENT">
                                        {t("settings.whatsappSettings.agents")}
                                    </Option>
                                    <Option value="CHATBOT">
                                        {t("settings.whatsappSettings.chatbot")}
                                    </Option>
                                </Select>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={t("settings.facebook")} key="6">
                        <div className="opt-settings-tab opt-settings-tab--integrations">
                            {/* FACEBOOK */}
                            <div className="opt-card opt-card--settings">
                                <div className="opt-card__header">
                                    <FontAwesomeIcon
                                        className={"opt-card-icon-settings facebook"}
                                        icon={faFacebookMessenger}
                                    />
                                    <h2 className="opt-subtitle">Facebook</h2>
                                </div>
                                <span className="opt-label-title">
                                    {t("settings.channelsSettings.humanAgent")}
                                </span>
                                <div className="opt-time-settings">
                                    <div className="opt-hours-settings">
                                        <span>{t("settings.channelsSettings.enabledHours")}</span>
                                        <RangePicker
                                            defaultValue={getDefaultDate("facebook", "humanAgent")}
                                            onChange={(e) =>
                                                changeChannelHours(e, "facebook", "humanAgent")
                                            }
                                        />
                                    </div>
                                    <div className="opt-timezone-settings">
                                        <span>{t("settings.channelsSettings.timezone")}</span>
                                        <InputNumber
                                            defaultValue={
                                                config.backend.automation.facebook.humanAgent.tz
                                            }
                                            onChange={(e) =>
                                                changeTimezone(e, "facebook", "humanAgent")
                                            }
                                        />
                                    </div>
                                </div>
                                <span>{t("settings.channelsSettings.enabledDays")}</span>
                                <Select
                                    defaultValue={[
                                        ...new Set(
                                            config.backend.automation.facebook.humanAgent.days
                                        ),
                                    ]}
                                    placeholder={t("settings.channelsSettings.selectDays")}
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    onChange={(val) => selectDays(val, "facebook", "humanAgent")}
                                >
                                    {listDays.map((day, index) => (
                                        <Option key={index} value={day.value}>
                                            {day.label}
                                        </Option>
                                    ))}
                                </Select>
                                <span>{t("settings.channelsSettings.welcomeMessage")}</span>
                                <Input.TextArea
                                    defaultValue={
                                        config.backend.automation.facebook.humanAgent.welcomeMessage
                                    }
                                    onChange={(e) => setWelcomeMessage(e.target.value, "facebook")}
                                />
                                <span>
                                    {t("settings.channelsSettings.outsideOfficeHoursMessage")}
                                </span>
                                <Input.TextArea
                                    defaultValue={
                                        config.backend.automation.facebook.humanAgent.absentMessage
                                    }
                                    onChange={(e) => setAbsentMessage(e.target.value, "facebook")}
                                />
                                <span className="opt-label-title checkbox-title">
                                    {t("settings.channelsSettings.aiAgent")}
                                </span>

                                <div className="opt-time-settings">
                                    <div className="opt-hours-settings">
                                        <span>{t("settings.channelsSettings.enabledHours")}</span>
                                        <RangePicker
                                            defaultValue={getDefaultDate("facebook", "aiAgent")}
                                            onChange={(e) =>
                                                changeChannelHours(e, "facebook", "aiAgent")
                                            }
                                        />
                                    </div>

                                    <div className="opt-timezone-settings">
                                        <span>{t("settings.channelsSettings.timezone")}</span>
                                        <InputNumber
                                            defaultValue={
                                                config.backend.automation.facebook.aiAgent.tz
                                            }
                                            onChange={(e) =>
                                                changeTimezone(e, "facebook", "aiAgent")
                                            }
                                        />
                                    </div>
                                </div>

                                <span>{t("settings.channelsSettings.enabledDays")}</span>
                                <Select
                                    defaultValue={[
                                        ...new Set(config.backend.automation.facebook.aiAgent.days),
                                    ]}
                                    placeholder={t("settings.channelsSettings.selectDays")}
                                    mode="multiple"
                                    style={{ width: "100%", fontSize: "16px" }}
                                    onChange={(val) => selectDays(val, "facebook", "aiAgent")}
                                >
                                    {listDays.map((day, index) => (
                                        <Option key={index} value={day.value}>
                                            {day.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={t("settings.instagram")} key="7">
                        <div className="opt-settings-tab opt-settings-tab--integrations">
                            {/* Instagram */}
                            <div className="opt-card opt-card--settings">
                                <div className="opt-card__header">
                                    <div
                                        className="instagram-container"
                                        style={{ borderRadius: "8px", padding: "2px 3px" }}
                                    >
                                        <FontAwesomeIcon
                                            className={"opt-card-icon-settings"}
                                            icon={faInstagram}
                                        />
                                    </div>
                                    <h2
                                        className="opt-subtitle"
                                        style={{ marginBottom: 0, marginLeft: "5px" }}
                                    >
                                        Instagram
                                    </h2>
                                </div>
                                <span className="opt-label-title">
                                    {t("settings.channelsSettings.humanAgent")}
                                </span>
                                <div className="opt-time-settings">
                                    <div className="opt-hours-settings">
                                        <span>{t("settings.channelsSettings.enabledHours")}</span>
                                        <RangePicker
                                            defaultValue={getDefaultDate("instagram", "humanAgent")}
                                            onChange={(e) =>
                                                changeChannelHours(e, "instagram", "humanAgent")
                                            }
                                        />
                                    </div>
                                    <div className="opt-timezone-settings">
                                        <span>{t("settings.channelsSettings.timezone")}</span>
                                        <InputNumber
                                            defaultValue={
                                                config.backend.automation.instagram.humanAgent.tz
                                            }
                                            onChange={(e) =>
                                                changeTimezone(e, "instagram", "humanAgent")
                                            }
                                        />
                                    </div>
                                </div>
                                <span>{t("settings.channelsSettings.enabledDays")}</span>
                                <Select
                                    defaultValue={[
                                        ...new Set(
                                            config.backend.automation.instagram.humanAgent.days
                                        ),
                                    ]}
                                    placeholder={t("settings.channelsSettings.selectDays")}
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    onChange={(val) => selectDays(val, "instagram", "humanAgent")}
                                >
                                    {listDays.map((day, index) => (
                                        <Option key={index} value={day.value}>
                                            {day.label}
                                        </Option>
                                    ))}
                                </Select>
                                <span>{t("settings.channelsSettings.welcomeMessage")}</span>
                                <Input.TextArea
                                    defaultValue={
                                        config.backend.automation.instagram.humanAgent
                                            .welcomeMessage
                                    }
                                    onChange={(e) => setWelcomeMessage(e.target.value, "instagram")}
                                />
                                <span>
                                    {t("settings.channelsSettings.outsideOfficeHoursMessage")}
                                </span>
                                <Input.TextArea
                                    defaultValue={
                                        config.backend.automation.instagram.humanAgent.absentMessage
                                    }
                                    onChange={(e) => setAbsentMessage(e.target.value, "instagram")}
                                />
                                <span className="opt-label-title checkbox-title">
                                    {t("settings.channelsSettings.aiAgent")}
                                </span>

                                <div className="opt-time-settings">
                                    <div className="opt-hours-settings">
                                        <span>{t("settings.channelsSettings.enabledHours")}</span>
                                        <RangePicker
                                            defaultValue={getDefaultDate("instagram", "aiAgent")}
                                            onChange={(e) =>
                                                changeChannelHours(e, "instagram", "aiAgent")
                                            }
                                        />
                                    </div>

                                    <div className="opt-timezone-settings">
                                        <span>{t("settings.channelsSettings.timezone")}</span>
                                        <InputNumber
                                            defaultValue={
                                                config.backend.automation.instagram.aiAgent.tz
                                            }
                                            onChange={(e) =>
                                                changeTimezone(e, "instagram", "aiAgent")
                                            }
                                        />
                                    </div>
                                </div>

                                <span>{t("settings.channelsSettings.enabledDays")}</span>
                                <Select
                                    defaultValue={[
                                        ...new Set(
                                            config.backend.automation.instagram.aiAgent.days
                                        ),
                                    ]}
                                    placeholder={t("settings.channelsSettings.selectDays")}
                                    mode="multiple"
                                    style={{ width: "100%", fontSize: "16px" }}
                                    onChange={(val) => selectDays(val, "instagram", "aiAgent")}
                                >
                                    {listDays.map((day, index) => (
                                        <Option key={index} value={day.value}>
                                            {day.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="opt-settings-tab opt-settings-tab--integrations">
                            <div className="opt-card opt-card--settings">
                                <div className="opt-card__header">
                                    <h2 className="opt-subtitle">
                                        {t("settings.instagramSettings.storyMentionHandler")}
                                    </h2>
                                </div>
                                <Select
                                    defaultValue={"NOBODY"}
                                    value={
                                        config.backend?.automation?.instagram?.storyMentionHandler
                                    }
                                    style={{ width: "100%" }}
                                    onChange={instagramStoryMentionHandlerChanged}
                                >
                                    <Option value="NOBODY">
                                        {t("settings.instagramSettings.nobody")}
                                    </Option>
                                    <Option value="AGENT">
                                        {t("settings.instagramSettings.agents")}
                                    </Option>
                                    <Option value="CHATBOT">
                                        {t("settings.instagramSettings.chatbot")}
                                    </Option>
                                </Select>
                            </div>
                        </div>
                        <div className="opt-settings-tab opt-settings-tab--integrations">
                            <div className="opt-card opt-card--settings">
                                <div className="opt-card__header">
                                    <h2 className="opt-subtitle">
                                        {t("settings.instagramSettings.storyReplyHandler")}
                                    </h2>
                                </div>
                                <Select
                                    defaultValue={"NOBODY"}
                                    value={config.backend?.automation?.instagram?.storyReplyHandler}
                                    style={{ width: "100%" }}
                                    onChange={instagramStoryReplyHandlerChanged}
                                >
                                    <Option value="NOBODY">
                                        {t("settings.instagramSettings.nobody")}
                                    </Option>
                                    <Option value="AGENT">
                                        {t("settings.instagramSettings.agents")}
                                    </Option>
                                    <Option value="CHATBOT">
                                        {t("settings.instagramSettings.chatbot")}
                                    </Option>
                                </Select>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={t("settings.sms")} key="8">
                        {/*SMS */}
                        <div className="opt-settings-tab opt-settings-tab--integrations">
                            <div className="opt-card opt-card--settings">
                                <div className="opt-card__header">
                                    <FontAwesomeIcon
                                        style={{ marginLeft: "10px" }}
                                        className={"opt-card-icon-settings"}
                                        icon={faSms}
                                    />
                                    <h2 className="opt-subtitle">SMS</h2>
                                </div>
                                <span className="opt-label-title">
                                    {t("settings.channelsSettings.humanAgent")}
                                </span>
                                <div className="opt-time-settings">
                                    <div className="opt-hours-settings">
                                        <span>{t("settings.channelsSettings.enabledHours")}</span>
                                        <RangePicker
                                            defaultValue={getDefaultDate("sms", "humanAgent")}
                                            onChange={(e) =>
                                                changeChannelHours(e, "sms", "humanAgent")
                                            }
                                        />
                                    </div>
                                    <div className="opt-timezone-settings">
                                        <span>{t("settings.channelsSettings.timezone")}</span>
                                        <InputNumber
                                            defaultValue={
                                                config.backend.automation.sms.humanAgent.tz
                                            }
                                            onChange={(e) => changeTimezone(e, "sms", "humanAgent")}
                                        />
                                    </div>
                                </div>
                                <span>{t("settings.channelsSettings.enabledDays")}</span>
                                <Select
                                    defaultValue={[
                                        ...new Set(config.backend.automation.sms.humanAgent.days),
                                    ]}
                                    placeholder={t("settings.channelsSettings.selectDays")}
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    onChange={(val) => selectDays(val, "sms", "humanAgent")}
                                >
                                    {listDays.map((day, index) => (
                                        <Option key={index} value={day.value}>
                                            {day.label}
                                        </Option>
                                    ))}
                                </Select>
                                <span>{t("settings.channelsSettings.welcomeMessage")}</span>
                                <Input.TextArea
                                    defaultValue={
                                        config.backend.automation.sms.humanAgent.welcomeMessage
                                    }
                                    onChange={(e) => setWelcomeMessage(e.target.value, "sms")}
                                />
                                <span>
                                    {t("settings.channelsSettings.outsideOfficeHoursMessage")}
                                </span>
                                <Input.TextArea
                                    defaultValue={
                                        config.backend.automation.sms.humanAgent.absentMessage
                                    }
                                    onChange={(e) => setAbsentMessage(e.target.value, "sms")}
                                />
                                <span className="opt-label-title checkbox-title">
                                    {t("settings.channelsSettings.aiAgent")}
                                </span>

                                <div className="opt-time-settings">
                                    <div className="opt-hours-settings">
                                        <span>{t("settings.channelsSettings.enabledHours")}</span>
                                        <RangePicker
                                            defaultValue={getDefaultDate("sms", "aiAgent")}
                                            onChange={(e) =>
                                                changeChannelHours(e, "sms", "aiAgent")
                                            }
                                        />
                                    </div>

                                    <div className="opt-timezone-settings">
                                        <span>{t("settings.channelsSettings.timezone")}</span>
                                        <InputNumber
                                            defaultValue={config.backend.automation.sms.aiAgent.tz}
                                            onChange={(e) => changeTimezone(e, "sms", "aiAgent")}
                                        />
                                    </div>
                                </div>

                                <span>{t("settings.channelsSettings.enabledDays")}</span>
                                <Select
                                    defaultValue={[
                                        ...new Set(config.backend.automation.sms.aiAgent.days),
                                    ]}
                                    placeholder={t("settings.channelsSettings.selectDays")}
                                    mode="multiple"
                                    style={{ width: "100%", fontSize: "16px" }}
                                    onChange={(val) => selectDays(val, "sms", "aiAgent")}
                                >
                                    {listDays.map((day, index) => (
                                        <Option key={index} value={day.value}>
                                            {day.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={t("settings.Webhooks")} key="9">
                        {/*Webhooks */}
                        <div className="opt-settings-tab opt-settings-tab--integrations">
                            <div className="opt-card opt-card--settings">
                                <div className="opt-card__header">
                                    <FontAwesomeIcon
                                        style={{ marginLeft: "10px" }}
                                        className={"opt-card-icon-settings"}
                                        icon={faAnchor}
                                    />
                                    <h2 className="opt-subtitle">{t("settings.Webhooks")}</h2>
                                </div>
                                <span className="opt-label-title">
                                    {t("settings.webhooks.url")}
                                </span>
                                <Input
                                    placeholder={t("settings.webhooks.urlPlaceholder")}
                                    defaultValue={webhooksUrl}
                                    onChange={(e) => setWebhooksUrl(e.target.value, "url")}
                                />
                                <span className="opt-label-title">
                                    {t("settings.webhooks.headers")}
                                </span>
                                <Popover
                                    visible={!isValidJson}
                                    style={{ borderColor: "red" }}
                                    trigger="click"
                                    placement="rightTop"
                                    title={t("settings.webhooks.headersPopoverTitle")}
                                    content={t("settings.webhooks.headersPopoverContent")}
                                >
                                    <Input.TextArea
                                        rows={6}
                                        maxLength={4096}
                                        placeholder={t("settings.webhooks.headersPlaceholder")}
                                        defaultValue={webhooksHeaders}
                                        onChange={(e) =>
                                            setWebhooksHeaders(e.target.value, "headers")
                                        }
                                    />
                                </Popover>
                                <span className="opt-label-title">
                                    {t("settings.webhooks.conversationNotifications")}
                                </span>
                                <div
                                    style={{
                                        display: "flex",
                                        marginBottom: "20px",
                                        marginTop: "20px",
                                    }}
                                >
                                    <span style={{ marginLeft: "15px" }}>
                                        {t("settings.webhooks.newConversation")}
                                    </span>
                                    <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                                        <Switch
                                            checked={newConversationWebhook}
                                            onChange={onChangeNewConversationEventsSetting}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        marginBottom: "20px",
                                        marginTop: "20px",
                                    }}
                                >
                                    <span style={{ marginLeft: "15px" }}>
                                        {t("settings.webhooks.conversationUpdated")}
                                    </span>
                                    <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                                        <Switch
                                            checked={conversationUpdatedWebhook}
                                            onChange={onChangeConversationUpdatedEventsSetting}
                                        />
                                    </div>
                                </div>
                                <span className="opt-label-title">
                                    {t("settings.webhooks.whatsappEvents")}
                                </span>
                                <div
                                    style={{
                                        display: "flex",
                                        marginBottom: "20px",
                                        marginTop: "20px",
                                    }}
                                >
                                    <span style={{ marginLeft: "15px" }}>
                                        {t("settings.webhooks.sent")}
                                    </span>
                                    <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                                        <Switch
                                            checked={gupshupSentWebhook}
                                            onChange={onChangeGupshupSentEventsSetting}
                                        />
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        marginBottom: "20px",
                                        marginTop: "20px",
                                    }}
                                >
                                    <span style={{ marginLeft: "15px" }}>
                                        {t("settings.webhooks.read")}
                                    </span>
                                    <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                                        <Switch
                                            checked={gupshupReadWebhook}
                                            onChange={onChangeGupshupReadEventsSetting}
                                        />
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        marginBottom: "20px",
                                        marginTop: "20px",
                                    }}
                                >
                                    <span style={{ marginLeft: "15px" }}>
                                        {t("settings.webhooks.failed")}
                                    </span>
                                    <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                                        <Switch
                                            checked={gupshupFailedWebhook}
                                            onChange={onChangeGupshupFailedEventsSetting}
                                        />
                                    </div>
                                </div>

                                <span className="opt-label-title">
                                    {t("settings.webhooks.whatsappCampaigns")}
                                </span>
                                <div style={{ marginLeft: "1rem" }}>
                                    <Text type="secondary" style={{ fontSize: ".9rem" }}>
                                        {t("settings.webhooks.whatsappCampaignsHelpText")}
                                    </Text>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        marginBottom: "20px",
                                        marginTop: "20px",
                                    }}
                                >
                                    <span style={{ marginLeft: "15px" }}>
                                        {t("settings.webhooks.enabled")}
                                    </span>
                                    <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                                        <Switch
                                            checked={whatsappCampaignOnFinish}
                                            onChange={onChangeCampaignOnFinishSetting}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={t("settings.Integrations")} key={integrationsTabKey}>
                        {/*Integrations */}
                        <div className="opt-settings-tab opt-settings-tab--integrations">
                            <div className="opt-card opt-card--settings">
                                <IntegrationsSettings />
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </ConfigProvider>
    );
}

export default Settings;
