import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt } from "frontend-lib/DomainTypes/Prompts";
import usePrompts from "./usePrompts";
import { NavLink } from "react-router-dom";
import TitleTooltip from "components/TitleTooltip/TitleTooltip";
import {
    Table,
    Card,
    Space,
    Popconfirm,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPencilAlt,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Loader from "components/Loader/Loader";
import "./Prompts.scss";

function Prompts() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [promptsList, , , , deletePrompt, ] = usePrompts(setIsLoading);

    const columns: ColumnsType<Prompt> = [
        {
            title: t("prompts.key"),
            dataIndex: "key",
            key: "key",
            align: "center",
        },
        {
            title: t("prompts.createdOn"),
            dataIndex: "createdOn",
            key: "createdOn",
            align: "center",
            render: (data) => (data ? moment(data).format("DD/MM/YY HH:mm:ss") : "-")
        },
        {
            title: t("prompts.updatedOn"),
            dataIndex: "updatedOn",
            key: "updatedOn",
            align: "center",
            render: (data) => (data ? moment(data).format("DD/MM/YY HH:mm:ss") : "-")
        },
        {
            title: t("actions"),
            fixed: "right",
            align: "center",
            render: (record: Prompt) => (
                <Space size="middle">
                    <NavLink
                        to={{
                            pathname: `/dashboard/prompts/form`,
                            state: record
                        }}
                        className="opt-table__button"
                    >
                        <button className="opt-table__button">
                            <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                    </NavLink>
                    <Popconfirm
                        title={`${t("prompts.deletePromptsConfirm")}`}
                        okText={t("yes")}
                        cancelText={t("no")}
                        //@ts-ignore
                        onConfirm={() => deletePrompt(record.id)} 
                    >
                        <button className="opt-table__button"> 
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div className="opt-view opt-view--prompts-list">
            <Loader isLoading={isLoading} />
            <div className="opt-view-header">
                <Space size="small">
                    <h1 className="opt-title">{t("prompts.title")}</h1>
                    <TitleTooltip content={t("prompts.titleTooltip")} />
                </Space>
                <div className="opt-view-header__actions">
                    <button className="opt-button">
                        <NavLink to="/dashboard/prompts/form">
                            {t("prompts.createPrompt")}
                        </NavLink>
                    </button>
                </div>
            </div>

            <Card bodyStyle={{ padding: 0 }}>
                <Table
                    //@ts-ignore
                    rowKey={(record) => record?.id}
                    columns={columns}
                    dataSource={promptsList}
                    pagination={false}
                />
            </Card>

        </div>
    )
}

export default Prompts;
