import { Controlled as CodeMirror } from "react-codemirror2";
import { useLocation, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "codemirror/mode/xml/xml";
import "codemirror/mode/javascript/javascript";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { Form, Tabs, Switch, Checkbox, Input } from "antd";
import api from "../../../requests/api";
import "./Skills.scss";
import { notification } from "antd";
import Loader from "../../../components/Loader/Loader";
import AnswerTextArea from "./AnswerTextArea/AnswerTextArea";
import { INITIAL_STATE } from "./init_state";
import { AppContext } from "../../../components/AppContext";

const { TabPane } = Tabs;
const FormItem = Form.Item;

function Skills() {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [skillsConfig, setSkillConfig] = useState(INITIAL_STATE);
    const gptIntegrationIsEnabledForWorkspace = useMemo(() => {
        return appContext.state.workspaceConfigs?.backend?.qaModelType === "LLM";
    }, [appContext.state.workspaceConfigs?.backend?.qaModelType]);

    const handleInputChange = function (group, key, evt) {
        const value = evt.target.value;
        setSkillConfig((config) => {
            const newConfig = { ...config };
            newConfig[group][key] = value;
            return newConfig;
        });
    };

    const toggleCheckbox = function (group, key) {
        setSkillConfig((config) => {
            const newConfig = { ...config };
            newConfig[group][key] = !config[group][key];
            return newConfig;
        });
    };

    const xmlIsValid = function (title, xmlString) {
        if (xmlString === "") {
            return true;
        }
        const parser = new DOMParser();
        const dom = parser.parseFromString(xmlString, "application/xml");
        if (isParseError(title, dom)) {
            return false;
        }
        return dom;
    };

    function isParseError(title, parsedDocument) {
        let parser = new DOMParser();
        let errorneousParse = parser.parseFromString("<", "application/xml");
        let parsererrorNS = errorneousParse.getElementsByTagName("parsererror")[0].namespaceURI;
        if (parsererrorNS === "http://www.w3.org/1999/xhtml") {
            if (parsedDocument.getElementsByTagName("parsererror").length > 0) {
                notification.error({
                    message: `${title} XML ERROR`,
                    description: parsedDocument.getElementsByTagName("parsererror")[0]["innerText"],
                    duration: 4,
                });
            }
            return parsedDocument.getElementsByTagName("parsererror").length > 0;
        }

        return parsedDocument.getElementsByTagNameNS(parsererrorNS, "parsererror").length > 0;
    }

    const saveConfigs = function () {
        setIsLoading(true);

        if (
            xmlIsValid("Router", skillsConfig.router.definition) &&
            xmlIsValid("Handoff", skillsConfig.handOff.xmlGuideBeforeHandOver)
        ) {
            api.chatbots()
                .createConfigs(skillsConfig, location.state.id)
                .then(() => {
                    setIsLoading(false);
                    notification.success({
                        message: "Sucess",
                        description: "Skills updated",
                        duration: 4,
                    });
                })
                .catch((error) => {
                    setIsLoading(false);
                    notification.error({
                        message: "Error",
                        description: error.response.data.error.message,
                        duration: 4,
                    });
                });
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        api.chatbots()
            .getConfigs(location.state.id)
            .then((res) => {
                setIsLoading(false);
                setSkillConfig((currentConfig) => {
                    return { currentConfig, ...res.data.botConfigs };
                });
            })
            .catch((error) => {
                setIsLoading(false);
                notification.error({
                    message: "Error",
                    description: error.response?.data?.error?.message,
                    duration: 4,
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="opt-view opt-view--skills">
            <Loader isLoading={isLoading} />
            {/*
             * Header - Save button
             */}
            <div className="opt-view-header">
                <div>
                    <NavLink to="/dashboard/chatbots/">
                        <h1 className="opt-title">← {t("chatbots.title")}</h1>
                    </NavLink>
                    <h1 className="opt-subtitle">
                        {t("skills.Skills")} - {location?.state?.name || ""}
                    </h1>
                </div>
                <div className="opt-view-header__actions">
                    <button className="opt-button" onClick={saveConfigs}>
                        {" "}
                        {t("skills.Save Changes")}
                    </button>
                </div>
            </div>
            {/*
             * Tabs
             */}
            <Tabs defaultActiveKey="1">
                <TabPane tab={t("skills.general")} key="1">
                    <div className="opt-skills-tab opt-skills-tab--general">
                        <div className="opt-card opt-card--skills">
                            <h2 className="opt-subtitle">{t("skills.Other questions")}</h2>
                            <p className="opt-text">
                                {t("skills.Defaults and other general questions")}
                            </p>
                            <div className="opt-input-group">
                                <h2 className="opt-subtitle">{t("skills.Handoff after errors")}</h2>
                                <span>
                                    {t("skills.How many errors will suggest an Agent connection")}
                                </span>
                                <FormItem
                                    validateStatus={
                                        !skillsConfig.general
                                            .suggestAConnectionWithAnAgentAtNFailuresTryingDetectIntention &&
                                        "error"
                                    }
                                    help={
                                        !skillsConfig.general
                                            .suggestAConnectionWithAnAgentAtNFailuresTryingDetectIntention &&
                                        t("notBlank")
                                    }
                                >
                                    <Input
                                        type="number"
                                        placeholder="Number of errors allowed"
                                        value={
                                            skillsConfig.general
                                                .suggestAConnectionWithAnAgentAtNFailuresTryingDetectIntention
                                        }
                                        onChange={(evt) =>
                                            handleInputChange(
                                                "general",
                                                "suggestAConnectionWithAnAgentAtNFailuresTryingDetectIntention",
                                                evt
                                            )
                                        }
                                    />
                                </FormItem>
                            </div>
                            <AnswerTextArea
                                label={"skills.Undetected question Ask more data"}
                                value={skillsConfig.general.couldNotBeUnderstood}
                                placeholder={"skills.couldNotBeUnderstoodPlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange("general", "couldNotBeUnderstood", evt)
                                }
                            />
                            <AnswerTextArea
                                label={"skills.Undetected question"}
                                value={skillsConfig.general.plainCouldNotBeUnderstood}
                                placeholder={"skills.plainCouldNotBeUnderstoodPlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange("general", "plainCouldNotBeUnderstood", evt)
                                }
                            />
                            <div className="opt-input-group">
                                <h2 className="opt-subtitle">
                                    {t("skills.chatbotBehaviourWhenAudio")}
                                </h2>
                                <Checkbox
                                    checked={
                                        skillsConfig.general
                                            .suggestAConnectionWithAnAgentWhenReceivingAudioMessageType
                                    }
                                    onChange={() =>
                                        toggleCheckbox(
                                            "general",
                                            "suggestAConnectionWithAnAgentWhenReceivingAudioMessageType"
                                        )
                                    }
                                >
                                    {t("skills.suggestAConnectionWithAgentAudio")}
                                </Checkbox>
                            </div>
                            <div className="opt-card__header">
                                <h2 className="opt-subtitle">
                                    {t("skills.gptIntegrationIsActive")}{" "}
                                </h2>
                                <div className="opt-checkbox-group">
                                    <Switch
                                        disabled={!gptIntegrationIsEnabledForWorkspace}
                                        checked={skillsConfig.general.gptIntegrationIsActive}
                                        onChange={() =>
                                            toggleCheckbox("general", "gptIntegrationIsActive")
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="opt-card opt-card--skills">
                            <h2 className="opt-subtitle">{t("skills.General information")}</h2>
                            <p className="opt-text">
                                {t("skills.Basic questions and answers that the bot will handle")}
                            </p>
                            <AnswerTextArea
                                label={"skills.Starter question"}
                                value={skillsConfig.general.whatCanIDoForYou}
                                placeholder={"skills.whatCanIDoForYouPlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange("general", "whatCanIDoForYou", evt)
                                }
                            />
                            <AnswerTextArea
                                label={"skills.canIDoSomethingElse"}
                                value={skillsConfig.general.canIDoSomethingElse}
                                placeholder={"skills.canIDoSomethingElse"}
                                onChangeCb={(evt) =>
                                    handleInputChange("general", "canIDoSomethingElse", evt)
                                }
                            />
                            <AnswerTextArea
                                label={"skills.Thank you"}
                                value={skillsConfig.general.thankYou}
                                placeholder={"skills.Thank you"}
                                onChangeCb={(evt) => handleInputChange("general", "thankYou", evt)}
                            />
                            <AnswerTextArea
                                label={"skills.Your welcome"}
                                value={skillsConfig.general.yourWelcome}
                                placeholder={"skills.Your welcome"}
                                onChangeCb={(evt) =>
                                    handleInputChange("general", "yourWelcome", evt)
                                }
                            />
                            <AnswerTextArea
                                label={"skills.Yes/No question"}
                                value={skillsConfig.general.replyYesOrNo}
                                placeholder={"skills.replyYesOrNoPlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange("general", "replyYesOrNo", evt)
                                }
                            />
                            <AnswerTextArea
                                label={"skills.Ask a question again"}
                                value={skillsConfig.general.whatElseCanIDoForYou}
                                placeholder={"skills.whatElseCanIDoForYouPlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange("general", "whatElseCanIDoForYou", evt)
                                }
                            />
                        </div>
                    </div>
                </TabPane>
                <TabPane tab={t("skills.greetings")} key="2">
                    <div className="opt-skills-tab">
                        <div className="opt-card opt-card--skills">
                            <div className="opt-card__header">
                                <h2 className="opt-subtitle">{t("skills.initialGreeting")}</h2>
                            </div>
                            <AnswerTextArea
                                label={"skills.chatbotOpening"}
                                value={skillsConfig.greetings.welcomeGreetings}
                                placeholder={"skills.chatbotOpeningPlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange("greetings", "welcomeGreetings", evt)
                                }
                            />
                            <AnswerTextArea
                                label={"skills.clientSayHi"}
                                value={skillsConfig.greetings.multiplesGreetings}
                                placeholder={"skills.clientSayHiPlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange("greetings", "multiplesGreetings", evt)
                                }
                            />
                            <div className="opt-card__header">
                                <h2 className="opt-subtitle">
                                    {t("skills.sendChattingInstructions")}{" "}
                                </h2>
                                <div className="opt-checkbox-group">
                                    <Switch
                                        checked={skillsConfig.greetings.sendChattingInstructions}
                                        onChange={() =>
                                            toggleCheckbox("greetings", "sendChattingInstructions")
                                        }
                                    />
                                </div>
                            </div>
                            <AnswerTextArea
                                disabled={!skillsConfig.greetings.sendChattingInstructions}
                                label={"skills.chattingInstructions"}
                                value={skillsConfig.greetings.chattingInstructions}
                                placeholder={"skills.chattingInstructions"}
                                onChangeCb={(evt) =>
                                    handleInputChange("greetings", "chattingInstructions", evt)
                                }
                            />
                        </div>
                    </div>
                    <div className="opt-card opt-card--skills">
                        <div className="opt-input-group">
                            <h2 className="opt-subtitle">{t("skills.greetByXmlEditor")}</h2>
                            <CodeMirror
                                value={skillsConfig.greetings.xml}
                                options={{
                                    mode: "xml",
                                    theme: "material",
                                    lineNumbers: true,
                                }}
                                onBeforeChange={(editor, data, value) => {
                                    handleInputChange("greetings", "xml", {
                                        target: { value: value },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </TabPane>
                <TabPane tab={t("skills.leadGeneration")} key="3">
                    <div className="opt-skills-tab opt-skills-tab--lead">
                        <div className="opt-card opt-card--skills">
                            <div className="opt-card__header">
                                <h2 className="opt-subtitle">{t("skills.enableLead")}</h2>
                                <div className="opt-checkbox-group">
                                    <Switch
                                        checked={skillsConfig.customerData.isMandatory}
                                        onChange={() =>
                                            toggleCheckbox("customerData", "isMandatory")
                                        }
                                    />
                                </div>
                            </div>
                            <AnswerTextArea
                                disabled={!skillsConfig.customerData.isMandatory}
                                label={"skills.requiredDataBeforeContinue"}
                                value={skillsConfig.general.customerDataRequiredBeforeContinue}
                                placeholder={"skills.customerDataRequiredBeforeContinuePlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange(
                                        "general",
                                        "customerDataRequiredBeforeContinue",
                                        evt
                                    )
                                }
                            />
                            <div className="opt-input-group">
                                <Checkbox
                                    disabled={!skillsConfig.customerData.isMandatory}
                                    checked={skillsConfig.customerData.askName}
                                    onChange={() => toggleCheckbox("customerData", "askName")}
                                >
                                    {t("skills.askuser")}
                                </Checkbox>
                                <AnswerTextArea
                                    value={skillsConfig.customerData.nameQuestion}
                                    disabled={!skillsConfig.customerData.askName}
                                    placeholder={"skills.askuser"}
                                    onChangeCb={(evt) =>
                                        handleInputChange("customerData", "nameQuestion", evt)
                                    }
                                />
                            </div>
                            <h2 className="opt-subtitle">Email</h2>
                            <div className="opt-input-group">
                                <Checkbox
                                    disabled={!skillsConfig.customerData.isMandatory}
                                    checked={skillsConfig.customerData.askEmail}
                                    onChange={() => toggleCheckbox("customerData", "askEmail")}
                                >
                                    {t("skills.askuserEmail")}
                                </Checkbox>
                                <AnswerTextArea
                                    value={skillsConfig.customerData.emailQuestion}
                                    disabled={!skillsConfig.customerData.askEmail}
                                    placeholder={"skills.askuserEmailPlaceholder"}
                                    onChangeCb={(evt) =>
                                        handleInputChange("customerData", "emailQuestion", evt)
                                    }
                                />
                            </div>
                            <AnswerTextArea
                                label="skills.invalidEmail"
                                value={skillsConfig.customerData.invalidEmail}
                                disabled={!skillsConfig.customerData.askEmail}
                                placeholder={"skills.invalidEmailPlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange("customerData", "invalidEmail", evt)
                                }
                            />
                            <AnswerTextArea
                                label="skills.continueWithoutEmail"
                                value={skillsConfig.customerData.couldNotCollectEmail}
                                disabled={!skillsConfig.customerData.askEmail}
                                placeholder={"skills.invalidEmailBotMessage"}
                                onChangeCb={(evt) =>
                                    handleInputChange("customerData", "couldNotCollectEmail", evt)
                                }
                            />
                        </div>
                        <div className="opt-card opt-card--skills">
                            <h2 className="opt-subtitle">{t("skills.phone")}</h2>
                            <div className="opt-input-group">
                                <Checkbox
                                    disabled={!skillsConfig.customerData.isMandatory}
                                    checked={skillsConfig.customerData.askPhone}
                                    onChange={() => toggleCheckbox("customerData", "askPhone")}
                                >
                                    {t("skills.askPhone")}
                                </Checkbox>
                                <AnswerTextArea
                                    value={skillsConfig.customerData.phoneQuestion}
                                    disabled={!skillsConfig.customerData.askPhone}
                                    placeholder={"skills.phonePlaceHolder"}
                                    onChangeCb={(evt) =>
                                        handleInputChange("customerData", "phoneQuestion", evt)
                                    }
                                />
                            </div>
                            <AnswerTextArea
                                label="skills.invalidPhone"
                                value={skillsConfig.customerData.invalidPhoneNumber}
                                disabled={!skillsConfig.customerData.askPhone}
                                placeholder={"skills.invalidPhone"}
                                onChangeCb={(evt) =>
                                    handleInputChange("customerData", "invalidPhoneNumber", evt)
                                }
                            />
                            <AnswerTextArea
                                label="skills.continuePhone"
                                value={skillsConfig.customerData.couldNotCollectPhone}
                                disabled={!skillsConfig.customerData.askPhone}
                                placeholder={"skills.invalidPhone"}
                                onChangeCb={(evt) =>
                                    handleInputChange("customerData", "couldNotCollectPhone", evt)
                                }
                            />
                            <AnswerTextArea
                                label="skills.thankyouMessage"
                                value={skillsConfig.customerData.thankYouForYourDetails}
                                disabled={!skillsConfig.customerData.isMandatory}
                                placeholder={"skills.thankyouMessagePlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange("customerData", "thankYouForYourDetails", evt)
                                }
                            />
                        </div>
                        <div className="opt-card opt-card--skills">
                            <h2 className="opt-subtitle">{t("skills.whatsappOptin")}</h2>
                            <div className="opt-input-group">
                                <Checkbox
                                    disabled={!skillsConfig.customerData.isMandatory}
                                    checked={skillsConfig.customerData.askWhatsappOptIn}
                                    onChange={() =>
                                        toggleCheckbox("customerData", "askWhatsappOptIn")
                                    }
                                >
                                    {t("skills.askWhastapp")}
                                </Checkbox>
                                <AnswerTextArea
                                    value={skillsConfig.customerData.whatsappOptInQuestion}
                                    disabled={!skillsConfig.customerData.askWhatsappOptIn}
                                    placeholder={"skills.whatsappOptinPlaceholder"}
                                    onChangeCb={(evt) =>
                                        handleInputChange(
                                            "customerData",
                                            "whatsappOptInQuestion",
                                            evt
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="opt-card opt-card--skills">
                        <div className="opt-input-group">
                            <h2 className="opt-subtitle">{t("skills.xmlEditor")}</h2>
                            <CodeMirror
                                value={skillsConfig.customerData.xml}
                                options={{
                                    mode: "xml",
                                    theme: "material",
                                    lineNumbers: true,
                                }}
                                onBeforeChange={(editor, data, value) => {
                                    handleInputChange("customerData", "xml", {
                                        target: { value: value },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </TabPane>
                <TabPane tab={t("skills.answerfaq")} key="4">
                    <div className="opt-skills-tab">
                        <div className="opt-card opt-card--skills">
                            <div className="opt-card__header">
                                <h2 className="opt-subtitle">{t("skills.answserConfig")}</h2>
                            </div>
                            <div className="opt-input-group">
                                <Checkbox
                                    checked={skillsConfig.qa.isActive}
                                    onChange={() => toggleCheckbox("qa", "isActive")}
                                >
                                    {t("skills.enableKnoqledgeFaq")}
                                </Checkbox>
                            </div>
                            <div className="opt-input-group">
                                <Checkbox
                                    checked={skillsConfig.qa.sendSimilarQuestions}
                                    onChange={() => toggleCheckbox("qa", "sendSimilarQuestions")}
                                >
                                    {t("skills.useOption")}
                                </Checkbox>
                            </div>
                            <div className="opt-input-group">
                                <span>{t("skills.maxAmountOfOptions")}</span>
                                <FormItem
                                    validateStatus={
                                        !skillsConfig.qa.sendNSimilarQuestion && "error"
                                    }
                                    help={!skillsConfig.qa.sendNSimilarQuestion && t("notBlank")}
                                >
                                    <Input
                                        type="number"
                                        value={skillsConfig.qa.sendNSimilarQuestion}
                                        placeholder="5"
                                        onChange={(evt) =>
                                            handleInputChange("qa", "sendNSimilarQuestion", evt)
                                        }
                                    />
                                </FormItem>
                            </div>
                            <AnswerTextArea
                                label="skills.neitherWasUsefull"
                                value={skillsConfig.qa.noneOfTheAbove}
                                placeholder={"skills.noneOfAbove"}
                                onChangeCb={(evt) => handleInputChange("qa", "noneOfTheAbove", evt)}
                            />
                            <AnswerTextArea
                                label="skills.askIsAnotherWay"
                                value={skillsConfig.qa.askInAnotherWay}
                                placeholder={"skills.askIsAnotherWayPlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange("qa", "askInAnotherWay", evt)
                                }
                            />
                            <div className="opt-input-group">
                                <Checkbox
                                    checked={skillsConfig.qa.askForFeedback}
                                    onChange={() => toggleCheckbox("qa", "askForFeedback")}
                                >
                                    {t("skills.askFeedback")}
                                </Checkbox>
                            </div>
                            <div className="opt-input-group">
                                <Checkbox
                                    checked={skillsConfig.qa.askForHumanHelpAfterNegativeFeedback}
                                    onChange={() =>
                                        toggleCheckbox("qa", "askForHumanHelpAfterNegativeFeedback")
                                    }
                                >
                                    {t("skills.askHumanAssistant")}
                                </Checkbox>
                            </div>
                            <div className="opt-input-group">
                                <span>{t("skills.howManyBotError")}</span>
                                <FormItem
                                    validateStatus={
                                        !skillsConfig.qa
                                            .suggestAConnectionWithAnAgentAtTheFailureNumber &&
                                        "error"
                                    }
                                    help={
                                        !skillsConfig.qa
                                            .suggestAConnectionWithAnAgentAtTheFailureNumber &&
                                        t("notBlank")
                                    }
                                >
                                    <Input
                                        type="number"
                                        value={
                                            skillsConfig.qa
                                                .suggestAConnectionWithAnAgentAtTheFailureNumber
                                        }
                                        placeholder={t("skills.howManyBotErrorPlaceholder")}
                                        onChange={(evt) =>
                                            handleInputChange(
                                                "qa",
                                                "suggestAConnectionWithAnAgentAtTheFailureNumber",
                                                evt
                                            )
                                        }
                                    />
                                </FormItem>
                            </div>
                            <div className="opt-input-group">
                                <span>{t("skills.howManyBotError")}</span>
                                <FormItem
                                    validateStatus={
                                        !skillsConfig.qa
                                            .suggestAConnectionWithAnAgentAtTheFailureNumber &&
                                        "error"
                                    }
                                    help={
                                        !skillsConfig.qa
                                            .suggestAConnectionWithAnAgentAtTheFailureNumber &&
                                        t("notBlank")
                                    }
                                >
                                    <Input
                                        type="number"
                                        value={
                                            skillsConfig.qa
                                                .suggestAConnectionWithAnAgentAtTheFailureNumber
                                        }
                                        placeholder={t("skills.howManyBotErrorPlaceholder")}
                                        onChange={(evt) =>
                                            handleInputChange(
                                                "qa",
                                                "suggestAConnectionWithAnAgentAtTheFailureNumber",
                                                evt
                                            )
                                        }
                                    />
                                </FormItem>
                            </div>
                            <AnswerTextArea
                                label="skills.questionCanNotbeAnswered"
                                value={skillsConfig.qa.canNotAnswerThat}
                                placeholder={"skills.questionCanNotbeAnsweredPlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange("qa", "canNotAnswerThat", evt)
                                }
                            />
                            <AnswerTextArea
                                label="skills.negativeAnswer"
                                value={skillsConfig.qa.canNotAnswerThat}
                                placeholder={"skills.negativeAnswerPlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange("qa", "messageAfterNegativeFeedback", evt)
                                }
                            />
                            <AnswerTextArea
                                label="skills.positiveFeedback"
                                value={skillsConfig.qa.messageAfterPositiveFeedback}
                                placeholder={"skills.positiveAnswerPlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange("qa", "messageAfterPositiveFeedback", evt)
                                }
                            />
                            <AnswerTextArea
                                label="skills.similarQuestions"
                                value={skillsConfig.qa.similarQuestions}
                                placeholder={"skills.similarQuestionsPlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange("qa", "similarQuestions", evt)
                                }
                            />
                            <div className="opt-input-group">
                                <Checkbox
                                    checked={skillsConfig.qa.tryEndChatAfterPositiveFeedback}
                                    onChange={() =>
                                        toggleCheckbox("qa", "tryEndChatAfterPositiveFeedback")
                                    }
                                >
                                    {t("skills.shouldBotEnd")}
                                </Checkbox>
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane tab={t("skills.handoff")} key="5">
                    <>
                        <div className="opt-skills-tab">
                            <div className="opt-card opt-card--skills">
                                <div className="opt-card__header">
                                    <h2 className="opt-subtitle">{t("skills.enableHandoff")} </h2>
                                    <div className="opt-checkbox-group">
                                        <Switch
                                            checked={skillsConfig.handOff.isActive}
                                            onChange={() => toggleCheckbox("handOff", "isActive")}
                                        />
                                    </div>
                                </div>
                                <div className="opt-card__header">
                                    <h2 className="opt-subtitle">
                                        {t("skills.disableHandoffOutOfOfficeHours")}{" "}
                                    </h2>
                                    <div className="opt-checkbox-group">
                                        <Switch
                                            checked={
                                                skillsConfig.handOff
                                                    .deactivateHandOverOutOfOfficeHours
                                            }
                                            onChange={() =>
                                                toggleCheckbox(
                                                    "handOff",
                                                    "deactivateHandOverOutOfOfficeHours"
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <AnswerTextArea
                                    label="skills.handoffMessage"
                                    value={
                                        skillsConfig.handOff.helpDetectedWithoutAgentsAndHandOverOff
                                    }
                                    placeholder={"skills.enableHandoffPlaceholder"}
                                    onChangeCb={(evt) =>
                                        handleInputChange(
                                            "handOff",
                                            "helpDetectedWithoutAgentsAndHandOverOff",
                                            evt
                                        )
                                    }
                                />
                                <AnswerTextArea
                                    label="skills.intentionDetected"
                                    value={skillsConfig.handOff.helpDetected}
                                    placeholder={"skills.intentionDetectedPlaceholder"}
                                    onChangeCb={(evt) =>
                                        handleInputChange("handOff", "helpDetected", evt)
                                    }
                                />
                                <AnswerTextArea
                                    label="skills.outOfficeHours"
                                    value={skillsConfig.handOff.helpDetectedWithoutAgents}
                                    placeholder={"skills.outOfficeHoursPlaceholder"}
                                    onChangeCb={(evt) =>
                                        handleInputChange(
                                            "handOff",
                                            "helpDetectedWithoutAgents",
                                            evt
                                        )
                                    }
                                />
                                <AnswerTextArea
                                    label="skills.handedOverHuman"
                                    value={skillsConfig.handOff.agentHandOverInOfficeHours}
                                    placeholder={"skills.handedOverHumanPlaceholder"}
                                    onChangeCb={(evt) =>
                                        handleInputChange(
                                            "handOff",
                                            "agentHandOverInOfficeHours",
                                            evt
                                        )
                                    }
                                />
                                <AnswerTextArea
                                    label="skills.handedOverHumanWhatsappFacebook"
                                    value={
                                        skillsConfig.handOff
                                            .agentHandOverOutOfOfficeHoursWithAsyncCapabilities
                                    }
                                    placeholder={
                                        "skills.handedOverHumanWhatsappFacebookPlaceholder"
                                    }
                                    onChangeCb={(evt) =>
                                        handleInputChange(
                                            "handOff",
                                            "agentHandOverOutOfOfficeHoursWithAsyncCapabilities",
                                            evt
                                        )
                                    }
                                />
                                <AnswerTextArea
                                    label="skills.outOfficeHoursWebChat"
                                    value={
                                        skillsConfig.handOff
                                            .agentHandOverOutOfOfficeHoursWithoutAsyncCapabilities
                                    }
                                    placeholder={"skills.outOfficeHoursWebChatPlaceholder"}
                                    onChangeCb={(evt) =>
                                        handleInputChange(
                                            "handOff",
                                            "agentHandOverOutOfOfficeHoursWithoutAsyncCapabilities",
                                            evt
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="opt-card opt-card--skills">
                            <div className="opt-input-group">
                                <h2 className="opt-subtitle">{t("skills.guideBeforeHandoff")}</h2>
                                <CodeMirror
                                    value={skillsConfig.handOff.xmlGuideBeforeHandOver}
                                    options={{
                                        mode: "xml",
                                        theme: "material",
                                        lineNumbers: true,
                                    }}
                                    onBeforeChange={(editor, data, value) => {
                                        handleInputChange("handOff", "xmlGuideBeforeHandOver", {
                                            target: { value: value },
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </>
                </TabPane>
                <TabPane tab={t("skills.closing")} key="6">
                    <div className="opt-skills-tab">
                        <div className="opt-card opt-card--skills">
                            <AnswerTextArea
                                label="skills.closingMessage"
                                value={skillsConfig.closing.farewell}
                                placeholder={"skills.closingMessagePlaceholder"}
                                onChangeCb={(evt) => handleInputChange("closing", "farewell", evt)}
                            />
                        </div>
                    </div>
                    <div className="opt-card opt-card--skills">
                        <div className="opt-input-group">
                            <h2 className="opt-subtitle">{t("skills.closingByXmlEditor")}</h2>
                            <CodeMirror
                                value={skillsConfig.closing.xml}
                                options={{
                                    mode: "xml",
                                    theme: "material",
                                    lineNumbers: true,
                                }}
                                onBeforeChange={(editor, data, value) => {
                                    handleInputChange("closing", "xml", {
                                        target: { value: value },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </TabPane>
                <TabPane tab={t("skills.appointments")} key="7">
                    <div className="opt-skills-tab">
                        <div className="opt-card opt-card--skills">
                            <div className="opt-card__header">
                                <h2 className="opt-subtitle">{t("skills.enableMenu")}</h2>
                                <div className="opt-checkbox-group">
                                    <Switch
                                        checked={skillsConfig.appointment.isActive}
                                        onChange={() => toggleCheckbox("appointment", "isActive")}
                                    />
                                </div>
                            </div>
                            <AnswerTextArea
                                label="skills.appointmentDetected"
                                value={skillsConfig.appointment.appointmentDetected}
                                placeholder={"skills.appointmentDetectedPlaceholder"}
                                onChangeCb={(evt) =>
                                    handleInputChange("appointment", "appointmentDetected", evt)
                                }
                            />
                        </div>
                    </div>
                    <div className="opt-card opt-card--skills">
                        <div className="opt-input-group">
                            <h2 className="opt-subtitle">{t("skills.appointmentByXmlEditor")}</h2>
                            <CodeMirror
                                value={skillsConfig.appointment.xml}
                                options={{
                                    mode: "xml",
                                    theme: "material",
                                    lineNumbers: true,
                                }}
                                onBeforeChange={(editor, data, value) => {
                                    handleInputChange("appointment", "xml", {
                                        target: { value: value },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </TabPane>
                <TabPane tab={t("skills.externalEvents")} key="8">
                    <div className="opt-card opt-card--skills">
                        <div className="opt-input-group">
                            <h2 className="opt-subtitle">XML</h2>
                            <CodeMirror
                                value={skillsConfig.externalEventProcessor.xml}
                                options={{
                                    mode: "xml",
                                    theme: "material",
                                    lineNumbers: true,
                                }}
                                onBeforeChange={(editor, data, value) => {
                                    handleInputChange("externalEventProcessor", "xml", {
                                        target: { value: value },
                                    });
                                }}
                            />
                        </div>
                    </div>
                </TabPane>
                <TabPane tab={t("skills.menurouting")} key="9">
                    <div className="opt-skills-tab--routing">
                        <div className="opt-card opt-card--skills">
                            <div className="opt-card__header">
                                <h2 className="opt-subtitle">{t("skills.enableMenu")}</h2>
                                <div className="opt-checkbox-group">
                                    <Switch
                                        checked={skillsConfig.router.isActive}
                                        onChange={() => toggleCheckbox("router", "isActive")}
                                    />
                                </div>
                            </div>
                            <div className="opt-input-group">
                                <span>{t("skills.chooseAnOption")}</span>
                                <FormItem
                                    validateStatus={
                                        !skillsConfig.router.chooseOneOfTheOptions && "error"
                                    }
                                    help={
                                        !skillsConfig.router.chooseOneOfTheOptions && t("notBlank")
                                    }
                                >
                                    <Input
                                        type="text"
                                        value={skillsConfig.router.chooseOneOfTheOptions}
                                        placeholder="Choose an option Text"
                                        onChange={(evt) =>
                                            handleInputChange(
                                                "router",
                                                "chooseOneOfTheOptions",
                                                evt
                                            )
                                        }
                                    />
                                </FormItem>
                            </div>
                            <div className="opt-input-group">
                                <span>{t("skills.knownQuestions")}</span>
                                <FormItem
                                    validateStatus={
                                        !skillsConfig.router.answersTheBotKnow && "error"
                                    }
                                    help={!skillsConfig.router.answersTheBotKnow && t("notBlank")}
                                >
                                    <Input
                                        type="text"
                                        value={skillsConfig.router.answersTheBotKnow}
                                        placeholder={t("skills.knownQuestions")}
                                        onChange={(evt) =>
                                            handleInputChange("router", "answersTheBotKnow", evt)
                                        }
                                    />
                                </FormItem>
                            </div>
                            <div className="opt-input-group">
                                <span>{t("skills.knownQuestionsGoback")}</span>
                                <FormItem
                                    validateStatus={
                                        !skillsConfig.router.wantToReturnToMenuQuestion && "error"
                                    }
                                    help={
                                        !skillsConfig.router.wantToReturnToMenuQuestion &&
                                        t("notBlank")
                                    }
                                >
                                    <Input
                                        type="text"
                                        value={skillsConfig.router.wantToReturnToMenuQuestion}
                                        placeholder={t("skills.knownQuestionsGobackPlaceholder")}
                                        onChange={(evt) =>
                                            handleInputChange(
                                                "router",
                                                "wantToReturnToMenuQuestion",
                                                evt
                                            )
                                        }
                                    />
                                </FormItem>
                            </div>
                            <div className="opt-input-group">
                                <span>{t("skills.knownQuestionsMaxQuestions")}</span>
                                <FormItem
                                    validateStatus={
                                        !skillsConfig.router.searchKnowledgeByTagLimit && "error"
                                    }
                                    help={
                                        !skillsConfig.router.searchKnowledgeByTagLimit &&
                                        t("notBlank")
                                    }
                                >
                                    <Input
                                        type="number"
                                        value={skillsConfig.router.searchKnowledgeByTagLimit}
                                        placeholder={t("skills.knownQuestionAllowed")}
                                        onChange={(evt) =>
                                            handleInputChange(
                                                "router",
                                                "searchKnowledgeByTagLimit",
                                                evt
                                            )
                                        }
                                    />
                                </FormItem>
                            </div>
                            <div className="opt-input-group">
                                <span>{t("skills.askGoback")}</span>
                                <FormItem
                                    validateStatus={
                                        !skillsConfig.router.askIfWantToGoBack && "error"
                                    }
                                    help={!skillsConfig.router.askIfWantToGoBack && t("notBlank")}
                                >
                                    <Input
                                        type="text"
                                        value={skillsConfig.router.askIfWantToGoBack}
                                        placeholder={t("skills.askGobackPlaceholder")}
                                        onChange={(evt) =>
                                            handleInputChange("router", "askIfWantToGoBack", evt)
                                        }
                                    />
                                </FormItem>
                            </div>
                            <div className="opt-input-group">
                                <span>{t("skills.yesWord")}</span>
                                <FormItem
                                    validateStatus={!skillsConfig.general.yes && "error"}
                                    help={!skillsConfig.general.yes && t("notBlank")}
                                >
                                    <Input
                                        type="text"
                                        value={skillsConfig.general.yes}
                                        placeholder={t("skills.yesPlaceholder")}
                                        onChange={(e) => {
                                            handleInputChange("general", "yes", e);
                                        }}
                                        maxLength="20"
                                    ></Input>
                                </FormItem>
                            </div>
                            <div className="opt-input-group">
                                <span>{t("skills.noWord")}</span>
                                <FormItem
                                    validateStatus={!skillsConfig.general.no && "error"}
                                    help={!skillsConfig.general.no && t("notBlank")}
                                >
                                    <Input
                                        type="text"
                                        value={skillsConfig.general.no}
                                        placeholder={t("skills.noPlaceholder")}
                                        onChange={(e) => {
                                            handleInputChange("general", "no", e);
                                        }}
                                        maxLength="20"
                                    ></Input>
                                </FormItem>
                            </div>
                            <div className="opt-input-group">
                                <span>{t("skills.returnWord")}</span>
                                <FormItem
                                    validateStatus={!skillsConfig.router.returnWord && "error"}
                                    help={!skillsConfig.router.returnWord && t("notBlank")}
                                >
                                    <Input
                                        type="text"
                                        value={skillsConfig.router.returnWord}
                                        placeholder={t("skills.returnPlaceholder")}
                                        onChange={(e) => {
                                            handleInputChange("router", "returnWord", e);
                                        }}
                                        maxLength="20"
                                    ></Input>
                                </FormItem>
                            </div>
                            <div>
                                <Checkbox
                                    checked={skillsConfig.router.afterOpenQaShowFoundQuestions}
                                    onChange={() =>
                                        toggleCheckbox("router", "afterOpenQaShowFoundQuestions")
                                    }
                                >
                                    {t("skills.afterQuestion")}
                                </Checkbox>
                            </div>
                            <div className="opt-input-group">
                                <Checkbox
                                    checked={skillsConfig.router.alwaysInterceptFirstMessage}
                                    onChange={() =>
                                        toggleCheckbox("router", "alwaysInterceptFirstMessage")
                                    }
                                >
                                    {t("skills.firstMessage")}
                                </Checkbox>
                            </div>
                        </div>
                        <div className="opt-card opt-card--skills opt-card--xml">
                            <div className="opt-input-group">
                                <h2 className="opt-subtitle">{t("skills.definition")}</h2>
                                <CodeMirror
                                    value={skillsConfig.router.definition}
                                    style={{ minHeight: "600px" }}
                                    options={{
                                        mode: "xml",
                                        theme: "material",
                                        lineNumbers: true,
                                    }}
                                    onBeforeChange={(editor, data, value) => {
                                        handleInputChange("router", "definition", {
                                            target: { value: value },
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default Skills;
