import { useCallback, useEffect, useState } from "react";
import api from "../../../requests/api";
import { ChatChannels } from "../../../frontend-lib/DomainTypes/ChatChannels"

export interface ChatbotRepresentation {
    id: number;
    name: string;
    isActive: boolean;
    chatChannels: ChatChannels[];
    botConfigId: number;
}

export default function useChatbots(): [
    ChatbotRepresentation[],
    (chatbots: ChatbotRepresentation[]) => void
] {
    const [chatbotRepresentations, setChatbotRepresentations] = useState<ChatbotRepresentation[]>(
        []
    );
    const loadChatbots = useCallback(async () => {
        const res = await api.chatbots().list();
        setChatbotRepresentations(() => [...chatbotRepresentations, ...res.data.chatBots]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadChatbots();
    }, [loadChatbots]);

    return [chatbotRepresentations, setChatbotRepresentations];
}
