import { faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch, Radio, Form, Input, Button, List, Divider, Col, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import React from "react";
import { useTranslation } from "react-i18next";
import { EditWorkspaceConfigsProps } from "./Types";

interface NotificationEmailConfig {
    backend: {
        notificationsEmails?: {
            enabled: boolean;
            sendToCustom: boolean;
            emails: string[];
        };
    };
}

const { Item } = Form;

enum RadioButtonValue {
    Admin,
    Custom,
}

function ErrorMailSettings({
    configs,
    setConfig,
}: EditWorkspaceConfigsProps<NotificationEmailConfig>) {
    const mailEnabled = configs.backend.notificationsEmails?.enabled;
    const sendToCustom = configs.backend.notificationsEmails?.sendToCustom;
    const emails = configs.backend.notificationsEmails?.emails;
    const { t } = useTranslation();

    function onClickRemoveEmail(email: string) {
        const config = { ...configs };
        if (!config.backend.notificationsEmails) {
            return;
        } // This shouldn't happen. It's a safety check

        config.backend.notificationsEmails.emails =
            config.backend.notificationsEmails.emails.filter((e: string) => e !== email);
        setConfig(config);
    }

    function addNotificationObjectIfNotPresent(
        config: NotificationEmailConfig
    ): NotificationEmailConfig {
        const newConfig = { ...config };
        if (!newConfig.backend.notificationsEmails) {
            newConfig.backend.notificationsEmails = {
                enabled: false,
                sendToCustom: false,
                emails: [],
            };
        }
        return newConfig;
    }

    function onAddMailClick(values: { email: string }) {
        const email = values.email;
        const newConfig = addNotificationObjectIfNotPresent(configs);
        if (email.length === 0) {
            return;
        }

        const found = newConfig.backend.notificationsEmails?.emails.find(
            (e: string) => e === email
        );
        if (found) {
            return;
        }

        newConfig.backend.notificationsEmails!.emails = [
            ...newConfig.backend.notificationsEmails!.emails,
            email,
        ];
        setConfig(newConfig);
    }

    function onChangeSetMailEnabled(checked: boolean) {
        const newConfig = addNotificationObjectIfNotPresent(configs);
        newConfig.backend.notificationsEmails!.enabled = checked;
        setConfig(newConfig);
    }

    function onChangeRadioButton(e: RadioChangeEvent) {
        const newSendToCustom = e.target.value === RadioButtonValue.Custom;
        const newConfig = addNotificationObjectIfNotPresent(configs);
        newConfig.backend.notificationsEmails!.sendToCustom = newSendToCustom;
        setConfig(newConfig);
    }

    const validateMessages = {
        types: {
            email: t("settings.notificationsEmails.emailNotValid"),
        },
    };

    function sendMailToCustomDisabled(): boolean {
        return !mailEnabled || !sendToCustom;
    }

    return (
        <>
            <div>
                <div style={{ display: "flex", marginBottom: "20px" }}>
                    <span style={{ fontWeight: "bold" }}>
                        {t("settings.notificationsEmails.title")}
                    </span>
                    <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                        <Switch checked={mailEnabled} onChange={onChangeSetMailEnabled} />
                    </div>
                </div>
                <span style={{ fontSize: 14, color: "#727272" }}>
                    {t("settings.notificationsEmails.description")}
                </span>
            </div>
            <Row>
                <Col span={1}>
                    <Divider type="vertical" style={{ height: "100%" }} />
                </Col>
                {mailEnabled && (
                    <Col span={23}>
                        <Radio.Group
                            value={sendToCustom ? RadioButtonValue.Custom : RadioButtonValue.Admin}
                            onChange={(e) => onChangeRadioButton(e)}
                        >
                            <Radio value={RadioButtonValue.Admin}>
                                {t("settings.notificationsEmails.sendToAdmin")}
                            </Radio>
                            <Radio value={RadioButtonValue.Custom}>
                                {t("settings.notificationsEmails.sendToCustom")}
                            </Radio>
                        </Radio.Group>
                        <Form
                            layout="horizontal"
                            validateMessages={validateMessages}
                            onFinish={onAddMailClick}
                            style={{ display: "flex" }}
                        >
                            <Item label="Email" rules={[{ type: "email" }]} name={["email"]}>
                                <Input disabled={sendMailToCustomDisabled()} />
                            </Item>
                            <Item style={{ marginLeft: "5px", borderRadius: "15px" }}>
                                <Button
                                    disabled={sendMailToCustomDisabled()}
                                    className="opt-icon-button"
                                    style={{ borderRadius: "10px" }}
                                    htmlType="submit"
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </Item>
                        </Form>
                        {emails && emails.length > 0 ? (
                            <>
                                <List
                                    header={t("settings.notificationsEmails.predefinedEmails")}
                                    size="small"
                                >
                                    {emails ? (
                                        emails.map((email: string) => (
                                            <List.Item
                                                actions={[
                                                    <button
                                                        style={{ borderRadius: "10px" }}
                                                        disabled={sendMailToCustomDisabled()}
                                                        onClick={() => onClickRemoveEmail(email)}
                                                        className="opt-icon-button"
                                                    >
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </button>,
                                                ]}
                                            >
                                                {email}
                                            </List.Item>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </List>
                            </>
                        ) : (
                            <></>
                        )}
                    </Col>
                )}
            </Row>
        </>
    );
}

export default ErrorMailSettings;
