import React, { useState, useEffect, useContext } from "react";
import api from "requests/api";
import { Table, Card, Space, Button, message } from "antd";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { UploadOutlined } from "@ant-design/icons";
import { AppContext } from "components/AppContext";
import Loader from "components/Loader/Loader";
import FileUpload from "./FileUpload/FileUpload";
export default function Files() {
    const { t } = useTranslation();
    const [files, setFiles] = useState([]);
    const [page, setPage] = useState(0);
    const [hasNext, setHasNext] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const appContext = useContext(AppContext);
    const columns = [
        {
            title: t("name"),
            dataIndex: "name",
            key: 1,
        },
        {
            title: t("type"),
            dataIndex: "fileType",
            key: 2,
        },
        {
            title: t("actions"),
            fixed: "right",
            render: (text, record) => (
                <Space size="middle">
                    <Button>
                        <a rel="noreferrer" href={text.url} target="_blank">
                            {t("view")}
                        </a>
                    </Button>
                    <Button danger onClick={() => handleDelete(record)}>
                        {t("delete")}
                    </Button>
                </Space>
            ),
        },
    ];
    function loadMoreItems() {
        setIsLoading(true);
        setPage(page + 1);
        api.files()
            .list(page)
            .then((res) => {
                setFiles([...files, ...res.data.filesMetadata]);
                setHasNext(res.data.paginator.hasNext);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                message.error(err.response?.data);
            });
    }
    function loadItems() {
        api.files()
            .list(0)
            .then((res) => {
                setIsLoading(false);
                setFiles(res.data.filesMetadata);
                setHasNext(res.data.paginator.hasNext);
            })
            .catch((err) => {
                setIsLoading(false);
                message.error(err.response?.data);
            });
    }

    function handleDelete(item) {
        setIsLoading(true);
        api.files()
            .deleteFileMetadata(item.id)
            .then((res) => {
                loadItems();
            })
            .catch((err) => {
                message.error(err.response.data?.error?.message);
                setIsLoading(false);
            });
    }

    const toggleModal = (val) => setIsModalOpen(val);

    const uploadFile = async (formData, fileToUpload, multimediaType) => {
        try {
            const { data } = await api.file().uploadFile(appContext.state.workspaceId, formData);
            const fileMetadata = {
                name: fileToUpload.name,
                url: data.url,
                fileType: multimediaType,
            };
            await api.files().createFileMetadata(fileMetadata);
            message.success(t("files.fileUploaded"));
            setIsLoading(false);
            setIsModalOpen(false);
            loadItems();
        } catch (e) {
            message.error(e.response?.data?.message);
            setIsLoading(false);
        }
    };

    function openModal() {
        setIsModalOpen(true);
    }

    useEffect(() => {
        loadItems();
    }, []);

    return (
        <div className="opt-view opt-view--files">
            <Loader isLoading={isLoading} />
            <div className="opt-view-header">
                <h1 className="opt-title">{t("files.title")}</h1>
                <div className="opt-view-header__actions">
                    <div className="filters">
                        <Button onClick={openModal}>
                            <UploadOutlined /> {t("upload")}{" "}
                        </Button>
                    </div>
                </div>
            </div>
            <Card bodyStyle={{ padding: 0 }}>
                <Table
                    rowKey={(record) => record.id}
                    columns={columns}
                    dataSource={files}
                    pagination={false}
                />
                {hasNext && (
                    <div className="view-more" style={{ margin: "20px" }} onClick={loadMoreItems}>
                        <span className="view-more__text">
                            {t("viewMore")}
                            <FontAwesomeIcon icon={faChevronDown} />
                        </span>
                    </div>
                )}
            </Card>
            <FileUpload
                isModalOpen={isModalOpen}
                uploadCb={uploadFile}
                toggleModalCb={toggleModal}
            />
        </div>
    );
}
