import React from "react";
import { i18next } from "../../../../../../lang/i18n";
class InteractionsByHourChartItem {
    constructor(name = null, hour = null) {
        this.name = name;
        this.hour = hour;
        this.amount = null;
        this.formatedHour = this.getFormatted(this.hour);
    }
    setAmount(amount) {
        this.amount = amount;
    }
    getFormatted(hour) {
        return hour;
    }
}
const interactionsChartItemTexts = [
    {
        id: 1,
        name: i18next.t("reportInteractions.bot"),
        label: i18next.t("reportInteractions.bot"),
        description: i18next.t("reportInteractions.botDesc"),
        color: "#83E1BD",
    },
    {
        id: 2,
        name: i18next.t("reportInteractions.agent"),
        label: i18next.t("reportInteractions.agent"),
        description: i18next.t("reportInteractions.agentDesc"),
        color: "#5D7092",
    },
    {
        id: 3,
        name: i18next.t("reportInteractions.user"),
        label: i18next.t("reportInteractions.user"),
        description: i18next.t("reportInteractions.userDesc"),
        color: "#F8CD50",
    },
    {
        id: 4,
        name: i18next.t("reportInteractions.userToBot"),
        label: i18next.t("reportInteractions.userToBot"),
        description: i18next.t("reportInteractions.userToBotDesc"),
        color: "#7666F9",
    },
    {
        id: 5,
        name: i18next.t("reportInteractions.userToAgent"),
        label: i18next.t("reportInteractions.userToAgent"),
        description: i18next.t("reportInteractions.userToAgentDesc"),
        color: "#74CBED",
    },
];
const interactionsByHourConfig = {
    height: 300,
    xField: "formatedHour",
    yField: "amount",
    isGroup: true,
    seriesField: "name",
    legend: {
        position: "top-left",
    },
    tooltip: {
        formatter: (data) => {
            let visibleData = {};
            visibleData.name = interactionsChartItemTexts.find(
                (item) => item.name === data.name
            ).label;
            visibleData.value = data.amount;
            return visibleData;
        },
        showMarkers: false,
    },
    color: (data) => {
        return interactionsChartItemTexts.find((item) => item.name === data.name).color;
    },
};

const interactionsByHourModal = (
    <ul>
        {interactionsChartItemTexts.map((item) => (
            <li>
                <h3>
                    <span
                        style={{
                            display: "inline-block",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            marginRight: "5px",
                            background: item.color,
                        }}
                    ></span>
                    {item.name}
                </h3>
                <p>{item.description}</p>
            </li>
        ))}
    </ul>
);

export {
    interactionsByHourConfig,
    InteractionsByHourChartItem,
    interactionsChartItemTexts,
    interactionsByHourModal,
};
