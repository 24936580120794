import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import "./Paginator.scss";
const WithPaginator = ({
    page = null,
    hasNext = null,
    showPage = true,
    changePageCb = null,
    loadMoreCb = null,
    children,
}) => {
    const { t } = useTranslation();
    if (showPage) {
        return (
            <>
                {children}
                <div className="opt-paginator opt-card__pagination">
                    {page >= 2 && (
                        <FontAwesomeIcon
                            onClick={() => changePageCb(0)}
                            className="opt-paginator__icon opt-paginator__icon--first"
                            icon={faAngleDoubleLeft}
                        />
                    )}
                    {page >= 1 && (
                        <FontAwesomeIcon
                            onClick={() => changePageCb(page - 1)}
                            className="opt-paginator__icon opt-paginator__icon--previous"
                            icon={faAngleLeft}
                        />
                    )}
                    <p className="opt-paginator__page-content">
                        <span className="opt-paginator__label">{t("page")}: </span>
                        <span className="opt-paginator__page">{page + 1}</span>
                    </p>
                    {hasNext && (
                        <FontAwesomeIcon
                            onClick={() => changePageCb(page + 1)}
                            className="opt-paginator__icon opt-paginator__icon--next"
                            icon={faAngleRight}
                        />
                    )}
                </div>
            </>
        );
    }
    return (
        <>
            {children}
            {hasNext && (
                <div className="view-more" onClick={loadMoreCb}>
                    <span className="view-more__text">
                        {" "}
                        {t("customers.viewMore")} <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                </div>
            )}
        </>
    );
};
export default WithPaginator;
