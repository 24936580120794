import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppContext } from "components/AppContext";
import ChannelsConfig from "components/ChannelsConfig/ChannelsConfig";
import ConfigWrapper from "components/ConfigWrapper";
import Loader from "components/Loader/Loader";
import logo from "img/dashboardlogo.png";
import notFound from "img/question-not-found.png";
import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Route, Switch } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Chatbots from "./Chatbots/Chatbots";
import Customers from "./Customers/Customers";
import CustomersImport from "./Customers/CustomersImport";
import "./Dashboard.scss";
import Files from "./Files/Files";
import Home from "./Home/Home";
import Intentions from "./Intentions/Intentions";
import IntentionsForm from "./Intentions/IntentionsForm";
import Knowledge from "./Knowledge/Knowledge";
import KnowledgeForm from "./Knowledge/KnowledgeForm/KnowledgeForm";
import MonitoringList from "./Monitoring/MonitoringList";
import Monitoring from "./Monitoring/Monitoring";
import Outbound from "./Outbound/Outbound";
import Profile from "./Profile/Profile";
import Prompts from "./Prompts/Prompts";
import PromptsForm from "./Prompts/PromptsForm";
import Reports from "./Reports/Reports";
import Settings from "./Settings/Settings";
import Skills from "./Skills/Skills";
import Tags from "./Tags/Tags";
import AgentStateHistory from "./Users/AgentStateHistory";
import Users from "./Users/Users";

function Dashboard(props) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [openedSubmenus, setOpenedSubmenus] = useState([]);
    // eslint-disable-next-line
    const sideNavActiveClass = isOpen ? "active" : "";
    const appContext = useContext(AppContext);
    const logout = useAuth()[1];

    const performLogout = useCallback(async () => {
        try {
            setIsLoading(true);
            await logout();
        } finally {
            setIsLoading(false);
        }
    }, [logout]);

    const toggleSubmenu = (submenuKey) => {
        setOpenedSubmenus((prevOpenedSubmenus) =>
            prevOpenedSubmenus.includes(submenuKey)
                ? prevOpenedSubmenus.filter((menu) => menu !== submenuKey)
                : [...prevOpenedSubmenus, submenuKey]
        );
    };

    return (
        <ConfigWrapper>
            <div className="opt-grid-container">
                <Loader isLoading={isLoading} />
                <div className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
                    <FontAwesomeIcon icon={faBars} />
                </div>
                <aside className={`sidenav ${sideNavActiveClass}`}>
                    <div className="sidenav__close-icon" onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                    <div className="sidenav__logo">
                        <img src={logo} alt="dashboard logo" className="dashboard-logo" />
                        <p style={{ marginTop: "20px", fontSize: "12px" }}>
                            {(appContext.state.workspaceName || "").toUpperCase()}
                        </p>
                    </div>
                    <ul className="sidenav__list">
                        <li onClick={() => setIsOpen(false)}>
                            <NavLink
                                className="sidenav__list-item"
                                activeClassName="sidenav__list-item--active"
                                to="/dashboard/home"
                            >
                                {t("sidebar.home")}
                            </NavLink>
                        </li>
                        {appContext.state.userData.isAdmin && (
                            <li>
                                <div
                                    className={`sidenav__list-menu ${
                                        openedSubmenus.includes("agents") ? "open" : ""
                                    }`}
                                    onClick={() => {
                                        toggleSubmenu("agents");
                                    }}
                                >
                                    {t("sidebar.agents")}
                                </div>
                                <ul
                                    className={`sidenav__submenu ${
                                        openedSubmenus.includes("agents") ? "open" : ""
                                    }`}
                                >
                                    <li
                                        className="sidenav__submenu-item"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsOpen(false);
                                        }}
                                    >
                                        <NavLink
                                            className="sidenav__list-item"
                                            activeClassName="sidenav__list-item--active"
                                            to="/dashboard/users"
                                        >
                                            {t("sidebar.users")}
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        )}

                        <li>
                            <div
                                className={`sidenav__list-menu ${
                                    openedSubmenus.includes("chatbots") ? "open" : ""
                                }`}
                                onClick={() => {
                                    toggleSubmenu("chatbots");
                                }}
                            >
                                {t("sidebar.chatbots")}
                            </div>
                            <ul
                                className={`sidenav__submenu ${
                                    openedSubmenus.includes("chatbots") ? "open" : ""
                                }`}
                            >
                                <li
                                    className="sidenav__submenu-item"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsOpen(false);
                                    }}
                                >
                                    <NavLink
                                        className="sidenav__list-item"
                                        activeClassName="sidenav__list-item--active"
                                        to="/dashboard/knowledge"
                                    >
                                        {t("sidebar.answers")}
                                    </NavLink>
                                </li>
                                <li
                                    className="sidenav__submenu-item"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsOpen(false);
                                    }}
                                >
                                    <NavLink
                                        className="sidenav__list-item"
                                        activeClassName="sidenav__list-item--active"
                                        to="/dashboard/chatbots"
                                    >
                                        {t("sidebar.assistant")}
                                    </NavLink>
                                </li>
                                {appContext.state?.globalUser?.isSysadmin && (
                                    <li
                                        className="sidenav__submenu-item"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsOpen(false);
                                        }}
                                    >
                                        <NavLink
                                            className="sidenav__list-item"
                                            activeClassName="sidenav__list-item--active"
                                            to="/dashboard/prompts"
                                        >
                                            {t("sidebar.prompts")}
                                        </NavLink>
                                    </li>
                                )}
                                <li
                                    className="sidenav__submenu-item"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsOpen(false);
                                    }}
                                >
                                    <NavLink
                                        className="sidenav__list-item"
                                        activeClassName="sidenav__list-item--active"
                                        to="/dashboard/intentions"
                                    >
                                        {t("sidebar.intentions")}
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <div
                                className={`sidenav__list-menu ${
                                    openedSubmenus.includes("clients") ? "open" : ""
                                }`}
                                onClick={() => {
                                    toggleSubmenu("clients");
                                }}
                            >
                                {t("sidebar.customers")}
                            </div>
                            <ul
                                className={`sidenav__submenu ${
                                    openedSubmenus.includes("clients") ? "open" : ""
                                }`}
                            >
                                <li
                                    className="sidenav__submenu-item"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsOpen(false);
                                    }}
                                >
                                    <NavLink
                                        className="sidenav__list-item"
                                        activeClassName="sidenav__list-item--active"
                                        to="/dashboard/customers"
                                    >
                                        {t("sidebar.contacts")}
                                    </NavLink>
                                </li>
                                <li
                                    className="sidenav__submenu-item"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsOpen(false);
                                    }}
                                >
                                    <NavLink
                                        className="sidenav__list-item"
                                        activeClassName="sidenav__list-item--active"
                                        to="/dashboard/tags"
                                    >
                                        {t("sidebar.conversationTags")}
                                    </NavLink>
                                </li>
                                <li
                                    className="sidenav__submenu-item"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsOpen(false);
                                    }}
                                >
                                    <NavLink
                                        className="sidenav__list-item"
                                        activeClassName="sidenav__list-item--active"
                                        to="/dashboard/monitoring"
                                    >
                                        {t("sidebar.conversations")}
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li onClick={() => setIsOpen(false)}>
                            <NavLink
                                className="sidenav__list-item"
                                activeClassName="sidenav__list-item--active"
                                to="/dashboard/outbound/templates"
                            >
                                {t("sidebar.outbound")}
                            </NavLink>
                        </li>
                        <li onClick={() => setIsOpen(false)}>
                            <NavLink
                                className="sidenav__list-item"
                                activeClassName="sidenav__list-item--active"
                                to="/dashboard/reports"
                            >
                                {t("sidebar.reports")}
                            </NavLink>
                        </li>
                        <li onClick={() => setIsOpen(false)}>
                            <NavLink
                                className="sidenav__list-item"
                                activeClassName="sidenav__list-item--active"
                                to="/dashboard/files"
                            >
                                {t("sidebar.files")}
                            </NavLink>
                        </li>
                        <li onClick={() => setIsOpen(false)}>
                            <NavLink
                                className="sidenav__list-item"
                                activeClassName="sidenav__list-item--active"
                                to="/dashboard/settings"
                            >
                                {t("sidebar.settings")}
                            </NavLink>
                        </li>
                    </ul>
                    {
                        <button className="logout-button" onClick={performLogout}>
                            {t("sidebar.logout")}
                        </button>
                    }
                </aside>

                <main className="main">
                    <Switch>
                        {appContext.state.userData.isAdmin && (
                            <Route exact path="/dashboard/users">
                                <Users />
                            </Route>
                        )}
                        {appContext.state.userData.isAdmin && (
                            <Route exact path="/dashboard/users/history/:agentId">
                                <AgentStateHistory />
                            </Route>
                        )}
                        {appContext.state.userData.isAdmin && (
                            <Route exact path="/agents/:agentId/historic-agent-state">
                                <AgentStateHistory />
                            </Route>
                        )}
                        <Route exact path="/dashboard/home">
                            <Home />
                        </Route>
                        <Route exact path="/dashboard/home/channels-config">
                            <ChannelsConfig />
                        </Route>
                        <Route exact path="/dashboard/knowledge">
                            <Knowledge />
                        </Route>
                        <Route exact path="/dashboard/knowledge/form">
                            <KnowledgeForm />
                        </Route>
                        <Route exact path="/dashboard/customers">
                            <Customers />
                        </Route>
                        <Route exact path="/dashboard/customers/import">
                            <CustomersImport />
                        </Route>
                        <Route exact path="/dashboard/monitoring">
                            <MonitoringList />
                        </Route>
                        <Route path="/dashboard/monitoring/:chatId">
                            <Monitoring />
                        </Route>
                        <Route path="/dashboard/reports">
                            <Reports />
                        </Route>
                        <Route path="/dashboard/profile">
                            <Profile />
                        </Route>
                        <Route path="/dashboard/settings">
                            <Settings />
                        </Route>
                        <Route path="/dashboard/chatbots">
                            <Chatbots />
                        </Route>
                        <Route exact path="/dashboard/prompts">
                            <Prompts />
                        </Route>
                        <Route exact path="/dashboard/prompts/form">
                            <PromptsForm />
                        </Route>
                        <Route exact path="/dashboard/intentions">
                            <Intentions />
                        </Route>
                        <Route exact path="/dashboard/intentions/form">
                            <IntentionsForm />
                        </Route>
                        <Route path="/dashboard/skills">
                            <Skills />
                        </Route>
                        <Route path="/dashboard/files">
                            <Files />
                        </Route>
                        <Route path="/dashboard/tags">
                            <Tags />
                        </Route>
                        <Route path="/dashboard/outbound/:section?">
                            <Outbound />
                        </Route>
                        <Route path="/dashboard">
                            <div className="opt-view opt-view--not-found">
                                <div className="not-found">
                                    <img src={notFound} alt="Not found" />
                                    <span className="not-found-text">
                                        This section is under development <br />
                                    </span>
                                </div>
                            </div>
                        </Route>
                    </Switch>
                </main>
            </div>
        </ConfigWrapper>
    );
}
export default Dashboard;
