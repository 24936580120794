import { useTranslation } from "react-i18next";

// TODO: Move this to the /charts folder structure
// Each chart is an independent file

const defaultChartStyles = {
    height: 300,
};
const amountQuestionConfig = {
    xField: "date",
    yField: "amount",
    isGroup: true,
    seriesField: "name",
    ...defaultChartStyles,
};
const interactionsByHourConfig = {
    xField: "hour",
    yField: "amount",
    isGroup: true,
    seriesField: "name",
    ...defaultChartStyles,
};

function TranslatedConfigs() {
    const { t } = useTranslation();
    const agentAvgTimeInStateConfig = {
        xField: "hour",
        yField: "minutes",
        isPercent: true,
        isStack: true,
        seriesField: "state",
        ...defaultChartStyles,
        tooltip: {
            formatter: (data) => {
                if (data.state) {
                    return {
                        name: `${t("minutes")} ${data.state.toLowerCase()}`,
                        value: `${data.minutes ? Math.floor(data.minutes * 60) : 0}`,
                    };
                }
                return {
                    name: `${t("minutes")}`,
                    value: `${t("notRegistered")}`,
                };
            },
            showMarkers: false,
        },
    };
    const agentTimeInStateConfig = {
        xField: "date",
        yField: "minutes",
        isStack: true,
        seriesField: "state",
        tooltip: {
            formatter: (data) => {
                if (!data.minutes) {
                    return { name: `${t("time")}`, value: `${t("notRegistered")}` };
                }
                if (data.minutes > 60) {
                    const num = data.minutes;
                    const hours = num / 60;
                    const minutes = num % 60;
                    const rhours = Math.floor(hours);
                    const rminutes = Math.round(minutes);
                    return {
                        name: `${t("time")} ${data.state.toLowerCase()}`,
                        value: `${rhours} ${t("hours")} - ${rminutes} ${t("minutes")}`,
                    };
                } else {
                    return {
                        name: `${t("time")} ${data.state.toLowerCase()}`,
                        value: `${data.minutes} ${t("minutes")}`,
                    };
                }
            },
        },
        ...defaultChartStyles,
    };
    const agentServiceTimeConfig = {
        xField: "date",
        yField: "serviceTime",
        tooltip: {
            formatter: (data) => {
                let visibleData = null;
                if (data.serviceTime) {
                    if (data.serviceTime > 60) {
                        const num = data.serviceTime;
                        const hours = num / 60;
                        const rhours = Math.floor(hours);
                        const minutes = (hours - rhours) * 60;
                        const rminutes = Math.round(minutes);
                        visibleData = {
                            name: `${t("time")}`,
                            value: `${rhours} ${t("hours")}- ${rminutes} ${t("minutes")}`,
                        };
                    } else {
                        visibleData = {
                            name: `${t("time")}`,
                            value: `${data.serviceTime} ${t("minutes")}`,
                        };
                    }
                } else {
                    visibleData = {
                        name: `${t("time")}`,
                        value: `${t("notRegistered")}`,
                    };
                }
                return visibleData;
            },
            showMarkers: false,
        },
        ...defaultChartStyles,
    };
    const agentAverageInteractionsConfig = {
        xField: "date",
        yField: "averageInteractions",
        tooltip: {
            formatter: (data) => {
                let visibleData = null;
                if (data.averageInteractions) {
                    data.averageInteractions = parseFloat(data.averageInteractions).toFixed(1);
                    visibleData = {
                        name: `${t("agentReports.meanInt")}`,
                        value: `${data.averageInteractions}`,
                    };
                } else {
                    visibleData = {
                        name: `${t("agentReports.meanInt")}`,
                        value: `${t("notRegistered")}`,
                    };
                }
                return visibleData;
            },
            showMarkers: false,
        },
        ...defaultChartStyles,
    };
    const agentAmountChartConfig = {
        xField: "date",
        yField: "amount",
        isGroup: false,
        tooltip: {
            formatter: (data) => {
                if (data.amount) {
                    return { name: t("agentReports.amount"), value: data.amount };
                } else {
                    return {
                        name: t("agentReports.amount"),
                        value: `${t("notRegistered")}`,
                    };
                }
            },
        },
        ...defaultChartStyles,
    };
    const agentTotalInteractionsConfig = {
        xField: "date",
        yField: "interactions",
        tooltip: {
            formatter: (data) => {
                if (data.interactions) {
                    return {
                        name: t("agentReports.interactions"),
                        value: data.interactions,
                    };
                } else {
                    return {
                        name: t("agentReports.interactions"),
                        value: `${t("notRegistered")}`,
                    };
                }
            },
        },
        ...defaultChartStyles,
    };

    return {
        agentAvgTimeInStateConfig: agentAvgTimeInStateConfig,
        agentTimeInStateConfig: agentTimeInStateConfig,
        agentServiceTimeConfig: agentServiceTimeConfig,
        agentAverageInteractionsConfig: agentAverageInteractionsConfig,
        agentAmountChartConfig: agentAmountChartConfig,
        agentTotalInteractionsConfig: agentTotalInteractionsConfig,
    };
}

export { amountQuestionConfig, interactionsByHourConfig, TranslatedConfigs };
