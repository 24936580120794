import React, { useState, useMemo, useContext } from "react";
import { Route, useHistory } from "react-router-dom";
import { AppContext } from "components/AppContext";
import { Tag, Table, Space, Button, Spin, Modal, message, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import NewTemplateForm from "./NewTemplateForm";
import api from "../../../requests/api";
import ShowTemplate from "./ShowTemplate";

function Templates({ templateList, reloadCb, newTemplateCb }) {
    const history = useHistory();
    const appContext = useContext(AppContext);
    const [isModalVisible, setIsModalVisible] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [newTemplate, setNewTemplate] = useState({
        key: "",
        name: "",
        text: "",
    });
    const [modalState, setModalState] = useState("edit");
    const [isLoading, setIsLoading] = useState(false);
    const currentUserIsAdmin = useMemo(() => {
        return appContext.state?.userData?.isAdmin;
    }, [appContext.state?.userData?.isAdmin]);
    const { t } = useTranslation();
    const columns = [
        {
            title: t("outbound.templateObj.name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("outbound.templateObj.status"),
            dataIndex: "status",
            key: "status",
            render: (record) => (
                <Tag color={parseStatusTag(record)}>{parseStatusTranslation(record)}</Tag>
            ),
        },
        {
            title: t("outbound.templateObj.action"),
            key: "action",
            fixed: "right",
            align: "center",
            render: (record) => (
                <Space size="middle">
                    <Button onClick={() => openTemplateModal(record)}>
                        {t("outbound.templateObj.view")}
                    </Button>
                    <Popconfirm
                        title={`${t("outbound.templateObj.deleteTemplateConfirm")}`}
                        okText={t("yes")}
                        cancelText={t("no")}
                        onConfirm={() => handleTemplateDelete(record)}
                        disabled={!currentUserIsAdmin}
                    >
                        {currentUserIsAdmin ? (
                            <Button danger disabled={!currentUserIsAdmin}>
                                {" "}
                                {t("delete")}{" "}
                            </Button>
                        ) : (
                            <></>
                        )}
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    const parseStatusTag = (status) => {
        if (status === "APPROVED") {
            return "green";
        } else if (status === "DENIED") {
            return "red";
        } else {
            return "blue";
        }
    };
    const parseStatusTranslation = (status) => {
        return t(`outbound.templateObj.statusText.${status}`);
    };
    const openTemplateModal = function (record) {
        setModalState("edit");
        setSelectedTemplate(record);
        setIsModalVisible(true);
    };
    const handleErrorMessage = function (errorKey) {
        if (errorKey === "FORBIDDEN") {
            message.error(t("outbound.templateObj.onlyAdminCanDelete"));
        } else {
            message.error(t("outbound.templateObj.unexpectedErrorWhileDeleting"));
        }
    };
    const handleTemplateDelete = function (template) {
        api.outbound()
            .deleteWhatsappTemplate(template)
            .then((res) => {
                setIsLoading(false);
                message.success(t("outbound.templateObj.deleted"));
                reloadCb();
            })
            .catch((err) => {
                handleErrorMessage(err.response?.data?.error?.key);
            });
    };
    const handleInput = function (e) {
        setNewTemplate({ ...newTemplate, [e.target.name]: e.target.value });
    };
    const okModal = function () {
        if (modalState === "create") {
            setIsLoading(true);
            const template = {
                ...newTemplate,
                key: newTemplate.name.split(" ").join("_"),
            };
            api.outbound()
                .createWhatsappTemplate(template)
                .then((res) => {
                    setIsLoading(false);
                    message.success(t("outbound.templateObj.created"));
                    reloadCb();
                });
            setIsModalVisible(false);
        } else {
            setIsModalVisible(false);
        }
    };
    return (
        <>
            <Route exact path="/dashboard/outbound/templates">
                <Spin spinning={isLoading}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "20px",
                        }}
                    >
                        <Button
                            className="opt-button opt-button--light"
                            style={{ maxWidth: "15rem" }}
                            onClick={() => {
                                history.push("/dashboard/outbound/templates/new");
                            }}
                        >
                            {t("outbound.templateObj.createNewTemplate")}
                        </Button>
                    </div>
                    <div className="opt-card" style={{ padding: 0 }}>
                        <Table dataSource={templateList} columns={columns} pagination={false} />
                        <Modal
                            title={`${t("outbound.templateObj.template")} ${
                                selectedTemplate && selectedTemplate.id
                            }`}
                            visible={isModalVisible}
                            onOk={okModal}
                            onCancel={() => setIsModalVisible(false)}
                        >
                            <ShowTemplate template={selectedTemplate} />
                        </Modal>
                    </div>
                </Spin>
            </Route>
            <Route exact path="/dashboard/outbound/templates/new">
                <NewTemplateForm
                    templates={templateList}
                    reloadCb={reloadCb}
                    createTemplateCb={newTemplateCb}
                />
            </Route>
        </>
    );
}
export default Templates;
