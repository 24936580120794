import { useEffect } from "react";
import axios from "axios";
import useAuth from "./useAuth"

const UNAUTHORIZED = 401;
function useAxiosInterceptor(): undefined {
    const [deauthenticateUser] = useAuth();

    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use(
            config => {
                // Get req url & query params
                const url = new URL(config.url!);
                const queryParams = new URLSearchParams(url.search);
                const newParams = new URLSearchParams();

                // Get params without null values
                queryParams.forEach((val, key) => val !== "null" && newParams.append(key, val));
                // Use new values
                config.url = `${url.origin}${url.pathname}?${newParams}`;

                const token = window.localStorage.getItem("token");

                if (token) {
                    config.headers["Authorization"] = `Bearer ${token}`;
                }

                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

        const responseInterceptor = axios.interceptors.response.use(
            response => response,
            error => {
                if (error?.status === UNAUTHORIZED) {
                    deauthenticateUser();
                }
                return Promise.reject(error);
            }
        );

        // Clean up the interceptor when the component is unmounted
        return () => {
            axios.interceptors.response.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        };
    }, [deauthenticateUser]);

    return;
}

export default useAxiosInterceptor;
