import React, { useState } from "react";
import CrudManagerModal from "./CrudManagerModal";
function CrudManagerButton({
    light,
    buttonText,
    isLoading,
    modalTitle,
    closeCb,
    saveCb,
    createCb,
    deleteCb,
    dataSource,
    columns,
    formContent,
}) {
    const [isVisible, setIsVisible] = useState(false);
    function closeModal() {
        setIsVisible(false);
        closeCb();
    }
    return (
        <>
            <button
                onClick={() => setIsVisible(!isVisible)}
                className={`opt-button ${light && "opt-button--light"}`}
            >
                {buttonText}
            </button>
            <CrudManagerModal
                modalTitle={modalTitle}
                isVisible={isVisible}
                isLoading={isLoading}
                closeCb={closeModal}
                saveCb={saveCb}
                createCb={createCb}
                deleteCb={deleteCb}
                dataSource={dataSource}
                columns={columns}
                formContent={formContent}
            />
        </>
    );
}
export default CrudManagerButton;
