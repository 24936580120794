import React, { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import api from "../../requests/api";
import RouteErrorCatcher from "../ErrorBoundary/RouteErrorCatcher";
import Loader from "../Loader/Loader";
import * as queryString from "query-string";
import useAuth from "../../hooks/useAuth";


interface PrivateRouteProps {
    path: string,
    component: JSX.Element
}

function PrivateRoute({ path, component }: PrivateRouteProps) {
    // @ts-ignore
    const { state, actions } = useContext(AppContext);

    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const history = useHistory();
    const logout = useAuth()[1];



    useEffect(() => {
        const searchQueryString = queryString.parse(location.search);
        if (searchQueryString.accessToken) {
            localStorage.setItem("token", searchQueryString.accessToken as string);
            history.push(path);
        }

        setIsLoading(true);
        api.login()
            .isLoggedIn()
            .then((authResponse) => {
                actions.setUserData(authResponse.data, authResponse.data.workspace.id);
                actions.setIsLoggedIn(true);
            })
            .catch(async () => {
                await logout();
            }).finally(() => {
                setIsLoading(false)
            });
    }, []);

    return isLoading ? (
        <Loader isLoading={isLoading} />
    ) : state.isLoggedIn ? (
        <RouteErrorCatcher exact to={path} component={component} />
    ) : (
        <Redirect to={{ pathname: "/signin" }} />
    );
}

export default PrivateRoute;
