import React from "react";
import { Switch, Redirect, withRouter } from "react-router-dom";
import { AppConsumer } from "./components/AppContext";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import RouteErrorCatcher from "./components/ErrorBoundary/RouteErrorCatcher";
import PrivateRoute from "./components/PrivateRoute/PriveateRoute";
import Login from "./views/Login/Login";
import Signup from "./views/Signup/Signup";
import EarlyAdopterPlan from "./views/Payments/EarlyAdopterPlan";
import EarlyAdopterLicense from "./views/Payments/EarlyAdopterLicense";
import DiscountedLicense from "./views/Payments/DiscountedLicense";
import PartnerLicense from "./views/Payments/PartnerLicense";
import SetupEs from "./views/Payments/SetupEs";
import PremiumPlanEs from "./views/Payments/PremiumPlanEs";
import ResellerPremiumPlanEs from "./views/Payments/ResellerPremiumPlanEs";
import AdvancePlanEs from "./views/Payments/AdvancePlanEs";
import ResellerAdvancePlanEs from "./views/Payments/ResellerAdvancePlanEs";
import Success from "./views/Payments/Success";
import Canceled from "./views/Payments/Canceled";
import Dashboard from "./views/Dashboard/Dashboard";
import ChannelsConfigView from "./views/ChannelsConfig/ChannelsConfigView";
import FacebookCallback from "./views/FacebookCallback/FacebookCallback";
import "antd/dist/antd.css";
import "react-chat-widget/lib/styles.css";
import "./styles/main.scss";
import "font-awesome/css/font-awesome.min.css";
import Workspaces from "views/workspaces/Workspaces";
import Invitation from "views/Invitation/Invitation";
import SignIn from "views/Signin/SignIn";
import CreateWorkspace from "views/workspaces/CreateWorkspace";
import useAxiosInterceptor from "hooks/useAxiosInterceptor";

function App() {
    useAxiosInterceptor();

    return (
        <ErrorBoundary>
            <Switch>
                <RouteErrorCatcher exact path="/" component={SignIn} />
                <RouteErrorCatcher exact path="/start" component={Login} />
                <RouteErrorCatcher path="/signup" component={Signup} />
                <RouteErrorCatcher path="/createworkspace" component={CreateWorkspace} />
                <RouteErrorCatcher
                    exact
                    path="/payments/early-adopter-plan"
                    component={EarlyAdopterPlan}
                />
                <RouteErrorCatcher
                    exact
                    path="/payments/early-adopter-license"
                    component={EarlyAdopterLicense}
                />
                <RouteErrorCatcher
                    exact
                    path="/payments/discounted-license"
                    component={DiscountedLicense}
                />
                <RouteErrorCatcher exact path="/payments/partner" component={PartnerLicense} />
                <RouteErrorCatcher exact path="/payments/setup-es" component={SetupEs} />
                <RouteErrorCatcher
                    exact
                    path="/payments/premium-plan-es"
                    component={PremiumPlanEs}
                />
                <RouteErrorCatcher
                    exact
                    path="/payments/reseller-premium-plan-es"
                    component={ResellerPremiumPlanEs}
                />
                <RouteErrorCatcher
                    exact
                    path="/payments/advance-plan-es"
                    component={AdvancePlanEs}
                />
                <RouteErrorCatcher
                    exact
                    path="/payments/reseller-advance-plan-es"
                    component={ResellerAdvancePlanEs}
                />
                <RouteErrorCatcher exact path="/payments/success" component={Success} />
                <RouteErrorCatcher exact path="/payments/canceled" component={Canceled} />
                <PrivateRoute path="/workspaces" component={Workspaces} />
                <PrivateRoute path="/dashboard" component={Dashboard} />
                <PrivateRoute path="/channels-config" component={ChannelsConfigView} />
                <PrivateRoute path="/facebook-callback" component={FacebookCallback} />
                <RouteErrorCatcher path="/invitation" component={Invitation} />
                <Redirect from="*" to="/" />
            </Switch>
        </ErrorBoundary>
    );
}

/**
 * Just for access to the method and values of the context
 */
const AppRef = React.forwardRef((props, ref) => (
    <AppConsumer>{(ctx) => <App {...props} ctx={ctx} ref={ref} />}</AppConsumer>
));

/**
 * For access to the history API
 */
export default withRouter(AppRef);
