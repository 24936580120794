import React from "react";
import { AppContext } from "../../components/AppContext";
// resources
import logo from "../../img/logo.svg";
import "./Payments.scss";
import { withTranslation } from "react-i18next";
class EarlyAdopterPlanSuccess extends React.Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="section-login">
                <div className="section-login__logo">
                    <h1>
                        <img src={logo} alt="Optiwe logo" />
                        <span className="d-none">Optiwe</span>
                    </h1>
                    <p>{this.props.t("paymentSystem")}</p>
                </div>
                <div className="section-login__form">
                    <p>{this.props.t("payments.success")}</p>
                </div>
            </div>
        );
    }
}
EarlyAdopterPlanSuccess.contextType = AppContext;
export default withTranslation()(EarlyAdopterPlanSuccess);
