import React from "react";
import Loader from "../Loader/Loader";
function Button(props) {
    return (
        <button
            id={props.id}
            style={props.style}
            className={
                "opt-button " +
                (props.light ? "opt-button--light" : "") +
                (props.violet ? "opt-button--violet" : "")
            }
            disabled={props.isLoading || props.disabled}
        >
            {props.isLoading ? (
                <Loader
                    isLoading={props.isLoading}
                    style={{ width: "20px", height: "20px", borderTopColor: "white" }}
                />
            ) : (
                props.children
            )}
        </button>
    );
}
export default Button;
