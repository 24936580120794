import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getCharChannelSizesWarnings } from "frontend-lib/SizeChecker/";
import SizeWarnings from "frontend-lib/SizeChecker/SizeWarnings/SizeWarnings";

interface ChannelCharWarningsProps {
    text: string;
}

function ChannelCharWarnings({ text }: ChannelCharWarningsProps) {
    const { t } = useTranslation();
    const warnings = useMemo(() => {
        return getCharChannelSizesWarnings(text);
    }, [text]);

    return <SizeWarnings warnings={warnings} text={t("channelCharExceded")} />;
}

export default ChannelCharWarnings;
