import React, { useState, useEffect, useMemo, useContext } from "react";
import { useLocation, NavLink } from "react-router-dom";
import api from "../../../requests/api";
import "./Customers.scss";
import moment from "moment";
import {
    Table,
    Space,
    Button,
    Modal,
    Checkbox,
    Input,
    Select,
    message,
    Row,
    Col,
    Tooltip,
    Spin,
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faTrashAlt,
    faPencilAlt,
    faComments,
} from "@fortawesome/free-solid-svg-icons";
import CustomerGroups from "./CustomerGroups";
import { useTranslation } from "react-i18next";
import TitleTooltip from "../../../components/TitleTooltip/TitleTooltip";
import CustomerFilters from "./CustomerFilters";
import useCustomerFilter from "../../../hooks/useCustomerFilter";
import ACTIONS from "../Monitoring/filters/actions";
import EditCustomerModal from "./EditCustomer";
import { ExportCustomers } from "./ExportCustomers";
import { momentToYYMMDD } from "./momentUtils";
import { AppContext } from "components/AppContext";
import axios from "axios";

const agentUrl = process.env?.REACT_APP_AGENT_URL;

function Customers() {
    const { t } = useTranslation();
    const location = useLocation();
    const { state } = useContext(AppContext);
    const [filterState, filterDispatch] = useCustomerFilter();
    const [firstLoad, setFirstLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [exportCustomersLoading, setExportCustomersLoading] = useState(false);
    const [nextPage, setNextPage] = useState(false);
    const [page, setPage] = useState(0);
    const [customers, setCustomers] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isGroupsModalVisible, setIsGroupsModalVisible] = useState(false);
    const [selectedCustomersIds, setSelectedCustomersIds] = useState([]);
    const [isVisibleNewGroupModal, setIsVisibleNewGroupModal] = useState(false);
    const [newGroupName, setNewGroupName] = useState(null);
    const [editCustomerModalVisible, setEditCustomerModalVisible] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    /**
     * @type {number | undefined}
     */
    const [recordIdLoading, setRecordIdLoading] = useState();

    const userIsAdmin = useMemo(() => {
        return state.userData?.isAdmin;
    }, [state]);

    const columns = [
        {
            title: <Checkbox onClick={(e) => onChangeSelectAllCheckBox(e.target.checked)} />,
            render: (text, record) => (
                <Checkbox
                    checked={selectedCustomersIds.includes(record.id)}
                    onChange={(e) => onCustomerCheckboxChange(e.target.checked, record.id)}
                />
            ),
        },
        {
            title: (
                <>
                    <span style={{ marginRight: "5px" }}>{t("customers.name")}</span>
                    <TitleTooltip content={t("customers.nameTitleTooltip")} />
                </>
            ),
            dataIndex: "fullName",
            key: 1,
            render: (record, row) => (
                <>
                    {record}
                    {isCustomerOptedIn(row)}
                </>
            ),
        },
        {
            title: t("customers.email"),
            dataIndex: "email",
            key: 2,
            render: (data) => (data ? data : "-"),
        },
        {
            title: t("customers.phone"),
            dataIndex: "phone",
            key: 3,
            render: (data) => (data ? data : "-"),
        },
        {
            title: t("customers.createdOn"),
            dataIndex: "createdOn",
            key: 4,
            render: (data) => (data ? data : "-"),
        },
        {
            title: t("customers.lastInteraction"),
            dataIndex: "lastActivity",
            key: 5,
            render: (data) => (data ? data : "-"),
        },
        {
            title: t("customers.action"),
            fixed: "right",
            render: (text, record) => (
                <Space size="middle">
                    <button
                        onClick={() => {
                            openEditModalCustomer(record);
                        }}
                        className="opt-icon-button"
                    >
                        <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                    <button
                        className="opt-icon-button"
                        onClick={() => {
                            handleOpenConversation(record.id);
                        }}
                    >
                        {recordIdLoading === record.id ? (
                            <Spin size="small" />
                        ) : (
                            <Tooltip placement="left" title={t("customers.openLastConvHelpText")}>
                                <FontAwesomeIcon icon={faComments} />
                            </Tooltip>
                        )}
                    </button>
                    <button onClick={() => deleteCustomer(record)} className="opt-icon-button">
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                </Space>
            ),
        },
    ];

    const handleOpenConversation = async (customerId) => {
        setRecordIdLoading(customerId);
        try {
            const res = await api.customers().lastConvId(customerId);
            const convId = res.data.id;
            window.open(`${agentUrl}/chat/${convId}/`);
        } catch (e) {
            if (!axios.isAxiosError(e) || e.response?.status !== 404) {
                message.error(t("unknownError"));
            }
            message.error(t("customers.customerDoesNotHaveChat"));
        } finally {
            setRecordIdLoading(undefined);
        }
    };

    async function openEditModalCustomer(customer) {
        setSelectedCustomer(customer);
        setEditCustomerModalVisible(true);
    }

    function onChangeSelectAllCheckBox(checked) {
        if (checked) {
            const customersIds = customers.map((c) => c.id);
            setSelectedCustomersIds(customersIds);
        } else {
            setSelectedCustomersIds([]);
        }
    }

    function onCustomerCheckboxChange(checked, customerId) {
        const customerIdInt = parseInt(customerId);
        if (checked) {
            const customersIds = [...selectedCustomersIds];
            customersIds.push(customerIdInt);
            setSelectedCustomersIds(customersIds);
        } else {
            const customersIds = selectedCustomersIds.filter((id) => id !== customerIdInt);
            setSelectedCustomersIds(customersIds);
        }
    }

    const isCustomerOptedIn = function (customer) {
        if (customer.hasWhatsappOptIn) {
            return (
                <Space>
                    <div style={{ marginLeft: "8px" }}>
                        <CheckCircleOutlined />
                    </div>
                </Space>
            );
        }
    };

    const deleteCustomer = function (data) {
        setIsLoading(true);
        // todo: show notification
        api.customers()
            .delete(data.id)
            .then((response) => {
                loadItems();
            });
    };

    const loadMoreItems = function (data) {
        setIsLoading(true);
        const filterStartDate = filterState.customersStartDate
            ? momentToYYMMDD(filterState.customersStartDate)
            : null;
        const filterEndDate = filterState.customersEndDate
            ? momentToYYMMDD(filterState.customersEndDate)
            : null;
        const hasOptIn = filterState.hasOptIn;
        const customersId = filterState.customersId;
        const customerGroupsIds = filterState.customerGroupsIds;
        const conversationTagsIds = filterState.conversationTagsIds;
        const conversationGroupsIds = filterState.conversationGroupsIds;
        const agentsIds = filterState.agentsIds;
        const searchText = filterState.searchText;
        const orCustomersAndGroups = filterState.orCustomersAndGroups;
        const lastActivityStartDate = filterState.lastActivityStartDate
            ? momentToYYMMDD(filterState.lastActivityStartDate)
            : null;
        const lastActivityEndDate = filterState.lastActivityEndDate
            ? momentToYYMMDD(filterState.lastActivityEndDate)
            : null;
        setPage(page + 1);
        api.customers()
            .search(
                page + 1,
                searchText,
                filterStartDate,
                filterEndDate,
                hasOptIn,
                customersId,
                customerGroupsIds,
                conversationTagsIds,
                conversationGroupsIds,
                agentsIds,
                orCustomersAndGroups,
                lastActivityStartDate,
                lastActivityEndDate
            )
            .then((response) => {
                let formattedCustomers = formatCustomers(response.data.customers);
                setNextPage(response.data.paginator.hasNext);
                setCustomers([...customers, ...formattedCustomers]);
                setIsLoading(false);
            });
    };

    const loadItems = function () {
        setIsLoading(true);
        setPage(0);
        const filterStartDate = filterState.customersStartDate
            ? momentToYYMMDD(filterState.customersStartDate)
            : null;
        const filterEndDate = filterState.customersEndDate
            ? momentToYYMMDD(filterState.customersEndDate)
            : null;
        const hasOptIn = filterState.hasOptIn;
        const customersId = filterState.customersId;
        const customerGroupsIds = filterState.customerGroupsIds;
        const conversationTagsIds = filterState.conversationTagsIds;
        const conversationGroupsIds = filterState.conversationGroupsIds;
        const agentsIds = filterState.agentsIds;
        const searchText = filterState.searchText;
        const orCustomersAndGroups = filterState.orCustomersAndGroups;
        const lastActivityStartDate = filterState.lastActivityStartDate
            ? momentToYYMMDD(filterState.lastActivityStartDate)
            : null;
        const lastActivityEndDate = filterState.lastActivityEndDate
            ? momentToYYMMDD(filterState.lastActivityEndDate)
            : null;

        api.customers()
            .search(
                0,
                searchText,
                filterStartDate,
                filterEndDate,
                hasOptIn,
                customersId,
                customerGroupsIds,
                conversationTagsIds,
                conversationGroupsIds,
                agentsIds,
                orCustomersAndGroups,
                lastActivityStartDate,
                lastActivityEndDate
            )
            .then((response) => {
                let formattedCustomers = formatCustomers(response.data.customers);
                setNextPage(response.data.paginator.hasNext);
                setCustomers(formattedCustomers);
                setIsLoading(false);
            });
    };

    const formatCustomers = function (customers) {
        return customers.map((value) => {
            value.createdOn = moment(value.createdOn).format("DD/MM/YY");
            value.lastActivity = moment(value.lastActivity).format("DD/MM/YY HH:mm");
            return value;
        });
    };

    function onClickCreateCustomerGroup() {
        setIsVisibleNewGroupModal(true);
    }

    function handleGroupCreation() {
        // This functionality is duplicated from the modal CustomerGroup.js
        // because it was easier to duplicate it than to synchronize  the state between
        // the modal and this window
        if (!newGroupName) {
            message.error(t("customers.customerGroups.nameNotSet"));
            return;
        }
        const group = {
            name: newGroupName,
            customersId: selectedCustomersIds,
        };
        api.customers()
            .createGroup(group)
            .then((res) => {
                message.success(t("customers.customerGroups.groupCreated"));
                setIsVisibleNewGroupModal(false);
                setNewGroupName(null);
            })
            .catch((err) => {
                message.error(err.response?.data?.error?.message);
            });
    }

    useEffect(() => {
        if (firstLoad) {
            loadItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filterState.customersStartDate,
        filterState.customersEndDate,
        filterState.hasOptIn,
        filterState.customersId,
        filterState.customerGroupsIds,
        filterState.conversationTagsIds,
        filterState.conversationGroupsIds,
        filterState.agentsIds,
        filterState.searchText,
        filterState.lastActivityStartDate,
        filterState.lastActivityEndDate,
        firstLoad,
    ]);

    useEffect(() => {
        if (location?.state?.customersIds) {
            filterDispatch({
                type: ACTIONS.CHANGE_CUSTOMERS_ID,
                payload: {
                    customersId: location.state.customersIds,
                },
            });
        }
        setFirstLoad(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function editCustomer(editedCustomer) {
        try {
            await api.customers().update(editedCustomer);
            const indexOfEditedCustomer = customers.findIndex((c) => c.id === editedCustomer.id);
            const newCustomers = [...customers];
            newCustomers[indexOfEditedCustomer] = editedCustomer;
            setCustomers(newCustomers);
            setEditCustomerModalVisible(false);
            message.success(t("clientForm.customerUpdateSuccessful"));
        } catch (e) {
            message.error(t("clientForm.customerUpdateError"));
        }
    }

    return (
        <div className="opt-view">
            <div className="opt-view-header">
                <h1 className="opt-title">{t("customers.title")}</h1>
                <div className="opt-view-header__actions">
                    <div className="filters">
                        <button className="opt-button opt-button--light">
                            <NavLink to="/dashboard/customers/import">
                                {t("customers.import.title")}
                            </NavLink>
                        </button>
                        <button
                            className="opt-button opt-button--light"
                            onClick={() => setIsGroupsModalVisible(true)}
                        >
                            {" "}
                            {t("customers.groups")}{" "}
                        </button>

                        {userIsAdmin ? (
                            <button
                                className="opt-button"
                                onClick={() => setIsModalVisible(true)}
                                disabled={exportCustomersLoading}
                            >
                                {t("customers.export.title")}
                            </button>
                        ) : (
                            <Tooltip title={t("customers.export.tooltip")}>
                                <div>
                                    <button className="opt-button" disabled>
                                        {t("customers.export.title")}
                                    </button>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
            <Row gutter={[16, 0]}>
                <Col xs={16} md={6}>
                    <CustomerFilters
                        filterDispatch={filterDispatch}
                        setIsLoadingCb={setIsLoading}
                        customersIdsFromCampaign={filterState.customersId || null}
                    />
                    <Button
                        className="opt-button opt-button--light"
                        disabled={selectedCustomersIds.length === 0}
                        onClick={onClickCreateCustomerGroup}
                    >
                        {t("customers.createGroup")}
                    </Button>
                </Col>
                <Col xs={48} md={18}>
                    <div className="opt-card opt-card--table opt-card--no-padding">
                        <Table
                            rowKey={(record) => record.id}
                            columns={columns}
                            dataSource={customers}
                            pagination={false}
                            loading={isLoading}
                        />
                        {nextPage && (
                            <div className="view-more" onClick={loadMoreItems}>
                                <span className="view-more__text">
                                    {" "}
                                    {t("customers.viewMore")}{" "}
                                    <FontAwesomeIcon icon={faChevronDown} />
                                </span>
                            </div>
                        )}
                    </div>
                    <ExportCustomers
                        isModalVisible={isModalVisible}
                        setModalVisible={setIsModalVisible}
                        selectedCustomersId={selectedCustomersIds}
                        filters={filterState}
                        setIsLoading={setExportCustomersLoading}
                    />
                    <Modal
                        visible={isVisibleNewGroupModal}
                        onCancel={(e) => setIsVisibleNewGroupModal(false)}
                        title={t("customers.createGroupModal.title")}
                        okText={t("create")}
                        onOk={handleGroupCreation}
                    >
                        <span>{t("customers.createGroupModal.groupName")}</span>
                        <Input
                            placeholder={t("customers.createGroupModal.namePlaceholder")}
                            value={newGroupName}
                            onChange={(e) => setNewGroupName(e.target.value)}
                        />
                        <span>{t("customers.createGroupModal.customers")}</span> <br />
                        <Select
                            mode="multiple"
                            disabled={true}
                            value={
                                customers
                                    ? customers
                                          .filter((customer) =>
                                              selectedCustomersIds.includes(customer.id)
                                          )
                                          .map((c) => c.fullName)
                                    : null
                            }
                        />
                    </Modal>
                    <CustomerGroups
                        isModalVisible={isGroupsModalVisible}
                        isModalVisibleCb={setIsGroupsModalVisible}
                    />
                    <EditCustomerModal
                        customer={selectedCustomer}
                        visible={editCustomerModalVisible}
                        onCloseCb={() => {
                            setEditCustomerModalVisible(false);
                        }}
                        editCustomerCb={editCustomer}
                    />
                </Col>
            </Row>
        </div>
    );
}
export default Customers;
