import api from "../requests/api";
import ApiError from "./apiError";

class FacebookInstagramClient {
    async connect(facebookAccessToken, currentBots) {
        let response;
        try {
            response = await api.chatbots().facebookConnect(facebookAccessToken);
        } catch (error) {
            throw new ApiError("Error while connecting facebook", error.response.data?.error?.key);
        }

        let connectedBots = this.parseAggregatedFacebookMessengerInstagramBots(
            response.data.aggregatedFacebookMessengerInstagramBot
        );

        let newBots = [...connectedBots];
        currentBots.forEach((currentBot) => {
            const currentBotWasNotConnected = newBots.filter((bot) => {
                return bot.pageId === currentBot.pageId;
            });
            if (currentBotWasNotConnected.length === 0) {
                newBots.push(currentBot);
            }
        });

        return newBots;
    }

    async listBots() {
        let response;
        try {
            response = await api.chatbots().listFacebookMessengerInstagramBots();
        } catch (error) {
            throw new ApiError(
                "Error while listing facebook and ig bots",
                error.response.data?.error?.key
            );
        }

        return this.parseAggregatedFacebookMessengerInstagramBots(
            response.data.aggregatedFacebookMessengerInstagramBot
        );
    }

    async getFacebookConnectionURL(redirectPath) {
        let response;
        try {
            response = await api.featureRequests().getFacebookAuthUrl(true, redirectPath);
        } catch (error) {
            throw new ApiError(
                "Error while fetching facebook connection url",
                error.response.data?.error?.key
            );
        }

        return response.data.url;
    }

    async unsubscribeFacebookPage(pageId, bots) {
        try {
            await api.chatbots().facebookUnsubscribe(pageId);
        } catch (error) {
            throw new ApiError(
                "Error while unsubscribing from facebook page",
                error.response.data?.error?.key
            );
        }
        return this.reloadBotState(bots, pageId, false);
    }

    async subscribeFacebookPage(pageId, bots) {
        try {
            await api.chatbots().facebookSubscribe(pageId);
        } catch (error) {
            throw new ApiError(
                "Error while subscribing to facebook page",
                error.response.data?.error?.key
            );
        }
        return this.reloadBotState(bots, pageId, true);
    }

    reloadBotState(bots, pageId, connected) {
        const foundBot = bots.find((bot) => {
            return bot.pageId === pageId;
        });
        foundBot.connected = connected;
        return bots;
    }

    parseAggregatedFacebookMessengerInstagramBots(bots) {
        return bots.map((bot) => {
            const parsedBot = { ...bot.facebookMessengerBot };
            if (bot?.instagramBot) {
                parsedBot.instagramUsername = bot.instagramBot.instagramUserUsername;
            }
            return parsedBot;
        });
    }
}

export default FacebookInstagramClient;
