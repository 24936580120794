import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import api from "../../../requests/api";
import useChatbots, { ChatbotRepresentation } from "./useChatbots";
import useBotChannels from "./useBotChannels";
import {
    Table,
    Card,
    Space,
    Button,
    message,
    Modal,
    Input,
    Select,
    Switch,
    Popconfirm,
    Tag
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckCircleOutlined, CloseCircleOutlined, MessageOutlined } from "@ant-design/icons";
import { faComments, faSms } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp, faFacebookMessenger, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { ChatChannels } from "../../../frontend-lib/DomainTypes/ChatChannels"
import { useTranslation } from "react-i18next";

function Chatbots() {
    const { t } = useTranslation();
    const [chatbots, setChatbots] = useChatbots();
    const botChannels = useBotChannels();
    const [chatbotNameInput, setChatbotNameInput] = useState<string>("");
    const [chatbotChannels, setChatbotChannels] = useState<ChatChannels[]>();
    const [chatbotIsActive, setChatbotIsActive] = useState<boolean>(false);
    const [isChatbotModalOpen, setIsChatbotModalOpen] = useState<boolean>(false);
    const [editModalId, setEditModalId] = useState<number>();
    const { Option } = Select;

    const columns: ColumnsType<ChatbotRepresentation> = [
        {
            title: t("name"),
            dataIndex: "name",
            key: "name",
            align: "center",
        },
        {
            title: t("channels"),
            dataIndex: "chatChannels",
            key: "channels",
            align: "center",
            render: (chatChannels: string[]) => (
                <Space size="middle">{
                    chatChannels.length > 0? chatChannels
                        .map((chatChannel) => parseChatChannelWithLogo(chatChannel))
                        .map((parsedChatChannelWithLogo) => parsedChatChannelWithLogo[1])
                    : <Tag>{t("chatbots.noChannel")}</Tag>
                    }
                </Space>
            ),
        },
        {
            title: t("chatbots.active"),
            dataIndex: "isActive",
            key: "isActive",
            align: "center",
            render: (isActive: boolean) => (
                <Space size="middle">
                    {isActive ? (
                        <CheckCircleOutlined
                            title={t("chatbots.chatbotIsActive")}
                            style={{ color: "green" }}
                        />
                    ) : (
                        <CloseCircleOutlined
                            title={t("chatbots.chatbotIsInactive")}
                            style={{ color: "red" }}
                        />
                    )}
                </Space>
            ),
        },
        {
            title: t("actions"),
            fixed: "right",
            align: "center",
            render: (_, record: ChatbotRepresentation) => (
                <Space size="middle">
                    <Button onClick={() => handleChatbotEditModal(record)}> {t("edit")} </Button>
                    <NavLink
                        to={{
                            pathname: `/dashboard/skills`,
                            state: record
                        }}
                        className="opt-table__button"
                    >
                        <Button>
                            {t("skills.Skills")}
                        </Button>
                    </NavLink>
                    <Button onClick={() => handleTestButtonClick(record)}>{t("chatbots.test")}</Button>
                    <Popconfirm
                        title={`${t("chatbots.deleteChatbotsConfirm")}`}
                        okText={t("yes")}
                        cancelText={t("no")}
                        onConfirm={() => handleChatbotDelete(record)}
                    >
                        <Button danger> {t("delete")} </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    function parseChatChannelWithLogo(chatChannel: string) {
        let channelName = chatChannel;
        let channelLogo = (
            <MessageOutlined
                style={{ marginLeft: "10px", fontSize: "150%" }}
                className={"opt-card__channel-icon otherChannel"}
                title={chatChannel}
            />
        );
        if (chatChannel === ChatChannels.WHATSAPP) {
            channelName = "Whatsapp";
            channelLogo = (
                <FontAwesomeIcon
                    style={{ marginLeft: "10px", fontSize: "150%" }}
                    className={"opt-card__channel-icon whatsapp"}
                    icon={faWhatsapp}
                    title={channelName}
                />
            );
        } else if (chatChannel === ChatChannels.FACEBOOK_MESSENGER) {
            channelName = "Facebook";
            channelLogo = (
                <FontAwesomeIcon
                    style={{ marginLeft: "10px", fontSize: "150%" }}
                    className={"opt-card__channel-icon facebook"}
                    icon={faFacebookMessenger}
                    title={channelName}
                />
            );
        } else if (chatChannel === ChatChannels.INSTAGRAM) {
            channelName = "Instagram";
            channelLogo = (
                <FontAwesomeIcon
                    style={{ marginLeft: "10px", fontSize: "150%" }}
                    className={"opt-card__channel-icon instagram"}
                    icon={faInstagram}
                    title={channelName}
                />
            );
        } else if (chatChannel === ChatChannels.OPTIWE_CHAT) {
            channelName = "Web Chat";
            channelLogo = (
                <FontAwesomeIcon
                    style={{ marginLeft: "10px", fontSize: "150%" }}
                    className={"opt-card__channel-icon optiweChat"}
                    icon={faComments}
                    title={channelName}
                />
            );
        } else if (chatChannel === ChatChannels.SMS) {
            channelName = "SMS";
            channelLogo = (
                <FontAwesomeIcon
                    style={{ marginLeft: "10px", fontSize: "150%" }}
                    className={"opt-card__channel-icon sms"}
                    icon={faSms}
                    title={channelName}
                />
            );
        } 
        return [channelName, channelLogo];
    }

    function handleCreationModal() {
        setChatbotNameInput("");
        setChatbotChannels([]);
        setChatbotIsActive(false);
        setIsChatbotModalOpen(true);
        setEditModalId(undefined);
    }

    function handleChatbotEditModal(chatbot: ChatbotRepresentation) {
        setChatbotNameInput(chatbot.name);
        setChatbotChannels(chatbot.chatChannels);
        setChatbotIsActive(chatbot.isActive);
        setIsChatbotModalOpen(true);
        setEditModalId(chatbot.id);
    }

    function handleChatbotDelete(chatbot: ChatbotRepresentation) {
        api.chatbots()
            .delete(chatbot.id)
            .then((res) => {
                setChatbots(chatbots.filter((bot) => bot.id !== chatbot.id));
            })
            .catch((err) => {
                message.error(handleError(err.response?.data?.error?.key));
            });
    }

    function handleError(errorKey: string) {
        let errorMessage = t('chatbots.defaultError')
        
        if (errorKey === 'CHATBOT_NAME_IS_REQUIRED') {
            errorMessage = t('chatbots.emptyNameError')
        }
        else if (errorKey === 'CHATBOT_CHANNEL_IS_REQUIRED') {
            errorMessage = t('chatbots.emptyChannelError')
        }
        else if (errorKey === 'INVALID_BOT_CHANNEL') {
            errorMessage = t('chatbots.invalidBotChannel')
        }
        else if (errorKey === 'CHATBOT_ALREADY_EXISTS') {
            errorMessage = t('chatbots.chatbotAlreadyExists')
        }

        return errorMessage
    }

    function onOkChatbotModal() {
        if (!chatbotNameInput) {
            message.error(t("chatbots.emptyNameError"));
            return;
        }
        if (editModalId) {
            api.chatbots()
                .update(editModalId, chatbotNameInput, chatbotIsActive, chatbotChannels)
                .then((res) => {
                    setIsChatbotModalOpen(false);
                    const botIndex = chatbots.findIndex(
                        (bot: ChatbotRepresentation) => bot.id === res.data.id
                    );
                    const chatbotsToUpdate = [...chatbots];
                    chatbotsToUpdate[botIndex] = res.data;
                    setChatbots(chatbotsToUpdate);
                })
                .catch((err) => {
                    message.error(handleError(err.response?.data?.error?.key));
                });
        } else {
            if (!chatbotChannels) {
                message.error(t("chatbots.emptyChannelError"));
                return;
            }
            api.chatbots()
                .create(chatbotNameInput, chatbotIsActive, chatbotChannels)
                .then((res) => {
                    setIsChatbotModalOpen(false);
                    setChatbots([...chatbots, res.data]);
                })
                .catch((err) => {
                    message.error(handleError(err.response?.data?.error?.key));
                });
        }
    }

    function onCancel() {
        setIsChatbotModalOpen(false);
    }

    function onChangeChatbotChannels(chatChannel: ChatChannels[]) {
        setChatbotChannels(chatChannel);
    }

    function onChangeChatbotIsActive(checked: boolean) {
        setChatbotIsActive(checked);
    }

    function handleTestButtonClick(chatbot: ChatbotRepresentation) {
        api.featureRequests()
            .create("WEB_CHAT_URL")
            .then((response) => {
                const url = `${response.data.url}&baseChatbotId=${chatbot.id}`;
                console.log('url', url)
                window.open(url, "_blank");
            })
    }

    return (
        <div className="opt-view opt-view--chatbots">
            <div className="opt-view-header">
                <h1 className="opt-title">{t("chatbots.title")}</h1>
                <div className="opt-view-header__actions">
                    <button className="opt-button" onClick={handleCreationModal}>
                        {t("chatbots.createButton")}
                    </button>
                </div>
            </div>

            <Card bodyStyle={{ padding: 0 }}>
                <Table
                    rowKey={(record) => record?.id}
                    columns={columns}
                    dataSource={chatbots}
                    pagination={false}
                />
            </Card>

            <Modal
                okText={editModalId ? t("update") : t("create")}
                title={
                    editModalId ? (
                        <p style={{ fontSize: "18px", fontWeight: "bold" }}>{t("chatbots.edit")}</p>
                    ) : (
                        <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                            {t("chatbots.create")}
                        </p>
                    )
                }
                visible={isChatbotModalOpen}
                onCancel={onCancel}
                onOk={onOkChatbotModal}
            >
                <Space style={{ width: "100%", display: "flex" }} direction="vertical" size="large">
                    <div>
                        <p style={{ fontSize: "16px" }}>{t("name")}</p>
                        <Input
                            placeholder={t("chatbots.modalNamePlaceholder")}
                            style={{ width: "100%" }}
                            value={chatbotNameInput}
                            onChange={(e) => setChatbotNameInput(e.target.value)}
                        />
                    </div>
                    <div>
                        <p style={{ fontSize: "16px" }}>{t("chatbots.modalChannels")}</p>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            size="large"
                            placeholder={t("chatbots.modalChannelsPlaceholder")}
                            onChange={onChangeChatbotChannels}
                            value={chatbotChannels}
                        >
                            {botChannels &&
                                botChannels.map((item) => (
                                    <Option value={item.channel} style={{ display: "flex" }}>
                                        {parseChatChannelWithLogo(item.channel)[0]}
                                        {parseChatChannelWithLogo(item.channel)[1]}
                                    </Option>
                                ))}
                        </Select>
                    </div>

                    <div style={{ display: "flex", marginTop: "10px" }}>
                        <p style={{ width: "100px", fontSize: "16px" }}>{t("chatbots.active")}</p>
                        <Switch
                            checked={chatbotIsActive}
                            onChange={onChangeChatbotIsActive}
                            style={{ marginTop: "3px" }}
                        />
                    </div>
                </Space>
            </Modal>
        </div>
    );
}

export default Chatbots;
