import React, { useState, useEffect } from "react";
import { Row, Col, Skeleton, Empty, Switch } from "antd";
import OptChart from "../../../../../components/OptChart/OptChart";
import { UISTATES } from "../../configs/Constants";
import moment from "moment";
import {
    InteractionsByHourChartItem,
    interactionsByHourConfig,
    interactionsChartItemTexts,
    interactionsByHourModal,
} from "./charts/InteractionsByHour";
import {
    TotalAmountChatsItem,
    totalAmountTexts,
    totalAmountConfigs,
    totalAmountModal,
} from "./charts/TotalAmountChats";
import {
    ChatDurationsItem,
    chatDurationsTexts,
    chatDurationsConfig,
    chatDurationsModal,
} from "./charts/Durations";
import {
    MeanInteractionsItem,
    meanInteractionsTexts,
    meanInteractionsConfigs,
    meanInteractionsModal,
} from "./charts/MeanInteractions";
import {
    TotalInteractionsItem,
    totalInteractionsChartTexts,
    totalInteractionsConfig,
    totalInteractionsModal,
} from "./charts/TotalInteractions";
import { useTranslation } from "react-i18next";
function ChatReports({ daysData, hoursData, uistate, chartsRange, layout }) {
    const [chatsData, setChatsData] = useState([]);
    const [totalInteractionsChartData, setTotalInteractionsChartData] = useState([]);
    const [durationChartData, setDurationChartsData] = useState([]);
    const [meanChartsData, setMeanChartsData] = useState([]);
    const [interactionsByHourData, setInteractionsByHourData] = useState([]);
    const [chartsFilters, setChartFilters] = useState({ officeHours: true });
    const { t } = useTranslation();
    const changeFilters = (filter, val) => {
        const filters = { ...chartsFilters };
        filters[filter] = val;
        setChartFilters(filters);
        localStorage.setItem("optChartReportFilters", JSON.stringify(filters));
    };
    useEffect(() => {
        const totalAmountChartItems = [];

        const durationItems = [];
        const meanChartItems = [];
        const totalInteractionsChartItems = [];
        const interactionsByHourItems = [];

        chartsRange.forEach((date) => {
            const filtered = daysData.filter((item) => {
                return moment(item.date).utc().format("DD/MM/YYYY") === date;
            });

            // Total chats chart
            const totalAmountGeneralItem = new TotalAmountChatsItem(totalAmountTexts[0].name, date);
            const totalAmountBotItem = new TotalAmountChatsItem(totalAmountTexts[1].name, date);
            const totalAmountAgentItem = new TotalAmountChatsItem(totalAmountTexts[2].name, date);
            const totalAmountNotServedItem = new TotalAmountChatsItem(
                totalAmountTexts[3].name,
                date
            );

            // Duration chart
            const meanChatDurationsTotalItem = new ChatDurationsItem(
                chatDurationsTexts[0].name,
                date
            );
            const aiServiceTimeItem = new ChatDurationsItem(chatDurationsTexts[1].name, date);
            const agentServiceTimeItem = new ChatDurationsItem(chatDurationsTexts[2].name, date);
            const waitingTimeItem = new ChatDurationsItem(chatDurationsTexts[3].name, date);

            // Mean interactions
            const meanChartGeneralItem = new MeanInteractionsItem(
                meanInteractionsTexts[0].name,
                date
            );
            const botMeanAmountItem = new MeanInteractionsItem(meanInteractionsTexts[1].name, date);
            const humanAgentMeantAmountItem = new MeanInteractionsItem(
                meanInteractionsTexts[2].name,
                date
            );
            const userMeanAmountItem = new MeanInteractionsItem(
                meanInteractionsTexts[3].name,
                date
            );
            const userWithBotMeanAmountItem = new MeanInteractionsItem(
                meanInteractionsTexts[4].name,
                date
            );
            const userWitAgentMeanAmountItem = new MeanInteractionsItem(
                meanInteractionsTexts[5].name,
                date
            );

            // Total Interactions
            const totalInteractionsChartGeneralItem = new TotalInteractionsItem(
                totalInteractionsChartTexts[0].name,
                date
            );
            const totalInteractionsBotItem = new TotalInteractionsItem(
                totalInteractionsChartTexts[1].name,
                date
            );
            const totalInteractionsHumanAgenttItem = new TotalInteractionsItem(
                totalInteractionsChartTexts[2].name,
                date
            );
            const totalInteractionsUserItem = new TotalInteractionsItem(
                totalInteractionsChartTexts[3].name,
                date
            );
            const totalInteractionsUserWithBotItem = new TotalInteractionsItem(
                totalInteractionsChartTexts[4].name,
                date
            );
            const totalInteractionsUserWitAgentItem = new TotalInteractionsItem(
                totalInteractionsChartTexts[5].name,
                date
            );

            if (filtered.length > 0) {
                // Base item with date
                const item = JSON.parse(JSON.stringify(filtered[0]));
                const sourceItem = JSON.parse(JSON.stringify(filtered[0]));
                let parsedDate = moment(item.date).utc().format("DD/MM/YYYY");

                item.date = moment(item.date).utc().format("DD/MM/YYYY");

                // Total chats chart
                totalAmountGeneralItem.setValues(parsedDate, sourceItem.amountChats);
                totalAmountBotItem.setValues(parsedDate, sourceItem.amountChatsServedOnlyByAiAgent);
                totalAmountAgentItem.setValues(
                    parsedDate,
                    sourceItem.amountChatsServedByHumanAgent
                );
                totalAmountNotServedItem.setValues(parsedDate, sourceItem.amountChatsNotServed);

                // Duration chart
                meanChatDurationsTotalItem.setValues(parsedDate, sourceItem.meanChatDurationTime);
                aiServiceTimeItem.setValues(parsedDate, sourceItem.meanAiAgentServiceTime);
                agentServiceTimeItem.setValues(parsedDate, sourceItem.meanHumanAgentServiceTime);
                const waitingTimeValue = chartsFilters.officeHours
                    ? sourceItem.meanInOfficeHoursWaitingTime
                    : sourceItem.meanWaitingTime;
                waitingTimeItem.setValues(parsedDate, waitingTimeValue);

                // Mean interactions
                meanChartGeneralItem.setValues(parsedDate, sourceItem.meanAmountOfInteractions);
                botMeanAmountItem.setValues(parsedDate, sourceItem.meanAmountOfAiAgentInteractions);
                humanAgentMeantAmountItem.setValues(
                    parsedDate,
                    sourceItem.meanAmountOfHumanAgentInteractions
                );
                userMeanAmountItem.setValues(parsedDate, sourceItem.meanAmountOfUserInteractions);
                userWithBotMeanAmountItem.setValues(
                    parsedDate,
                    sourceItem.meanAmountOfUserInteractionsWithAiAgent
                );
                userWitAgentMeanAmountItem.setValues(
                    parsedDate,
                    sourceItem.meanAmountOfUserInteractionsWithHumanAgent
                );

                // Total Interactions
                totalInteractionsChartGeneralItem.setValues(
                    parsedDate,
                    sourceItem.totalAmountOfInteractions
                );
                totalInteractionsBotItem.setValues(
                    parsedDate,
                    sourceItem.totalAmountOfAiAgentInteractions
                );
                totalInteractionsHumanAgenttItem.setValues(
                    parsedDate,
                    sourceItem.totalAmountOfHumanAgentInteractions
                );
                totalInteractionsUserItem.setValues(
                    parsedDate,
                    sourceItem.totalAmountOfUserInteractions
                );
                totalInteractionsUserWithBotItem.setValues(
                    parsedDate,
                    sourceItem.totalAmountUserMessagesWithAiAgent
                );
                totalInteractionsUserWitAgentItem.setValues(
                    parsedDate,
                    sourceItem.totalAmountUserMessagesWithHumanAgent
                );
            }
            // Add all charts items parsed values
            totalAmountChartItems.push(
                totalAmountGeneralItem,
                totalAmountBotItem,
                totalAmountAgentItem,
                totalAmountNotServedItem
            );
            durationItems.push(
                meanChatDurationsTotalItem,
                aiServiceTimeItem,
                agentServiceTimeItem,
                waitingTimeItem
            );
            meanChartItems.push(
                meanChartGeneralItem,
                botMeanAmountItem,
                humanAgentMeantAmountItem,
                userMeanAmountItem,
                userWithBotMeanAmountItem,
                userWitAgentMeanAmountItem
            );
            totalInteractionsChartItems.push(
                totalInteractionsChartGeneralItem,
                totalInteractionsBotItem,
                totalInteractionsHumanAgenttItem,
                totalInteractionsUserItem,
                totalInteractionsUserWithBotItem,
                totalInteractionsUserWitAgentItem
            );
        });

        // Get 24hs range
        for (let i = 0; i < 24; i++) {
            // Filtrar data
            const sorted = hoursData.sort((a, b) => (parseInt(a.hour) > parseInt(b.hour) ? 1 : -1));
            const filtered = sorted.filter((item) => {
                return parseInt(item.hour) === i;
            });
            // Crear cada barra del gráfico
            const today = new Date();
            let year = today.getFullYear(),
                month = today.getMonth(),
                day = today.getDay();
            const utcDate = new Date(Date.UTC(year, month, day, i, 0, 0));
            const localDate = utcDate.toLocaleTimeString([], { hour12: false });
            const chartDate = localDate.substring(0, localDate.length - 3);

            let totalAmountAiAgentMessagesItem = new InteractionsByHourChartItem(
                interactionsChartItemTexts[0].name,
                chartDate
            );
            let totalAmountHumanAgentMessagesItem = new InteractionsByHourChartItem(
                interactionsChartItemTexts[1].name,
                chartDate
            );
            let totalAmountUserMessagesItem = new InteractionsByHourChartItem(
                interactionsChartItemTexts[2].name,
                chartDate
            );
            let totalAmountUserMessagesWithAiAgentItem = new InteractionsByHourChartItem(
                interactionsChartItemTexts[3].name,
                chartDate
            );
            let totalAmountUserMessagesWithHumanAgentItem = new InteractionsByHourChartItem(
                interactionsChartItemTexts[4].name,
                chartDate
            );

            if (filtered.length > 0) {
                // Si existe agrego datos de la request
                const sourceItem = JSON.parse(JSON.stringify(filtered[0]));
                totalAmountAiAgentMessagesItem.setAmount(sourceItem.totalAmountAiAgentMessages);
                totalAmountHumanAgentMessagesItem.setAmount(
                    sourceItem.totalAmountHumanAgentMessages
                );
                totalAmountUserMessagesItem.setAmount(sourceItem.totalAmountUserMessages);
                totalAmountUserMessagesWithAiAgentItem.setAmount(
                    sourceItem.totalAmountUserMessagesWithAiAgent
                );
                totalAmountUserMessagesWithHumanAgentItem.setAmount(
                    sourceItem.totalAmountUserMessagesWithHumanAgent
                );
            }
            interactionsByHourItems.push(
                totalAmountUserMessagesWithHumanAgentItem,
                totalAmountUserMessagesWithAiAgentItem,
                totalAmountUserMessagesItem,
                totalAmountHumanAgentMessagesItem,
                totalAmountAiAgentMessagesItem
            );
        }

        // Save to state
        setChatsData(totalAmountChartItems);
        setDurationChartsData(durationItems);
        setMeanChartsData(meanChartItems);
        setTotalInteractionsChartData(totalInteractionsChartItems);

        interactionsByHourItems.sort((a, b) => (a.hour > b.hour ? 1 : -1));

        const sortedInteractionsByHour = [];
        interactionsByHourItems.forEach((item) => {
            if (parseInt(item.hour) > 5) {
                sortedInteractionsByHour.push(item);
            }
        });
        interactionsByHourItems.forEach((item) => {
            if (parseInt(item.hour) <= 5) {
                sortedInteractionsByHour.push(item);
            }
        });

        // Reorder interactions by hour
        setInteractionsByHourData(sortedInteractionsByHour);
    }, [daysData, hoursData, chartsRange, chartsFilters]);

    useEffect(() => {
        const filters = JSON.parse(localStorage.getItem("optChartReportFilters"));
        if (filters) {
            setChartFilters(filters);
        }
    }, []);

    const charts = [
        {
            title: t("chatReports.totalChats"),
            type: "column",
            config: totalAmountConfigs,
            data: chatsData,
            description: t("chatReports.totalChatsDesc"),
            modalContent: totalAmountModal,
            showModal: true,
            id: 1,
        },
        {
            title: t("chatReports.duration"),
            type: "column",
            config: chatDurationsConfig,
            data: durationChartData,
            extraData: (
                <>
                    Office Hours
                    <Switch
                        style={{ marginLeft: "10px" }}
                        checked={chartsFilters.officeHours}
                        onChange={() => changeFilters("officeHours", !chartsFilters.officeHours)}
                    />
                </>
            ),
            modalContent: chatDurationsModal,
            showModal: true,
            id: 2,
        },
        {
            title: t("chatReports.meanInteractions"),
            type: "column",
            config: meanInteractionsConfigs,
            data: meanChartsData,
            description: t("chatReports.meanInteractionsDesc"),
            modalContent: meanInteractionsModal,
            showModal: true,
            id: 3,
        },
        {
            title: t("chatReports.totalInteractions"),
            type: "column",
            config: totalInteractionsConfig,
            data: totalInteractionsChartData,
            description: t("chatReports.totalInteractionsDesc"),
            showModal: true,
            modalContent: totalInteractionsModal,
            id: 4,
        },
        {
            title: t("chatReports.interactionsByHour"),
            type: "column",
            config: interactionsByHourConfig,
            data: interactionsByHourData,
            showModal: true,
            modalContent: interactionsByHourModal,
            id: 5,
        },
    ];
    const showChart = (
        title,
        type,
        config,
        data,
        description,
        extraData = null,
        modalContent = null,
        showModal = null
    ) => {
        if (uistate === UISTATES.LOADING) {
            return <Skeleton paragraph={{ rows: 8 }} />;
        }
        if (uistate === UISTATES.EMPTY) {
            return <Empty />;
        }
        if (uistate === UISTATES.FINISHED) {
            return (
                <OptChart
                    title={title}
                    type={type}
                    config={config}
                    data={data}
                    showModal={showModal}
                    modalContent={modalContent}
                    tooltipText={description}
                    extraData={extraData}
                />
            );
        }
    };
    return (
        <>
            <Row gutter={[50, 50]}>
                {charts.map((chart) => (
                    <Col span={layout === "grid" ? 12 : 24} key={chart.id}>
                        {showChart(
                            chart.title,
                            chart.type,
                            chart.config,
                            chart.data,
                            chart.description,
                            chart.extraData,
                            chart.modalContent,
                            chart.showModal
                        )}
                    </Col>
                ))}
            </Row>
        </>
    );
}
export default ChatReports;
