import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import "./KnowledgeBox.scss";
function KnowledgeBox(props) {
    return (
        <div className={`knowledge-box`}>
            <div className="opt-app__recomendation-header">
                <p className="opt-app__title">{props.title}</p>
                <div className="opt-app__recomendation-actions">
                    <FontAwesomeIcon icon={faEdit} onClick={props.editCb} />
                    <FontAwesomeIcon
                        icon={faTrash}
                        className="delete-icon"
                        onClick={props.deleteCb}
                    />
                </div>
            </div>
            <div className="opt-app__recomendation-content">
                <p className="opt-app__text">{props.text}</p>
            </div>
            <div className="opt-app__recomendation-id">
                <p className="opt-app__text">ID: {props.knowledgeId}</p>
            </div>
        </div>
    );
}
export default KnowledgeBox;
